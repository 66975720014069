import React from "react";
import Modal from 'react-modal';


export default function UpdatePlayerDetailsModal(props) {

  let { updatePointTableModal, handleCloseUpdatePointTableModal, fieldsPointTableUpdate, errorsPointTableUpdate, inputChangePointTableUpdate, handleFile, pointTableUpdateSubmit } = props;

  return (

    <Modal
      isOpen={updatePointTableModal}
    >


      <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
        <div className="bg-transparent outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

          <div className="modal-content text-left p-10 space-y-8">
            <div className="flex justify-between items-center pb-3">
              <p className="text-black text-2xl font-bold whitespace-nowrap">Update Player Details</p><br />
              <div className="modal-close cursor-pointer z-50">
                <svg
                  onClick={() => handleCloseUpdatePointTableModal()}

                  className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>

            <form autoComplete="off" className='space-y-5'>

            

              <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  <label className="text-black text-base font-medium ">Name : </label> &nbsp;
                  <input type="text" name="name"
                    value={fieldsPointTableUpdate && fieldsPointTableUpdate["name"] ? fieldsPointTableUpdate["name"] : ""}
                    onChange={inputChangePointTableUpdate}
                  ></input>
                </div>
              </div>

              <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  <label className="text-black text-base font-medium ">Type : </label> &nbsp;
                  <input type="text" name="type"
                    value={fieldsPointTableUpdate && fieldsPointTableUpdate["type"] ? fieldsPointTableUpdate["type"] : ""}
                    onChange={inputChangePointTableUpdate}
                  ></input>
                </div>
              </div>

              

              <div className="w-64 sm:w-72 mx-auto">
                <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={pointTableUpdateSubmit}
                >
                  Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>


    </Modal>
  );
}
