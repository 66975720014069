import React from "react";
import { withRouter } from "react-router-dom";
// import { FaUsers } from "react-icons/ri";
import { IoGridSharp, } from "react-icons/io5";
// import { HiChevronLeft, HiChevronDown } from "react-icons/hi";
import { IoLockClosed } from "react-icons/io5";
// import { BiRupee } from "react-icons/bi";
import { connect } from 'react-redux';
// import { BsTranslate, BsLifePreserver } from "react-icons/bs";
// import { MdOutlineArrowDropDown } from 'react-icons/md';
// import { SiBookstack } from 'react-icons/si';
// import { FaUsers } from 'react-icons/fa';
// import Popup from "../Profile/Profile";
// import { useState } from "react";
import { isMobile } from "react-device-detect";





function Sidebar({ location, history, user }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  // const [userType, NavbarOpen] = React.useState(0);
  const [userNav, setUserNav] = React.useState(false);
  const [sport, setSport] = React.useState(false);
  const [ledger, setLedger] = React.useState(false);
  const [manageClient, setManageClient] = React.useState(false);
  const [buttonPopup, setButtonPopup] = React.useState(false);

  const handleClose = () => setButtonPopup(false);

  const onClickMenu = (url) => {
    history.push(url);
    setNavbarOpen(!navbarOpen)
  }

  const navigate = (url, userType) => {
    history.push(url + userType);
    setNavbarOpen(!navbarOpen)
  }
  // const [show, currentCount] = useState(false);

  return (



    <>
      {
        isMobile ?

          <>

            <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} fixed left-0 inset-y-0 lg:static  lg:left-auto lg:inset-auto lg:flex w-56 z-50`}>

              <div className="relative flex flex-col w-56 ">

                <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto border-r border-gray-100 border-opacity-20 ">
                  <nav style={{ backgroundColor: "#2f4050" }} className="flex-1 space-y-2">

                    <span onClick={() => onClickMenu('/app/mainpage')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/mainpage" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-3 ">
                        <IoGridSharp size={13} />
                      </div>
                      <span className="text-sm font-semibold">Main Page</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/activeMatch')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/activeMatch" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Active Match</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/activeMatch')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/activeMatch" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Player</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/teams')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/teams" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Teams</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/teamranktype')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/teamranktype" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Team Rank Type</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/teamRanking')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/teamRanking" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Team Ranking</span>
                    </span>



                    <span onClick={() => onClickMenu('/app/match')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/match" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Match</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/series')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/series" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Series</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/playerranktype')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/playerranktype" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Player Rank Type</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/playerranking')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/playerranking" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Player Ranking</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/pointtable')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/pointtable" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Point Table</span>
                    </span>

                  </nav>
                </div>
              </div>

            </div >


          </>

          :

          <>
            {/* Main Navbar */}
            <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} fixed left-0 inset-y-0 lg:static  lg:left-auto lg:inset-auto lg:flex w-56 z-50`}>

              <div className="relative flex flex-col w-56 ">

                <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto border-r border-gray-100 border-opacity-20 ">
                  <nav style={{ backgroundColor: "#2f4050" }} className="flex-1 space-y-2">

                    <span onClick={() => onClickMenu('/app/mainpage')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/mainpage" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-3 ">
                        <IoGridSharp size={13} />
                      </div>
                      <span className="text-sm font-semibold">Main Page</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/activeMatch')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/activeMatch" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Active Match</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/player')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/player" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Player</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/playerranktype')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/playerranktype" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Player Rank Type</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/playerranking')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/playerranking" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Player Ranking</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/teams')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/teams" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Teams</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/teamrankingtype')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/teamrankingtype" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Team Rank Type</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/teamRanking')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/teamRanking" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Team Ranking</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/match')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/match" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Match</span>
                    </span>

                    <span onClick={() => onClickMenu('/app/series')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/series" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Series</span>
                    </span>

                    {/* <span onClick={() => onClickMenu('/app/pointtable')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/pointtable" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Point Table</span>
                    </span> */}

                    <span onClick={() => onClickMenu('/app/news')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/news" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">News</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/category')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/category" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Category</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/Venue')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/Venue" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Venues</span>
                    </span>
                    <span onClick={() => onClickMenu('/app/image')} className={`hover:bg-gray-800 cursor-pointer h-12 items-center flex space-x-2 px-1 py-2 text-sm font-semibold text-white hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/image" ? "text-white bg-gray-800 border-l-4 border-green-700" : ""}`}>
                      <div className="ml-2.5">
                        <IoLockClosed />
                      </div>
                      <span className="text-sm font-semibold">Image</span>
                    </span>

                  </nav>
                </div>
              </div>

            </div >
          </>

      }
    </>


  );

}

function mapStateToProps(state) {
  const { users } = state;
  const { user } = state.authentication;
  // console.log("mapStateToProps___________user:::", user);
  return {
    users,
    user
  };
}
export default withRouter(connect(mapStateToProps)(Sidebar));
// export default withRouter(Sidebar);
