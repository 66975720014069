/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { teamRankingTypeActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdateTeamRankingTypeDetailsModal from "./components/UpdateTeamRankingTypeDetailsModal/UpdateTeamRankingTypeDetailsModal";
import AddTeamRankingTypeDetailsModal from "./components/AddTeamRankingTypeDetailsModal/AddTeamRankingTypeDetailsModal";

class TeamRankType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},
      keyWord: '',
      pageNo: 1,
      size: 10,
      addTeamRankingTypeModal: false,
      fieldsteamRankingTypeAdd: {},
      errorsTeamRankingTypeAdd: {},
      updateTeamRankingTypeModal: false,
      fieldsteamRankingTypeUpdate: {},
      errorsTeamRankingTypeUpdate: {},
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {


    if (nextProps.teamRankingType.addteamRankingTypeuccess) {

      return {
        ...nextProps,
        addTeamRankingTypeModal: false,
        fieldsteamRankingTypeAdd: {},
        errorsTeamRankingTypeAdd: {},
        updateTeamRankingTypeModal: false,
        fieldsteamRankingTypeUpdate: {},
        errorsTeamRankingTypeUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teamRankingTypeActions.getTeamRankingTypeList(data));
  }


  handleOpenToAddTeamRankingTypeModal = () => {
    this.setState({ addTeamRankingTypeModal: true });
  }
  handleCloseAddTeamRankingTypeModal = () => {
    // this.setState({ addteamRankingTypeSuccess: false });
    this.setState({ addTeamRankingTypeModal: false });
  }

  inputChangeTeamRankingTypeAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsteamRankingTypeAdd = this.state.fieldsteamRankingTypeAdd;
    let errorsTeamRankingTypeAdd = this.state.errorsTeamRankingTypeAdd;
    fieldsteamRankingTypeAdd[name] = value;
    errorsTeamRankingTypeAdd[name] = "";
    this.setState({ fieldsteamRankingTypeAdd, errorsTeamRankingTypeAdd });
  }

  teamRankingTypeAddSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationTeamRankingTypeAdd()) {
      let resData = {
        "type": this.state.fieldsteamRankingTypeAdd.type,
        "name": this.state.fieldsteamRankingTypeAdd.name,
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(teamRankingTypeActions.createTeamRankingType(resData, data));
      this.setState({ fieldsteamRankingTypeAdd: {} })
      this.handleCloseAddTeamRankingTypeModal()
    }
  }

  handleValidationTeamRankingTypeAdd = () => {
    const { fieldsteamRankingTypeAdd } = this.state;
    const fieldNames = ["type", "name"];
    const errorsTeamRankingTypeAdd = {};

    let formIsValid = true;

    fieldNames.forEach(fieldName => {
      if (!fieldsteamRankingTypeAdd[fieldName] || fieldsteamRankingTypeAdd[fieldName] === "") {
        formIsValid = false;
        errorsTeamRankingTypeAdd[fieldName] = `${fieldName} Cannot be empty`;
      }
    });

    this.setState({ errorsTeamRankingTypeAdd }); // Corrected line

    return formIsValid;
  }


  handleOpenToUpdateTeamRankingTypeModal = (data) => {
    this.setState({ updateTeamRankingTypeModal: true, fieldsteamRankingTypeUpdate: data });
  }

  handleCloseUpdateTeamRankingTypeModal = () => {
    // this.setState({ addteamRankingTypeSuccess: false });
    this.setState({ updateTeamRankingTypeModal: false });
  }

  inputChangeTeamRankingTypeUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsteamRankingTypeUpdate = this.state.fieldsteamRankingTypeUpdate;
    let errorsTeamRankingTypeUpdate = this.state.errorsTeamRankingTypeUpdate;
    fieldsteamRankingTypeUpdate[name] = value;
    errorsTeamRankingTypeUpdate[name] = "";
    this.setState({ fieldsteamRankingTypeUpdate, errorsTeamRankingTypeUpdate });
  }

  teamRankingTypeUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationTeamRankingTypeUpdate()) {
      // let { teamRankingType } = this.props
      // let { fieldsteamRankingTypeUpdate } = teamRankingType
      let resData = {
        "id" : this.state.fieldsteamRankingTypeUpdate.id,
        "type": this.state.fieldsteamRankingTypeUpdate.type,
        "name": this.state.fieldsteamRankingTypeUpdate.name,
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(teamRankingTypeActions.updateTeamRankingType(resData, data));
    }
  }

  handleValidationTeamRankingTypeUpdate = () => {
    const { fieldsteamRankingTypeUpdate } = this.state;
    const fieldNames = ["type", "name"];
    const errorsTeamRankingTypeUpdate = {};

    let formIsValid = true;

    fieldNames.forEach(fieldName => {
      if (!fieldsteamRankingTypeUpdate[fieldName] || fieldsteamRankingTypeUpdate[fieldName] === "") {
        formIsValid = false;
        errorsTeamRankingTypeUpdate[fieldName] = `${fieldName} Cannot be empty`;
      }
    });

    this.setState({ errorsTeamRankingTypeUpdate }, () => {
      // Callback after state is updated
      console.log("Validation errors updated:", this.state.errorsTeamRankingTypeUpdate);
    });

    return formIsValid;
  }



  updateTeamRankingTypeStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.image} TeamRankingType ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teamRankingTypeActions.disableTeamRankingType(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenToDeleteTeamRankingTypeModal = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.image} TeamRankingType ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teamRankingTypeActions.deleteTeamRankingType(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  render() {

    let { teamRankingType } = this.props;
    let { getTeamRankingTypeList } = teamRankingType;
    return (

      <>

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Team Ranking Type</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Team Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddTeamRankingTypeModal()}>ADD</button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Team Ranking Type</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getTeamRankingTypeList && getTeamRankingTypeList.length > 0 ?
                                getTeamRankingTypeList.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.type ? element.type : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdateTeamRankingTypeModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updateTeamRankingTypeStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToDeleteTeamRankingTypeModal(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <AddTeamRankingTypeDetailsModal
                    addTeamRankingTypeModal={this.state.addTeamRankingTypeModal}
                    handleCloseAddTeamRankingTypeModal={this.handleCloseAddTeamRankingTypeModal}
                    inputChangeTeamRankingTypeAdd={this.inputChangeTeamRankingTypeAdd}
                    fieldsteamRankingTypeAdd={this.state.fieldsteamRankingTypeAdd}
                    errorsTeamRankingTypeAdd={this.state.errorsTeamRankingTypeAdd}
                    handleFile={this.handleFile}
                    teamRankingTypeAddSubmit={this.teamRankingTypeAddSubmit}
                  />
                  <UpdateTeamRankingTypeDetailsModal
                    updateTeamRankingTypeModal={this.state.updateTeamRankingTypeModal}
                    handleCloseUpdateTeamRankingTypeModal={this.handleCloseUpdateTeamRankingTypeModal}
                    inputChangeTeamRankingTypeUpdate={this.inputChangeTeamRankingTypeUpdate}
                    fieldsteamRankingTypeUpdate={this.state.fieldsteamRankingTypeUpdate}
                    errorsTeamRankingTypeUpdate={this.state.errorsTeamRankingTypeUpdate}
                    handleFile={this.handleFile}
                    teamRankingTypeUpdateSubmit={this.teamRankingTypeUpdateSubmit}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { teamRankingType } = state;

  console.log('teamRankingType:::::mapStateToProps:', teamRankingType);

  return {
    teamRankingType,
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(TeamRankType);