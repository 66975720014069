import React from "react";
import Modal from 'react-modal';
import Select2 from 'react-select';


export default function AddPlayerDetailsModal(props) {

  let { addPlayerModal, handleCloseAddPlayerModal, fieldsplayerAdd, allRankType, getAllPlayer, errorsPlayerAdd, inputChangePlayerType, inputChangePlayerAdd, handleFile, playerAddSubmit, inputChangePlayerAdd01 } = props;
  let rankType = allRankType && allRankType.length ? allRankType.map((element) => ({ value: element.id, label: element.name })) : [];
  let allPlayer = getAllPlayer && getAllPlayer.length ? getAllPlayer.map((element) => ({ value: element.id, label: element.name })) : [];
  return (

    < >


      <div className={addPlayerModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">



          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Add Player Rank</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddPlayerModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>


          <div className="p-4">
            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">


              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">

                {/* <div class=" w-full">
                  <label className="text-black text-base font-medium">Player Type :</label>
                  <select class="w-full mt-1 px-2 py-[11px] bg-white text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500" aria-label="Default select example" id="typeId"
                    name="playerRankingTypeId"
                    value={fieldsplayerAdd && fieldsplayerAdd["playerRankingTypeId"] ? fieldsplayerAdd["playerRankingTypeId"] : ""}
                    onChange={inputChangePlayerAdd}
                  >
                    <option selected>Plz Select Type</option>
                    {
                      allRankType && allRankType && allRankType.length > 0 ?
                        allRankType.map((element, index) => (
                          <option key={index} name={element && element.name ? element.name : null} value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div> */}

                {/* {JSON.stringify(fieldsplayerAdd)} */}

                <div class=" w-full">
                  <label className="text-black text-base font-medium">Player Type :</label>
                  <Select2
                    width="full"
                    name="playerRankingTypeId"
                    id="playerRankingTypeId"
                    onChange={inputChangePlayerAdd}
                    // value={rankType.filter(function (option) {
                    //   return option.value === inputChangePlayerAdd.playerRankingTypeId;
                    // })}
                    options={rankType}
                    className="basic-multi-select width: 'full'"
                    classNamePrefix="Activities"
                  />
                </div>

                {/* <div class=" w-full">
                  <label className="text-black text-base font-medium">Player :</label>
                  <select class="w-full mt-1 px-2 py-[11px] bg-white text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500" aria-label="Default select example" id="name"
                    name="playerId"
                    value={fieldsplayerAdd && fieldsplayerAdd["playerId"] ? fieldsplayerAdd["playerId"] : ""}
                    onChange={inputChangePlayerAdd}
                  >
                    <option selected>Plz Select Player</option>
                    {
                      getAllPlayer && getAllPlayer && getAllPlayer.length > 0 ?
                        getAllPlayer.map((elemente, index) => (
                          <option key={index} name={elemente && elemente.name ? elemente.name : null} value={elemente && elemente.id ? elemente.id : null}>{elemente && elemente.name ? elemente.name : "NA"}</option>
                        )) : null
                    }
                    isSearchable
                  </select>
                </div> */}
                <div class=" w-full">
                  <label className="text-black text-base font-medium">Player :</label>
                  <Select2
                    width="full"
                    name="playerId"
                    id="playerId"
                    onChange={inputChangePlayerAdd}
                    // value={allPlayer.filter(function (option) {
                    //   return option.value === inputChangePlayerAdd.playerId;
                    // })}
                    options={allPlayer}
                    className="basic-multi-select width: 'full'"
                    classNamePrefix="Activities"
                  />
                </div>



                <div class="w-full">
                  <label className="text-black text-base font-medium ">Ranking Type Slug : </label>
                  <input type="text" name="playerRankingTypeSlug" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsplayerAdd && fieldsplayerAdd["playerRankingTypeSlug"] ? fieldsplayerAdd["playerRankingTypeSlug"] : ""}
                    onChange={inputChangePlayerAdd01}
                  ></input>
                </div>



                <div class="w-full">
                  <label className="text-black text-base font-medium ">Rank : </label>
                  <input type="text" name="rank" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsplayerAdd && fieldsplayerAdd["rank"] ? fieldsplayerAdd["rank"] : ""}
                    onChange={inputChangePlayerAdd01}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-base font-medium ">Country : </label>
                  <input type="text" name="country" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsplayerAdd && fieldsplayerAdd["country"] ? fieldsplayerAdd["country"] : ""}
                    onChange={inputChangePlayerAdd01}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-base font-medium ">Rating : </label>
                  <input type="text" name="rating" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsplayerAdd && fieldsplayerAdd["rating"] ? fieldsplayerAdd["rating"] : ""}
                    onChange={inputChangePlayerAdd01}
                  ></input>
                </div>
              </div>



              <div className=" mx-auto">
                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={playerAddSubmit}
                >
                  Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>



    </>
  );
}
