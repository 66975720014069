import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerActions, teamsActions, matchMoreDetailActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';

class MatchMoreDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      dropdown: false,
      dropdown2: false,
      teamB: false,
      teamB2: false,
      inning1: false,
      inning2: false,
      matchStatus: '',
      match_status_error: '',
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sectiontype: '',
      sportAndSeriesId: {},
      rowDetails: {},

      fieldsMatchInfo: {},
      errorsMatchInfo: {},

      keyWord: '',
      pageNo: 1,
      size: 10,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.matchMoreDetail.updateUserSuccess) {

      return {
        ...nextProps,
        fieldsMatchInfo: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData ? nextProps.matchMoreDetail.matchInfoData : null,
        errorsMatchInfo: {},
      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {
    // let data = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    this.props.dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: this.props.match.params._id }));
  }


  inputChangeMatchInfo = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsMatchInfo = this.state.fieldsMatchInfo;
    let errorsMatchInfo = this.state.errorsMatchInfo;
    fieldsMatchInfo[name] = value;
    errorsMatchInfo[name] = "";
    console.log(name, value);
    this.setState({ fieldsMatchInfo, errorsMatchInfo });
  }

  updateMatchInfo = () => {
    if (this.handleValidationMatchInfo()) {
      let reqData = {
        "matchId": this.state.fieldsMatchInfo.matchId,
        "match_id": this.state.fieldsMatchInfo.matchId,
        "marketId": this.state.fieldsMatchInfo.marketId,
        "tv_id": this.state.fieldsMatchInfo.tv_id,
        "series": this.state.fieldsMatchInfo.series,
        "matchs": this.state.fieldsMatchInfo.matchs,
        "match_time": this.state.fieldsMatchInfo.match_time,
        "venue": this.state.fieldsMatchInfo.venue,
        "toss": this.state.fieldsMatchInfo.toss,
        "umpire": this.state.fieldsMatchInfo.umpire,
        "third_umpire": this.state.fieldsMatchInfo.third_umpire,
        "referee": this.state.fieldsMatchInfo.referee,
        "man_of_match": this.state.fieldsMatchInfo.man_of_match,
        "match_type": this.state.fieldsMatchInfo.match_type,
        "team_a_id": this.state.fieldsMatchInfo.team_a_id,
        "team_a": this.state.fieldsMatchInfo.team_a,
        "team_a_short": this.state.fieldsMatchInfo.team_a_short,
        "team_a_img": this.state.fieldsMatchInfo.team_a_img,
        "team_b_id": this.state.fieldsMatchInfo.team_b_id,
        "team_b": this.state.fieldsMatchInfo.team_b,
        "team_b_short": this.state.fieldsMatchInfo.team_b_short,
        "team_b_img": this.state.fieldsMatchInfo.team_b_img

      }

      console.log('reqDatareqDatareqDatareqDatareqDatareqData??????', reqData);

      this.props.dispatch(matchMoreDetailActions.updateMatchInfo(reqData, this.props.match.params.matchId));
    }
  }

  handleSelectMatchStatus = (e) => {
    e.preventDefault();
    // console.log('handleSelectMatchStatushandleSelectMatchStatus', e.target.value);
    this.setState({ matchStatus: e.target.value });
  }

  updateMatch_Status = () => {
    if (this.handleValidationMatch_Status()) {
      let reqData = {
        "matchId": this.props.match.params.matchId,
        "matchStatus": this.state.matchStatus
      }

      console.log('reqDatareqDatareqDatareqDatareqDatareqData??????', reqData);

      this.props.dispatch(matchMoreDetailActions.updateMatch_Status(reqData));
    }
  }

  handleValidationMatch_Status = () => {
    let matchStatus = this.state.matchStatus;
    let match_status_error = "";
    let formIsValid = true;

    //matchStatus
    if (matchStatus === "") {
      formIsValid = false;
      match_status_error = "Cannot be empty ";
    }

    console.log('errorsMatchInfoerrorsMatchInfoerrorsMatchInfo', match_status_error);

    this.setState({ match_status_error: match_status_error });
    return formIsValid;
  }

  handleValidationMatchInfo = () => {
    let fieldsMatchInfo = this.state.fieldsMatchInfo;
    let errorsMatchInfo = {};
    let formIsValid = true;

    //tv_id
    if (!fieldsMatchInfo["tv_id"] || fieldsMatchInfo["tv_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["tv_id"] = "Cannot be empty tv_id";
    }
    //marketId
    if (!fieldsMatchInfo["marketId"] || fieldsMatchInfo["marketId"] === "") {
      formIsValid = false;
      errorsMatchInfo["marketId"] = "Cannot be empty marketId";
    }
    //series
    if (!fieldsMatchInfo["series"] || fieldsMatchInfo["series"] === "") {
      formIsValid = false;
      errorsMatchInfo["series"] = "Cannot be empty series";
    }
    //matchs
    if (!fieldsMatchInfo["matchs"] || fieldsMatchInfo["matchs"] === "") {
      formIsValid = false;
      errorsMatchInfo["matchs"] = "Cannot be empty matchs";
    }
    //match_time
    if (!fieldsMatchInfo["match_time"] || fieldsMatchInfo["match_time"] === "") {
      formIsValid = false;
      errorsMatchInfo["match_time"] = "Cannot be empty match_time";
    }
    //venue
    if (!fieldsMatchInfo["venue"] || fieldsMatchInfo["venue"] === "") {
      formIsValid = false;
      errorsMatchInfo["venue"] = "Cannot be empty venue";
    }
    //toss
    if (!fieldsMatchInfo["toss"] || fieldsMatchInfo["toss"] === "") {
      formIsValid = false;
      errorsMatchInfo["toss"] = "Cannot be empty toss";
    }
    //umpire
    if (!fieldsMatchInfo["umpire"] || fieldsMatchInfo["umpire"] === "") {
      formIsValid = false;
      errorsMatchInfo["umpire"] = "Cannot be empty umpire";
    }
    //third_umpire
    if (!fieldsMatchInfo["third_umpire"] || fieldsMatchInfo["third_umpire"] === "") {
      formIsValid = false;
      errorsMatchInfo["third_umpire"] = "Cannot be empty third_umpire";
    }
    //referee
    if (!fieldsMatchInfo["referee"] || fieldsMatchInfo["referee"] === "") {
      formIsValid = false;
      errorsMatchInfo["referee"] = "Cannot be empty referee";
    }
    //man_of_match
    if (!fieldsMatchInfo["man_of_match"] || fieldsMatchInfo["man_of_match"] === "") {
      formIsValid = false;
      errorsMatchInfo["man_of_match"] = "Cannot be empty man_of_match";
    }
    //match_type
    if (!fieldsMatchInfo["match_type"] || fieldsMatchInfo["match_type"] === "") {
      formIsValid = false;
      errorsMatchInfo["match_type"] = "Cannot be empty match_type";
    }
    //team_a_id
    if (!fieldsMatchInfo["team_a_id"] || fieldsMatchInfo["team_a_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_id"] = "Cannot be empty team_a_id";
    }
    //team_a
    if (!fieldsMatchInfo["team_a"] || fieldsMatchInfo["team_a"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a"] = "Cannot be empty team_a";
    }
    //team_a_short
    if (!fieldsMatchInfo["team_a_short"] || fieldsMatchInfo["team_a_short"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_short"] = "Cannot be empty team_a_short";
    }
    //team_a_img
    if (!fieldsMatchInfo["team_a_img"] || fieldsMatchInfo["team_a_img"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_img"] = "Cannot be empty team_a_img";
    }
    //team_b_id
    if (!fieldsMatchInfo["team_b_id"] || fieldsMatchInfo["team_b_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_id"] = "Cannot be empty team_b_id";
    }
    //team_b
    if (!fieldsMatchInfo["team_b"] || fieldsMatchInfo["team_b"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b"] = "Cannot be empty team_b";
    }
    //team_b_short
    if (!fieldsMatchInfo["team_b_short"] || fieldsMatchInfo["team_b_short"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_short"] = "Cannot be empty team_b_short";
    }
    //team_b_img
    if (!fieldsMatchInfo["team_b_img"] || fieldsMatchInfo["team_b_img"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_img"] = "Cannot be empty team_b_img";
    }


    console.log('errorsMatchInfoerrorsMatchInfoerrorsMatchInfo', errorsMatchInfo);

    this.setState({ errorsMatchInfo: errorsMatchInfo });
    return formIsValid;
  }
  handleSectionType = (data) => {
    this.setState({ sectiontype: data })
  }


  handledropdown = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };
  handledropdown2 = () => {
    this.setState({ dropdown2: !this.state.dropdown2 });
  };
  handleTeamB = () => {
    this.setState({ teamB: !this.state.teamB });
  };
  handleTeamB2 = () => {
    this.setState({ teamB2: !this.state.teamB2 });
  };
  handleFirstInning = () => {
    this.setState({ inning1: !this.state.inning1 });
  };
  handleSecondInning = () => {
    this.setState({ inning2: !this.state.inning2 });
  };

  handleUpdateTeamABatsmanScore = () => {
    let reqData = {
      "matchId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.id,
      "innings": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.currentInning,
      "teamId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.teamA && this.state.matchMoreDetail.matchInfoData.teamA.id
    }
    console.log('reqDatareqDatareqDatareqDatareqData', reqData);
    this.props.history.push(`/app/updatebatsmanscore/${reqData.innings}/${reqData.matchId}/${reqData.teamId}`)
  }
  handleUpdateTeamBBowlerScore = () => {
    let reqData = {
      "matchId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.id,
      "innings": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.currentInning,
      "teamId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.teamB && this.state.matchMoreDetail.matchInfoData.teamB.id
    }
    this.props.history.push(`/app/updatebolwerscore/${reqData.innings}/${reqData.matchId}/${reqData.teamId}`)
  }

  handleUpdateTeamBBatsmanScore = () => {
    let reqData = {
      "matchId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.id,
      "innings": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.currentInning,
      "teamId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.teamB && this.state.matchMoreDetail.matchInfoData.teamB.id
    }
    console.log('reqDatareqDatareqDatareqDatareqData', reqData);
    this.props.history.push(`/app/updatebatsmanscore/${reqData.innings}/${reqData.matchId}/${reqData.teamId}`)
  }
  handleUpdateTeamABowlerScore = () => {
    let reqData = {
      "matchId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.id,
      "innings": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.currentInning,
      "teamId": this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.teamA && this.state.matchMoreDetail.matchInfoData.teamA.id
    }
    this.props.history.push(`/app/updatebolwerscore/${reqData.innings}/${reqData.matchId}/${reqData.teamId}`)
  }
  handleUpdateCommentry = () => {
    this.props.history.push(`/app/updatecommentry`)
  }
  handleUpdateBallByBall = () => {
    this.props.history.push(`/app/updateballbyball`)
  }
  handleUpdateExtraRun = () => {
    this.props.history.push(`/app/updateextrarun`)
  }
  handleUpdateFallOfWicket = () => {
    this.props.history.push(`/app/updatefallofwicket`)
  }
  handleUpdateMatch = () => {
    this.props.history.push(`/app/updatematch`)
  }
  handleUpdatePartnership = () => {
    this.props.history.push(`/app/updatepartnership`)
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  render() {

    let { matchMoreDetail } = this.props;
    let { matchInfoData } = matchMoreDetail;
    // console.log("RENDER____allMatch_allMatch_allMatch::::", matchInfoData);

    // console.log("RENDER____this.state.fieldsMatchInfo::", this.state.fieldsMatchInfo);
    console.log("RENDER____this.state.matchStatus::", this.props.match.params);
    console.log('RENDER_________________________________________', this.state.matchMoreDetail && this.state.matchMoreDetail && this.state.matchMoreDetail.matchInfoData && this.state.matchMoreDetail.matchInfoData.teamA && this.state.matchMoreDetail.matchInfoData.teamA.id);


    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 flex  justify-between items-centre border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Match More Detail</h2>
              <div className='w-xl space-x-3 flex'>
                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onClick={this.handleSelectMatchStatus}
                >
                  <option selected>Update Match Status</option>
                  <option value="Live">Live</option>
                  <option value="Upcoming">Upcoming</option>
                  <option value="Recent">Recent</option>
                </select>
                <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.updateMatch_Status()}>ADD</button>
              </div>
            </div>

            <div className='p-6'>
              <div className='bg-white pb-10'>
                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className='flex justify-between items-center space-y-3'>
                    <div>
                      <button className='border solid black whitespace-nowrap p-2 text-l font-semibold' onClick={() => this.handleFirstInning()} > 1st Inning</button>
                      <>{this.state.inning1 === true ?
                        <>
                          <div>
                            <button className='border solid black whitespace-nowrap p-2 text-l font-semibold' onClick={() => this.handledropdown()}>Team A India</button>
                            <>  {this.state.dropdown === true ?

                              <div className='space-x-1'>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateTeamABatsmanScore()}>Batsman</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateTeamBBowlerScore()}>Bowler</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBallByBall()}>Ball by Ball</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateFallOfWicket()}>Fall of Wicket</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateCommentry()}>Commentary</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateExtraRun()}>Extra</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdatePartnership()}>Partnership</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateMatch()}>Match</button>
                              </div>
                              : null}
                            </>
                          </div>

                          <div>
                            <button className='border solid black whitespace-nowrap p-2 text-l font-semibold' onClick={() => this.handleTeamB()}>Team B Australia</button>
                            <>  {this.state.teamB === true ?

                              <div className='space-x-1'>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateTeamBBatsmanScore()}>Batsman</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateTeamABowlerScore()}>Bowler</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBallByBall()}>Ball by Ball</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateFallOfWicket()}>Fall of Wicket</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateCommentry()}>Commentary</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateExtraRun()}>Extra</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdatePartnership()}>Partnership</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateMatch()}>Match</button>
                              </div>
                              : null}
                            </>
                          </div>

                        </>

                        : null}

                      </>
                    </div>
                    <div>
                      <button className='border solid black whitespace-nowrap p-2 text-l font-semibold' onClick={() => this.handleSecondInning()} > 2nd Inning</button>
                      <>{this.state.inning2 === true ?
                        <>
                          <div>
                            <button className='border solid black whitespace-nowrap p-2 text-l font-semibold' onClick={() => this.handledropdown2()}>Team A India</button>
                            <>  {this.state.dropdown2 === true ?

                              <div className='space-x-1'>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBatsmanScore()}>Batsman</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBowlerScore()}>Bowler</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBallByBall()}>Ball by Ball</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateFallOfWicket()}>Fall of Wicket</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateCommentry()}>Commentary</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateExtraRun()}>Extra</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdatePartnership()}>Partnership</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateMatch()}>Match</button>
                              </div>
                              : null}
                            </>
                          </div>

                          <div>
                            <button className='border solid black whitespace-nowrap p-2 text-l font-semibold' onClick={() => this.handleTeamB2()}>Team B Australia</button>
                            <>  {this.state.teamB2 === true ?

                              <div className='space-x-1'>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBatsmanScore()}>Batsman</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBowlerScore()}>Bowler</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateBallByBall()}>Ball by Ball</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateFallOfWicket()}>Fall of Wicket</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateCommentry()}>Commentary</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateExtraRun()}>Extra</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdatePartnership()}>Partnership</button>
                                <button className="border solid black whitespace-nowrap p-2 text-l font-semibold" onClick={() => this.handleUpdateMatch()}>Match</button>
                              </div>
                              : null}
                            </>
                          </div>

                        </>

                        : null}

                      </>
                    </div>

                  </div>















                  {/* <>
                    <div className="h-screen m-4 overflow-hidden overflow-y-auto">

                      <div className="overflow-y-auto bg-white ">
                        <div className="w-full px-6 py-4 text-left">
                          <form autoComplete="on">




                            <div className="justify-between w-full mt-2 lg:flex">


                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">SportId :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["sportId"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="sportId" name="sportId" placeholder="Title" value={this.state.fieldsMatchInfo.sportId} type="sportId" onChange={this.inputChangeMatchInfo} disabled />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["sportId"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["sportId"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">SeriesId :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["seriesId"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="seriesId" name="seriesId" placeholder="Title" value={this.state.fieldsMatchInfo.seriesId} type="seriesId" onChange={this.inputChangeMatchInfo} disabled />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["seriesId"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["seriesId"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">matchId :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["matchId"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="matchId" name="matchId" placeholder="Title" value={this.state.fieldsMatchInfo.matchId} type="matchId" onChange={this.inputChangeMatchInfo} disabled />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["matchId"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["matchId"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">marketId :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["marketId"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="marketId" name="marketId" placeholder="Title" value={this.state.fieldsMatchInfo.marketId} type="marketId" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["marketId"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["marketId"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                            </div>




                            <div className="justify-between w-full mt-2 lg:flex">

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">Name :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["name"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="name" name="name" placeholder="Name" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["name"] ? this.state.fieldsMatchInfo["name"] : ""} type="text" onChange={this.inputChangeMatchInfo} disabled />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["name"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["name"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">Open Date :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["openDate"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="openDate" name="openDate" placeholder="openDate" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["openDate"] ? this.state.fieldsMatchInfo["openDate"] : ""} type="text" onChange={this.inputChangeMatchInfo} disabled />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["openDate"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["openDate"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">tv_id :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["tv_id"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="tv_id" name="tv_id" placeholder="tv_id" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["tv_id"] ? this.state.fieldsMatchInfo["tv_id"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["tv_id"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["tv_id"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">series :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["series"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="series" name="series" placeholder="series" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["series"] ? this.state.fieldsMatchInfo["series"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["series"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["series"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                            </div>


                            <div className="justify-between w-full mt-2 lg:flex">

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">matchs :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["matchs"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="matchs" name="matchs" placeholder="matchs" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["matchs"] ? this.state.fieldsMatchInfo["matchs"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["matchs"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["matchs"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">match_time :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["match_time"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="match_time" name="match_time" placeholder="match_time" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["match_time"] ? this.state.fieldsMatchInfo["match_time"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["match_time"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["match_time"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">venue :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["venue"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="venue" name="venue" placeholder="venue" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["venue"] ? this.state.fieldsMatchInfo["venue"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["venue"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["venue"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">toss :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["toss"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="toss" name="toss" placeholder="toss" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["toss"] ? this.state.fieldsMatchInfo["toss"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["toss"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["toss"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                            </div>

                            <div className="justify-between w-full mt-2 lg:flex">

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">umpire :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["umpire"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="umpire" name="umpire" placeholder="umpire" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["umpire"] ? this.state.fieldsMatchInfo["umpire"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["umpire"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["umpire"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">third_umpire :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["third_umpire"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="third_umpire" name="third_umpire" placeholder="third_umpire" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["third_umpire"] ? this.state.fieldsMatchInfo["third_umpire"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["third_umpire"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["third_umpire"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">referee :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["referee"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="referee" name="referee" placeholder="referee" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["referee"] ? this.state.fieldsMatchInfo["referee"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["referee"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["referee"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">man_of_match :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["man_of_match"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="man_of_match" name="man_of_match" placeholder="man_of_match" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["man_of_match"] ? this.state.fieldsMatchInfo["man_of_match"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["man_of_match"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["man_of_match"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                            </div>

                            <div className="justify-between w-full mt-2 lg:flex">

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">match_type :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["match_type"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="match_type" name="match_type" placeholder="match_type" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["match_type"] ? this.state.fieldsMatchInfo["match_type"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["match_type"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["match_type"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_a_id :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_a_id"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_a_id" name="team_a_id" placeholder="team_a_id" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_a_id"] ? this.state.fieldsMatchInfo["team_a_id"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_a_id"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_a_id"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_a :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_a"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_a" name="team_a" placeholder="team_a" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_a"] ? this.state.fieldsMatchInfo["team_a"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_a"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_a"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_a_short :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_a_short"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_a_short" name="team_a_short" placeholder="team_a_short" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_a_short"] ? this.state.fieldsMatchInfo["team_a_short"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_a_short"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_a_short"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                            </div>

                            <div className="justify-between w-full mt-2 lg:flex">

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_b_id :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_b_id"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_b_id" name="team_b_id" placeholder="team_b_id" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_b_id"] ? this.state.fieldsMatchInfo["team_b_id"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_b_id"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_b_id"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_b :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_b"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_b" name="team_b" placeholder="team_b" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_b"] ? this.state.fieldsMatchInfo["team_b"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_b"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_b"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_b_short :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_b_short"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_b_short" name="team_b_short" placeholder="team_b_short" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_b_short"] ? this.state.fieldsMatchInfo["team_b_short"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_b_short"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_b_short"]}
                                    </div>
                                    : null}
                                </div>
                              </div>

                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_a_img :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_a_img"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_a_img" name="team_a_img" placeholder="team_a_img" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_a_img"] ? this.state.fieldsMatchInfo["team_a_img"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_a_img"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_a_img"]}
                                    </div>
                                    : null}
                                </div>
                              </div>
                            </div>



                            <div className="justify-between w-full mt-2 lg:flex">
                              <div className="w-full mr-2">
                                <div className="relative shadow-sm">
                                  <label className="mt-2 mr-2 text-sm font-medium text-black">team_b_img :</label>
                                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsMatchInfo && !this.state.errorsMatchInfo["team_b_img"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                    id="team_b_img" name="team_b_img" placeholder="team_b_img" value={this.state.fieldsMatchInfo && this.state.fieldsMatchInfo["team_b_img"] ? this.state.fieldsMatchInfo["team_b_img"] : ""} type="text" onChange={this.inputChangeMatchInfo} />
                                  {this.state.errorsMatchInfo && this.state.errorsMatchInfo["team_b_img"] ?
                                    <div className="text-xs text-red-600 invalid-feedback">
                                      {this.state.errorsMatchInfo["team_b_img"]}
                                    </div>
                                    : null}
                                </div>
                              </div>
                            </div>





                            <div className="grid w-full grid-cols-1 gap-4 mt-2 lg:grid-cols-2">
                              <div className="w-full mr-2">
                                {
                                  this.state.fieldsAddUser && this.state.fieldsAddUser.imageUrlLink ?
                                    <>
                                      <div className="w-full mt-1 mr-2 ">
                                        <img src={this.state.fieldsAddUser && this.state.fieldsAddUser.imageUrlLink ? this.state.fieldsAddUser.imageUrlLink : "NA"}
                                          alt=" " height="20%" width="20%" />
                                      </div>
                                    </> :
                                    null
                                }
                                <div className="w-full mt-1 shadow-md">
                                  <div className="flex flex-wrap justify-between w-full p-2 border border-gray-300">
                                    <div className="w-full">
                                      <label class="block text-gray-700 text-sm" for="username"> News Image </label>
                                      <input type="file" name="image" onChange={this.handleFile} />
                                      <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                                    </div>
                                    <div className="w-20 mt-1 space-x-4">
                                      <button className="px-4 py-1 text-base font-semibold text-white bg-blue-500 border rounded-lg cursor-pointer" type="button" onClick={() => this.handleUpload()} >Set</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mx-auto mt-6 w-60">
                              <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out border rounded-full bg-shine-400 border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button"
                                onClick={this.updateMatchInfo}
                              >Submit</button>
                            </div>

                          </form>

                        </div>
                      </div>
                    </div >

                  </> */}

                  {/* {
                  isMobile ?
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>
                } */}

                </div>

              </div>
            </div>

          </main>

          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}

function mapStateToProps(state) {
  const { matchMoreDetail } = state;
  // console.log('matchMoreDetail:::::mapStateToProps:', matchMoreDetail);
  return {
    matchMoreDetail
  };
}

export default connect(mapStateToProps)(MatchMoreDetail);
