import { matchConstants } from '../_constants';

export function match(state = {}, action) {
  console.log('actionactionactionactionactionactionaction', action);
  switch (action.type) {

    case matchConstants.GET_LIST_CUSTOM_MATCH_REQUEST:
      return {
        ...state,
        loading: true,
        addCustomMatchSuccess: false,
      };
    case matchConstants.GET_LIST_CUSTOM_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        getMatchList: action.customMatch.getCustomMatchList.list,
        total: action.customMatch.getCustomMatchList.total
      };
    case matchConstants.GET_LIST_CUSTOM_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchConstants.GET_ALL_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        addCustomMatchSuccess: false,
      };
    case matchConstants.GET_ALL_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        allTeam: action.teams.getAllTeams
      };
    case matchConstants.GET_ALL_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case matchConstants.ADD_CUSTOM_MATCH_REQUEST:
      return {
        ...state
      };
    case matchConstants.ADD_CUSTOM_MATCH_SUCCESS:
      return {
        ...state,
        addCustomMatchSuccess: true,
      };
    case matchConstants.ADD_CUSTOM_MATCH_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case matchConstants.UPDATE_CUSTOM_MATCH_REQUEST:
      return {
        ...state
      };
    case matchConstants.UPDATE_CUSTOM_MATCH_SUCCESS:
      return {
        ...state,
        addCustomMatchSuccess: true,
      };
    case matchConstants.UPDATE_CUSTOM_MATCH_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case matchConstants.UPDATE_MATCH_STATUS_REQUEST:
      return {
        ...state
      };
    case matchConstants.UPDATE_MATCH_STATUS_SUCCESS:
      return {
        ...state,
        addCustomMatchSuccess: true,
      };
    case matchConstants.UPDATE_MATCH_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchConstants.DELETE_CUSTOM_MATCH_REQUEST:
      return {
        ...state
      };
    case matchConstants.DELETE_CUSTOM_MATCH_SUCCESS:
      return {
        ...state,
        addCustomMatchSuccess: true,
      };
    case matchConstants.DELETE_CUSTOM_MATCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchConstants.DISABLE_CUSTOM_MATCH_REQUEST:
      return {
        ...state
      };
    case matchConstants.DISABLE_CUSTOM_MATCH_SUCCESS:
      return {
        ...state,
        addCustomMatchSuccess: true,
      };
    case matchConstants.DISABLE_CUSTOM_MATCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchConstants.UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_REQUEST:
      return {
        ...state
      };
    case matchConstants.UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        addCustomMatchSuccess: true,
      };
    case matchConstants.UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    default:
      return state
  }
}