import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { player } from './player.reducer';
import { team } from './teams.reducer';
import { match } from './match.reducer';
import { series } from './series.reducer';
import { playerRankType } from './playerRankType.reducer';
import { pointTable } from './pointTable.reducer';
import { playerRanking } from './playerRanking.reducer';


import { matchMoreDetail } from './matchMoreDetail.reducer';
import { news } from './news.reducer';
//import { branch } from './branch.reducer';
// import { dashboard } from './dashboard.reducer';
import { teamRankingType } from './teamRankingType.reducer';
import { teamRanking } from './teamRanking.reducer';
import { image } from './image.reducer';
import { category } from './category.reducer';


const rootReducer = combineReducers({
  //branch,
  authentication,
  users,
  alert,
  category,
  player,
  team,
  image,
  series,
  pointTable,
  playerRankType,
  playerRanking,
  teamRankingType,
  matchMoreDetail,
  // matchMoreDetail,
  news,
  match,
  teamRanking,
  // dashboard,
});

export default rootReducer;
