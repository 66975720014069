import React from "react";
import Modal from 'react-modal';

export default function ViewMatchDetailsModal(props) {
  let { viewMatchModal, handleCloseViewMatchModal, inputChangeMatchUpdate, rowData, matchUpdateSubmit, fieldsUpdatePassword } = props;
  return (

    <>
      <div className={viewMatchModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-2/3 w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">

          {/*Title*/}

          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">View Match Details</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseViewMatchModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <div className="p-3">

            <form autoComplete="off" className=" space-y-4 capitalize   ">
              <div className="grid grid-cols-1 gap-3  lg:grid-cols-4">

                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Sport Id : </label>
                  <input type="text" name="sportId" className={`w-full p-2 mt-1 text-sm   rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["sportId"] ? rowData["sportId"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Id : </label>
                  <input type="text" name="seriesId" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["seriesId"] ? rowData["seriesId"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Match Id : </label>
                  <input type="text" name="matchId" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["matchId"] ? rowData["matchId"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>
                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Match Type : </label>
                  <input type="text" name="match_type" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["match_type"] ? rowData["match_type"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>
                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Matchs : </label>
                  <input type="text" name="matchs" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["matchs"] ? rowData["matchs"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>
                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Man Of Match : </label>
                  <input type="text" name="man_of_match" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["man_of_match"] ? rowData["man_of_match"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>
                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Match Time : </label>
                  <input type="text" name="match_time" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["match_time"] ? rowData["match_time"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series : </label>
                  <input type="text" name="series" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["series"] ? rowData["series"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>

                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Name : </label>
                  <input type="text" name="name" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["name"] ? rowData["name"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>





                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Open Date : </label>
                  <input type="text" name="openDate" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["openDate"] ? rowData["openDate"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                {/* <div class="w-full">
                    <label className="block text-xs font-medium text-gray-500 md:text-left">Match Date : </label>
                    <input type="text" name="matchDate" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                      value={rowData && rowData["matchDate"] ? rowData["matchDate"] : ""}
                      onChange={inputChangeMatchUpdate}
                    ></input>
                  </div> */}


             


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Venue : </label>
                  <input type="text" name="venue" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["venue"] ? rowData["venue"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Toss : </label>
                  <input type="text" name="toss" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["toss"] ? rowData["toss"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Umpire : </label>
                  <input type="text" name="umpire" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["umpire"] ? rowData["umpire"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Third Umpire : </label>
                  <input type="text" name="third_umpire" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["third_umpire"] ? rowData["third_umpire"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>
                
{/* 

                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Referee : </label>
                  <input type="text" name="referee" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["referee"] ? rowData["referee"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div> */}


             




                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Team A Detail : </label>
                  <input type="text" name="teamAdetail" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["teamAdetail"] ? rowData["teamAdetail"] : ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>


                <div class="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Team B Detail : </label>
                  <input type="text" name="teamBdetail" className={`w-full p-2 mt-1 text-sm    rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={rowData && rowData["teamBdetail"]  ? rowData["teamBdetail"]: ""}
                    onChange={inputChangeMatchUpdate} disabled
                  ></input>
                </div>

              </div>

            </form>

          </div>

        </div>
      </div>
    </>
  );
}
