import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const teamsService = {
    login,
    logout,
    getAllTeams,
    createTeams,
    updateTeams,
    getTeamsList,
    // getAllCityByStateId,
    deleteTeams,
    disableTeams,
    getTeamsById
};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getTeamsList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamsList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getTeamsList: data.data
            }
            console.log("getTeamsList_Data_:::::", userObj);
            return userObj;
        });
}

// function getAllCityByStateId(data) {
//     // console.log("data m kya aa rha h::service:::", data);
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllCityByStateId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 getAllCityByStateId: data.data
//             }
//             console.log("i am in service getAllCityByStateId", userObj);

//             return userObj;
//         });
// }
function getAllTeams(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllTeams`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTeams: data.data
            }
            console.log("i am in service getAllTeams", userObj);

            return userObj;
        });
}

function deleteTeams(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTeams`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteTeams: data.data
            }
            console.log("i am in service''...>> deleteTeams ::", userObj);

            return userObj;
        });
}

function getTeamsById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamsById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTeamsById: data.data
            }
            console.log("i am in service''...>> getTeamsById ::", userObj);

            return userObj;
        });
}
function disableTeams(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTeamsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateTeamsStatus: data.data
            }
            console.log("i am in service''...>> updateTeamsStatus ::", userObj);

            return userObj;
        });
}

function updateTeams(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTeams`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateTeams: data.data
            }
            console.log("I am in service updateTeams", userObj);

            return userObj;
        });
}
function createTeams(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createTeams`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createTeams: data.data
            }
            console.log("I am in service createTeams", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}