import { authHeader, history } from '../_helpers';
import { CONST, } from '../_config';

export const playerRankTypeService = {
    getPlayerRankingTypeList,
    getAllPlayerRankingType,
    createPlayerRankingType,
    UpdatePlayerRankingType,
    disablePlayerRankingType,
    deletePlayerRankingType,

    login,
    logout,

};


function getPlayerRankingTypeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPlayerRankingTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPlayerRankingTypeList: data.data
            }

            console.log('serviesd_getPlayerRankingTypeList', playerObj);

            return playerObj;
        });
}
function getAllPlayerRankingType() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPlayerRankingTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                getAllPlayerRankingType: data.data
            }


            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);



            return playerObj;
        });
}
function createPlayerRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPlayerRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                createPlayerRankingType: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function UpdatePlayerRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/UpdatePlayerRankingTypeDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                UpdatePlayerRankingType: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function disablePlayerRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/disablePlayerRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                disablePlayerRankingType: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function deletePlayerRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePlayerRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                deletePlayerRankingType: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}










function logout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
}
function onerrorlogout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
    window.location.reload();
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(player => {
            let playerObj = {
                playerinfo: player
            }
            if (player.data) {
                localStorage.setItem('player', JSON.stringify(player.data));
            }
            return playerObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {

                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}