import {
  userConstants, newsConstants
} from '../_constants';

export function news(state = {}, action) {

  switch (action.type) {
    case newsConstants.DELETE_NEWS_MANUAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.DELETE_NEWS_MANUAL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case newsConstants.DELETE_NEWS_MANUAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.DISABLE_ACTIVE_MANUAL_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.DISABLE_ACTIVE_MANUAL_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case newsConstants.DISABLE_ACTIVE_MANUAL_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.GET_LIST_NEWS_MANUAL_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case newsConstants.GET_LIST_NEWS_MANUAL_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadSuccess: false,
        items: action.users.getNewsListManual.list,
        total: action.users.getNewsListManual.total
      };
    case newsConstants.GET_LIST_NEWS_MANUAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.UPDATE_NEWS_PTI_REQUEST:
      return {
        ...state
      };
    case newsConstants.UPDATE_NEWS_PTI_SUCCESS:
      return {
        ...state,
        updateUserSuccess: false,
        // imageUploadSuccess: false,
      };
    case newsConstants.UPDATE_NEWS_PTI_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case newsConstants.DISABLE_ACTIVE_PTI_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.DISABLE_ACTIVE_PTI_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case newsConstants.DISABLE_ACTIVE_PTI_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        imageUploadSuccess: true,
        filesDetails: action.uploadImage.filesDetails
      };
    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };
    case newsConstants.ADD_NEWS_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case newsConstants.ADD_NEWS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case newsConstants.ADD_VENUES_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_VENUES_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case newsConstants.ADD_VENUES_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case newsConstants.ADD_NOTIFICATION_REQUEST:
      return {
        ...state
      };
    case newsConstants.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
        // notificationSuccess: true
      };
    case newsConstants.ADD_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.GET_LIST_NEWS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case newsConstants.GET_LIST_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUserSuccess: true,
        newsRowData: action.users.getNewsById,
        // total: action.users.getNewsById
      };
    case newsConstants.GET_LIST_NEWS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.GET_VENUES_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case newsConstants.GET_VENUES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUserSuccess: true,
        venuesResData: action.users.getVenuesById,
        // total: action.users.getNewsById
      };
    case newsConstants.GET_VENUES_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.GET_LIST_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case newsConstants.GET_LIST_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadSuccess: false,
        items: action.users.getNewsList.list,
        total: action.users.getNewsList.total
      };
    case newsConstants.GET_LIST_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.GETALL_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GETALL_NEWS_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        newsItems: action.users.getAllNews,
        newsTotal: action.users.getAllNews
      };
    case newsConstants.GETALL_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.UPDATE_NEWS_REQUEST:
      return {
        ...state
      };
    case newsConstants.UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        updateUserSuccess: false,
        // imageUploadSuccess: false,
      };
    case newsConstants.UPDATE_NEWS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case newsConstants.UPDATE_VENUES_REQUEST:
      return {
        ...state
      };
    case newsConstants.UPDATE_VENUES_SUCCESS:
      return {
        ...state,
        updateUserSuccess: false,
        // imageUploadSuccess: false,
      };
    case newsConstants.UPDATE_VENUES_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case newsConstants.DELETE_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.DELETE_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case newsConstants.DELETE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case newsConstants.DISABLE_NEWS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.DISABLE_NEWS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case newsConstants.DISABLE_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case newsConstants.GET_LIST_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case newsConstants.GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        notificationItems: action.users.getNotificationList.list,
        notificationTotal: action.users.getNotificationList.total
      };
    case newsConstants.GET_LIST_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}