import React from "react";


export default function AddTeamRankingTypeDetailsModal(props) {

  let { addTeamRankingTypeModal, handleCloseAddTeamRankingTypeModal, fieldsteamRankingTypeAdd, errorsTeamRankingTypeAdd, inputChangeTeamRankingTypeAdd, teamRankingTypeAddSubmit } = props;
  return (

    < >
      <div className={addTeamRankingTypeModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">


          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Add Team Rank Type</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddTeamRankingTypeModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-4">

            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 " onSubmit={teamRankingTypeAddSubmit}>

              <div class="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left">Name * : </label>
                <input type="text" name="name" className={` w-full p-2 mt-1 text-sm rounded-md focus:outline-none  ${errorsTeamRankingTypeAdd["name"] ? "border  border-red-500" : "border  placeholder-gray-500"}`}
                  value={fieldsteamRankingTypeAdd && fieldsteamRankingTypeAdd["name"] ? fieldsteamRankingTypeAdd["name"] : ""}
                  onChange={inputChangeTeamRankingTypeAdd}
                />
                {errorsTeamRankingTypeAdd && errorsTeamRankingTypeAdd["name"] ?
                  <div className="text-xs text-red-500 ">
                    {errorsTeamRankingTypeAdd["name"]}
                  </div>
                  : null}
              </div>


              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left">Type * : </label>
                <input type="text" name="type" className={`w-full p-2 mt-1 text-sm rounded-md focus:outline-none focus:ring-2 ring-gray-500 ${errorsTeamRankingTypeAdd["type"] ? "border  border-red-500" : "border  placeholder-gray-500"}`}
                  value={fieldsteamRankingTypeAdd && fieldsteamRankingTypeAdd["type"] ? fieldsteamRankingTypeAdd["type"] : ""}
                  onChange={inputChangeTeamRankingTypeAdd}
                />
                {errorsTeamRankingTypeAdd["type"] ?
                  <div className="text-xs text-red-500">
                    {errorsTeamRankingTypeAdd["type"]}
                  </div>
                  : null}
              </div>

              <div className="mx-auto">
                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="submit"
                >
                  Submit</button>
              </div>
            </form>
          </div>
        </div>

      </div>


    </>
  );
}
