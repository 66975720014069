import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchActions, seriesActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CreateMatchModal from "./components/CreateMatchModal/CreateMatchModal";
import UpdateMatchModal from "./components/UpdateMatchModal/UpdateMatchModal";
import ViewMatchDetailsModal from "./components/ViewMatchDetailsModal/ViewMatchDetailsModal";
import LoadingOverlay from 'react-loading-overlay';

class Match extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},

      keyWord: '',
      pageNo: 1,
      size: 10,

      // allSeries: {},

      addMatchModal: false,
      fieldsMatchAdd: {},
      errorsMatchAdd: {},

      updateMatchModal: false,
      fieldsMatchUpdate: {},
      errorsMatchUpdate: {},
    }
  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(matchActions.getCustomMatchList(data));
    this.props.dispatch(matchActions.getAllTeams());
    this.props.dispatch(seriesActions.getAllCustomSeries());
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.match.addCustomMatchSuccess) {
      return {
        ...nextProps,
        addMatchModal: false,
        fieldsMatchAdd: {},
        errorsMatchAdd: {},

        updateMatchModal: false,
        fieldsMatchUpdate: {},
        errorsMatchUpdate: {},
      }
    }
    // if (nextProps.series.addplayeruccess) {
    //   return {
    //     ...nextProps,
    //     allSeries: {},
    //     addMatchModal: false,

    //   }
    // } 

    else {
      return {
        ...nextProps
      }
    }
  }



  handleOpenToAddMatchModal = () => {
    this.setState({ addMatchModal: true });
  }
  handleCloseAddMatchModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ addMatchModal: false });
  }
  inputChangeMatchAdd = (e) => {
    console.log('e.target', e.target);
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsMatchAdd = this.state.fieldsMatchAdd;
    let errorsMatchAdd = this.state.errorsMatchAdd;
    fieldsMatchAdd[name] = value;
    errorsMatchAdd[name] = "";
    console.log('this.state.fieldsMatchAdd', this.state.fieldsMatchAdd);
    this.setState({ fieldsMatchAdd, errorsMatchAdd });
  }
  matchAddSubmit = () => {


    if (this.handleValidationMatchAdd()) {
      let { match } = this.props;
      let { allTeam } = match;
  
      let resData = {
        "sportId": this.state.fieldsMatchAdd.sportId,
        "seriesRefId": this.state.fieldsMatchAdd.seriesRefId,
        "seriesId": this.state.fieldsMatchAdd.seriesId,
        "matchId": this.state.fieldsMatchAdd.matchId,
        "series": this.state.fieldsMatchAdd.series,
        "name": this.state.fieldsMatchAdd.name,
        "matchs": this.state.fieldsMatchAdd.matchs,
        "openDate": this.state.fieldsMatchAdd.openDate,
        "match_date": this.state.fieldsMatchAdd.match_date,
        "match_time": this.state.fieldsMatchAdd.match_time,
        "venue": this.state.fieldsMatchAdd.venue,
        "toss": allTeam && allTeam.length > 0
          ? allTeam
            .filter((element) => element.id === this.state.fieldsMatchAdd.teamA)
            .map((element) => element.name)
            .join(", ")
          : null,
        "umpire": this.state.fieldsMatchAdd.umpire,
        "third_umpire": this.state.fieldsMatchAdd.third_umpire,
        "referee": this.state.fieldsMatchAdd.referee,
        "man_of_match": this.state.fieldsMatchAdd.man_of_match,
        "matchType": this.state.fieldsMatchAdd.matchType,
        "teamA": this.state.fieldsMatchAdd.teamA,
        // "64786fc1a145bd815df836c3", //teamA
        "teamB": this.state.fieldsMatchAdd.teamB,
        "isCustomMatch": "true",
        // "64787009a145bd815df836ca", //teamB
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(matchActions.createCustomMatch(resData, data));
    }
  }
  handleValidationMatchAdd = () => {
    let fieldsMatchAdd = this.state.fieldsMatchAdd;
    let errorsMatchAdd = {};
    let formIsValid = true;
    if (!fieldsMatchAdd["sportId"] || fieldsMatchAdd["sportId"] === "") {
      formIsValid = false;
      errorsMatchAdd["sportId"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["seriesRefId"] || fieldsMatchAdd["seriesRefId"] === "") {
      formIsValid = false;
      errorsMatchAdd["seriesRefId"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["seriesId"] || fieldsMatchAdd["seriesId"] === "") {
      formIsValid = false;
      errorsMatchAdd["seriesId"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["matchId"] || fieldsMatchAdd["matchId"] === "") {
      formIsValid = false;
      errorsMatchAdd["matchId"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["series"] || fieldsMatchAdd["series"] === "") {
      formIsValid = false;
      errorsMatchAdd["series"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["name"] || fieldsMatchAdd["name"] === "") {
      formIsValid = false;
      errorsMatchAdd["name"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["matchs"] || fieldsMatchAdd["matchs"] === "") {
      formIsValid = false;
      errorsMatchAdd["matchs"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["openDate"] || fieldsMatchAdd["openDate"] === "") {
      formIsValid = false;
      errorsMatchAdd["openDate"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["match_date"] || fieldsMatchAdd["match_date"] === "") {
      formIsValid = false;
      errorsMatchAdd["match_date"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["match_time"] || fieldsMatchAdd["match_time"] === "") {
      formIsValid = false;
      errorsMatchAdd["match_time"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["venue"] || fieldsMatchAdd["venue"] === "") {
      formIsValid = false;
      errorsMatchAdd["venue"] = "Cannot be empty";
    }

    if (!fieldsMatchAdd["umpire"] || fieldsMatchAdd["umpire"] === "") {
      formIsValid = false;
      errorsMatchAdd["umpire"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["third_umpire"] || fieldsMatchAdd["third_umpire"] === "") {
      formIsValid = false;
      errorsMatchAdd["third_umpire"] = "Cannot be empty";
    }
    // if (!fieldsMatchAdd["referee"] || fieldsMatchAdd["referee"] === "") {
    //   formIsValid = false;
    //   errorsMatchAdd["referee"] = "Cannot be empty";
    // }
    // if (!fieldsMatchAdd["man_of_match"] || fieldsMatchAdd["man_of_match"] === "") {
    //   formIsValid = false;
    //   errorsMatchAdd["man_of_match"] = "Cannot be empty";
    // }
    if (!fieldsMatchAdd["matchType"] || fieldsMatchAdd["matchType"] === "") {
      formIsValid = false;
      errorsMatchAdd["matchType"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["teamA"] || fieldsMatchAdd["teamA"] === "") {
      formIsValid = false;
      errorsMatchAdd["teamA"] = "Cannot be empty";
    }
    if (!fieldsMatchAdd["teamB"] || fieldsMatchAdd["teamB"] === "") {
      formIsValid = false;
      errorsMatchAdd["teamB"] = "Cannot be empty";
    }
    console.log('errorsMatchAdderrorsMatchAdderrorsMatchAdderrorsMatchAdd', errorsMatchAdd);
    this.setState({ errorsMatchAdd: errorsMatchAdd });
    return formIsValid;
  }



  handleOpenToUpdateMatchModal = (data) => {

    console.log(data)

    this.setState({ updateMatchModal: true, fieldsMatchUpdate: data });
  }
  handleCloseUpdateMatchModal = () => {
    this.setState({ updateMatchModal: false });
  }
  inputChangeMatchUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsMatchUpdate = this.state.fieldsMatchUpdate;
    let errorsMatchUpdate = this.state.errorsMatchUpdate;
    fieldsMatchUpdate[name] = value;
    errorsMatchUpdate[name] = "";
    this.setState({ fieldsMatchUpdate, errorsMatchUpdate });
  }
  matchUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationMatchUpdate()) {
      let { match } = this.props
      let { fieldsMatchUpdate } = match
      let resData = {
        "id": this.state.fieldsMatchUpdate.id,
        "sportId": this.state.fieldsMatchUpdate.sportId,
        "seriesRefId": this.state.fieldsMatchUpdate.seriesRefId,
        "seriesId": this.state.fieldsMatchUpdate.seriesId,
        "matchId": this.state.fieldsMatchUpdate.matchId,
        "series": this.state.fieldsMatchUpdate.series,
        "name": this.state.fieldsMatchUpdate.name,
        "matchs": this.state.fieldsMatchUpdate.matchs,
        "openDate": this.state.fieldsMatchUpdate.openDate,
        "match_date": this.state.fieldsMatchUpdate.match_date,
        "match_time": this.state.fieldsMatchUpdate.match_time,
        "venue": this.state.fieldsMatchUpdate.venue,
        "toss": this.state.fieldsMatchUpdate.toss,
        "umpire": this.state.fieldsMatchUpdate.umpire,
        "third_umpire": this.state.fieldsMatchUpdate.third_umpire,
        "man_of_match": this.state.fieldsMatchUpdate.man_of_match,
        "matchType": this.state.fieldsMatchUpdate.matchType,
        "teamA": this.state.fieldsMatchUpdate.teamA,
        //  "6474799759cbd156ba1a7843",
        "teamB": this.state.fieldsMatchUpdate.teamB,
        // "6474795c59cbd156ba1a783e",
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(matchActions.updateCustomMatch(resData, data));
    }
  }
  handleValidationMatchUpdate = () => {
    let fieldsMatchUpdate = this.state.fieldsMatchUpdate;
    let errorsMatchUpdate = {};
    let formIsValid = true;
    if (!fieldsMatchUpdate["sportId"] || fieldsMatchUpdate["sportId"] === "") {
      formIsValid = false;
      errorsMatchUpdate["sportId"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["seriesRefId"] || fieldsMatchUpdate["seriesRefId"] === "") {
      formIsValid = false;
      errorsMatchUpdate["seriesRefId"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["seriesId"] || fieldsMatchUpdate["seriesId"] === "") {
      formIsValid = false;
      errorsMatchUpdate["seriesId"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["matchId"] || fieldsMatchUpdate["matchId"] === "") {
      formIsValid = false;
      errorsMatchUpdate["matchId"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["series"] || fieldsMatchUpdate["series"] === "") {
      formIsValid = false;
      errorsMatchUpdate["series"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["name"] || fieldsMatchUpdate["name"] === "") {
      formIsValid = false;
      errorsMatchUpdate["name"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["matchs"] || fieldsMatchUpdate["matchs"] === "") {
      formIsValid = false;
      errorsMatchUpdate["matchs"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["openDate"] || fieldsMatchUpdate["openDate"] === "") {
      formIsValid = false;
      errorsMatchUpdate["openDate"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["match_date"] || fieldsMatchUpdate["match_date"] === "") {
      formIsValid = false;
      errorsMatchUpdate["match_date"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["match_time"] || fieldsMatchUpdate["match_time"] === "") {
      formIsValid = false;
      errorsMatchUpdate["match_time"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["venue"] || fieldsMatchUpdate["venue"] === "") {
      formIsValid = false;
      errorsMatchUpdate["venue"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["toss"] || fieldsMatchUpdate["toss"] === "") {
      formIsValid = false;
      errorsMatchUpdate["toss"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["umpire"] || fieldsMatchUpdate["umpire"] === "") {
      formIsValid = false;
      errorsMatchUpdate["umpire"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["third_umpire"] || fieldsMatchUpdate["third_umpire"] === "") {
      formIsValid = false;
      errorsMatchUpdate["third_umpire"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["referee"] || fieldsMatchUpdate["referee"] === "") {
      formIsValid = false;
      errorsMatchUpdate["referee"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["man_of_match"] || fieldsMatchUpdate["man_of_match"] === "") {
      formIsValid = false;
      errorsMatchUpdate["man_of_match"] = "Cannot be empty";
    }
    if (!fieldsMatchUpdate["matchType"] || fieldsMatchUpdate["matchType"] === "") {
      formIsValid = false;
      errorsMatchUpdate["matchType"] = "Cannot be empty";
    }
    this.setState({ errorsMatchUpdate: errorsMatchUpdate });
    console.log('errorsMatchUpdateerrorsMatchUpdateerrorsMatchUpdateerrorsMatchUpdate::::::::::::', errorsMatchUpdate);
    return formIsValid;
  }


  updateMatchStatus = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Disable?',
      message: `Are you sure to Disable ${data.name} Match ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchActions.updateCustomMatchStatus(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenToDeleteMatchModal = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} Match ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchActions.deleteCustomMatch(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  updateCustomMatchIsActiveStatus = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(matchActions.updateCustomMatchIsActiveStatus(datatemp, pdata))

  }


  handleOpenToViewMatchModal = (data) => {
    this.setState({ viewMatchModal: true, rowData: data });
  }
  handleCloseViewMatchModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ viewMatchModal: false });
  }


  handleShowData = (e) => {
    const size = e.target.value;
    this.setState({ size }, () => {
      const { keyWord, pageNo, size } = this.state; // Destructure the updated state
      const data = {
        keyWord,
        pageNo,
        size // Use the updated value of size
      };
      this.props.dispatch(matchActions.getCustomMatchList(data));
    });
  }




  render() {
    let { match, series } = this.props;
    let { getMatchList, allTeam } = match;
    let { allSeries } = series

    // console.log('allSeriesallSeriesallSeriesallSeriesallSeriesallSeriesallSeries', allSeries);
    // console.log("RENDER____allMaallTeamallTeamallTeamtch_all", allTeam);
    // console.log('111111111111111111111111111111111111111111111111111', this.state.rowData);
    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}  
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Match</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Match Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={(e)=>this.handleShowData(e)} className="text-sm bg-white focus:outline-none w-full" value={this.state.size?.toString()}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddMatchModal()}>ADD</button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Series</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Match Time</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Match Type</th>
                              {/* <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Image</th> */}
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">venue</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getMatchList && getMatchList.length > 0 ?
                                getMatchList.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.series ? element.series : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.match_time ? element.match_time : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.matchType ? element.matchType : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.venue ? element.venue : "-"}</td>

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToViewMatchModal(element)}>More</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdateMatchModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updateMatchStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToDeleteMatchModal(element)}>Delete</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        {/* 'bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' */}
                                        <button className={`${element && element.isActive ? "bg-green-500 hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm" : "bg-red-500 hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm"}`}

                                          onClick={() => this.updateCustomMatchIsActiveStatus(element)}

                                        >{
                                            element && element.isActive ? "Active" : "DeActive"
                                          }</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          getMatchList && getMatchList.length > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getMatchList.length / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          getMatchList && getMatchList.length > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getMatchList.length / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  <CreateMatchModal
                    addMatchModal={this.state.addMatchModal}
                    allSeries={allSeries}
                    allTeam={allTeam}
                    handleCloseAddMatchModal={this.handleCloseAddMatchModal}
                    inputChangeMatchAdd={this.inputChangeMatchAdd}
                    matchAddSubmit={this.matchAddSubmit}
                    fieldsMatchAdd={this.state.fieldsMatchAdd}
                    errorsMatchAdd={this.state.errorsMatchAdd}
                    handleFile={this.handleFile}
                  />
                  <UpdateMatchModal
                    updateMatchModal={this.state.updateMatchModal}
                    handleCloseUpdateMatchModal={this.handleCloseUpdateMatchModal}
                    allSeries={allSeries}
                    allTeam={allTeam}
                    inputChangeMatchUpdate={this.inputChangeMatchUpdate}
                    fieldsMatchUpdate={this.state.fieldsMatchUpdate}
                    errorsMatchUpdate={this.state.errorsMatchUpdate}
                    handleFile={this.handleFile}
                    matchUpdateSubmit={this.matchUpdateSubmit}
                  />
                  <ViewMatchDetailsModal
                    viewMatchModal={this.state.viewMatchModal}
                    handleCloseViewMatchModal={this.handleCloseViewMatchModal}
                    inputChangeMatchUpdate={this.inputChangeMatchUpdate}
                    rowData={this.state.rowData}
                    errorsMatchUpdate={this.state.errorsMatchUpdate}
                    handleFile={this.handleFile}
                  // matchUpdateSubmit={this.matchUpdateSubmit}

                  // matchUpdateSubmit={this.matchUpdateSubmit}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { match, series } = state;
  return {
    match,
    series,
  };
}
export default connect(mapStateToProps)(Match);