import React from "react";
import Multiselect from 'multiselect-react-dropdown';

import Modal from 'react-modal';


export default function UpdateTeamModal(props) {

  let { updateTeamModal, handleCloseUpdateTeamModal, inputChangeTeamUpdate, selectedPlayer, handleSelectedPlayer, getAllPlayer, fieldsTeamUpdate, teamUpdateSubmit, filesDetails, handleFile, fieldsUpdatePassword } = props;
  //console.log("userCreateModal  ",userCreateModal);
  // console.log('getAllPlayergetAllPlayergetAllPlayergetAllPlayer', getAllPlayer);
  let options = []


  if (getAllPlayer && getAllPlayer.length > 0) {
    getAllPlayer.forEach(element => (
      options.push(
        {
          value: element.id,
          label: element.name
        }
      )
    ));

  }

  console.log('optionsoptionsoptionsoptions', options);




  return (

    < >


      <div className={updateTeamModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">

          {/*Title*/}
          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase"> Team Details</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseUpdateTeamModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          {/*Body*/}
          <div className="p-4">
            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">


              <div class=" w-full">
                <img src={fieldsTeamUpdate && fieldsTeamUpdate["imageLinkUrl"] ? fieldsTeamUpdate["imageLinkUrl"] : ""} alt="Girl in a jacket" width="200" height="300" />
              </div>


              <div class=" w-full">
                <label className="block text-xl text-bold font-medium text-black md:text-left">Name : {fieldsTeamUpdate && fieldsTeamUpdate["name"] ? fieldsTeamUpdate["name"] : ""}</label>

              </div>
              <div class=" w-full">
                <label className="block text-xl text-bold font-medium text-black md:text-left">Short Name : {fieldsTeamUpdate && fieldsTeamUpdate["short_name"] ? fieldsTeamUpdate["short_name"] : ""}</label>

              </div>




              <div class="w-full ">
                <label className="block text-Xl text-bold font-medium text-black md:text-left">Players : </label>
                {fieldsTeamUpdate && fieldsTeamUpdate["player"] && fieldsTeamUpdate["player"].length > 0 ?
                  fieldsTeamUpdate["player"].map((ele, index) => (
                    <div class="w-full">
                      {index + 1}  {ele && ele["name"] ? ele["name"] : ""}
                    </div>
                  )) :
                  null}
              </div>



            </form>


          </div>
          {/*Footer*/}

        </div>
      </div>


    </>



  );
}
