import {
  playerRankingConstants
} from '../_constants';

export function playerRanking(state = {}, action) {

  // console.log('actionactionactionactionactionactionactionactionactionaction', action);

  switch (action.type) {

    case playerRankingConstants.GET_PLAYER_RANKING_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case playerRankingConstants.GET_PLAYER_RANKING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        rankingItem: action.player.getPlayerRankingList.list,
        rankingTotal: action.player.getPlayerRankingList.total
      };
    case playerRankingConstants.GET_PLAYER_RANKING_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };
 
      case playerRankingConstants.GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case playerRankingConstants.GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        rankingTypeListItem: action.player.getPlayerRankingListByPlayerRankingTypeId.list,
        rankingTypeListTotal: action.player.getPlayerRankingListByPlayerRankingTypeId.total
      };
    case playerRankingConstants.GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankingConstants.GET_ALL_PLAYER_RANKING_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case playerRankingConstants.GET_ALL_PLAYER_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        allRankItem: action.player.getAllPlayerRankingType,
        allRankTotal: action.player.getAllPlayerRankingList.total
      };
    case playerRankingConstants.GET_ALL_PLAYER_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // case playerRankingConstants.GET_ALL_PLAYER_RANKING_TYPE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     addplayeruccess: false
    //   };
    // case playerRankingConstants.GET_ALL_PLAYER_RANKING_TYPE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     allRankType: action.player.getAllPlayerRankingType
    //   };
    // case playerRankingConstants.GET_ALL_PLAYER_RANKING_TYPE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };


    case playerRankingConstants.UPDATE_PLAYER_RANKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankingConstants.UPDATE_PLAYER_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankingConstants.UPDATE_PLAYER_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankingConstants.ADD_PLAYER_RANKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankingConstants.ADD_PLAYER_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankingConstants.ADD_PLAYER_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankingConstants.UPDATE_PLAYER_RANKING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankingConstants.UPDATE_PLAYER_RANKING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankingConstants.UPDATE_PLAYER_RANKING_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case playerRankingConstants.DELETE_PLAYER_RANKING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankingConstants.DELETE_PLAYER_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankingConstants.DELETE_PLAYER_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };


    default:
      return state
  }
}