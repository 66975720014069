import { userConstants, newsConstants } from '../_constants';
import { userService, newsService } from '../_services';
// import { Service, categoryService } from '../_services/category.service';

import { alertActions, categoryActions } from './';
import { toast } from 'react-toastify';
export const newsActions = {

    login,
    logout,
    disableNews,
    getAllNews,
    createNews,
    createVenues,
    updateNews,
    updateVenues,
    getNewsList,
    getNewsById,
    getVenuesById,
    // getAllCategory,
    deleteNews,
    uploadImageClear,
    createNotificationManual,
    createNotificationPti,
    getNotificationList,
    uploadImage,
    disableActivePtiNews,
    updateNewsPti,
    getNewsListManual,
    disableActiveManualNews,
    deleteNewsManual
};

function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function disableActiveManualNews(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.disableActiveManualNews(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getNewsListManual(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DISABLE_ACTIVE_MANUAL_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.DISABLE_ACTIVE_MANUAL_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DISABLE_ACTIVE_MANUAL_NEWS_FAILURE, error } }
}

function disableActivePtiNews(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.disableActivePtiNews(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getNewsList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DISABLE_ACTIVE_PTI_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.DISABLE_ACTIVE_PTI_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DISABLE_ACTIVE_PTI_NEWS_FAILURE, error } }
}

function createVenues(data, props) {

    return dispatch => {
        dispatch(request());
        newsService.createVenues(data)
            .then(

                users => {
                    dispatch(alertActions.success("Venue Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/Venue' });
                    // dispatch(this.getNewsList(paginationData));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_VENUES_REQUEST } }
    function success(users) { return { type: newsConstants.ADD_VENUES_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.ADD_VENUES_FAILURE, error } }
}

function createNews(data, props) {

    return dispatch => {
        dispatch(request());
        newsService.createNews(data)
            .then(

                users => {
                    dispatch(alertActions.success("News Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/news' });
                    // dispatch(this.getNewsList(paginationData));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.ADD_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.ADD_NEWS_FAILURE, error } }
}

function createNotificationManual(data, paginationData) {
    return dispatch => {
        dispatch(request());
        newsService.createNotification(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Notification Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getNewsListManual(paginationData));
                    // dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.ADD_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.ADD_NOTIFICATION_FAILURE, error } }
}

function createNotificationPti(data, paginationData) {
    return dispatch => {
        dispatch(request());
        newsService.createNotification(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Notification Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getNewsList(paginationData));
                    // dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.ADD_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.ADD_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.ADD_NOTIFICATION_FAILURE, error } }
}

function getNotificationList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getNotificationList(data)
            .then(
                users => {
                    console.log("getNotificationList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_LIST_NOTIFICATION_REQUEST } }
    function success(users) { return { type: newsConstants.GET_LIST_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_LIST_NOTIFICATION_FAILURE, error } }
}
function updateNews(data, paginationData, props) {

    return dispatch => {
        dispatch(request());
        newsService.updateNews(data)
            .then(

                users => {
                    console.log('paginationData____________', paginationData);
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("News Update Successfully."));
                    dispatch(success(users));
                    // dispatch(this.getNewsList(paginationData));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/news' });

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.UPDATE_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.UPDATE_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.UPDATE_NEWS_FAILURE, error } }
}
function updateVenues(data, paginationData, props) {

    return dispatch => {
        dispatch(request());
        newsService.updateVenues(data)
            .then(

                users => {
                    console.log('paginationData____________', paginationData);
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Venues Update Successfully."));
                    dispatch(success(users));
                    // dispatch(this.getNewsList(paginationData));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/Venue' });

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.UPDATE_VENUES_REQUEST } }
    function success(users) { return { type: newsConstants.UPDATE_VENUES_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.UPDATE_VENUES_FAILURE, error } }
}

function updateNewsPti(data, paginationData, props,) {

    return dispatch => {
        dispatch(request());
        newsService.updateNewsPti(data)
            .then(

                users => {
                    console.log('paginationData____________', paginationData);
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("News Update Successfully."));
                    dispatch(success(users));
                    // dispatch(this.getNewsList(paginationData));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/activePtiNews' });

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.UPDATE_NEWS_PTI_REQUEST } }
    function success(users) { return { type: newsConstants.UPDATE_NEWS_PTI_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.UPDATE_NEWS_PTI_FAILURE, error } }
}

function getAllNews(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getAllNews(data)
            .then(
                users => {
                    console.log("getAllNews $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GETALL_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.GETALL_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GETALL_NEWS_FAILURE, error } }
}
function getVenuesById(data, props) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getVenuesById(data)
            .then(
                users => {
                    console.log("getVenuesById $$$$ action:", users);
                    dispatch(success(users));
                    // props.history.push({ pathname: '/app/Venue' });
                    // 8888888


                    //  dispatch(categoryActions.getSubCategoryById(error));
                    // dispatch(categoryActions.getSubCategoryByIdTemp({
                    //     shortName: users.getVenuesById.category
                    // }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_VENUES_BY_ID_REQUEST } }
    function success(users) { return { type: newsConstants.GET_VENUES_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_VENUES_BY_ID_FAILURE, error } }
}
function getNewsById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        newsService.getNewsById(data)
            .then(
                users => {
                    console.log("getNewsById $$$$ action:", users);
                    dispatch(success(users));
                    // 8888888


                    //  dispatch(categoryActions.getSubCategoryById(error));
                    dispatch(categoryActions.getSubCategoryByIdTemp({
                        shortName: users.getNewsById.category
                    }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_LIST_NEWS_BY_ID_REQUEST } }
    function success(users) { return { type: newsConstants.GET_LIST_NEWS_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_LIST_NEWS_BY_ID_FAILURE, error } }
}
function getNewsList(data) {
    // console.log("data m kya aa rha h::action:::", data);

    let newData = {
        ...data, "newsType": data.newsType === "ALL" ? null : data.newsType,
    }
    return dispatch => {
        dispatch(request());
        newsService.getNewsList(newData)
            .then(
                users => {
                    console.log("getNewsList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_LIST_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.GET_LIST_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_LIST_NEWS_FAILURE, error } }
}

function getNewsListManual(data) {
    // console.log("data m kya aa rha h::action:::", data);

    // let newData = {
    //     ...data, "newsType": data.newsType === "ALL" ? null : data.newsType,
    // }
    return dispatch => {
        dispatch(request());
        newsService.getNewsListManual(data)
            .then(
                users => {
                    console.log("getNewsListManual $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.GET_LIST_NEWS_MANUAL_REQUEST } }
    function success(users) { return { type: newsConstants.GET_LIST_NEWS_MANUAL_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.GET_LIST_NEWS_MANUAL_FAILURE, error } }
}

function deleteNews(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.deleteNews(data)
            .then(
                users => {
                    dispatch(success(users));
                    toast("News Deleted successfully.")
                    dispatch(this.getNewsList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DELETE_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.DELETE_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DELETE_NEWS_FAILURE, error } }
}

function deleteNewsManual(data, paginationData) {
    return dispatch => {
        dispatch(request());
        newsService.deleteNews(data)
            .then(
                users => {
                    dispatch(success(users));
                    toast("News Deleted successfully.")
                    dispatch(this.getNewsListManual(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DELETE_NEWS_MANUAL_REQUEST } }
    function success(users) { return { type: newsConstants.DELETE_NEWS_MANUAL_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DELETE_NEWS_MANUAL_FAILURE, error } }
}

function disableNews(data, paginationData) {

    return dispatch => {
        dispatch(request());
        newsService.disableNews(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getNewsList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: newsConstants.DISABLE_NEWS_REQUEST } }
    function success(users) { return { type: newsConstants.DISABLE_NEWS_SUCCESS, users } }
    function failure(error) { return { type: newsConstants.DISABLE_NEWS_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        newsService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: newsConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: newsConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}