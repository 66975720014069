export const playerRankTypeConstants = {
    GET_PLAYER_RANK_TYPE_LIST_REQUEST: 'GET_PLAYER_RANK_TYPE_LIST_REQUEST',
    GET_PLAYER_RANK_TYPE_LIST_SUCCESS: 'GET_PLAYER_RANK_TYPE_LIST_SUCCESS',
    GET_PLAYER_RANK_TYPE_LIST_FAILURE: 'GET_PLAYER_RANK_TYPE_LIST_FAILURE',

    GET_ALL_PLAYER_RANK_TYPE_REQUEST: 'GET_ALL_PLAYER_RANK_TYPE_REQUEST',
    GET_ALL_PLAYER_RANK_TYPE_SUCCESS: 'GET_ALL_PLAYER_RANK_TYPE_SUCCESS',
    GET_ALL_PLAYER_RANK_TYPE_FAILURE: 'GET_ALL_PLAYER_RANK_TYPE_FAILURE',

    ADD_PLAYER_RANK_TYPE_REQUEST: 'ADD_PLAYER_RANK_TYPE_REQUEST',
    ADD_PLAYER_RANK_TYPE_SUCCESS: 'ADD_PLAYER_RANK_TYPE_SUCCESS',
    ADD_PLAYER_RANK_TYPE_FAILURE: 'ADD_PLAYER_RANK_TYPE_FAILURE',

    UPDATE_PLAYER_RANK_TYPE_REQUEST: 'UPDATE_PLAYER_RANK_TYPE_REQUEST',
    UPDATE_PLAYER_RANK_TYPE_SUCCESS: 'UPDATE_PLAYER_RANK_TYPE_SUCCESS',
    UPDATE_PLAYER_RANK_TYPE_FAILURE: 'UPDATE_PLAYER_RANK_TYPE_FAILURE',

    UPDATE_PLAYER_RANK_TYPE_STATUS_REQUEST: 'UPDATE_PLAYER_RANK_TYPE_STATUS_REQUEST',
    UPDATE_PLAYER_RANK_TYPE_STATUS_SUCCESS: 'UPDATE_PLAYER_RANK_TYPE_STATUS_SUCCESS',
    UPDATE_PLAYER_RANK_TYPE_STATUS_FAILURE: 'UPDATE_PLAYER_RANK_TYPE_STATUS_FAILURE',

    DELETE_PLAYER_RANK_TYPE_REQUEST: 'DELETE_PLAYER_RANK_TYPE_REQUEST',
    DELETE_PLAYER_RANK_TYPE_SUCCESS: 'DELETE_PLAYER_RANK_TYPE_SUCCESS',
    DELETE_PLAYER_RANK_TYPE_FAILURE: 'DELETE_PLAYER_RANK_TYPE_FAILURE',


};
