import React from "react";
import Modal from 'react-modal';
import Select2 from 'react-select';



export default function CreateMatchModal(props) {

  let { addMatchModal, handleCloseAddMatchModal, errorsTeamAdd, allSeries, inputChangeMatchAdd, matchAddSubmit, allTeam, fieldsMatchAdd } = props;
  //console.log("userCreateModal  ",userCreateModal);
  // console.log('seriesseriesseriesseriesseriesseriesseries', allSeries);
  // const options = [
  //   { value: 'apple', label: 'Apple' },
  //   { value: 'banana', label: 'Banana' },
  //   { value: 'cherry', label: 'Cherry' },
  //   // Add more options as needed
  // ];
  let series = allSeries && allSeries.length ? allSeries.map((element) => ({ value: element.id, label: element.name })) : [];



  return (

    < >

      <div className={addMatchModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-2/3 w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">



          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Create Match</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddMatchModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-3">
            <form autoComplete="off" className=" space-y-3 capitalize  ">
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">

                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Sport Id : </label>
                  <input type="text" name="sportId" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["sportId"] ? fieldsMatchAdd["sportId"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium">Series:</label>
                  <select class="form-select bg-white w-full px-2  py-[10px] text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  " aria-label="Default select example" id="typeId"
                    name="seriesRefId"
                    value={fieldsMatchAdd && fieldsMatchAdd["seriesRefId"] ? fieldsMatchAdd["seriesRefId"] : ""}
                    onChange={inputChangeMatchAdd}
                  >
                    <option selected>Plz Select Series</option>
                    {
                      allSeries && allSeries && allSeries.length > 0 ?
                        allSeries.map((element, index) => (
                          <option key={index} name={element && element.series ? element.series : null} value={element && element.id ? element.id : null}>{element && element.series ? element.series : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>






                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Series Id : </label>
                  <input type="text" name="seriesId" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["seriesId"] ? fieldsMatchAdd["seriesId"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Match Id : </label>
                  <input type="text" name="matchId" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["matchId"] ? fieldsMatchAdd["matchId"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Series : </label>
                  <input type="text" name="series" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["series"] ? fieldsMatchAdd["series"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Name : </label>
                  <input type="text" name="name" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["name"] ? fieldsMatchAdd["name"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Matchs : </label>
                  <input type="text" name="matchs" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["matchs"] ? fieldsMatchAdd["matchs"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>


                {/* <div class="display flex"> */}

                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Open Date : </label>
                  <input type="date" name="openDate" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["openDate"] ? fieldsMatchAdd["openDate"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Match Date : </label>
                  <input type="date" name="match_date" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["match_date"] ? fieldsMatchAdd["match_date"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>


                {/* </div> */}



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Match Time : </label>
                  <input type="text" name="match_time" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["match_time"] ? fieldsMatchAdd["match_time"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Venue : </label>
                  <input type="text" name="venue" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["venue"] ? fieldsMatchAdd["venue"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>







                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Umpire : </label>
                  <input type="text" name="umpire" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["umpire"] ? fieldsMatchAdd["umpire"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Third Umpire : </label>
                  <input type="text" name="third_umpire"
                    value={fieldsMatchAdd && fieldsMatchAdd["third_umpire"] ? fieldsMatchAdd["third_umpire"] : ""}
                    onChange={inputChangeMatchAdd} className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                  ></input>
                </div>





                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Man Of Match : </label>
                  <input type="text" name="man_of_match" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["man_of_match"] ? fieldsMatchAdd["man_of_match"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Match Type : </label>
                  <input type="text" name="matchType" className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchAdd && fieldsMatchAdd["matchType"] ? fieldsMatchAdd["matchType"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>


                {/* <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  <label className="text-black text-sm font-medium ">Team A Detail : </label>
                  <input type="text" name="teamAdetail"
                    value={fieldsMatchAdd && fieldsMatchAdd["teamAdetail"] ? fieldsMatchAdd["teamAdetail"] : ""}
                    onChange={inputChangeMatchAdd}
                  ></input>
                </div>
              </div> */}

                {/* <div class="display flex"> */}
                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium">Team A :</label>
                  <select class="form-select bg-white  w-full px-2 py-[11px]  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  " aria-label="Default select example" id="typeId"
                    name="teamA"
                    value={fieldsMatchAdd && fieldsMatchAdd["teamA"] ? fieldsMatchAdd["teamA"] : ""}
                    onChange={inputChangeMatchAdd}
                  >
                    <option selected>Plz Select Team A</option>
                    {
                      allTeam && allTeam && allTeam.length > 0 ?
                        allTeam.map((element, index) => (
                          <option key={index} name={element && element.name ? element.name : null} value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>

                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium">Team B:</label>
                  <select class="form-select bg-white w-full px-2  py-[10px] text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  " aria-label="Default select example" id="typeId"
                    name="teamB"
                    value={fieldsMatchAdd && fieldsMatchAdd["teamB"] ? fieldsMatchAdd["teamB"] : ""}
                    onChange={inputChangeMatchAdd}
                  >
                    <option selected>Plz Select Team B</option>
                    {
                      allTeam && allTeam && allTeam.length > 0 ?
                        allTeam.map((element, index) => (
                          <option key={index} name={element && element.name ? element.name : null} value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>




                <div class=" w-full">
                  <label className="text-black text-sm font-medium">Toss : </label>
                  <select
                    disabled
                    className="form-select bg-white w-full px-2 py-[11px] text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500 appearance-none"
                    aria-label="Default select example"
                    id="typeId"
                    name="teamA"
                    value={fieldsMatchAdd && fieldsMatchAdd["teamA"] ? fieldsMatchAdd["teamA"] : ""}
                    onChange={inputChangeMatchAdd}
                  >
                    <option selected>Plz Select Team A</option>
                    {allTeam && allTeam && allTeam.length > 0 ? allTeam.map((element, index) => (
                      <option key={index} name={element && element.name ? element.name : null} value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                    )) : null}
                  </select>


                </div>

              </div>
              {/* </div> */}







              <div className="w-64 sm:w-72 mx-auto">

                <div className=" mx-auto">
                  <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-sm font-semibold rounded-md text-white focus:outline-none" type="button"
                    onClick={matchAddSubmit}
                  >
                    Submit</button>
                </div>

              </div>
            </form>
          </div>
        </div>

      </div>

    </>



  );
}
