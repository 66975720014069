import React from "react";
import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';


export default function AddPlayerDetailsModal(props) {

  let { addSeriesModal, handleCloseAddCustomSeriesModal, fieldsSeriesAdd, inputChangeSeriesAdd, filesDetails, handleFile, customSeriesAddSubmit, getTeamsList, selectedPlayer, handleSelectedPlayer, handleSelectedVenue, selectedVenue, venuesList } = props;


  let options = []


  if (getTeamsList && getTeamsList.length > 0) {
    getTeamsList.forEach(element => (
      options.push(
        {
          value: element.id,
          label: element.name
        }
      )
    ));

  }

  let venueOptions = []


  if (venuesList && venuesList.length > 0) {
    venuesList.forEach(element => (
      venueOptions.push(
        {
          value: element.id,
          label: element.name
        }
      )
    ));

  }

  return (

    < >


      <div className={addSeriesModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">



          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Add Custom Series</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddCustomSeriesModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-4">
            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">
              <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Sport Id : </label>
                  <input type="text" name="sportId" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["sportId"] ? fieldsSeriesAdd["sportId"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Id : </label>
                  <input type="text" name="series_id" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["series_id"] ? fieldsSeriesAdd["series_id"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Id : </label>
                  <input type="text" name="seriesId" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["seriesId"] ? fieldsSeriesAdd["seriesId"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series : </label>
                  <input type="text" name="series" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["series"] ? fieldsSeriesAdd["series"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Total Matches : </label>
                  <input type="text" name="total_matches" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["total_matches"] ? fieldsSeriesAdd["total_matches"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Date : </label>
                  <input type="date" name="series_date" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["series_date"] ? fieldsSeriesAdd["series_date"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>




                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Start Date : </label>
                  <input type="date" name="start_date" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["start_date"] ? fieldsSeriesAdd["start_date"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">End Date : </label>
                  <input type="date" name="end_date" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["end_date"] ? fieldsSeriesAdd["end_date"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Month Wise : </label>
                  <input type="date" name="month_wise" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["month_wise"] ? fieldsSeriesAdd["month_wise"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>






              </div>

              <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  {/* <label className="text-black text-base font-medium ">Player : </label> &nbsp; */}
                  <label for="countries" className="block text-xs font-medium text-gray-500 md:text-left">Select Teams</label>
                  <Multiselect
                    options={options}
                    onSelect={handleSelectedPlayer}
                    onRemove={handleSelectedPlayer}
                    selected={selectedPlayer}
                    isObject={true}
                    displayValue="label"
                  />
                </div>
              </div>

              <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  {/* <label className="text-black text-base font-medium ">Player : </label> &nbsp; */}
                  <label for="countries" className="block text-xs font-medium text-gray-500 md:text-left">Select Venues</label>
                  <Multiselect
                    options={venueOptions}
                    onSelect={handleSelectedVenue}
                    onRemove={handleSelectedVenue}
                    selected={selectedVenue}
                    isObject={true}
                    displayValue="label"
                  />
                </div>
              </div>

              <div className="flex mt-1 space-x-4">
                {
                  filesDetails && filesDetails.imageLinkUrl ?
                    <>
                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }
              </div>

              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Image
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <p className="pl-2">Note :128 pixels – 128 pixels</p>
              </div>






              <div className="mx-auto">
                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={customSeriesAddSubmit}
                >
                  Submit</button>
              </div>
            </form>
          </div>

        </div>
      </div>


    </>
  );
}
