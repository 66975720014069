
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const matchMoreDetailService = {
    login,
    logout,
    getAllMatchMoreDetail,
    createMatchMoreDetail,
    saveBatsmanInLiveMatch,
    saveBowlerInLiveMatch,
    updateMatchMoreDetail,
    getMatchMoreDetailList,
    getBattingTeamList,
    getBowlingTeamList,
    updateCurrentInnings,
    // getAllCityByStateId,
    deleteMatchMoreDetail,
    cancelBatsmanInLiveMatch,
    cancelBowlerInLiveMatch,
    disableMatchMoreDetail,
    updateMatchInfo,
    updateMatch_Status,
    getMatchDetailsByMatchId,
    getLastTwentyBallData,
    getFancyListForFancyResult,
    updateFancyResultDeclare,
    setCurrentInnings,
    getAllTeams,
    setCurrentTeam,
    getLiveMatchRun,
    getRollBackLastRunData,
    getLiveMatchExtraRuns,
    updateCurrentTeam,
    manualStrike,
    getBatsmanOrBowlerStatus,
    getMatchInfoById,
    getLiveMatchBatsmanById,
    getLiveMatchBowlerById,
    deleteInning
};
function deleteInning(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteInning`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteInning: data.data
            }
            console.log("i am in service deleteInning", userObj);

            return userObj;
        });
}
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getMatchMoreDetailList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMatchMoreDetailList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getMatchMoreDetailList: data.data
            }
            console.log("i am in service getMatchMoreDetailList", userObj);

            return userObj;
        });
}
function getMatchInfoById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMatchInfoById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getMatchInfoById: data.data
            }
            console.log("i am in service getMatchInfoById", userObj);
            return userObj;
        });
}

function updateCurrentInnings(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCurrentInnings`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateCurrentInnings: data.data
            }
            console.log("i am in service updateCurrentInnings", userObj);

            return userObj;
        });
}

function setCurrentTeam(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/setCurrentTeam`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                setCurrentTeam: data.data
            }
            console.log("i am in service setCurrentTeam", userObj);

            return userObj;
        });
}

function getLiveMatchRun(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLiveMatchRun`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getLiveMatchRun: data.data
            }
            console.log("i am in service getLiveMatchRun", userObj);

            return userObj;
        });
}

function getRollBackLastRunData(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getRollBackLastRunData`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getRollBackLastRunData: data.data
            }
            console.log("i am in service getRollBackLastRunData", userObj);

            return userObj;
        });
}

function getLiveMatchExtraRuns(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLiveMatchExtraRuns`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getLiveMatchExtraRuns: data.data
            }
            console.log("i am in service getLiveMatchExtraRuns", userObj);

            return userObj;
        });
}

function updateCurrentTeam(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCurrentTeam`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateCurrentTeam: data.data
            }
            console.log("i am in service updateCurrentTeam", userObj);

            return userObj;
        });
}

function getBatsmanOrBowlerStatus(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBatsmanOrBowlerStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getBatsmanOrBowlerStatus: data.data
            }
            console.log("i am in service getBatsmanOrBowlerStatus", userObj);

            return userObj;
        });
}

function manualStrike(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/manualStrike`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                manualStrike: data.data
            }
            console.log("i am in service manualStrike", userObj);

            return userObj;
        });
}

function setCurrentInnings(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/setCurrentInnings`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                setCurrentInnings: data.data
            }
            console.log("i am in service setCurrentInnings", userObj);

            return userObj;
        });
}

function getBattingTeamList(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBattingTeamList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getBattingTeamList: data.data
            }
            console.log("i am in service getBattingTeamList", userObj);

            return userObj;
        });
}

function getAllTeams(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllTeams`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTeams: data.data
            }
            console.log("i am in service getAllTeams", userObj);

            return userObj;
        });
}

function getBowlingTeamList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBowlingTeamList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getBowlingTeamList: data.data
            }
            console.log("i am in service getBowlingTeamList", userObj);

            return userObj;
        });
}

// function getAllCityByStateId(data) {
//     // console.log("data m kya aa rha h::service:::", data);
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllCityByStateId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 getAllCityByStateId: data.data
//             }
//             console.log("i am in service getAllCityByStateId", userObj);

//             return userObj;
//         });
// }
function getAllMatchMoreDetail(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllMatchMoreDetail`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllMatchMoreDetail: data.data
            }
            console.log("i am in service getAllMatchMoreDetail", userObj);

            return userObj;
        });
}

function deleteMatchMoreDetail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteMatchMoreDetail`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteMatchMoreDetail: data.data
            }
            console.log("i am in service''...>> deleteMatchMoreDetail ::", userObj);

            return userObj;
        });
}


function cancelBatsmanInLiveMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/cancelBatsmanInLiveMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                cancelBatsmanInLiveMatch: data.data
            }
            console.log("i am in service''...>> cancelBatsmanInLiveMatch ::", userObj);

            return userObj;
        });
}

// function cancelBatsmanInLiveMatch(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/deleteBatsmanInLiveMatch`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 cancelBatsmanInLiveMatch: data.data
//             }
//             console.log("i am in service''...>> cancelBatsmanInLiveMatch ::", userObj);

//             return userObj;
//         });
// }

function cancelBowlerInLiveMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/cancelBowlerInLiveMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                cancelBowlerInLiveMatch: data.data
            }
            console.log("i am in service''...>> cancelBowlerInLiveMatch ::", userObj);

            return userObj;
        });
}

function getLastTwentyBallData(data) {
    console.log('getLastTwentyBallDatagetLastTwentyBallData', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLastTwentyBallData`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getLastTwentyBallDataById: data.data
            }
            // console.log("i am in service''...>> getLastTwentyBallData ::", userObj);

            return userObj;
        });
}

function getMatchDetailsByMatchId(data) {
    console.log('getMatchDetailsByMatchIdgetMatchDetailsByMatchId', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMatchDetailsByMatchId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getMatchMoreDetailById: data.data
            }
            // console.log("i am in service''...>> getMatchDetailsByMatchId ::", userObj);

            return userObj;
        });
}

function getFancyListForFancyResult(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getFancyListForFancyResult`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getFancyListForFancyResult: data.data
            }
            // console.log("i am in service''...>> getFancyListForFancyResult ::", userObj);

            return userObj;
        });
}

function updateFancyResultDeclare(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateFancyResultDeclare`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateFancyResultDeclare: data.data
            }
            // console.log("i am in service''...>> updateFancyResultDeclare ::", userObj);

            return userObj;
        });
}
function disableMatchMoreDetail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchMoreDetailStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchMoreDetailStatus: data.data
            }
            console.log("i am in service''...>> updateMatchMoreDetailStatus ::", userObj);

            return userObj;
        });
}
function updateMatchInfo(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchInfo`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatchInfo: data.data
            }
            console.log("i am in service''...>> updateMatchInfo ::", userObj);

            return userObj;
        });
}

function updateMatch_Status(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatch_Status`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateMatch_Status: data.data
            }
            console.log("i am in service''...>> updateMatch_Status ::", userObj);

            return userObj;
        });
}

function getLiveMatchBatsmanById(data) {
    console.log('SERVICE::::::::::::::::::::::::::::::::::::::::data', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('SERVICE___VVVrequestOptionsrequestOptionsrequestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getLiveMatchBatsmanById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                liveMatchBatsmanById: data.data
            }
            console.log("i am in service''...>> getLiveMatchBatsmanById ::", userObj);

            return userObj;
        });
}

function getLiveMatchBowlerById(data) {
    // console.log('SERVICE::::::::::::::::::::::::::::::::::::::::data', data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // console.log('SERVICE___VVVrequestOptionsrequestOptionsrequestOptions', requestOptions);
    return fetch(CONST.BACKEND_URL + `/getLiveMatchBowlerById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                liveMatchBowlerById: data.data
            }
            console.log("i am in service''...>> getLiveMatchBowlerById ::", userObj);

            return userObj;
        });
}

function updateMatchMoreDetail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchMoreDetail`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateMatchMoreDetail: data.data
            }
            console.log("I am in service updateMatchMoreDetail", userObj);

            return userObj;
        });
}
function createMatchMoreDetail(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createMatchMoreDetail`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createMatchMoreDetail: data.data
            }
            console.log("I am in service createMatchMoreDetail", userObj);

            return userObj;
        });
}

function saveBatsmanInLiveMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBatsmanInLiveMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                saveBatsmanInLiveMatch: data.data
            }
            console.log("I am in service saveBatsmanInLiveMatch", userObj);

            return userObj;
        });
}

function saveBowlerInLiveMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveBowlerInLiveMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                saveBowlerInLiveMatch: data.data
            }
            console.log("I am in service saveBowlerInLiveMatch", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}