import React from "react";

import Modal from 'react-modal';



export default function CreateAddCategoryModal(props) {

  let { addUserCreateModal, handleAddUserHideModal, errorsCategory, inputAddUserChange, createCategorySubmit, fieldsCategory, handleFile,
    // inputChangeIsVideo, isVideo,
    allCategoryParent, filesDetails
    //  handleFile2

  } = props;
  // console.log("isVideo_modal::", isVideo);

  return (

    <Modal
      isOpen={addUserCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold">Add Category</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleAddUserHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>

            {/*Body*/}
            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">
                  <div class="w-full">
                    <label className="text-sm font-medium text-black ">Category :</label>
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example"
                      onChange={inputAddUserChange}
                      id="parentId"
                      name="parentId"
                      value={fieldsCategory && fieldsCategory["parentId"] ? fieldsCategory["parentId"] : null}
                    >
                      <option selected>Plz Select Category</option>
                      {
                        allCategoryParent && allCategoryParent && allCategoryParent.length > 0 ?
                          allCategoryParent.map((element, index) => (
                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsCategory && errorsCategory["parentId"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsCategory["parentId"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative my-2 shadow-sm">
                    <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsCategory && !errorsCategory["name"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="name" name="name" placeholder="Enter Name" value={fieldsCategory.name} type="text" onChange={inputAddUserChange} />
                    {errorsCategory && errorsCategory["name"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsCategory["name"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative shadow-sm">
                    <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsCategory && !errorsCategory["shortName"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="shortName" name="shortName" placeholder="Enter Short Name" value={fieldsCategory.shortName} type="text" onChange={inputAddUserChange} />
                    {errorsCategory && errorsCategory["shortName"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsCategory["shortName"]}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative shadow-sm mt-2">
                    <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsCategory && !errorsCategory["indexCategory"] ? "border border-gray-400 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="indexCategory" name="indexCategory" placeholder="Enter Index Category" value={fieldsCategory.indexCategory} type="text" onChange={inputAddUserChange} />
                    {errorsCategory && errorsCategory["indexCategory"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsCategory["indexCategory"]}
                      </div>
                      : null}
                  </div>
                </div>


                <div className="flex mt-1 space-x-4">
                  {
                    filesDetails && filesDetails.imageURL ?
                      <>
                        <div className="w-full mt-1 mr-2 ">
                          <img src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                      </> :
                      null
                  }
                </div>
                <div className="flex flex-wrap w-full py-2">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input id="image" name="image" type="file" onChange={handleFile} />
                  <p className="pl-2">Note :128 pixels – 128 pixels</p>
                </div>
                <div className="mx-auto w-60">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button" onClick={createCategorySubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
