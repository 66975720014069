import React, { useEffect } from "react";
import Multiselect from 'multiselect-react-dropdown';

import Modal from 'react-modal';


export default function UpdateTeamModal(props) {

  let { updateTeamModal, handleCloseUpdateTeamModal, inputChangeTeamUpdate, selectedPlayer, handleSelectedPlayer, getAllPlayer, fieldsTeamUpdate, teamUpdateSubmit, filesDetails, handleFile, fieldsUpdatePassword,handleRemovePlayer } = props;


  console.log(fieldsTeamUpdate?.player)


  let options = []


  if (getAllPlayer && getAllPlayer.length > 0) {
    getAllPlayer.forEach(element => (
      options.push(
        {
          value: element.id,
          label: element.name
        }
      )
    ));

  }

  useEffect(() => {
    let PlayerData =
    fieldsTeamUpdate?.player?.map((e) => ({ value: e?.playerId, label: e?.name })) || [];
  

    if (selectedPlayer && selectedPlayer.length > 0) {
      handleSelectedPlayer(PlayerData);
    }
  }, [fieldsTeamUpdate]);



  return (

    < >


      <div className={updateTeamModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">

          {/*Title*/}
          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Update Team Details</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseUpdateTeamModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          {/*Body*/}
          <div className="p-4">
            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">


              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left">Name : </label>
                <input type="text" name="name" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsTeamUpdate && fieldsTeamUpdate["name"] ? fieldsTeamUpdate["name"] : ""}
                  onChange={inputChangeTeamUpdate}
                ></input>
              </div>



              <div class="w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left">Short Name : </label>
                <input type="text" name="short_name" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsTeamUpdate && fieldsTeamUpdate["short_name"] ? fieldsTeamUpdate["short_name"] : ""}
                  onChange={inputChangeTeamUpdate}
                ></input>
              </div>





              <div class=" w-full">
                {/* <label className="text-black text-base font-medium ">Player : </label> &nbsp; */}
                <label for="countries" className="block text-xs font-medium text-gray-500 md:text-left">Select Palyers</label>
            
                <Multiselect
                  options={options}
                  onSelect={handleSelectedPlayer}
                  onRemove={handleRemovePlayer}
                  selectedValues={selectedPlayer}
                  displayValue="label"
                />

              </div>



              <div className="flex mt-1 space-x-4">
                {
                  filesDetails && filesDetails.imageLinkUrl ?
                    <>
                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }
              </div>

              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Flag Image
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <p className="pl-2">Note :128 pixels – 128 pixels</p>
              </div>









              <div className=" mx-auto">
                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={teamUpdateSubmit}
                >
                  Submit</button>
              </div>
            </form>


          </div>
          {/*Footer*/}

        </div>
      </div>


    </>



  );
}
