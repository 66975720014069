import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pointTableActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePlayerDetailsModal from "./components/UpdatePlayerDetailsModal/UpdatePlayerDetailsModal";
import AddPlayerDetailsModal from "./components/AddPlayerDetailsModal/AddPlayerDetailsModal";
import LoadingOverlay from 'react-loading-overlay';
import { HiArrowSmLeft } from "react-icons/hi";

class PointTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},

      keyWord: '',
      pageNo: 1,
      size: 10,

      addPointTableModal: false,
      fieldsPointTableAdd: {},
      errorsPointTableAdd: {},

      updatePointTableModal: false,
      fieldsPointTableUpdate: {},
      errorsPointTableUpdate: {},
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {


    if (nextProps.pointTable.addplayeruccess) {

      return {
        ...nextProps,

        addPointTableModal: false,
        fieldsPointTableAdd: {},
        errorsPointTableAdd: {},

        updatePointTableModal: false,
        fieldsPointTableUpdate: {},
        errorsPointTableUpdate: {},


      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let data1 = {
      "seriesId": this.props.match.params.id,
    }
    console.log('data1data1data1data1data1data1data1data1', data1);
    this.props.dispatch(pointTableActions.getPointsTableList(data));
    this.props.dispatch(pointTableActions.getPointsTableBySeriesId(data1));
  }

  navigate = (url) => {
    this.props.history.push(url);
  }
  handleUrl = (url, data) => {
    this.props.history.push(url + data.id);
    // console.log('datadatadatadatadatadatadatadatadatadatadata',data.id);
  }
  handleOpenToAddPlayerModal = () => {
    this.setState({ addPointTableModal: true });
  }
  handleCloseAddPointTableModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ addPointTableModal: false });
  }
  inputChangePointTableAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsPointTableAdd = this.state.fieldsPointTableAdd;
    let errorsPointTableAdd = this.state.errorsPointTableAdd;
    fieldsPointTableAdd[name] = value;
    errorsPointTableAdd[name] = "";
    this.setState({ fieldsPointTableAdd, errorsPointTableAdd });
  }
  pointTableAddSubmit = () => {
    if (this.handleValidationPointTableAdd()) {
      let { player } = this.props
      let { fieldsPointTableAdd } = player
      let resData = {
        // "image": fieldsPointTableAdd && fieldsPointTableAdd.imageName ? fieldsPointTableAdd.imageName : this.state.fieldsPointTableAdd.image,

        "name": this.state.fieldsPointTableAdd.name,
        "type": this.state.fieldsPointTableAdd.type,

      }

      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(pointTableActions.createPlayerRankingType(resData, data));
    }
  }
  handleValidationPointTableAdd = () => {
    let fieldsPointTableAdd = this.state.fieldsPointTableAdd;
    let errorsPointTableAdd = {};
    let formIsValid = true;

    if (!fieldsPointTableAdd["name"] || fieldsPointTableAdd["name"] === "") {
      formIsValid = false;
      errorsPointTableAdd["name"] = "Cannot be empty";
    }
    if (!fieldsPointTableAdd["type"] || fieldsPointTableAdd["type"] === "") {
      formIsValid = false;
      errorsPointTableAdd["type"] = "Cannot be empty";
    }

    this.setState({ errorsPointTableAdd: errorsPointTableAdd });
    return formIsValid;
  }



  handleOpenToUpdatePlayerModal = (data) => {
    this.setState({ updatePointTableModal: true, fieldsPointTableUpdate: data });
  }
  handleCloseUpdatePointTableModal = () => {
    this.setState({ updatePointTableModal: false });
  }
  inputChangePointTableUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsPointTableUpdate = this.state.fieldsPointTableUpdate;
    let errorsPointTableUpdate = this.state.errorsPointTableUpdate;
    fieldsPointTableUpdate[name] = value;
    errorsPointTableUpdate[name] = "";
    this.setState({ fieldsPointTableUpdate, errorsPointTableUpdate });
  }
  pointTableUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPlayerUpdate()) {
      let { player } = this.props
      let { fieldsPointTableUpdate } = player
      let resData = {
        // "image": fieldsPointTableUpdate && fieldsPointTableUpdate.imageName ? fieldsPointTableUpdate.imageName : this.state.fieldsPointTableUpdate.image,
        "id": this.state.fieldsPointTableUpdate.id,
        "name": this.state.fieldsPointTableUpdate.name,
        "type": this.state.fieldsPointTableUpdate.type
      }

      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(pointTableActions.updatePointsTable(resData, data));
    }
  }
  handleValidationPlayerUpdate = () => {
    let fieldsPointTableUpdate = this.state.fieldsPointTableUpdate;
    let errorsPointTableUpdate = {};
    let formIsValid = true;

    if (!fieldsPointTableUpdate["name"] || fieldsPointTableUpdate["name"] === "") {
      formIsValid = false;
      errorsPointTableUpdate["name"] = "Cannot be empty";
    }
    if (!fieldsPointTableUpdate["type"] || fieldsPointTableUpdate["type"] === "") {
      formIsValid = false;
      errorsPointTableUpdate["type"] = "Cannot be empty";
    }

    console.log('errorsPlayerUpdateerrorsPlayerUpdateerrorsPlayerUpdate', errorsPointTableUpdate);
    this.setState({ errorsPointTableUpdate: errorsPointTableUpdate });
    return formIsValid;
  }


  disablePointsTable = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    let data1 = {
      "seriesId": this.props.match.params.id,
    }


    let resData = data && data.isDisable ? 'Enable' : 'Disable'

    confirmAlert({

      title: `Confirm to ${resData}?`,
      message: `Are you sure to ${resData} ${data.teamId.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(pointTableActions.disablePointsTable(datatemp, pdata, data1))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deletePointsTable = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    let data1 = {
      "seriesId": this.props.match.params.id,
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.teamId.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(pointTableActions.deletePointsTable(datatemp, pdata, data1))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleCreatePointTable = () => {
    this.props.history.push(`/app/createpointtable/${this.props.match.params.id}`)
  }
  handleUpdatePointTable = (data) => {
    this.props.history.push(`/app/updatepointtable/${this.props.match.params.id}/${data.id}`)
  }
  handleBack = () => {
    this.props.history.goBack()
  }
  render() {

    let { player, pointTable } = this.props;
    let { pointItem, pointTotal, getBySeriesId } = pointTable;
    console.log('getBySeriesIdgetBySeriesIdgetBySeriesId', getBySeriesId);
    // console.log("RENDER____rankTyperankTyperankTyperankTyperankType::::", pointItem, pointTotal);
    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='flex pb-3 w-full bg-white p-4 border-b border-gray-200'>
              <div onClick={this.handleBack} className='p-1.5 bg-gray-200 border rounded-full hover:bg-blue-300 hover:text-white'>
                <HiArrowSmLeft className='w-5 h-5 ' />
              </div>
              <h2 className='pl-4 mt-0.5 text-3xl text-gray-400 font-noraml'>Point Table</h2>
            </div>


            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Point Table Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      {/* <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddPlayerModal()}>ADD</button> */}
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'
                        // className="bg-blue-500 text-white py-2 w-64 text-sm px-6 mt-2 md:mt-0 rounded-md font-medium capitalize flex items-center justify-center " 
                        onClick={() => this.handleCreatePointTable()} >
                        {/* <MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  */}
                        Add Point Table </button>
                    </div>
                  </div>
                </div>
                {/* <div className="p-2 whitespace-nowrap text-xl tabletext cursor-pointer">{getBySeriesId && getBySeriesId[0].seriesId && getBySeriesId[0].seriesId.series ? getBySeriesId[0].seriesId.series : null}</div> */}

                {getBySeriesId > 0 ? <div className="p-2 whitespace-nowrap text-xl tabletext cursor-pointer">{getBySeriesId && getBySeriesId[0].seriesId && getBySeriesId[0].seriesId.series ? getBySeriesId[0].seriesId.series : null}</div> : null}
                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">

                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              {/* <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Series Id</th> */}
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Team Id</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Slug</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Match</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Won</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Lost</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Tied</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">NR</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Points</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">NRR</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getBySeriesId && getBySeriesId.length > 0 ?
                                getBySeriesId.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>

                                    {/* <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.seriesId && element.seriesId.name ? element.seriesId && element.seriesId.name : "-"}</td> */}

                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.seriesId && element.teamId.name ? element.teamId && element.teamId.name : "-"}</td>

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.slug ? element.slug : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.mat ? element.mat : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.won ? element.won : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.lost ? element.lost : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.ties ? element.ties : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.nR ? element.nR : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.pts ? element.pts : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.nRR ? element.nRR : "-"}</td>

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      {/* <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.SFKSHFJKFJKHSJKFHKJSF(element)}>More</button>
                                      </div> */}

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleUpdatePointTable(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        {element.isDisable ? <button className='bg-red-500 hover:bg-red-700 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.disablePointsTable(element)}>Enable</button>
                                          :
                                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.disablePointsTable(element)}>Disable</button>}
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.deletePointsTable(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  {/* <AddPlayerDetailsModal
                    addPointTableModal={this.state.addPointTableModal}
                    handleCloseAddPointTableModal={this.handleCloseAddPointTableModal}
                    inputChangePointTableAdd={this.inputChangePointTableAdd}
                    fieldsPointTableAdd={this.state.fieldsPointTableAdd}
                    errorsPointTableAdd={this.state.errorsPointTableAdd}
                    handleFile={this.handleFile}
                    pointTableAddSubmit={this.pointTableAddSubmit}
                  /> */}
                  <UpdatePlayerDetailsModal
                    updatePointTableModal={this.state.updatePointTableModal}
                    handleCloseUpdatePointTableModal={this.handleCloseUpdatePointTableModal}
                    inputChangePointTableUpdate={this.inputChangePointTableUpdate}
                    fieldsPointTableUpdate={this.state.fieldsPointTableUpdate}
                    errorsPointTableUpdate={this.state.errorsPointTableUpdate}
                    handleFile={this.handleFile}
                    pointTableUpdateSubmit={this.pointTableUpdateSubmit}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { pointTable, player } = state;

  console.log('player:::::mapStateToProps:', pointTable, player);

  return {
    pointTable,
    player,
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(PointTable);