// import React from "react";
import React, { useEffect } from "react";
// import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';
import { Grid } from "@material-ui/core";
import Select2 from 'react-select';


export default function UpdatePlayerDetailsModal(props) {

  let { updateSeriesModal, handleCloseUpdateSeriesModal, fieldSeriesUpdate, inputChangeSeriesUpdate, filesDetails, handleFile, seriesUpdateSubmit, getTeamsList, selectedPlayer, handleSelectedPlayer, handleRemovePlayer, handleUpdateSquads } = props;
  // console.log('fieldSeriesUpdate___________________', fieldSeriesUpdate);


  let options = []



  if (getTeamsList && getTeamsList.length > 0) {
    getTeamsList.forEach((emp) => {
      // console.log('emp__________________', emp);

      let result = fieldSeriesUpdate && fieldSeriesUpdate.squads && fieldSeriesUpdate.squads.length && fieldSeriesUpdate.squads.length > 0 ? fieldSeriesUpdate.squads.filter(o => (o.id == emp.id)) : [];

      console.log('resultresultresultresult', result)

      if (result && result.length > 0) {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        options.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        // console.log("ppppppppppppppppppppppppppppppppppppppppp",emp)
        options.push(
          {
            value: emp.id,
            label: emp.name,
            id: emp.id,
            disabled: false
          }
        )
      }
    })
  }


  // console.log('options___________________', fieldSeriesUpdate.squads.length);


  return (

    < >


      <div className={updateSeriesModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">

          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Update Custom Series</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseUpdateSeriesModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          <div className="p-4">
            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">
              <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">

                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">sportId : </label>
                  <input type="text" name="sportId" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["sportId"] ? fieldSeriesUpdate["sportId"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Id : </label>
                  <input type="text" name="series_id" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["series_id"] ? fieldSeriesUpdate["series_id"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Id : </label>
                  <input type="text" name="seriesId" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["seriesId"] ? fieldSeriesUpdate["seriesId"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series : </label>
                  <input type="text" name="series" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["series"] ? fieldSeriesUpdate["series"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Total Matches : </label>
                  <input type="text" name="total_matches" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["total_matches"] ? fieldSeriesUpdate["total_matches"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Series Date : </label>
                  <input type="date" name="series_date" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["series_date"] ? fieldSeriesUpdate["series_date"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>




                <div class="mb-3 w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Start Date : </label>
                  <input type="date" name="start_date" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["start_date"] ? fieldSeriesUpdate["start_date"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>


                <div class="mb-3 w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">End Date : </label>
                  <input type="date" name="end_date" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["end_date"] ? fieldSeriesUpdate["end_date"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">month_wise : </label>
                  <input type="date" name="month_wise" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldSeriesUpdate && fieldSeriesUpdate["month_wise"] ? fieldSeriesUpdate["month_wise"] : ""}
                    onChange={inputChangeSeriesUpdate}
                  ></input>
                </div>
              </div>


              <div class=" w-full">

                <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                  <label htmlFor="resourceList" style={{ color: 'black' }}>Select Squad</label>


                  <Select2
                    width="full"
                    isMulti
                    onChange={handleUpdateSquads}
                    defaultValue={fieldSeriesUpdate && fieldSeriesUpdate.squads && fieldSeriesUpdate.squads.length && fieldSeriesUpdate.squads.length > 0 ? fieldSeriesUpdate.squads.map((element) => ({
                      value: element && element.id ? element.id : null,
                      label: element && element.name ? element.name : null,
                      id: element && element.id ? element.id : null
                    })) : []}
                    options={options}
                    isOptionDisabled={(option) => option.disabled}
                    className="basic-multi-select width: 'full'"
                    classNamePrefix="Activities"
                  />


                </Grid>

              </div>


              <div className="flex mt-1 space-x-4">
                {
                  filesDetails && filesDetails.imageLinkUrl ?
                    <>
                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }
              </div>

              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Image
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <p className="pl-2">Note :128 pixels – 128 pixels</p>
              </div>





              <div className=" mx-auto">
                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={seriesUpdateSubmit}
                >
                  Submit</button>
              </div>
            </form>
          </div>
        </div>

      </div>


    </>
  );
}
