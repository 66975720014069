import React from "react";
import Modal from 'react-modal';


export default function UpdatePlayerDetailsModal(props) {

  let { updatePlayerModal, handleCloseUpdatePlayerModal, editRef,fieldsplayerUpdate,errorsPlayerUpdate, inputChangePlayerUpdate, filesDetails, handleFile, playerUpdateSubmit } = props;

  console.log(fieldsplayerUpdate)
  return (

    <>




      <div className={updatePlayerModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">


          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Update Player</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseUpdatePlayerModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-3">
          <form autoComplete="off" className="p-2 space-y-3 capitalize border border-gray-300 ">
          <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >PlayerId : </label> 
                <input type="text" name="playerId" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["playerId"] ? fieldsplayerUpdate["playerId"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></input>
                     {errorsPlayerUpdate && errorsPlayerUpdate["playerId"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["playerId"]}
                    </div>
                    : null}
              </div>
       

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >Name : </label> 
                <input type="text" name="name" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["name"] ? fieldsplayerUpdate["name"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></input>
                  {errorsPlayerUpdate && errorsPlayerUpdate["name"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["name"]}
                    </div>
                    : null}
              </div>
         

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >Country : </label> 
                <input type="text" name="country" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["country"] ? fieldsplayerUpdate["country"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></input>
                    {errorsPlayerUpdate && errorsPlayerUpdate["country"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["country"]}
                    </div>
                    : null}
              </div>
           

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >Play Role : </label> 
                <input type="text" name="play_role" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["play_role"] ? fieldsplayerUpdate["play_role"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></input>
                    {errorsPlayerUpdate && errorsPlayerUpdate["play_role"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["play_role"]}
                    </div>
                    : null}
              </div>
        

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >Rank : </label> 
                <input type="number" name="rank" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["rank"] ? fieldsplayerUpdate["rank"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></input>
                    {errorsPlayerUpdate && errorsPlayerUpdate["rank"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["rank"]}
                    </div>
                    : null}
              </div>
          

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >Rating : </label> 
                <input type="number" name="rating" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["rating"] ? fieldsplayerUpdate["rating"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></input>
                     {errorsPlayerUpdate && errorsPlayerUpdate["rating"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["rating"]}
                    </div>
                    : null}
              </div>
            </div>
   

         
              <div class=" w-full">
                <label className="block text-xs font-medium text-gray-500 md:text-left" >Description : </label> 
                <textarea style={{resize:'none'}} rows={3} type="text" name="description" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                  value={fieldsplayerUpdate && fieldsplayerUpdate["description"] ? fieldsplayerUpdate["description"] : ""}
                  onChange={inputChangePlayerUpdate}
                ></textarea>
                      {errorsPlayerUpdate && errorsPlayerUpdate["description"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerUpdate["description"]}
                    </div>
                    : null}
              </div>
        
            <div className="flex mt-1 space-x-4">
              {
                filesDetails && filesDetails.imageLinkUrl ?
                  <>
                    <div className="w-full mt-1 mr-2 ">
                      <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                        alt=" " height="20%" width="20%" />
                    </div>
                  </> :
                  null
              }
            </div>

            <div className="flex flex-wrap w-full py-2">
              <label htmlFor="image">
                Upload Image
              </label>
              <input id="image" name="image" ref={editRef} type="file" onChange={handleFile} />
              <p className="pl-2">Note :128 pixels – 128 pixels</p>
            </div>





            <div className="  mx-auto">
              <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2 uppercase px-7  text-sm font-semibold rounded-md text-white focus:outline-none" type="button"
                onClick={playerUpdateSubmit}
              >
                Submit</button>
            </div>
          </form>
        </div>
        </div>
      </div>


    </>
  );
}
