import { playerRankingConstants } from '../_constants';
import { playerRankingService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';
export const playerRankingActions = {
    getPlayerRankingList,
    getAllPlayerRankingList,
    getPlayerRankingListByPlayerRankingTypeId,
    disablePlayerRanking,
    deletePlayerRanking,
    createPlayerRanking,
    updatePlayerRanking,
    // getAllPlayerRankingType
};

function getPlayerRankingList(data) {
    return dispatch => {
        dispatch(request());
        playerRankingService.getPlayerRankingList(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.GET_PLAYER_RANKING_LIST_REQUEST } }
    function success(player) { return { type: playerRankingConstants.GET_PLAYER_RANKING_LIST_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.GET_PLAYER_RANKING_LIST_FAILURE, error } }
}

function getPlayerRankingListByPlayerRankingTypeId(data) {
    return dispatch => {
        dispatch(request());
        playerRankingService.getPlayerRankingListByPlayerRankingTypeId(data)
            .then(
                player => {
                    console.log("getPlayerRankingListByPlayerRankingTypeId_Action:::", player);
                    dispatch(success(player))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_REQUEST } }
    function success(player) { return { type: playerRankingConstants.GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_FAILURE, error } }
}
function getAllPlayerRankingList(data) {
    return dispatch => {
        dispatch(request());
        playerRankingService.getAllPlayerRankingList(data)
            .then(
                player => {
                    console.log("getAllPlayerRankingList___players_Action:::", player);
                    dispatch(success(player))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.GET_ALL_PLAYER_RANKING_REQUEST } }
    function success(player) { return { type: playerRankingConstants.GET_ALL_PLAYER_RANKING_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.GET_ALL_PLAYER_RANKING_FAILURE, error } }
}
// function getAllPlayerRankingType() {
//     return dispatch => {
//         dispatch(request());
//         playerRankingService.getAllPlayerRankingType()
//             .then(
//                 player => {
//                     console.log("getAllPlayerRankingType___players_Action:::", player);
//                     dispatch(success(player))
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: playerRankingConstants.GET_ALL_PLAYER_RANKING_TYPE_REQUEST } }
//     function success(player) { return { type: playerRankingConstants.GET_ALL_PLAYER_RANKING_TYPE_SUCCESS, player } }
//     function failure(error) { return { type: playerRankingConstants.GET_ALL_PLAYER_RANKING_TYPE_FAILURE, error } }
// }

function createPlayerRanking(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankingService.createPlayerRanking(data)
            .then(
                player => {
                    console.log("createPlayerRankingcreatePlayerRanking___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.ADD_PLAYER_RANKING_REQUEST } }
    function success(player) { return { type: playerRankingConstants.ADD_PLAYER_RANKING_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.ADD_PLAYER_RANKING_FAILURE, error } }
}
function updatePlayerRanking(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankingService.updatePlayerRanking(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.UPDATE_PLAYER_RANKING_REQUEST } }
    function success(player) { return { type: playerRankingConstants.UPDATE_PLAYER_RANKING_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.UPDATE_PLAYER_RANKING_FAILURE, error } }
}
function disablePlayerRanking(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankingService.disablePlayerRanking(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.UPDATE_PLAYER_RANKING_STATUS_REQUEST } }
    function success(player) { return { type: playerRankingConstants.UPDATE_PLAYER_RANKING_STATUS_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.UPDATE_PLAYER_RANKING_STATUS_FAILURE, error } }
}
function deletePlayerRanking(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankingService.deletePlayerRanking(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankingConstants.DELETE_PLAYER_RANKING_REQUEST } }
    function success(player) { return { type: playerRankingConstants.DELETE_PLAYER_RANKING_SUCCESS, player } }
    function failure(error) { return { type: playerRankingConstants.DELETE_PLAYER_RANKING_FAILURE, error } }
}