import { userConstants, teamRankingConstants } from '../_constants';
import { userService, teamRankingService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const teamRankingActions = {
    // login,
    // logout,
    disableTeamRanking,
    getAllTeamRankingType,
    createTeamRanking,
    updateTeamRanking,
    getTeamRankingList,
    // getAllCityByStateId,
    deleteTeamRanking,
    uploadImageClear,
    getTeamRankingTypeById,
    getTeamRankingListByRankingType,
};




function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createTeamRanking(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamRankingService.createTeamRanking(data)
            .then(

                teamRanking => {
                    dispatch(alertActions.success("Team Ranking Type Add Successfully."));
                    dispatch(success(teamRanking));
                    dispatch(this.getTeamRankingList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.ADD_TEAM_RANKING_REQUEST } }
    function success(teamRanking) { return { type: teamRankingConstants.ADD_TEAM_RANKING_SUCCESS, teamRanking } }
    function failure(error) { return { type: teamRankingConstants.ADD_TEAM_RANKING_FAILURE, error } }
}
function updateTeamRanking(data, paginationData, props) {

    return dispatch => {
        dispatch(request());
        teamRankingService.updateTeamRanking(data)
            .then(
                teamRankingType => {
                    dispatch(alertActions.success("TeamRankingType Update Successfully."));
                    dispatch(success(teamRankingType));
                    dispatch(this.getTeamRankingList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.UPDATE_TEAM_RANKING_REQUEST } }
    function success(teamRankingType) { return { type: teamRankingConstants.UPDATE_TEAM_RANKING_SUCCESS, teamRankingType } }
    function failure(error) { return { type: teamRankingConstants.UPDATE_TEAM_RANKING_FAILURE, error } }
}
function getAllTeamRankingType(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        teamRankingService.getAllTeamRankingType(data)
            .then(
                teamRanking => {
                    console.log("getAllTeamRankingType $$$$ action:", teamRanking);
                    dispatch(success(teamRanking));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.GETALL_TEAM_RANKING_REQUEST } }
    function success(teamRanking) { return { type: teamRankingConstants.GETALL_TEAM_RANKING_SUCCESS, teamRanking } }
    function failure(error) { return { type: teamRankingConstants.GETALL_TEAM_RANKING_FAILURE, error } }
}
function getTeamRankingList(data) {
    return dispatch => {
        dispatch(request());
        teamRankingService.getTeamRankingList(data)
            .then(
                team => {
                    console.log("getTeamRankingList $$$$ action:", team);
                    dispatch(success(team));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.GET_LIST_TEAM_RANKING_REQUEST } }
    function success(team) { return { type: teamRankingConstants.GET_LIST_TEAM_RANKING_SUCCESS, team } }
    function failure(error) { return { type: teamRankingConstants.GET_LIST_TEAM_RANKING_FAILURE, error } }
}
function getTeamRankingListByRankingType(data, paginationData) {
    return dispatch => {
        dispatch(request());
        teamRankingService.getTeamRankingListByRankingType(data)
            .then(
                team => {
                    console.log("getTeamRankingListByRankingType $$$$ action:", team);
                    dispatch(success(team));
                    // dispatch(this.getTeamRankingList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_REQUEST } }
    function success(team) { return { type: teamRankingConstants.GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_SUCCESS, team } }
    function failure(error) { return { type: teamRankingConstants.GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_FAILURE, error } }
}

// function getTeamRankingTypeList(data) {
//     return dispatch => {
//         dispatch(request());
//         teamRankingService.getTeamRankingTypeList(data)
//             .then(
//                 team => {
//                     console.log("getTeamRankingTypeList $$$$ action:", team);
//                     dispatch(success(team));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: teamRankingConstants.GET_LIST_TEAM_RANKING_REQUEST } }
//     function success(team) { return { type: teamRankingConstants.GET_LIST_TEAM_RANKING_SUCCESS, team } }
//     function failure(error) { return { type: teamRankingConstants.GET_LIST_TEAM_RANKING_FAILURE, error } }
// }
// function getAllCityByStateId(data) {
//     return dispatch => {
//         dispatch(request());
//         teamRankingService.getAllCityByStateId(data)
//             .then(
//                 teamRanking => {
//                     console.log("getAllCityByStateId $$$$ action:", teamRanking);
//                     dispatch(success(teamRanking));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: teamRankingConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST } }
//     function success(teamRanking) { return { type: teamRankingConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS, teamRanking } }
//     function failure(error) { return { type: teamRankingConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE, error } }
// }

function deleteTeamRanking(data, paginationData) {
    return dispatch => {
        dispatch(request());
        teamRankingService.deleteTeamRanking(data)
            .then(
                teamRanking => {
                    dispatch(success(teamRanking));
                    dispatch(this.getTeamRankingList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.DELETE_TEAM_RANKING_REQUEST } }
    function success(teamRanking) { return { type: teamRankingConstants.DELETE_TEAM_RANKING_SUCCESS, teamRanking } }
    function failure(error) { return { type: teamRankingConstants.DELETE_TEAM_RANKING_FAILURE, error } }
}

function getTeamRankingTypeById(data) {

    return dispatch => {
        dispatch(request());
        teamRankingService.getTeamRankingTypeById(data)
            .then(
                teamRanking => {
                    dispatch(success(teamRanking));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.GET_TEAM_RANKING_BY_ID_REQUEST } }
    function success(teamRanking) { return { type: teamRankingConstants.GET_TEAM_RANKING_BY_ID_SUCCESS, teamRanking } }
    function failure(error) { return { type: teamRankingConstants.GET_TEAM_RANKING_BY_ID_FAILURE, error } }
}
function disableTeamRanking(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamRankingService.disableTeamRanking(data)
            .then(
                teamRanking => {
                    dispatch(success(teamRanking));
                    dispatch(this.getTeamRankingList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingConstants.DISABLE_TEAM_RANKING_REQUEST } }
    function success(teamRanking) { return { type: teamRankingConstants.DISABLE_TEAM_RANKING_SUCCESS, teamRanking } }
    function failure(error) { return { type: teamRankingConstants.DISABLE_TEAM_RANKING_FAILURE, error } }
}
