import { authHeader, history } from '../_helpers';
import { CONST, } from '../_config';

export const pointTableService = {
    getPointsTableList,
    getAllPointsTable,
    createPointsTable,
    updatePointsTable,
    disablePointsTable,
    deletePointsTable,
    getPointsTableById,
    getPointsTableBySeriesId,

    login,
    logout,

};


function getPointsTableList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPointsTableList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPointsTableList: data.data
            }

            console.log('serviesd_getPointsTableList', playerObj);

            return playerObj;
        });
}
function getPointsTableBySeriesId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPointsTableBySeriesId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                pointsTableBySeriesId: data.data
            }

            console.log('serviesd_getPointsTableBySeriesId', playerObj);

            return playerObj;
        });
}

function getPointsTableById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPointsTableById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPointsTableById: data.data
            }

            console.log('serviesd_getPointsTableById', playerObj);

            return playerObj;
        });
}
function getAllPointsTable(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPointsTable`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                getAllPointsTable: data.data
            }


            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);



            return playerObj;
        });
}
function createPointsTable(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPointsTable`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                createPointsTable: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function updatePointsTable(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePointsTable`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                updatePointsTable: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function disablePointsTable(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePointsTableStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                disablePointsTable: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function deletePointsTable(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePointsTable`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                deletePointsTable: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}










function logout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
}
function onerrorlogout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
    window.location.reload();
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(player => {
            let playerObj = {
                playerinfo: player
            }
            if (player.data) {
                localStorage.setItem('player', JSON.stringify(player.data));
            }
            return playerObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {

                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}