import { seriesConstants } from '../_constants';
import { seriesService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';
export const seriesActions = {
    getVenuesList,
    deleteVenues,
    getCustomSeriesList,
    getAllCustomSeries,
    disableCustomSeries,
    disableVenues,
    deleteCustomSeries,
    createCustomSeries,
    updateCustomSeries,
};

function getVenuesList(data) {
    return dispatch => {
        dispatch(request());
        seriesService.getVenuesList(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.GET_VENUES_LIST_REQUEST } }
    function success(series) { return { type: seriesConstants.GET_VENUES_LIST_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.GET_VENUES_LIST_FAILURE, error } }
}
function getCustomSeriesList(data) {
    return dispatch => {
        dispatch(request());
        seriesService.getCustomSeriesList(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.GET_CUSTOME_SERIES_LIST_REQUEST } }
    function success(series) { return { type: seriesConstants.GET_CUSTOME_SERIES_LIST_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.GET_CUSTOME_SERIES_LIST_FAILURE, error } }
}

function getAllCustomSeries(data) {
    return dispatch => {
        dispatch(request());
        seriesService.getAllCustomSeries(data)
            .then(
                series => {
                    console.log("getAllCustomSeries___players_Action:::", series);
                    dispatch(success(series))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.GET_ALL_CUSTOM_SERIES_REQUEST } }
    function success(series) { return { type: seriesConstants.GET_ALL_CUSTOM_SERIES_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.GET_ALL_CUSTOM_SERIES_FAILURE, error } }
}

function createCustomSeries(data, pagination) {
    return dispatch => {
        dispatch(request());
        seriesService.createCustomSeries(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getCustomSeriesList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.ADD_CUSTOM_SERIES_REQUEST } }
    function success(series) { return { type: seriesConstants.ADD_CUSTOM_SERIES_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.ADD_CUSTOM_SERIES_FAILURE, error } }
}
function updateCustomSeries(data, pagination) {
    return dispatch => {
        dispatch(request());
        seriesService.updateCustomSeries(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getCustomSeriesList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.UPDATE_CUSTOM_SERIES_REQUEST } }
    function success(series) { return { type: seriesConstants.UPDATE_CUSTOM_SERIES_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.UPDATE_CUSTOM_SERIES_FAILURE, error } }
}
function disableVenues(data, pagination) {
    return dispatch => {
        dispatch(request());
        seriesService.disableVenues(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getVenuesList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.UPDATE_VENUE_STATUS_REQUEST } }
    function success(series) { return { type: seriesConstants.UPDATE_VENUE_STATUS_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.UPDATE_VENUE_STATUS_FAILURE, error } }
}
function disableCustomSeries(data, pagination) {
    return dispatch => {
        dispatch(request());
        seriesService.disableCustomSeries(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getCustomSeriesList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.UPDATE_CUSTOM_SERIES_STATUS_REQUEST } }
    function success(series) { return { type: seriesConstants.UPDATE_CUSTOM_SERIES_STATUS_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.UPDATE_CUSTOM_SERIES_STATUS_FAILURE, error } }
}
function deleteCustomSeries(data, pagination) {
    return dispatch => {
        dispatch(request());
        seriesService.deleteCustomSeries(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getCustomSeriesList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.DELETE_CUSTOM_SERIES_REQUEST } }
    function success(series) { return { type: seriesConstants.DELETE_CUSTOM_SERIES_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.DELETE_CUSTOM_SERIES_FAILURE, error } }
}
function deleteVenues(data, pagination) {
    return dispatch => {
        dispatch(request());
        seriesService.deleteVenues(data)
            .then(
                series => {
                    console.log("getPlayerList___players_Action:::", series);
                    dispatch(success(series));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getVenuesList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: seriesConstants.DELETE_VENUES_REQUEST } }
    function success(series) { return { type: seriesConstants.DELETE_VENUES_SUCCESS, series } }
    function failure(error) { return { type: seriesConstants.DELETE_VENUES_FAILURE, error } }
}