import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions,
  //  userActions,
    categoryActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Multiselect from 'multiselect-react-dropdown';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { HiArrowSmLeft } from "react-icons/hi";
import "./style.css"

class CreateUpdatePasswordGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        story: ``
      },
      errorsAddUser: {
        content: [""],
      },
      fieldsRowData: {},
      // fieldsRowData: {
      //   content: [""],
      // },
      errorsUpdatePassword: {
        content: [""],
      },
      viewRowData: {},
      inputAddUserChange: [],
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      newsContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ],
      imageUploader: {
        upload: async file => {

          const bodyFormData = new FormData();
          bodyFormData.append("image", file);
          const response = await fetch({
            method: "post",
            // header: header,
            url:
              "https://news.fastline.one/api/dadmin/uploadImage",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MmVlNTViYjUxZDczMTFjNjhhODE3NmUiLCJpYXQiOjE2Njg1MDE5MzV9.6m19t-fGB0xpFnHcrIlNYDGhs380lk5dDHgWEaeEUKk"
            }
          });
          return response.data.data.url;
        }
      }
    };

    this.formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "align",
      "color",
      "link",
      "image",
      "imageBlot",
      "background"
    ];

  }

  componentDidMount() {
    // let data = {

    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    this.handleOpenCreateModal();
    // this.props.dispatch(newsActions.getNewsList(data));
    // this.props.dispatch(categoryActions.getAllCategory());

    let id = this.props.match.params.id
    this.props.dispatch(newsActions.getNewsById({ id: id }));

    this.props.dispatch(categoryActions.getCategory());
    // this.props.dispatch(categoryActions.getSubCategoryById());

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.news && nextProps.news.filesDetails && nextProps.news.filesDetails.imageName ? nextProps.news.filesDetails.imageName : null,
        imageUrl: nextProps.news && nextProps.news.filesDetails && nextProps.news.filesDetails.imageURL ? nextProps.news.filesDetails.imageURL : null

      }
    }

    if (nextProps.news.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsRowData: nextProps.news && nextProps.news.newsRowData ? nextProps.news.newsRowData : null,
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNewsList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(newsActions.getNewsList(data));
  }
  disableNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.author} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.disableNews(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNews(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/viewdetails/" + data.id)
  }


  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsRowData: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(newsActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['news_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsRowData = this.state.fieldsRowData;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsRowData[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsRowData, errorsUpdatePassword });
  }

  addNewsSubmit = () => {
    if (this.handleValidationAddNews()) {
      let reqData = {
        "news_id": this.state.fieldsAddUser.news_id.toString(),
        "title": this.state.fieldsAddUser.title,
        "author": this.state.fieldsAddUser.author,
        // "descirption": this.state.fieldsAddUser.descirption,
        "image": this.state.fieldsAddUser && this.state.fieldsAddUser.image ? this.state.fieldsAddUser.image : null,
        "tag": this.state.selectedTag ? this.state.selectedTag : [],
        // "slug": this.state.fieldsAddUser.slug,
        "category": this.state.fieldsAddUser.category,
        "subcategory": this.state.fieldsAddUser.subcategory,
        "story": this.state.fieldsAddUser.story,
        "newsType": this.state.fieldsAddUser.newsType,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(newsActions.createNews(reqData, paginationData), this.props);
    }
  }

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //news_id
    if (!fieldsAddUser["news_id"] || fieldsAddUser["news_id"] === "") {
      formIsValid = false;
      errorsAddUser["news_id"] = "Cannot be empty";
    }

    //title
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //author
    if (!fieldsAddUser["author"] || fieldsAddUser["author"] === "") {
      formIsValid = false;
      errorsAddUser["author"] = "Cannot be empty";
    }

    //category
    if (!fieldsAddUser["category"] || fieldsAddUser["category"] === "") {
      formIsValid = false;
      errorsAddUser["category"] = "Cannot be empty";
    }

    //subcategory
    if (!fieldsAddUser["subcategory"] || fieldsAddUser["subcategory"] === "") {
      formIsValid = false;
      errorsAddUser["subcategory"] = "Cannot be empty";
    }
    //newsType
    if (!fieldsAddUser["newsType"] || fieldsAddUser["newsType"] === "") {
      formIsValid = false;
      errorsAddUser["newsType"] = "Cannot be empty";
    }

    //story
    if (!fieldsAddUser["story"] || fieldsAddUser["story"] === "") {
      formIsValid = false;
      errorsAddUser["story"] = "Cannot be empty";
    }


    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsRowData.id,
        "news_id": this.state.fieldsRowData.news_id,
        "title": this.state.fieldsRowData.title,
        "author": this.state.fieldsRowData.author,
        "image": this.state.fieldsRowData && this.state.fieldsRowData.image ? this.state.fieldsRowData.image : null,
        "tag": this.state.updateSelectedTag ? this.state.updateSelectedTag : this.state.fieldsRowData.content,
        "category": this.state.fieldsRowData.category,
        "subcategory": this.state.fieldsRowData.subcategory,
        "story": this.state.fieldsRowData.story,
        "newsType": this.state.fieldsRowData.newsType,
      }

      let paginationData = {
        "newsType": "MANUAL",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(newsActions.updateNews(reqData, paginationData, this.props,));
    }

  }


  handleValidationUpdateNews = () => {
    let fieldsRowData = this.state.fieldsRowData;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //news_id
    if (!fieldsRowData["news_id"] || fieldsRowData["news_id"] === "") {
      formIsValid = false;
      errorsUpdatePassword["news_id"] = "Cannot be empty";
    }
    //title
    if (!fieldsRowData["title"] || fieldsRowData["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //author
    if (!fieldsRowData["author"] || fieldsRowData["author"] === "") {
      formIsValid = false;
      errorsUpdatePassword["author"] = "Cannot be empty";
    }
    //category
    if (!fieldsRowData["category"] || fieldsRowData["category"] === "") {
      formIsValid = false;
      errorsUpdatePassword["category"] = "Cannot be empty";
    }
    // newsType
    if (!fieldsRowData["newsType"] || fieldsRowData["newsType"] === "") {
      formIsValid = false;
      errorsUpdatePassword["newsType"] = "Cannot be empty";
    }
    //story
    if (!fieldsRowData["story"] || fieldsRowData["story"] === "") {
      formIsValid = false;
      errorsUpdatePassword["story"] = "Cannot be empty";
    }


    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsRowData } = this.state;

    fieldsRowData.content.push('')
    this.setState({ fieldsRowData })
    console.log("fieldsRowData  ", fieldsRowData);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsRowData } = this.state;
    fieldsRowData.content.splice(index, 1)
    this.setState({ fieldsRowData });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsRowData = this.state.fieldsRowData;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsRowData);
    fieldsRowData["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsRowData });
  }



  handleUploadImageUser = () => {
    const { fieldsAddUser, fieldsRowData, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsRowData['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsRowData['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsRowData });
  }

  addTagField = () => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }

  tagFieldUpdate = () => {
    const { fieldsRowData } = this.state;
    fieldsRowData.tag.push('')
    this.setState({ fieldsRowData })
    // console.log("fieldsRowData  ", fieldsRowData);
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsRowData } = this.state;
    fieldsRowData.tag.splice(index, 1)
    this.setState({ fieldsRowData });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsRowData = this.state.fieldsRowData;
    fieldsRowData["tag"][this.state.currentConetntUpdateIndex] = value;
    console.log("fieldsTag*****:  ", fieldsRowData);
    this.setState({ fieldsRowData });
  }

  handleSelectedTag = (selectedTag) => {
    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {
    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["story"] = editor.getHTML();
    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters
    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsRowData = this.state.fieldsRowData;
    fieldsRowData["story"] = editor.getHTML();
    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters
    this.setState({ fieldsRowData });
  };

  rteChangeTextUpdate = (e) => {


    e.preventDefault();
    let { value } = e.target;

    let fieldsRowData = this.state.fieldsRowData;
    fieldsRowData["story"] = value;

    this.setState({ fieldsRowData });
  };


  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(newsActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleUpload = () => {
    console.log('______________________', this.state.imageWebLink);
    const { fieldsAddUser, fieldsRowData, imageWebLink, imageUrl } = this.state;

    fieldsAddUser['image'] = imageWebLink;
    fieldsRowData['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsRowData['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsRowData });
  }

  handleBack = () => {
    this.props.history.goBack()
  }


  handleCategorySelectUpdate = (e) => {
    e.preventDefault();
    let { value, id } = e.target;
    const { fieldsRowData } = this.state;

    let { category } = this.props;

    let { getCategoryItems, subCategoryItems } = category;
    console.log("idididididid::", getCategoryItems[e.target.selectedIndex - 1].shortName);
    // shortName
    // fieldsRowData['category'] = value;
    fieldsRowData['category'] = getCategoryItems[e.target.selectedIndex - 1].shortName;
    this.setState({ fieldsRowData });
    this.props.dispatch(categoryActions.getSubCategoryById({ parentId: getCategoryItems[e.target.selectedIndex - 1].id }));
  }
  handleSubCategorySelectUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    const { fieldsRowData } = this.state;
    console.log("&&&&&&&&&&&&&&&&&&&&&::", value);

    fieldsRowData['subcategory'] = value;
    this.setState({ fieldsRowData });
  }
  handleNewstypeSelectUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    const { fieldsRowData } = this.state;
    console.log("&&&&&&&&&&&&&&&&&&&&&::", value);

    fieldsRowData['newsType'] = value;
    this.setState({ fieldsRowData });
  }

  render() {

    let { modules, formats, users, news, category } = this.props;
    let { filesDetails, imageUploadSuccess } = news;
    let { updateUserSuccess } = news;
    let { getCategoryItems, subCategoryItems } = category;

    console.log("RENDER__fieldsUpdatePassword****:", this.state.fieldsRowData);

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]

    return (

      <>
        <div className="h-screen m-4 overflow-hidden overflow-y-auto">
          <div className="overflow-y-auto bg-white ">
            <div className="w-full px-6 py-4 text-left">
              <div className="flex pb-3">
                <div onClick={this.handleBack} className='p-1.5 bg-gray-200 border rounded-full hover:bg-blue-300 hover:text-white'>
                  <HiArrowSmLeft className='w-5 h-5 ' />
                </div>
                <p className="pl-4 mt-0.5 text-2xl font-bold">View News</p>
              </div>

              <form autoComplete="off">
                <div className="flex justify-between w-full">

                  <div class="mr-2 w-full">
                    <label className="mt-2 mr-2 text-sm font-medium text-black">News Type :</label>
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" onChange={this.handleNewstypeSelectUpdate} id="newsType" name="newsType"
                      value={this.state.fieldsRowData && this.state.fieldsRowData.newsType ? this.state.fieldsRowData.newsType : null} disabled>
                      <option selected>Plz Select Sub-Category</option>
                      <option value="MANUAL">MANUAL</option>
                      <option value="PTI">PTI</option>
                    </select>
                    {this.state.errorsUpdatePassword && this.state.errorsUpdatePassword["newsType"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {this.state.errorsUpdatePassword["newsType"]}
                      </div>
                      : null}
                  </div>

                  <div class="mr-2 w-full">
                    <label className="text-sm text-gray-700">Category :</label>
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example"
                      id="category" name="category"
                      value={this.state.fieldsRowData && this.state.fieldsRowData.category ? this.state.fieldsRowData.category : null}
                      onChange={this.handleCategorySelectUpdate}
                      disabled >
                      <option selected>Plz Select Category</option>
                      {
                        getCategoryItems && getCategoryItems && getCategoryItems.length > 0 ?
                          getCategoryItems.map((element, index) => (
                            <option key={index} name={element && element.shortName ? element.shortName : null} value={element && element.shortName ? element.shortName : null}>{element && element.name ? element.name : "NA"}</option>
                          )) : null
                      }
                    </select>
                    {this.state.errorsUpdatePassword && this.state.errorsUpdatePassword["category"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {this.state.errorsUpdatePassword["category"]}
                      </div>
                      : null}
                  </div>

                  <div class="mr-2 w-full">
                    <label className="text-sm text-gray-700">Sub Category :</label>
                    {/* <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example"
                      onChange={this.inputChange} id="subcategory" name="subcategory"
                      value={this.state.fieldsRowData && this.state.fieldsRowData.subcategory ? this.state.fieldsRowData.subcategory : null}>
                      <option selected>Plz Select Category</option>
                      {
                        subCategoryItems && subCategoryItems && subCategoryItems.length > 0 ?
                          subCategoryItems.map((element, index) => (
                            <option value={element.value}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select> */}


                    {/* {JSON.stringify(subCategoryItems)} */}
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" onChange={this.handleSubCategorySelectUpdate} id="subcategory" name="subcategory"
                      value={this.state.fieldsRowData && this.state.fieldsRowData.subcategory ? this.state.fieldsRowData.subcategory : null} disabled>
                      <option selected>Plz Select Sub-Category</option>
                      {
                        subCategoryItems && subCategoryItems && subCategoryItems.length > 0 ?
                          subCategoryItems.map((element, index) => (
                            <option value={element && element.shortName ? element.shortName : null}>{element && element.name ? element.name : "NA"}</option>
                          )) : null
                      }
                    </select>
                    {this.state.errorsUpdatePassword && this.state.errorsUpdatePassword["subcategory"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {this.state.errorsUpdatePassword["subcategory"]}
                      </div>
                      : null}
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">News Id</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsUpdatePassword && !this.state.errorsUpdatePassword["news_id"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="news_id" name="news_id" placeholder="News Id" value={this.state.fieldsRowData.news_id} type="text"
                        // onChange={this.inputChange}
                        disabled />
                      {this.state.errorsUpdatePassword && this.state.errorsUpdatePassword["news_id"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsUpdatePassword["news_id"]}
                        </div>
                        : null}
                    </div>
                  </div>

                </div>

                <div className="flex justify-between w-full mt-2">

                  <div className="w-full mr-2">
                    <div className="relative mt-1 shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">Title</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsUpdatePassword && !this.state.errorsUpdatePassword["title"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="title" name="title" placeholder="Title" value={this.state.fieldsRowData.title} type="title" onChange={this.inputChange} disabled/>
                      {this.state.errorsUpdatePassword && this.state.errorsUpdatePassword["title"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsUpdatePassword["title"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <label class="block text-gray-700 text-sm" for="username">Author</label>
                      <input className={`border-1 w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsUpdatePassword && !this.state.errorsUpdatePassword["author"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="author" name="author" placeholder="Author" value={this.state.fieldsRowData.author} type="text" onChange={this.inputChange} disabled/>
                      {this.state.errorsUpdatePassword && this.state.errorsUpdatePassword["author"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsUpdatePassword["author"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>


                <div className="flex justify-between w-full mt-2">
                  <div className="w-full mr-2">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <ReactQuill
                        modules={modules}
                        formats={formats}
                        onChange={this.rteChangeUpdate}
                        value={this.state.fieldsRowData.story}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>


                <div className='mt-2 mr-2'>
                  <label className="mt-2 mr-2 text-sm font-medium text-black">HTML as text: :</label>
                  <span className=''>
                    <textarea className='border border-gray-400' name='story'
                      onChange={this.rteChangeTextUpdate}
                      value={this.state.fieldsRowData.story}
                      rows={5}
                      // readOnly
                      style={{ width: '100%' }} disabled/>
                  </span>
                </div>


                <label class="block text-gray-700 text-sm" for="username">Tag :</label>
                <div className="grid grid-cols-1 gap-4 mt-2 md:grid-cols-3">
                  <Multiselect
                    options={options}
                    onSelect={this.handleSelectedTagUpdate}
                    selected={this.state.updateSelectedTag}
                    isObject={false}
                    displayValue="label"
                    selectedValues={(this.state.fieldsRowData && this.state.fieldsRowData.tag && this.state.fieldsRowData.tag.length > 0) ? this.state.fieldsRowData.tag.map((val) => { return (val ? val : "N/A") }) : "-"}
                    disabled
                  />
                </div>

                <div className="grid grid-cols-1 gap-4 mt-2 md:grid-cols-3">
                  {
                    this.state.fieldsRowData && this.state.fieldsRowData.content && this.state.fieldsRowData.content.length > 0 ?
                      this.state.fieldsRowData.content.map((item, index) => (
                        <React.Fragment key={index}>
                          <>
                            <div>
                              {index === 0 ?
                                <button className="px-4 py-1 text-base font-semibold text-white border rounded-lg cursor-pointer bg-shine-400" type="button" onClick={() => this.contentFieldUpdate(index)}>Add</button>
                                :
                                <button className="px-2 py-1 text-sm font-semibold text-white bg-red-500 border rounded-lg cursor-pointer" type="button" onClick={() => this.deleteContentFieldUpdate(index)}>Delete</button>
                              }
                              <label class="block text-gray-700 text-sm" for="username">Content : {this.state.offset + index + 1}</label>
                              <input className={`border-1 w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsUpdatePassword && !this.state.errorsUpdatePassword["content"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                                id="content" name="content" placeholder="content" value={item}
                                onClick={() => this.setCurrentIndexUpdate(index)}
                                type="text" onChange={this.inputChangeContentUpdate} />
                            </div>
                          </>
                        </React.Fragment>
                      )) : null
                  }
                </div>

                <div className="grid grid-cols-2 gap-4 mt-2 md:grid-cols-2">
                  <div className="w-full mr-2">
                    {
                      this.state.fieldsRowData && this.state.fieldsRowData.imageUrlLink ?
                        <>
                          <div className="w-full mt-1 mr-2 ">
                            <img src={this.state.fieldsRowData && this.state.fieldsRowData.imageUrlLink ? this.state.fieldsRowData.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        <div className="w-full mt-1 mr-2 ">
                          <img src={this.state.fieldsRowData && this.state.fieldsRowData.imageLinkUrl ? this.state.fieldsRowData.imageLinkUrl : "NA"}
                            alt=" " height="20%" width="20%" />
                        </div>
                    }
                    
                    
                    {/* <div className="relative mt-1 shadow-md">
                      <div className="flex flex-wrap justify-between w-full p-2 border border-gray-300">
                        <div className="">
                          <label class="block text-gray-700 text-sm" for="username">News Image</label>
                          <input type="file" name="image" onChange={this.handleFile} disabled/>
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>
                        <div className="w-20 mt-1 space-x-4">
                          <button className="px-4 py-1 text-base font-semibold text-white bg-blue-500 border rounded-lg cursor-pointer" type="button" onClick={() => this.handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div> */}


                  </div>
                </div>

                {/* <div className="mx-auto mt-6 w-60">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out border rounded-full bg-shine-400 border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button" onClick={this.updateNewsSubmit}>Submit</button>
                </div> */}

              </form>

            </div>
          </div>
        </div >

      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category } = state;
  return {
    news,
    users,
    category
  };

}
export default connect(mapStateToProps)(CreateUpdatePasswordGame);
