export const matchConstants = {

    GET_LIST_CUSTOM_MATCH_REQUEST: 'GET_LIST_CUSTOM_MATCH_REQUEST',
    GET_LIST_CUSTOM_MATCH_SUCCESS: 'GET_LIST_CUSTOM_MATCH_SUCCESS',
    GET_LIST_CUSTOM_MATCH_FAILURE: 'GET_LIST_CUSTOM_MATCH_FAILURE',

    ADD_CUSTOM_MATCH_REQUEST: 'ADD_CUSTOM_MATCH_REQUEST',
    ADD_CUSTOM_MATCH_SUCCESS: 'ADD_CUSTOM_MATCH_SUCCESS',
    ADD_CUSTOM_MATCH_FAILURE: 'ADD_CUSTOM_MATCH_FAILURE',

    UPDATE_CUSTOM_MATCH_REQUEST: 'UPDATE_CUSTOM_MATCH_REQUEST',
    UPDATE_CUSTOM_MATCH_SUCCESS: 'UPDATE_CUSTOM_MATCH_SUCCESS',
    UPDATE_CUSTOM_MATCH_FAILURE: 'UPDATE_CUSTOM_MATCH_FAILURE',

    UPDATE_MATCH_STATUS_REQUEST: 'UPDATE_MATCH_STATUS_REQUEST',
    UPDATE_MATCH_STATUS_SUCCESS: 'UPDATE_MATCH_STATUS_SUCCESS',
    UPDATE_MATCH_STATUS_FAILURE: 'UPDATE_MATCH_STATUS_FAILURE',

    DISABLE_CUSTOM_MATCH_REQUEST: 'DISABLE_CUSTOM_MATCH_REQUEST',
    DISABLE_CUSTOM_MATCH_SUCCESS: 'DISABLE_CUSTOM_MATCH_SUCCESS',
    DISABLE_CUSTOM_MATCH_FAILURE: 'DISABLE_CUSTOM_MATCH_FAILURE',

    DELETE_CUSTOM_MATCH_REQUEST: 'DELETE_CUSTOM_MATCH_REQUEST',
    DELETE_CUSTOM_MATCH_SUCCESS: 'DELETE_CUSTOM_MATCH_SUCCESS',
    DELETE_CUSTOM_MATCH_FAILURE: 'DELETE_CUSTOM_MATCH_FAILURE',

    UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_REQUEST: 'UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_REQUEST',
    UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_SUCCESS: 'UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_SUCCESS',
    UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_FAILURE: 'UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_FAILURE',

    GET_ALL_TEAM_REQUEST: 'GET_ALL_TEAM_REQUEST',
    GET_ALL_TEAM_SUCCESS: 'GET_ALL_TEAM_SUCCESS',
    GET_ALL_TEAM_FAILURE: 'GET_ALL_TEAM_FAILURE',

};
