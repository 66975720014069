import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import moment from 'moment'
import Modal from 'react-modal';
import { HiPencilAlt } from "react-icons/hi";


class AdminList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      declareMatchModal: false,
      tossDeclareModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      tossDeclareDetails: {},
      fieldsMatch: {},
      errorsMatch: {},
      marketIdActiveFancy: "",
      activeFancyList: false,
      currentFancyIndex: null,
      matchDetails: {},
      fieldsMatchFancy: {},
      errorsMatchFancy: {},
      fancyDetails: {},
      declareFancyModal: false,
      declareFancyModal: false,
      offset: 0,
    }
  }

  componentDidMount() {

    this.props.dispatch(userActions.getAllMatchActive());
    this.props.dispatch(userActions.upcomingMatches());
    this.props.dispatch(userActions.liveMatchList());
  }

  static getDerivedStateFromProps(nextProps, prevState) {


    if (nextProps.users.addUserSuccess) {

      return {
        ...nextProps,
        fieldsMatch: {},
        errorsMatch: {},
        declareMatchModal: false,
        fieldsMatchFancy: {},
        errorsMatchFancy: {},
        declareFancyModal: false,
        setFmatchId: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  //ScoreBoard
  handleOpenScoreBoardModal = (data) => {
    this.props.history.push('/app/LiveScoreBoard/' + data._id);
  }
  //ScoreBoard
  handleOpenMatchInfoModal = (data) => {
    this.props.history.push('/app/matchInfo/' + data._id);
  }




  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  declareResultSubmit = () => {
    let data = {
      "matchId": this.state.declareMatchDetails.matchId,
      "marketId": this.state.declareMatchDetails.marketId,
      "selectionId": this.state.fieldsMatch.selectionId,
      "selectionName": this.state.fieldsMatch.selectionName
    }

    console.log("declareResultSubmit___________data:::", data);
    this.props.dispatch(userActions.declareResult(data));
  }

  tossDeclareResultSubmit = () => {
    let data = {
      "matchId": this.state.tossDeclareDetails.matchId,
      "marketId": this.state.tossDeclareDetails.marketId,
      "selectionId": this.state.fieldsMatch.selectionId,
      "selectionName": this.state.fieldsMatch.selectionName
    }

    console.log("tossDeclareResultSubmit___________data:::", data);
    this.props.dispatch(userActions.tossResultDeclare(data));
  }

  navigate = (data) => {
    console.log("navigate___matchId", data._id);
    this.props.history.push('/app/ChangePassword/' + data._id);
  }

  navigateAdminDetails = (data) => {
    console.log("navigate___matchId", data._id);
    this.props.history.push('/app/AdminDetails/' + data._id);
  }

  handleOpenDeclareMatchModal = (data) => {
    this.setState({ declareMatchModal: true, declareMatchDetails: data });
    this.props.dispatch(userActions.getSelectionByMarketId({ marketId: data.marketId }));

  }

  handleOpenMoreDetailsModal = (data) => {
    console.log('111111111111111111111111111111111111111111111datadatattat', data);
    this.props.history.push('/app/matchMoreDetail/' + data.sportId + "/" + data.seriesId + "/" + data.matchId + "/" + data.marketId + "/" + data._id);
  }

  handleOpenFancyModal = (data) => {
    this.props.history.push('/app/fancyResultDeclare/' + data.sportId + "/" + data.seriesId + "/" + data.matchId + "/" + data.marketId);
  }

  handleOpenLiveMatchModal = (data) => {
    this.props.history.push('/app/liveMatchScore/' + data.sportId + "/" + data.seriesId + "/" + data.matchId + "/" + data._id + "/" + data.marketId);
  }

  handleOpenTossDeclareMatchModal = (data) => {
    this.setState({ tossDeclareModal: true, tossDeclareDetails: data });
    this.props.dispatch(userActions.getSelectionByMarketId({ marketId: data.marketId }));

  }

  handleCloseTossDeclareMatchModal = () => {
    this.setState({ tossDeclareModal: false });
  }

  handleCloseDeclareMatchModal = () => {
    this.setState({ declareMatchModal: false });
  }

  ///////////////////////////////////////////////////////////////////////

  handleOpenFmatchIdModal = (data) => {
    // console.log("data_________:::", data);
    this.setState({ setFmatchId: true, rowMatchId: data._id });
  }

  handleOpenActiveFancyList = (data) => {
    // console.log("data_________:::555", data);
    this.setState({ marketIdActiveFancy: data.marketId, activeFancyList: true, matchDetails: data });
    this.props.dispatch(userActions.getAllActiveFancy({ marketId: data.marketId }));
  }

  handleDeclareFancySubmit = () => {

    let { matchDetails } = this.state;

    let reqData = {
      "marketId": this.state.fancyDetails && this.state.fancyDetails.marketId ? this.state.fancyDetails.marketId : null,
      "matchId": this.state.fancyDetails && this.state.fancyDetails.matchId ? this.state.fancyDetails.matchId : null,
      "selectionId": this.state.fancyDetails && this.state.fancyDetails.SelectionId ? this.state.fancyDetails.SelectionId.toString() : null,
      "sportId": matchDetails && matchDetails.sportId ? matchDetails.sportId : "",
      "seriesId": matchDetails && matchDetails.seriesId ? matchDetails.seriesId : "",
      "selectionName": this.state.fancyDetails && this.state.fancyDetails.RunnerName ? this.state.fancyDetails.RunnerName : null,
      "matchName": matchDetails && matchDetails.name ? matchDetails.name : "",
      "result": this.state.fieldsMatchFancy && this.state.fieldsMatchFancy.result ? parseInt(this.state.fieldsMatchFancy.result) : null
    }

    console.log('reqData????????????/', reqData);
    this.props.dispatch(userActions.fancyResultDeclare(reqData));
  }

  handleOpenActiveFancyListClose = (data) => {
    console.log("data_________:::555", data);
    this.setState({ marketIdActiveFancy: '', activeFancyList: false });
  }

  handleCloseFmatchIdModal = () => {
    this.setState({ setFmatchId: false, fieldsMatch: {} });
  }


  inputChangeFmatchId = (event) => {
    event.preventDefault();
    const { fieldsMatch, errorsMatch } = this.state;
    fieldsMatch[event.target.name] = event.target.value;
    errorsMatch[event.target.name] = "";
    console.log(event.target.name, event.target.value);
    this.setState({ fieldsMatch, errorsMatch });

  }



  inputChangeFancy = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    const { fieldsMatchFancy, errorsMatchFancy } = this.state;
    fieldsMatchFancy[name] = value;
    errorsMatchFancy[name] = "";
    console.log(name, value);
    this.setState({ fieldsMatchFancy, errorsMatchFancy });
  }

  handleCurrentFancy = (index) => {
    // console.log('index????????//', index);
    this.setState({ currentFancyIndex: index });
  }


  inputChangeSelectCoin = (event) => {
    event.preventDefault();

    let { users } = this.props;
    let { selectionIdMarketWise } = users;

    console.log("RENDER_____activeMatch::", selectionIdMarketWise);

    let result = selectionIdMarketWise.filter((element) => (element.selectionId == event.target.value))
    console.log('result_____:::::::::::::::', result);
    console.log('result_____:::::::::::::::', result[0].name);



    const { fieldsMatch, errorsMatch } = this.state;
    fieldsMatch["selectionName"] = result[0].name;
    fieldsMatch[event.target.name] = event.target.value;
    errorsMatch[event.target.name] = "";
    console.log(event.target.name, event.target.value);

    this.setState({ fieldsMatch, errorsMatch, currentSelectedCoin: JSON.parse(event.target.value) });

  }


  updateMatchStatus = (data) => {

    console.log("%%%%%%%%%%%%%%%%%%%@@@.id:", data);

    // if (this.handleValidationAddUser()) {
    let reqData = {
      "id": data._id
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>Id$", reqData);
    this.props.dispatch(userActions.updateMatchStatus(reqData));
    // }

  }

  updateMatchFmIdSubmit = (data) => {

    console.log("%%%%%%%%%%%%%%%%%%%@@@.id:", data);

    // if (this.handleValidationAddUser()) {
    let reqData = {
      "id": this.state.rowMatchId,
      "fmId": this.state.fieldsMatch.fmId
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>Id$", reqData);
    this.props.dispatch(userActions.updateMatchfmId(reqData));
    // }

  }

  handleOpenDeclareFancyModal = (data) => {
    this.setState({ declareFancyModal: true, fancyDetails: data });
    // this.props.dispatch(userActions.getSelectionByMarketId({ marketId: data.marketId }));

  }

  handleCloseDeclareFancyModal = () => {
    this.setState({ declareFancyModal: false });
  }


  render() {

    let { users } = this.props;
    let { activeMatch, selectionIdMarketWise, upcomingMatchesList, liveMatchItems, allActiveFancy } = users;

    console.log("RENDER_____this.state.fieldsMatchFancy", this.state.fieldsMatchFancy);
    console.log("RENDER_____this.state.fancyDetails", this.state.fancyDetails);

    return (

      <>
        <div className='w-full h-full page_bg'>
          <div className='py-2'>
            <marquee className="text-white text-sm font-semibold font-serif py-2 bgheader">!! Welcome To API PROVIDER CP2 27-5-2022(( RR VS KKR )) AT 7:30 PM LIVE ON STARS SPORT!!</marquee>
          </div>

          <div className='w-full bg-white p-4 border-b border-gray-200'>
            <h2 className='text-2xl text-gray-500  capitalize'>Active Match Page</h2>
          </div>

          <div className='p-6'>
            <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
              <h2 className='text-base font-semibold text_color'>Active Match</h2>

            </div>
            <div className='bg-white pb-10'>
              <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                <div className='flex items-center space-x-4'>

                  <div className="">
                    <label className='text-sm text-gray-500 mr-2'>Show:</label>
                    <div className="dropdown inline-block relative">
                      <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                        <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex justify-between whitespace-nowrap space-x-4'>
                  <div>
                    <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                    <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                  </div>
                </div>
              </div>

              <div className="rounded-lg overflow-hidden pb-2 p-6">
                <div className="overflow-auto max-w-full ">
                  <div className="inline-block min-w-full  ">
                    <div className="overflow-hidden  ">
                      <table className="min-w-full divide-y divide-gray-800 border-0">
                        <thead className="rounded-t">
                          <tr className="uppercase text-left text-sm font-semibold text-gray-600 bg-white">
                            <th className="whitespace-nowrap p-2 bg-gray-100">#</th>
                            <th className="whitespace-nowrap p-2">Name</th>
                            <th className="whitespace-nowrap p-2">SPORTID</th>
                            <th className="whitespace-nowrap p-2">SERIESID</th>
                            <th className="whitespace-nowrap p-2">MATCHID</th>
                            <th className="whitespace-nowrap p-2">MARKETID</th>
                            <th className="whitespace-nowrap p-2">MATCHDATE</th>
                            <th className="whitespace-nowrap p-2">F_MATCH_ID</th>
                            <th className="whitespace-nowrap p-2">Match Custom / Betfair</th>
                            <th className="whitespace-nowrap p-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            activeMatch && activeMatch.length > 0 ?
                              activeMatch.map((element, index) => (<React.Fragment key={element.id}>
                                <tr key={element.id} className="border-b border-black border-opacity-10 text-sm text-gray-600">
                                  <td className="p-2 whitespace-nowrap">{index + 1}</td>
                                  <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                  <td className="p-2 whitespace-nowrap text-sm">{element && element.sportId ? element.sportId : "-"}</td>
                                  <td className="p-2 whitespace-nowrap">{element && element.seriesId ? element.seriesId : "-"}</td>
                                  <td className="p-2 whitespace-nowrap">{element && element.matchId ? element.matchId : 0}</td>
                                  <td className="p-2 whitespace-nowrap">{element && element.marketId ? element.marketId : 0}</td>
                                  <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.openDate ? moment(element.openDate).format("YYYY-MM-DD HH:mm") : 0}</td>
                                  {/* <td className="p-2 whitespace-nowrap">{element && element.openDate ? element.openDate : 0}</td> */}
                                  <td className="p-2 whitespace-nowrap">{element && element.fmId ? element.fmId : 0}</td>
                                  <td className="p-2 whitespace-nowrap">{element && element.isCustomMatch ? "Custom" : "Betfair"}</td>

                                  <td className="p-2 whitespace-nowrap flex space-x-2">

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenScoreBoardModal(element)}>Score Board</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenMatchInfoModal(element)}>Match Info</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenLiveMatchModal(element)}>Live Match</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenMoreDetailsModal(element)}>More Details</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenFancyModal(element)}>Fancy Details</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenDeclareMatchModal(element)}>Declare Match</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenTossDeclareMatchModal(element)}>Toss Match</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.updateMatchStatus(element)}>{element.isActive ? "active" : "deactive"}</button>

                                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenFmatchIdModal(element)}>Update FmatchId</button>

                                    {
                                      this.state.activeFancyList && this.state.activeFancyList === true ?
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenActiveFancyListClose(element)}> - Active Fancy List</button>
                                        :
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm' onClick={() => this.handleOpenActiveFancyList(element)}>Active Fancy List</button>
                                    }

                                    {/* <div className='flex items-center p-1'>
                                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenActiveFancyList(element)}>Active Fancy List</button>
                                    </div> */}

                                  </td>

                                </tr>

                                {
                                  element.marketId === this.state.marketIdActiveFancy && this.state.activeFancyList && this.state.activeFancyList === true ?
                                    <tr className="bg-white border-b border-black border-opacity-10">
                                      <td className="p-0.5" colspan="9">
                                        <table class="bg-white text-gray-900 table-auto w-full shadow-none">
                                          <thead className="bg-gray-200 rounded-t">
                                            <tr className='text-left text-gray-500 uppercase text-md font-semibold'>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">Sno.</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">SelectionId</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">RunnerName</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">LayPrice1</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">LaySize1</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">BackPrice1</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">BackSize1</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">GameStatus</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">MarkStatus</th>
                                              <th className="px-2 py-3 tracking-wider whitespace-nowrap">Actions</th>
                                            </tr>
                                          </thead>


                                          <tbody className="">
                                            {
                                              allActiveFancy && allActiveFancy.length > 0 ? allActiveFancy.map((element2, innerIndex) => (<React.Fragment key={innerIndex}>
                                                <>
                                                  <tr className="bg-white border-b border-black border-opacity-10 text-sm text-gray-600">
                                                    <td className="px-2 py-3 whitespace-nowrap">{this.state.offset + innerIndex + 1}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.SelectionId ? element2.SelectionId : 0}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.RunnerName ? element2.RunnerName : "-"}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.LayPrice1 ? element2.LayPrice1 : 0}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.LaySize1 ? element2.LaySize1 : 0}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.BackPrice1 ? element2.BackPrice1 : 0}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.BackSize1 ? element2.BackSize1 : 0}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.GameStatus ? element2.GameStatus : "-"}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap">{element2 && element2.MarkStatus ? element2.MarkStatus : "-"}</td>
                                                    <td className="px-2 py-3 whitespace-nowrap flex">

                                                      <div class="inline-flex">
                                                        <input
                                                          // onClick={() => this.updateFancyStatus(element2)}
                                                          defaultChecked={element2.isActive} type="checkbox" className="form-checkbox ppearance-none pt-2 h-5 mt-2 w-5 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" />
                                                      </div>

                                                      {
                                                        element2 && element2.isResultDeclare && element2.isResultDeclare === true ?
                                                          <button className='bg-green-700 text-white text-sm px-2 py-1.5 rounded-sm' >Result Declare</button>
                                                          :
                                                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenDeclareFancyModal(element2)}>Declare Fancy</button>
                                                      }
                                                    </td>
                                                  </tr>
                                                </>
                                              </React.Fragment>))
                                                : (<tr className="bg-white bg-opacity-5 " >
                                                  <td className="col-span-3 px-2 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                                </tr>)
                                            }
                                          </tbody>
                                        </table>

                                      </td>
                                    </tr>
                                    : null
                                }
                              </React.Fragment>))
                              : (<tr className="bg-white bg-opacity-5 " >
                                <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                              </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>



                <Modal isOpen={this.state.declareMatchModal} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >Declare Match</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseDeclareMatchModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">Selection Id :</label>
                                <select class="form-select appearance-none block lg:w-[400px] w-full sm:w[400] px-3 py-1.5 text-base font-normal  text-gray-300 bg-slate-700 bg-clip-padding bg-no-repeat  border border-solid border-slate-700 rounded transition ease-in-out  m-0  focus:outline-none" aria-label="Default select example"
                                  onChange={this.inputChangeSelectCoin}
                                  name="selectionId"
                                  value={this.state.fieldsMatch && this.state.fieldsMatch["selectionId"] ? this.state.fieldsMatch["selectionId"] : null}
                                >
                                  <option selected>Plz Select Selection Id</option>
                                  {
                                    selectionIdMarketWise && selectionIdMarketWise.length > 0 ?
                                      selectionIdMarketWise.map((element, index) => (
                                        <option value={element && element.selectionId ? element.selectionId : "NA"}>
                                          {element && element.name ? element.name : "NA"}
                                        </option>
                                      ))
                                      : null
                                  }
                                </select>
                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.declareResultSubmit}>Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>


                <Modal isOpen={this.state.tossDeclareModal} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >Toss Result Declare</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseTossDeclareMatchModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">Selection Id :</label>
                                <select class="form-select appearance-none block lg:w-[400px] w-full sm:w[400] px-3 py-1.5 text-base font-normal  text-gray-300 bg-slate-700 bg-clip-padding bg-no-repeat  border border-solid border-slate-700 rounded transition ease-in-out  m-0  focus:outline-none" aria-label="Default select example"
                                  onChange={this.inputChangeSelectCoin}
                                  name="selectionId"
                                  value={this.state.fieldsMatch && this.state.fieldsMatch["selectionId"] ? this.state.fieldsMatch["selectionId"] : null}
                                >
                                  <option selected>Plz Select Selection Id</option>
                                  {
                                    selectionIdMarketWise && selectionIdMarketWise.length > 0 ?
                                      selectionIdMarketWise.map((element, index) => (
                                        <option value={element && element.selectionId ? element.selectionId : "NA"}>
                                          {element && element.name ? element.name : "NA"}
                                        </option>
                                      ))
                                      : null
                                  }
                                </select>
                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.tossDeclareResultSubmit}>Submit toss</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal isOpen={this.state.setFmatchId} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >UPDATE F_MATCH_ID</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseFmatchIdModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">F_MATCH_ID</label>

                                <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                                  id="fmId" name="fmId" placeholder="fmId" value={this.state.fieldsMatch.fmId} type="text"
                                  onChange={this.inputChangeFmatchId}
                                />

                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.updateMatchFmIdSubmit}>Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>




                <Modal
                  isOpen={this.state.declareFancyModal}
                >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-transparent outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content text-left p-10 space-y-8">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-black text-2xl font-bold whitespace-nowrap" >Declare Fancy</p><br />
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseDeclareFancyModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <p className="text-black text-1xl font-bold" >Selection Name : {this.state.fancyDetails && this.state.fancyDetails.RunnerName ? this.state.fancyDetails.RunnerName : "-"}</p>
                        </div>
                        <form autoComplete="off" className='space-y-5'>
                          <div class="flex justify-center w-full">
                            <div class="mb-3 w-full">
                              <label className="text-black text-base font-medium ">Result : </label> &nbsp;
                              <input type="number" name="result"
                                value={this.state.fieldsMatchFancy && this.state.fieldsMatchFancy["result"] ? this.state.fieldsMatchFancy["result"] : ""}
                                onChange={this.inputChangeFancy}
                              ></input>
                            </div>
                          </div>
                          <div className="w-64 sm:w-72 mx-auto">
                            <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.handleDeclareFancySubmit}>Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Modal>






                {/* {
                  isMobile ?
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>
                } */}

              </div>

            </div>
          </div>


          <div className='p-6'>
            <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
              <h2 className='text-base font-semibold text_color'>Live Matches</h2>
            </div>
            <div className='bg-white pb-10'>
              <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                <div className='flex items-center space-x-4'>

                  <div className="">
                    <label className='text-sm text-gray-500 mr-2'>Show:</label>
                    <div className="dropdown inline-block relative">
                      <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                        <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex justify-between whitespace-nowrap space-x-4'>
                  <div>
                    <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                    <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                  </div>
                </div>
              </div>

              <div className="rounded-lg overflow-hidden pb-2 p-6">
                <div className="overflow-auto max-w-full ">
                  <div className="inline-block min-w-full  ">
                    <div className="overflow-hidden  ">
                      <table className="min-w-full divide-y divide-gray-800 border-0">
                        <thead className="rounded-t">
                          <tr className="uppercase text-left text-sm font-semibold text-gray-600">
                            <th className="whitespace-nowrap p-2 bg-gray-100">#</th>
                            <th className="whitespace-nowrap p-2 bg-white">match id</th>
                            <th className="whitespace-nowrap p-2 bg-white">match name</th>
                            <th className="whitespace-nowrap p-2 bg-white">match date</th>

                          </tr>
                        </thead>
                        <tbody>
                          {/* Odd row */}
                          {
                            liveMatchItems && liveMatchItems.length > 0 ?
                              liveMatchItems.map((element, index) => (<React.Fragment key={element.id}>
                                <tr key={element.id} className="border-b border-black border-opacity-10 text-sm text-gray-600">
                                  <td className="p-2 whitespace-nowrap">{index + 1}</td>
                                  <td className="p-2 whitespace-nowrap tabletext cursor-pointer">{element && element.match_id ? element.match_id : "-"}</td>
                                  <td className="p-2 whitespace-nowrap tabletext cursor-pointer">{element && element.team_a ? element.team_a : "-"} vs {element && element.team_b ? element.team_b : "-"}</td>
                                  <td className="p-2 whitespace-nowrap">{element && element.match_date ? element.match_date : "-"}</td>
                                </tr>
                              </React.Fragment>))
                              : (<tr className="bg-white bg-opacity-5 text-sm text-gray-600" >
                                <td colspan={4} className="px-6 py-2 whitespace-nowrap font-medium">Record Not Found</td>
                              </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>



                {/* <Modal isOpen={this.state.declareMatchModal} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >Declare Match</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseDeclareMatchModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">Selection Id :</label>
                                <select class="form-select appearance-none block lg:w-[400px] w-full sm:w[400] px-3 py-1.5 text-base font-normal  text-gray-300 bg-slate-700 bg-clip-padding bg-no-repeat  border border-solid border-slate-700 rounded transition ease-in-out  m-0  focus:outline-none" aria-label="Default select example"
                                  onChange={this.inputChangeSelectCoin}
                                  name="selectionId"
                                  value={this.state.fieldsMatch && this.state.fieldsMatch["selectionId"] ? this.state.fieldsMatch["selectionId"] : null}
                                >
                                  <option selected>Plz Select Selection Id</option>
                                  {
                                    selectionIdMarketWise && selectionIdMarketWise.length > 0 ?
                                      selectionIdMarketWise.map((element, index) => (
                                        <option value={element && element.selectionId ? element.selectionId : "NA"}>{element && element.name ? element.name : "NA"}</option>
                                      ))
                                      : null
                                  }
                                </select>
                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.declareResultSubmit}>Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal isOpen={this.state.setFmatchId} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >UPDATE F_MATCH_ID</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseFmatchIdModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">F_MATCH_ID</label>

                                <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                                  id="news_id" name="news_id" placeholder="News Id" value={this.state.fieldsMatch.fmId} type="text"
                                // onChange={this.inputAddUserChange} 
                                />

                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.declareResultSubmit}>Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal> */}


                {/* {
                  isMobile ?
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>
                } */}

              </div>

            </div>
          </div>

          <div className='p-6'>
            <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
              <h2 className='text-base font-semibold text_color'>Upcoming Matches</h2>

            </div>
            <div className='bg-white pb-10'>
              <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                <div className='flex items-center space-x-4'>

                  <div className="">
                    <label className='text-sm text-gray-500 mr-2'>Show:</label>
                    <div className="dropdown inline-block relative">
                      <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                        <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex justify-between whitespace-nowrap space-x-4'>
                  <div>
                    <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                    <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                  </div>
                </div>
              </div>

              <div className="rounded-lg overflow-hidden pb-2 p-6">
                <div className="overflow-auto max-w-full ">
                  <div className="inline-block min-w-full  ">
                    <div className="overflow-hidden  ">
                      <table className="min-w-full divide-y divide-gray-800 border-0">
                        <thead className="rounded-t">
                          <tr className="uppercase text-left text-sm font-semibold text-gray-600 bg-white">
                            <th className="whitespace-nowrap p-2 bg-gray-100">#</th>
                            <th className="whitespace-nowrap p-2">match id</th>
                            <th className="whitespace-nowrap p-2">match name</th>
                            <th className="whitespace-nowrap p-2">match date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Odd row */}
                          {
                            upcomingMatchesList && upcomingMatchesList.length > 0 ?
                              upcomingMatchesList.map((element, index) => (<React.Fragment key={element.id}>
                                <tr key={element.id} className=" border-b border-black border-opacity-10 text-sm text-gray-600">
                                  <td className="p-2 whitespace-nowrap">{index + 1}</td>
                                  <td className="p-2 whitespace-nowrap tabletext cursor-pointer">{element && element.match_id ? element.match_id : "-"}</td>
                                  <td className="p-2 whitespace-nowrap tabletext cursor-pointer">{element && element.team_a ? element.team_a : "-"} vs {element && element.team_b ? element.team_b : "-"}</td>
                                  <td className="p-2 whitespace-nowrap">{element && element.match_date ? element.match_date : "-"}</td>

                                </tr>
                              </React.Fragment>))
                              : (<tr className="bg-white bg-opacity-5 font-medium text-sm" >
                                <td colspan={4} className="col-span-8 px-6 py-2 whitespace-nowrap ">Record Not Found</td>
                              </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>



                {/* <Modal isOpen={this.state.declareMatchModal} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >Declare Match</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseDeclareMatchModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">Selection Id :</label>
                                <select class="form-select appearance-none block lg:w-[400px] w-full sm:w[400] px-3 py-1.5 text-base font-normal  text-gray-300 bg-slate-700 bg-clip-padding bg-no-repeat  border border-solid border-slate-700 rounded transition ease-in-out  m-0  focus:outline-none" aria-label="Default select example"
                                  onChange={this.inputChangeSelectCoin}
                                  name="selectionId"
                                  value={this.state.fieldsMatch && this.state.fieldsMatch["selectionId"] ? this.state.fieldsMatch["selectionId"] : null}
                                >
                                  <option selected>Plz Select Selection Id</option>
                                  {
                                    selectionIdMarketWise && selectionIdMarketWise.length > 0 ?
                                      selectionIdMarketWise.map((element, index) => (
                                        <option value={element && element.selectionId ? element.selectionId : "NA"}>{element && element.name ? element.name : "NA"}</option>
                                      ))
                                      : null
                                  }
                                </select>
                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.declareResultSubmit}>Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal isOpen={this.state.setFmatchId} >

                  <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                    <div className="bg-slate-800  outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                      <div className="modal-content py-4 text-left px-6">
                        <div className="flex justify-between items-center pb-3">
                          <p className="text-2xl font-bold" >UPDATE F_MATCH_ID</p>
                          <div className="modal-close cursor-pointer z-50">
                            <svg onClick={() => this.handleCloseFmatchIdModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                              </path>
                            </svg>
                          </div>
                        </div>
                        <div className="my-5">

                          <form autoComplete="off">
                            <div class="flex justify-center w-full">
                              <div class="mb-3 w-full">
                                <label className="text-blue text-sm font-medium ">F_MATCH_ID</label>

                                <input className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                                  id="news_id" name="news_id" placeholder="News Id" value={this.state.fieldsMatch.fmId} type="text"
                                // onChange={this.inputAddUserChange} 
                                />

                              </div>
                            </div>
                            <div className="mt-6 w-64 sm:w-72 mx-auto py-6">
                              <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button" onClick={this.declareResultSubmit}>Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal> */}




              </div>

            </div>
          </div>

          <div className="bg-white py-3 px-4 border-t bottom-0 lg:left-60 fixed w-full">
            <h3 className=" text-sm text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-{moment().format('YYYY')}</h3>
          </div>
        </div>

      </>


    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(AdminList);

// export default AdminList;