/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import { playerActions, userActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePlayerDetailsModal from "./components/UpdatePlayerDetailsModal/UpdatePlayerDetailsModal";
import ViewPlayerDetailsModal from "./components/ViewPlayerDetailsModal/ViewPlayerDetailsModal";
import AddPlayerDetailsModal from "./components/AddPlayerDetailsModal/AddPlayerDetailsModal";
import LoadingOverlay from 'react-loading-overlay';

class Player extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},
      keyWord: '',
      pageNo: 1,
      size: 10,

      addPlayerModal: false,
      fieldsplayerAdd: {},
      errorsPlayerAdd: {},
      updatePlayerModal: false,
      viewPlayerModal: false,
      fieldsplayerUpdate: {},
      errorsPlayerUpdate: {},
      rowData: {},
      buttonDisabled: false,
      
    }
    this.editRef = React.createRef();
    this.addRef = React.createRef();
  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(playerActions.getPlayerList(data));
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.player.addplayeruccess) {
      return {
        ...nextProps,
        addPlayerModal: false,
        fieldsplayerAdd: {},
        errorsPlayerAdd: {},
        updatePlayerModal: false,
        fieldsplayerUpdate: {},
        errorsPlayerUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  handleFile = (event) => {

    this.setState({errorsPlayerAdd:{...this.state.errorsPlayerAdd,img:""}});
    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
 
      

    }
    else {
      console.log("No File To Upload!")
    }

  }
  handleOpenToAddPlayerModal = () => {
    this.props.dispatch(userActions.uploadImageClear());
    this.editRef.current.value="";
    this.addRef.current.value="";
    this.setState({ addPlayerModal: true });
  }
  handleCloseAddPlayerModal = () => {
    this.setState({ addPlayerModal: false });
    this.setState({fieldsplayerAdd:{},errorsPlayerAdd:{}})
  }
  inputChangePlayerAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsplayerAdd = this.state.fieldsplayerAdd;
    let errorsPlayerAdd = this.state.errorsPlayerAdd;
    fieldsplayerAdd[name] = value;
    errorsPlayerAdd[name] = "";
    this.setState({ fieldsplayerAdd, errorsPlayerAdd });
  }


  playerAddSubmit = () => {

    // this.setState({ buttonDisabled: true })
    let { users } = this.props;
    let { filesDetails,
      //  filesDetailsVideo
    } = users;
    console.log(this.state.fieldsplayerAdd)
    if (this.handleValidationPlayerAdd()) {
      let resData = {
        "image": filesDetails && filesDetails.imageName && filesDetails.imageName,
        "id": this.state.fieldsplayerAdd.id,
        "playerId": this.state.fieldsplayerAdd.playerId,
        "name": this.state.fieldsplayerAdd.name,
        "country": this.state.fieldsplayerAdd.country,
        "play_role": this.state.fieldsplayerAdd.play_role,
        "rank": this.state.fieldsplayerAdd.Rank ? this.state.fieldsplayerAdd.Rank : 0,
        "rating": this.state.fieldsplayerAdd.Rating ? this.state.fieldsplayerAdd.Rating : 0,
        "description": this.state.fieldsplayerAdd.description,
      }
      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }


      this.props.dispatch(playerActions.createPlayer(resData, data));
      this.setState({ buttonDisabled: false })
      this.setState({fieldsplayerAdd:{},errorsPlayerAdd:{}})
    }
  }
  handleValidationPlayerAdd = () => {
    let { users } = this.props;
    const { fieldsplayerAdd } = this.state;
    const fieldNames = ["playerId", "name", "country", "play_role", "description","Rank","Rating"];
    const errorsPlayerAdd = {};
    let { filesDetails} = users;

    let formIsValid = true;

    fieldNames.forEach(fieldName => {
      if (!fieldsplayerAdd[fieldName]||(typeof fieldsplayerAdd[fieldName] === 'string' && !fieldsplayerAdd[fieldName].trim())   || fieldsplayerAdd[fieldName] === "") {
        formIsValid = false;
        errorsPlayerAdd[fieldName] = `${fieldName} Cannot be empty`;
        console.log(formIsValid)
      }
    });

    if (!filesDetails?.imageName || filesDetails?.imageName === "") {
      formIsValid = false;
      errorsPlayerAdd["img"] = `Image Cannot be Empty Cannot be empty`;
      console.log(formIsValid)
    }
    

    

    this.setState({ errorsPlayerAdd }, () => {
    });
console.log(formIsValid)
    return formIsValid;
  }


  handleOpenToUpdatePlayerModal = (data) => {
    const datatosend = {...data} 
    this.props.dispatch(userActions.uploadImageClear());
    this.editRef.current.value="";
    this.addRef.current.value="";
    this.setState({ updatePlayerModal: true, fieldsplayerUpdate: datatosend });
  }
  handleCloseUpdatePlayerModal = () => {
    // let data = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    // this.props.dispatch(playerActions.getPlayerList(data));
    this.setState({ updatePlayerModal: false });
    this.setState({fieldsplayerUpdate:{},fieldsplayerUpdate:{}})
  }
  inputChangePlayerUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsplayerUpdate = this.state.fieldsplayerUpdate;
    let errorsPlayerUpdate = this.state.errorsPlayerUpdate;
    fieldsplayerUpdate[name] = value;
    errorsPlayerUpdate[name] = "";
    this.setState({ fieldsplayerUpdate, errorsPlayerUpdate });
  }
  playerUpdateSubmit = (e) => {
    let { users } = this.props;
    let { filesDetails,
      //  filesDetailsVideo
    } = users;
    e.preventDefault();
    if (this.handleValidationPlayerUpdate()) {
      let resData = {
        "id": this.state.fieldsplayerUpdate.id,
        "playerId": this.state.fieldsplayerUpdate.playerId,
        "name": this.state.fieldsplayerUpdate.name,
        "country": this.state.fieldsplayerUpdate.country,
        "play_role": this.state.fieldsplayerUpdate.play_role,
        "description": this.state.fieldsplayerUpdate.description,
        "rank": this.state.fieldsplayerUpdate.rank,
        "rating": this.state.fieldsplayerUpdate.rating,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsplayerUpdate.image,
      }

      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(playerActions.UpdatePlayerDetails(resData, data));
      this.setState({ buttonDisabled: false })
      this.setState({ updatePlayerModal: false });
      this.setState({fieldsplayerUpdate:{},fieldsplayerUpdate:{}})
      
    }
  }
  handleValidationPlayerUpdate = () => {
    const { fieldsplayerUpdate } = this.state;
    const fieldNames = ["playerId", "name", "country", "play_role", "description", "image","rating","rank"];
    const errorsPlayerUpdate = {};


    let formIsValid = true;

    fieldNames.forEach(fieldName => {
      
      if (!fieldsplayerUpdate[fieldName] ||(typeof fieldsplayerUpdate[fieldName] === 'string' && !fieldsplayerUpdate[fieldName].trim()) ||fieldsplayerUpdate[fieldName] === "") {
        formIsValid = false;
        errorsPlayerUpdate[fieldName] = `${fieldName} Cannot be empty`;
      }
    });

    this.setState({ errorsPlayerUpdate }, () => {
    });

    return formIsValid;
  }


  updatePlayerStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }



    confirmAlert({

      title: 'Confirm to Disable?',
      message: `Are you sure to want change Status of ${data?.name} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(playerActions.disablePlayer(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenToDeletePlayerModal = (data) => {
  
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(playerActions.deletePlayer(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(playerActions.getPlayerList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(playerActions.getPlayerList(data));

  }

  handleOpenToViewPlayerModal = (data) => {
    this.setState({ viewPlayerModal: true, rowData: data });
  }
  handleCloseViewPlayerModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ viewPlayerModal: false });
  }

  handleShowData = (e) => {
    const size = e.target.value;
    this.setState({ size }, () => {
      const { keyWord, pageNo, size } = this.state; // Destructure the updated state
      const data = {
        keyWord,
        pageNo,
        size // Use the updated value of size
      };
      this.props.dispatch(playerActions.getPlayerList(data));
    });
  }
  


  render() {

    let { player, users } = this.props;
    let { getPlayerList, total ,loading } = player;
    let { filesDetails } = users;
    // console.log("RENDER____allMatch_allMatch_allMatch::::", getPlayerList);
    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Player</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Player Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={(e)=>this.handleShowData(e)} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddPlayerModal()}>ADD</button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">PlayerID</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rank</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rating</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Image</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getPlayerList && getPlayerList.length > 0 ?
                                getPlayerList.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{this.state.offset+index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.playerId ? element.playerId : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.rank ? element.rank : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.rating ? element.rating : 0}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">
                                      <div className='object-cover h-14 w-14 flex items-center justify-center'>
                                        <img className='h-14 w-14 object-cover flex items-center justify-center' src={element && element.imageLinkUrl ? element.imageLinkUrl : 0} alt="" />
                                      </div>
                                    </td>



                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToViewPlayerModal(element)}>More</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdatePlayerModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className={`${element.isDisable ? "bg-red-600 hover:bg-red-800 " : "  bg-emerald-500 hover:bg-emerald-600"}  text-white text-sm px-2 py-1.5 rounded-sm`} onClick={() => this.updatePlayerStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>



                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToDeletePlayerModal(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  }

                  <AddPlayerDetailsModal
                    buttonDisabled={this.state.buttonDisabled}
                    addPlayerModal={this.state.addPlayerModal}
                    handleCloseAddPlayerModal={this.handleCloseAddPlayerModal}
                    inputChangePlayerAdd={this.inputChangePlayerAdd}
                    fieldsplayerAdd={this.state.fieldsplayerAdd}
                    errorsPlayerAdd={this.state.errorsPlayerAdd}
                    filesDetails={filesDetails}
                    handleFile={this.handleFile}
                    playerAddSubmit={this.playerAddSubmit}
                    addRef={this.addRef}
                  />
                  <UpdatePlayerDetailsModal
                    updatePlayerModal={this.state.updatePlayerModal}
                    handleCloseUpdatePlayerModal={this.handleCloseUpdatePlayerModal}
                    inputChangePlayerUpdate={this.inputChangePlayerUpdate}
                    fieldsplayerUpdate={this.state.fieldsplayerUpdate}
                    errorsPlayerUpdate={this.state.errorsPlayerUpdate}
                    filesDetails={filesDetails}
                    handleFile={this.handleFile}
                    playerUpdateSubmit={this.playerUpdateSubmit}
                    editRef={this.editRef}
                  />
                  <ViewPlayerDetailsModal
                    viewPlayerModal={this.state.viewPlayerModal}
                    handleCloseViewPlayerModal={this.handleCloseViewPlayerModal}
                    inputChangePlayerUpdate={this.inputChangePlayerUpdate}
                    rowData={this.state.rowData}
                    // errorsPlayerUpdate={this.state.errorsPlayerUpdate}
                    filesDetails={filesDetails}
                    handleFile={this.handleFile}
                  // playerUpdateSubmit={this.playerUpdateSubmit}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>
      </>

    );
  }
}
function mapStateToProps(state) {
  const { player, users } = state;

  return {
    player,
    users,
  };
}
export default connect(mapStateToProps)(Player);