export const pointTableConstants = {
    GET_POINT_TABLE_LIST_REQUEST: 'GET_POINT_TABLE_LIST_REQUEST',
    GET_POINT_TABLE_LIST_SUCCESS: 'GET_POINT_TABLE_LIST_SUCCESS',
    GET_POINT_TABLE_LIST_FAILURE: 'GET_POINT_TABLE_LIST_FAILURE',
   
    GET_POINT_TABLE_BY_ID_REQUEST: 'GET_POINT_TABLE_BY_ID_REQUEST',
    GET_POINT_TABLE_BY_ID_SUCCESS: 'GET_POINT_TABLE_BY_ID_SUCCESS',
    GET_POINT_TABLE_BY_ID_FAILURE: 'GET_POINT_TABLE_BY_ID_FAILURE',

    GET_POINT_TABLE_BY_SERIES_ID_REQUEST: 'GET_POINT_TABLE_BY_SERIES_ID_REQUEST',
    GET_POINT_TABLE_BY_SERIES_ID_SUCCESS: 'GET_POINT_TABLE_BY_SERIES_ID_SUCCESS',
    GET_POINT_TABLE_BY_SERIES_ID_FAILURE: 'GET_POINT_TABLE_BY_SERIES_ID_FAILURE',

    GET_ALL_POINT_TABLE_REQUEST: 'GET_ALL_POINT_TABLE_REQUEST',
    GET_ALL_POINT_TABLE_SUCCESS: 'GET_ALL_POINT_TABLE_SUCCESS',
    GET_ALL_POINT_TABLE_FAILURE: 'GET_ALL_POINT_TABLE_FAILURE',

    ADD_POINT_TABLE_REQUEST: 'ADD_POINT_TABLE_REQUEST',
    ADD_POINT_TABLE_SUCCESS: 'ADD_POINT_TABLE_SUCCESS',
    ADD_POINT_TABLE_FAILURE: 'ADD_POINT_TABLE_FAILURE',

    UPDATE_POINT_TABLE_REQUEST: 'UPDATE_POINT_TABLE_REQUEST',
    UPDATE_POINT_TABLE_SUCCESS: 'UPDATE_POINT_TABLE_SUCCESS',
    UPDATE_POINT_TABLE_FAILURE: 'UPDATE_POINT_TABLE_FAILURE',

    UPDATE_POINT_TABLE_STATUS_REQUEST: 'UPDATE_POINT_TABLE_STATUS_REQUEST',
    UPDATE_POINT_TABLE_STATUS_SUCCESS: 'UPDATE_POINT_TABLE_STATUS_SUCCESS',
    UPDATE_POINT_TABLE_STATUS_FAILURE: 'UPDATE_POINT_TABLE_STATUS_FAILURE',

    DELETE_POINT_TABLE_REQUEST: 'DELETE_POINT_TABLE_REQUEST',
    DELETE_POINT_TABLE_SUCCESS: 'DELETE_POINT_TABLE_SUCCESS',
    DELETE_POINT_TABLE_FAILURE: 'DELETE_POINT_TABLE_FAILURE',


};
