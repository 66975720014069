import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerActions, teamsActions, matchMoreDetailActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-modal';


class MatchMoreDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      matchStatus: '',
      match_status_error: '',
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sectiontype: '',
      sportAndSeriesId: {},
      rowDetails: {},

      fieldsMatchInfo: {},
      errorsMatchInfo: {},

      keyWord: '',
      pageNo: 1,
      size: 10,

      declareFancyModal: false,
      rowData: {},
      rowDataError: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.matchMoreDetail.updateUserSuccess) {

      return {
        ...nextProps,
        // fieldsMatchInfo: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData ? nextProps.matchMoreDetail.matchInfoData : null,
        // errorsMatchInfo: {},
        declareFancyModal: false,

      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {
    // let data = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    // this.props.dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: this.props.match.params.matchId }));
    this.props.dispatch(matchMoreDetailActions.getFancyListForFancyResult({
      matchId: 32183614

      // this.props.match.params.matchId 
    }));
  }


  inputChangeFancy = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let rowData = this.state.rowData;
    let rowDataError = this.state.rowDataError;
    rowData[name] = value;
    rowDataError[name] = "";
    console.log(name, value);
    this.setState({ rowData, rowDataError });
  }

  handleDeclareFancySubmit = () => {
    if (this.handleValidationMatchInfo()) {
      let reqData = {
        "id": this.state.rowData.id,
        "result": this.state.rowData.result
      }

      console.log('reqDatareqDatareqDatareqDatareqDatareqData??????', reqData);

      this.props.dispatch(matchMoreDetailActions.updateFancyResultDeclare(reqData, this.props.match.params.matchId));
    }
  }


  handleValidationMatchInfo = () => {
    let rowData = this.state.rowData;
    let rowDataError = {};
    let formIsValid = true;

    //result
    if (!rowData["result"] || rowData["result"] === "") {
      formIsValid = false;
      rowDataError["result"] = "Cannot be empty result";
    }
 
    // console.log('errorsMatchInfoerrorsMatchInfoerrorsMatchInfo', rowDataError);

    this.setState({ rowDataError: rowDataError });
    return formIsValid;
  }




  handleSelectMatchStatus = (e) => {
    e.preventDefault();
    // console.log('handleSelectMatchStatushandleSelectMatchStatus', e.target.value);
    this.setState({ matchStatus: e.target.value });
  }

  updateMatch_Status = () => {
    if (this.handleValidationMatch_Status()) {
      let reqData = {
        "matchId": this.props.match.params.matchId,
        "matchStatus": this.state.matchStatus
      }

      console.log('reqDatareqDatareqDatareqDatareqDatareqData??????', reqData);

      this.props.dispatch(matchMoreDetailActions.updateMatch_Status(reqData));
    }
  }

  handleValidationMatch_Status = () => {
    let matchStatus = this.state.matchStatus;
    let match_status_error = "";
    let formIsValid = true;

    //matchStatus
    if (matchStatus === "") {
      formIsValid = false;
      match_status_error = "Cannot be empty ";
    }

    console.log('errorsMatchInfoerrorsMatchInfoerrorsMatchInfo', match_status_error);

    this.setState({ match_status_error: match_status_error });
    return formIsValid;
  }

  handleResultDeclareOpenModal = (data) => {
    this.setState({ declareFancyModal: true, rowData: data })
  }

  handleCloseDeclareFancyModal = (data) => {
    this.setState({ declareFancyModal: false, rowData: {} })
  }

  render() {

    let { matchMoreDetail } = this.props;
    let { matchInfoData, fancyListForFancyResult } = matchMoreDetail;
    // console.log("RENDER____this.state.matchStatus::", this.state.matchStatus);
    // console.log("RENDER____fancyListForFancyResult::", fancyListForFancyResult);


    // console.log("RENDER____ this.state.this.state.rowData::", this.state.rowData);

    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 flex  justify-between items-centre border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Fancy More Detail</h2>
              {/* <div className='w-xl space-x-3 flex'>
                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onClick={this.handleSelectMatchStatus}
                >
                  <option selected>Update Match Status</option>
                  <option value="Live">Live</option>
                  <option value="Upcoming">Upcoming</option>
                  <option value="Recent">Recent</option>
                </select>

                <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.updateMatch_Status()}>ADD</button>

              </div> */}
            </div>



            <div className='p-6'>

              <div className='bg-white pb-10'>

                <div className="rounded-lg overflow-hidden pb-2 p-6">


                  <div className="rounded-lg overflow-hidden pb-2 p-6">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="rounded-t">
                              <tr className="uppercase text-left text-sm font-semibold text-gray-600 bg-white">

                                <th className="whitespace-nowrap p-2 bg-gray-100">#</th>
                                <th className="whitespace-nowrap p-2">SelectionId</th>
                                <th className="whitespace-nowrap p-2">RunnerName</th>
                                <th className="whitespace-nowrap p-2">BackPrice1</th>
                                <th className="whitespace-nowrap p-2">LayPrice1</th>
                                <th className="whitespace-nowrap p-2">result</th>
                                <th className="whitespace-nowrap p-2">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                fancyListForFancyResult && fancyListForFancyResult.length > 0 ?
                                  fancyListForFancyResult.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="border-b border-black border-opacity-10 text-sm text-gray-600">
                                      <td className="p-2 whitespace-nowrap">{index + 1}</td>
                                      <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.SelectionId ? element.SelectionId : "-"}</td>
                                      <td className="p-2 whitespace-nowrap text-sm">{element && element.RunnerName ? element.RunnerName : "-"}</td>
                                      <td className="p-2 whitespace-nowrap">{element && element.BackPrice1 ? element.BackPrice1 : "-"}</td>
                                      <td className="p-2 whitespace-nowrap">{element && element.LayPrice1 ? element.LayPrice1 : 0}</td>
                                      <td className="p-2 whitespace-nowrap">{element && element.result ? element.result : 0}</td>


                                      <td className="p-2 whitespace-nowrap flex space-x-2">
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-3 py-1.5 rounded-sm'

                                          onClick={() => this.handleResultDeclareOpenModal(element)}

                                        >Live Match</button>

                                      </td>

                                    </tr>

                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>







                    <Modal
                      isOpen={this.state.declareFancyModal}
                    >

                      <div className="bg-slate-700 main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
                        <div className="bg-transparent outline-none  modal-container w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                          <div className="modal-content text-left p-10 space-y-8">
                            <div className="flex justify-between items-center pb-3">
                              <p className="text-black text-2xl font-bold whitespace-nowrap" >Declare Fancy</p><br />
                              <div className="modal-close cursor-pointer z-50">
                                <svg onClick={() => this.handleCloseDeclareFancyModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                  </path>
                                </svg>
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <p className="text-black text-1xl font-bold" >Selection Name :

                                {this.state.rowData && this.state.rowData.RunnerName ? this.state.rowData.RunnerName : "-"}

                              </p>
                            </div>
                            <form autoComplete="off" className='space-y-5'>
                              <div class="flex justify-center w-full">
                                <div class="mb-3 w-full">
                                  <label className="text-black text-base font-medium ">Result : </label> &nbsp;
                                  <input type="number" name="result"
                                    value={this.state.rowData && this.state.rowData["result"] ? this.state.rowData["result"] : ""}
                                    onChange={this.inputChangeFancy}
                                  ></input>
                                </div>
                              </div>
                              <div className="w-64 sm:w-72 mx-auto">
                                <button className="bg-[#2DA4FE] w-full mx-auto flex justify-center py-3 uppercase px-4  text-sm font-semibold rounded-md text-white focus:outline-none" type="button"
                                  onClick={this.handleDeclareFancySubmit}
                                >Submit</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal>






                    {/* {
                  isMobile ?
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        total && total > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total / this.state.size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>
                } */}

                  </div>


                </div>

              </div>
            </div>

          </main>

          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}

function mapStateToProps(state) {
  const { matchMoreDetail } = state;
  // console.log('matchMoreDetail:::::mapStateToProps:', matchMoreDetail);
  return {
    matchMoreDetail
  };
}

export default connect(mapStateToProps)(MatchMoreDetail);
