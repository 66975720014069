import {
  matchMoreDetailConstants
} from '../_constants';

export function matchMoreDetail(state = {}, action) {
  console.log("getBattingTeamListgetBattingTeamListgetBattingTeamList", action);
  switch (action.type) {





    case matchMoreDetailConstants.DELETE_INNING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.DELETE_INNING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case matchMoreDetailConstants.DELETE_INNING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case matchMoreDetailConstants.UPDATE_FANCY_RESULT_DECLARE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.UPDATE_FANCY_RESULT_DECLARE_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
      };
    case matchMoreDetailConstants.UPDATE_FANCY_RESULT_DECLARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
      };
    case matchMoreDetailConstants.UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_BATSMAN_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_BATSMAN_BY_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        batsmanData: action.users.liveMatchBatsmanById,
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_BATSMAN_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_BOWLER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_BOWLER_BY_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        bowlerData: action.users.liveMatchBowlerById,
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_BOWLER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.UPDATE_MATCH_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.UPDATE_MATCH_INFO_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        // allCityByStateId: action.users.getAllCityByStateId,
      };
    case matchMoreDetailConstants.UPDATE_MATCH_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        allCityByStateId: action.users.getAllCityByStateId,
      };
    case matchMoreDetailConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GET_FANCY_LIST_FOR_FANCY_RESULT_REQUEST:
      return {
        ...state,
        loading: true,
        updateUserSuccess: false,
      };
    case matchMoreDetailConstants.GET_FANCY_LIST_FOR_FANCY_RESULT_SUCCESS:
      return {
        ...state,
        // updateUserSuccess: true,
        loading: false,
        fancyListForFancyResult: action.users.getFancyListForFancyResult,
      };
    case matchMoreDetailConstants.GET_FANCY_LIST_FOR_FANCY_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GET_MATCH_MORE_DETAIL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_MATCH_MORE_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        matchInfoData: action.users.getMatchMoreDetailById,
      };
    case matchMoreDetailConstants.GET_MATCH_MORE_DETAIL_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GET_LAST_TWENTY_BALL_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LAST_TWENTY_BALL_DATA_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        lastTwentyBallData: action.users.getLastTwentyBallDataById.list,
        lastTwentyBallTotal: action.users.getLastTwentyBallDataById.total,
      };
    case matchMoreDetailConstants.GET_LAST_TWENTY_BALL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.ADD_MATCH_MORE_DETAIL_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.ADD_MATCH_MORE_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.ADD_MATCH_MORE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.ADD_BATSMAN_DETAIL_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.ADD_BATSMAN_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.ADD_BATSMAN_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.ADD_BOWLER_DETAIL_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.ADD_BOWLER_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.ADD_BOWLER_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.GET_LIST_MATCH_MORE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIST_MATCH_MORE_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getMatchMoreDetailList.list,
        total: action.users.getMatchMoreDetailList.total
      };
    case matchMoreDetailConstants.GET_LIST_MATCH_MORE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_LIST_BATSMAN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIST_BATSMAN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allBatsman: action.users.getBattingTeamList,
        // total: action.users.getBattingTeamList.total
      };
    case matchMoreDetailConstants.GET_LIST_BATSMAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_MATCHINFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_MATCHINFO_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        matchInfo: action.users.getMatchInfoById,
        // total: action.users.getBattingTeamList.total
      };
    case matchMoreDetailConstants.GET_MATCHINFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case matchMoreDetailConstants.GET_LIST_BOWLER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIST_BOWLER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allBowler: action.users.getBowlingTeamList,
      };
    case matchMoreDetailConstants.GET_LIST_BOWLER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_LIST_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIST_TEAM_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allTeam: action.users.getAllTeams,
      };
    case matchMoreDetailConstants.GET_LIST_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_STATUS_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_STATUS_TEAM_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allTeamStatus: action.users.getBatsmanOrBowlerStatus,
      };
    case matchMoreDetailConstants.GET_STATUS_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_MANNUAL_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_MANNUAL_TEAM_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        // allTeam: action.users.getAllTeams,
      };
    case matchMoreDetailConstants.GET_MANNUAL_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_LIVE_MATCH_EXTRA_RUNS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_EXTRA_RUNS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_EXTRA_RUNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.GETALL_MATCH_MORE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.GETALL_MATCH_MORE_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        matchMoreDetailItems: action.users.getAllMatchMoreDetail,
        matchMoreDetailTotal: action.users.getAllMatchMoreDetail
      };
    case matchMoreDetailConstants.GETALL_MATCH_MORE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.GET_CURRENT_STATUS_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.GET_CURRENT_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.GET_CURRENT_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.GET_CURRENT_TEAM_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.GET_CURRENT_TEAM_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.GET_CURRENT_TEAM_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.GET_AB_TEAM_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.GET_AB_TEAM_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.GET_AB_TEAM_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.GET_CURRENT_INNIGNS_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.GET_CURRENT_INNIGNS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.GET_CURRENT_INNIGNS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.GET_LIVE_MATCH_RUN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_RUN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getLiveMatchRun: action.users.getLiveMatchRun
      };
    case matchMoreDetailConstants.GET_LIVE_MATCH_RUN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case matchMoreDetailConstants.GET_ROLL_BACK_RUN_DATA_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.GET_ROLL_BACK_RUN_DATA_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        rollBackLastRunData: action.users.getRollBackLastRunData
      };
    case matchMoreDetailConstants.GET_ROLL_BACK_RUN_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case matchMoreDetailConstants.UPDATE_MATCH_MORE_DETAIL_REQUEST:
      return {
        ...state
      };
    case matchMoreDetailConstants.UPDATE_MATCH_MORE_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case matchMoreDetailConstants.UPDATE_MATCH_MORE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case matchMoreDetailConstants.DELETE_MATCH_MORE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.DELETE_MATCH_MORE_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case matchMoreDetailConstants.DELETE_MATCH_MORE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.DELETE_BATSMEN_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.DELETE_BATSMEN_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case matchMoreDetailConstants.DELETE_BATSMEN_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.CANCEL_BATSMEN_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.CANCEL_BATSMEN_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case matchMoreDetailConstants.CANCEL_BATSMEN_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case matchMoreDetailConstants.DELETE_BOWLER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.DELETE_BOWLER_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case matchMoreDetailConstants.DELETE_BOWLER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case matchMoreDetailConstants.DISABLE_MATCH_MORE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case matchMoreDetailConstants.DISABLE_MATCH_MORE_DETAIL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case matchMoreDetailConstants.DISABLE_MATCH_MORE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}