import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const teamRankingService = {
    login,
    logout,
    getAllTeamRankingType,
    createTeamRanking,
    disableTeamRanking,
    getTeamRankingList,
    // getAllCityByStateId,
    deleteTeamRanking,
    disableTeamRankingType,
    getTeamRankingTypeById,
    getTeamRankingListByRankingType,
};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }
            return userObj;
        });
}

function getTeamRankingList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamRankingList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getTeamRankingList: data.data
            }
            console.log("getTeamRankingList_Data_:::::", userObj);
            return userObj;
        });
}

function getTeamRankingListByRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamRankingListByRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getTeamRankingListByRankingType: data.data
            }
            console.log("getTeamRankingListByRankingType_Data_:::::", userObj);
            return userObj;
        });
}

function getAllTeamRankingType(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllTeamRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTeamRankingType: data.data
            }
            console.log("i am in service getAllTeamRankingType", userObj);

            return userObj;
        });
}

function deleteTeamRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTeamRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteTeamRanking: data.data
            }
            console.log("i am in service''...>> deleteTeamRanking ::", userObj);

            return userObj;
        });
}

function getTeamRankingTypeById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamRankingTypeById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTeamRankingTypeById: data.data
            }
            console.log("i am in service''...>> getTeamRankingTypeById ::", userObj);

            return userObj;
        });
}
function disableTeamRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTeamRankingStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateTeamRankingStatus: data.data
            }
            console.log("i am in service''...>> updateTeamRankingStatus ::", userObj);

            return userObj;
        });
}

function disableTeamRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/disableTeamRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                disableTeamRanking: data.data
            }
            console.log("I am in service disableTeamRanking", userObj);

            return userObj;
        });
}
function createTeamRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createTeamRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createTeamRanking: data.data
            }
            console.log("I am in service createTeamRanking", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}