import { userConstants, categoryConstants } from '../_constants';
import { userService, categoryService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const categoryActions = {

    login,
    logout,
    disableCategory,
    getAllCategory,
    getCategory,
    getSubCategoryById,
    createCategory,
    getSubCategoryByIdTemp,
    updateCategory,
    getCategoryList,
    deleteCategory,
    uploadImageClear,
    getAllCategoryParent
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createCategory(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        categoryService.createCategory(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Category Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getAllCategoryParent());
                    dispatch(this.getCategoryList(reqData));
                    dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.ADD_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.ADD_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.ADD_CATEGORY_FAILURE, error } }
}
function updateCategory(data,Pagination) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        categoryService.updateCategory(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Category Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.getAllCategoryParent());
                    dispatch(this.getCategoryList(Pagination));
                    dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.UPDATE_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.UPDATE_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.UPDATE_CATEGORY_FAILURE, error } }
}
function getSubCategoryById(data) {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        categoryService.getSubCategoryById(data)
            .then(
                users => {
                    console.log("getSubCategoryById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.GET_SUB_CATEGORY_BYID_REQUEST } }
    function success(users) { return { type: categoryConstants.GET_SUB_CATEGORY_BYID_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.GET_SUB_CATEGORY_BYID_FAILURE, error } }
}
function getSubCategoryByIdTemp(data) {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        categoryService.getSubCategoryByIdTemp(data)
            .then(
                users => {
                    console.log("getSubCategoryById $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.GET_SUB_CATEGORY_BYID_REQUEST } }
    function success(users) { return { type: categoryConstants.GET_SUB_CATEGORY_BYID_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.GET_SUB_CATEGORY_BYID_FAILURE, error } }
}
function getCategory() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        categoryService.getCategory()
            .then(
                users => {
                    console.log("getCategory $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.GET_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.GET_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.GET_CATEGORY_FAILURE, error } }
}
function getAllCategory() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        categoryService.getAllCategory()
            .then(
                users => {
                    console.log("getAllCategory $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.GETALL_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.GETALL_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.GETALL_CATEGORY_FAILURE, error } }
}
function getAllCategoryParent() {
    // console.log(" m kya aa rha h::action:::", );
    return dispatch => {
        dispatch(request());
        categoryService.getAllCategoryParent()
            .then(
                users => {
                    console.log("getAllCategoryParent $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.GET_ALL_CATEGORY_PARENT_REQUEST } }
    function success(users) { return { type: categoryConstants.GET_ALL_CATEGORY_PARENT_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.GET_ALL_CATEGORY_PARENT_FAILURE, error } }
}
function getCategoryList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        categoryService.getCategoryList(data)
            .then(
                users => {
                    console.log("getCategoryList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.GET_LIST_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.GET_LIST_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.GET_LIST_CATEGORY_FAILURE, error } }
}


function deleteCategory(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        categoryService.deleteCategory(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCategoryList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.DELETE_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.DELETE_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.DELETE_CATEGORY_FAILURE, error } }
}
function disableCategory(data, paginationData) {

    return dispatch => {
        dispatch(request());
        categoryService.disableCategory(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCategoryList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: categoryConstants.DISABLE_CATEGORY_REQUEST } }
    function success(users) { return { type: categoryConstants.DISABLE_CATEGORY_SUCCESS, users } }
    function failure(error) { return { type: categoryConstants.DISABLE_CATEGORY_FAILURE, error } }
}
