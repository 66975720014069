import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions, userActions, matchMoreDetailActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import SendNotification from "../../components/SendNotification/SendNotification";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiArrowDropDownLine } from 'react-icons/ri';

import "./style.css"


class MatchInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsNotification: {},
      activeTab: 1,
      dropdown1: false,
      dropdown2: false,

    };
  }
  componentDidMount() {
    let data = {
      "id": this.props.match.params._id,
    }
    this.props.dispatch(matchMoreDetailActions.getMatchInfoById(data));
  }

  // callAPI() {
  //   // let data = {
  //   //   "matchId": this.props.match.params._id,
  //   //   // "matchId": "64844bea3b574f8a6243ecba",
  //   // }
  //   // this.props.dispatch(matchMoreDetailActions.getMatchDetailsByMatchId(data));
  // }

  // componentWillUnmount() {
  //   // Clear the interval when the component is unmounted
  //   clearInterval(this.interval);
  // }


  // handleTabChange = (tab) => {
  //   this.setState({ activeTab: tab });
  // };

  // handledropdown1 = () => {
  //   this.setState({ dropdown1: !this.state.dropdown1 });
  // };
  // handledropdown2 = () => {
  //   this.setState({
  //     // dropdown2: true 
  //     dropdown2: !this.state.dropdown2
  //   });
  // };




  render() {

    let { matchMoreDetail } = this.props;
    let { matchInfo } = matchMoreDetail;
    const { activeTab } = this.state;
    console.log("RENDER_DATA", matchInfo);

    return (
      <>
        {/* <div>
        <LoadingOverlay
          active={loading}
          className="temp001"
          spinner
          text='Please wait...' />
      </div> */}

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl">

                <section className="p-6 space-y-6 bg-white border rounded-md">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold">Match Info</h3>
                    </div>
                  </div>
                </section>

                <table>
                  <tr>
                    <d>{matchInfo && matchInfo.man_of_match ? matchInfo.man_of_match + " " : "NA"}</d>
                  </tr>
                  <tr>
                    <d>{matchInfo && matchInfo.firstUmpire ? matchInfo.firstUmpire + " " : "NA"}</d>
                  </tr>
                  <tr>
                    <d>{matchInfo && matchInfo.secondUmpire ? matchInfo.secondUmpire + " " : "NA"}</d>
                  </tr>
                  <tr>
                    <d>{matchInfo && matchInfo.thirdUmpire ? matchInfo.thirdUmpire + " " : "NA"}</d>
                  </tr>
                  <tr>
                    <d>{matchInfo && matchInfo.venue ? matchInfo.venue + " " : "NA"}</d>
                  </tr>
                </table>

              </div>
            </div>
          </main>
        </div>

        {/* <div className='2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto bg-gray-200 object-cover m-6' >
                    <img alt='fiverr' src="/images/blog-cta-ad-specs-reference-guide-b.webp"  width="200%" height="80%" layout="responsive" className='object-cover' unoptimized={true} />
                  </div> */}

      </>

    );
  }
}
function mapStateToProps(state) {
  const { matchMoreDetail } = state;
  return {
    matchMoreDetail,
  };
}
export default connect(mapStateToProps)(MatchInfo);
