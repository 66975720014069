// import React from "react";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pointTableActions, userActions, seriesActions, teamsActions } from '../../_actions';

// import { alertActions, pointTableActions, userActions } from '../../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import "./style.css"
import { HiArrowSmLeft } from "react-icons/hi";


class UpdatePointTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      seriesId: "",
      teamId: "",
      slug: "",
      mat: "",
      won: "",
      lost: "",
      tied: "",
      nR: "",
      pts: "",
      nRR: "",
      fieldUpdatePointTable: {
        pointTable: [{
          teams: "",
          image: "",
          P: "",
          W: "",
          L: "",
          NR: "",
          Pts: "",
          NRR: ""
        }]
      },
      ereorUpdatePointTable: {
        pointTable: [{
          teams: "",
          flag: "",
          P: "",
          W: "",
          L: "",
          NR: "",
          Pts: "",
          NRR: "",
          image: "",
          // description: "",
          // link: ""
        }],
      },
      inputAddUserChange: [],
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      newsContent: [],
      fieldUpdatePointData: {},

      fieldsImageUrl: {
        options: [{
          image: "",
          // imageUrlLink: "",
        }]
      },
    };
  }


  componentDidMount() {

    this.props.dispatch(pointTableActions.getPointsTableById({ id: this.props.match.params.id }));
    this.props.dispatch(seriesActions.getAllCustomSeries());
    this.props.dispatch(teamsActions.getAllTeams());
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.pointTable.addUserSuccess) {
      return {
        ...nextProps,
        // fieldUpdatePointData: nextProps.pointTable && nextProps.pointTable.getById ? nextProps.pointTable.getById : null
        fieldUpdatePointTable: nextProps.pointTable && nextProps.pointTable.getById ? nextProps.pointTable.getById : null

      }
    }
    if (nextProps.news.addUserSuccess) {
      return {
        ...nextProps,
        fieldUpdatePointTable: {
          pointTable: [""],
          options: [""],
          tag: [""]
        },
        ereorUpdatePointTable: {
          pointTable: [""],
          options: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }

  inputUpdateChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldUpdatePointTable = this.state.fieldUpdatePointTable;
    let ereorUpdatePointTable = this.state.ereorUpdatePointTable;
    fieldUpdatePointTable[name] = value;
    ereorUpdatePointTable[name] = "";
    console.log(name, value);
    this.setState({ fieldUpdatePointTable, ereorUpdatePointTable });
  }

  updatePointTableSubmit = () => {

    if (this.handleValidationUpdateTable()) {
      // if (this.state.isImageSet) {
      let reqData = {
        "id": this.state.fieldUpdatePointTable.id,
        "seriesId": this.props.match.params.seriesId,
        "teamId": this.state.fieldUpdatePointTable.teamId && this.state.fieldUpdatePointTable.teamId.id,
        "slug": this.state.fieldUpdatePointTable.slug,
        "mat": this.state.fieldUpdatePointTable.mat,
        "won": this.state.fieldUpdatePointTable.won,
        "lost": this.state.fieldUpdatePointTable.lost,
        "tied": this.state && this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable.tied ? this.state.fieldUpdatePointTable.tied : 0,
        "nR": this.state.fieldUpdatePointTable.nR,
        "pts": this.state.fieldUpdatePointTable.pts,
        "nRR": this.state.fieldUpdatePointTable.nRR,

      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqData______:::", reqData);

      this.props.dispatch(pointTableActions.updatePointsTable(reqData, paginationData, this.props, this.props.match.params.seriesId));
      // this.handleBack()
      // } else {
      //   this.props.dispatch(alertActions.error("Please Set The Image."));
      // }
    }
  }

  handleValidationUpdateTable = () => {
    let fieldUpdatePointTable = this.state.fieldUpdatePointTable;
    let ereorUpdatePointTable = {};
    let formIsValid = true;

    // //seriesId
    // if (!fieldUpdatePointTable["seriesId"] || fieldUpdatePointTable["seriesId"] === "") {
    //   formIsValid = false;
    //   ereorUpdatePointTable["seriesId"] = "Cannot be empty";
    // }
    //teamId
    if (!fieldUpdatePointTable["teamId"] || fieldUpdatePointTable["teamId"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["teamId"] = "Cannot be empty";
    }
    //slug
    if (!fieldUpdatePointTable["slug"] || fieldUpdatePointTable["slug"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["slug"] = "Cannot be empty";
    }
    //mat
    if (!fieldUpdatePointTable["mat"] || fieldUpdatePointTable["mat"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["mat"] = "Cannot be empty";
    }
    //won
    if (!fieldUpdatePointTable["won"] || fieldUpdatePointTable["won"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["won"] = "Cannot be empty";
    }
    //lost
    if (!fieldUpdatePointTable["lost"] || fieldUpdatePointTable["lost"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["lost"] = "Cannot be empty";
    }
    //tied
    // if (!fieldUpdatePointTable["tied"] || fieldUpdatePointTable["tied"] === "") {
    //   formIsValid = false;
    //   ereorUpdatePointTable["tied"] = "Cannot be empty";
    // }
    //nR
    // if (!fieldUpdatePointTable["nR"] || fieldUpdatePointTable["nR"] === "") {
    //   formIsValid = false;
    //   ereorUpdatePointTable["nR"] = "Cannot be empty";
    // }
    //pts
    if (!fieldUpdatePointTable["pts"] || fieldUpdatePointTable["pts"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["pts"] = "Cannot be empty";
    }
    //nRR
    if (!fieldUpdatePointTable["nRR"] || fieldUpdatePointTable["nRR"] === "") {
      formIsValid = false;
      ereorUpdatePointTable["nRR"] = "Cannot be empty";
    }


    console.log("errorsAddUser_errorsAddUser_::::", ereorUpdatePointTable);

    this.setState({ ereorUpdatePointTable: ereorUpdatePointTable });
    return formIsValid;
  }

  addContentField = () => {
    const { fieldUpdatePointTable, fieldsImageUrl } = this.state;
    // fieldUpdatePointTable.options.push('')
    fieldUpdatePointTable.pointTable.push({
      "teams": "",
      "P": "",
      "W": "",
      "L": "",
      "NR": "",
      "Pts": "",
      "NRR": "",
      "image": "",
      // "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.filesDetails.image,

    });
    fieldsImageUrl.options.push({
      "image": "",
      // "imageUrlLink": "",
    });
    this.setState({ fieldUpdatePointTable, fieldsImageUrl });
    console.log("tttttttttttttttttttttttttttttttttttttttttttttt", fieldUpdatePointTable);
  }

  deleteContentField = (index) => {

    console.log('deleteContentField____index?????//', index);

    const { fieldUpdatePointTable, fieldsImageUrl } = this.state;
    fieldUpdatePointTable.pointTable.splice(index, 1)
    fieldsImageUrl.options.splice(index, 1)
    this.setState({ fieldUpdatePointTable, fieldsImageUrl });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputUpdateTableChangeContent = (e) => {
    e.preventDefault();
    let { value, name } = e.target;
    let fieldUpdatePointTable = this.state.fieldUpdatePointTable;
    console.log("fieldsAddUserfieldsAddUser  ", fieldUpdatePointTable);
    fieldUpdatePointTable["pointTable"][this.state.currentConetntIndex][name] = value;

    this.setState({ fieldUpdatePointTable });
  }

  handleFile = (event) => {

    console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJ', event);

    this.setState({ selectedFile: event.target.files[event.target.files.length - 1] });
    if (event.target.files[event.target.files.length - 1]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }

  handleUpload = () => {
    console.log('______________________', this.state.imageWebLink);
    const { fieldUpdatePointTable, imageWebLink, imageUrl, fieldsImageUrl } = this.state;

    console.log('llllllllllllllllllllll222222', fieldUpdatePointTable.options[this.state.currentConetntIndex]);

    fieldUpdatePointTable.options[this.state.currentConetntIndex]['image'] = imageWebLink;
    fieldsImageUrl.options[this.state.currentConetntIndex]['imageUrlLink'] = imageUrl;
    this.setState({ fieldUpdatePointTable, isImageSet: true });
  }

  handleBack = () => {
    this.props.history.goBack()
  }


  handleFileSingle = (event) => {

    console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJ');

    this.setState({ selectedFile: event.target.files[event.target.files.length - 1] });
    if (event.target.files[event.target.files.length - 1]) {
      this.props.dispatch(userActions.uploadImageSingle(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }


  render() {
    let { pointTable, series, team } = this.props
    let { getById } = pointTable
    let { allSeries } = series
    let { teamsItems } = team


    console.log("RENDER______getByIdgetByIdgetByIdgetByIdgetByIdgetByIdgetById**:", allSeries);
    console.log('this.state.fieldUpdatePointTable', this.state.fieldUpdatePointTable);
    // console.log("RENDER______this.state.currentConetntIndex**:", this.state.currentConetntIndex);
    // console.log("RENDER______this.state.fieldsImageUrl**:", this.state.fieldsImageUrl);
    // console.log("RENDER______this.state.fieldUpdatePointTable**:", this.state.fieldUpdatePointTable);


    // this.state.fieldUpdatePointTable["seriesId"].series ? this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable["seriesId"].series : null);
    return (

      <>
        <div className="h-screen m-4  overflow-y-auto">

          <div className="overflow-y-auto bg-white w-full">
            <div className="w-full px-6 py-4 text-left">
              <div className="flex pb-3">
                <div onClick={this.handleBack} className='p-1.5 bg-gray-200 border rounded-full hover:bg-blue-300 hover:text-white'>
                  <HiArrowSmLeft className='w-5 h-5 ' />
                </div>
                <p className="pl-4 mt-0.5 text-2xl font-bold">Update Point Table</p>
              </div>

              <form autoComplete="on">
                <div className=" w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">


                  <div class="">
                    <label class="block text-gray-700 text-base font-bold mb-2">Series :</label>
                    <select class="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" id="name"
                      name="seriesId"
                      // value={this.state.fieldUpdatePointTable["seriesId"].series}
                      onChange={this.inputUpdateChange}
                    >


                      <option selected>{this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['seriesId'] && this.state.fieldUpdatePointTable['seriesId'].series ? this.state.fieldUpdatePointTable['seriesId'].series : 'Plz Select Series'}</option>
                      {
                        allSeries && allSeries && allSeries.length > 0 ?
                          allSeries.map((elemente, index) => (
                            <option key={index} name={elemente && elemente.series ? elemente.series : null} value={elemente && elemente.id ? elemente.id : null}>{elemente && elemente.series ? elemente.series : "NA"}</option>
                          )) : null
                      }
                      isSearchable
                    </select>
                  </div>
                  <div class="">
                    <label class="block text-gray-700 text-base font-bold mb-2">Team :</label>
                    <select class="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" id="teamId"
                      name="teamId"
                      value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable["teamId"] && this.state.fieldUpdatePointTable["teamId"].name ? this.state.fieldUpdatePointTable["teamId"] && this.state.fieldUpdatePointTable["teamId"].name : ""}
                      onChange={this.inputUpdateChange}
                    >
                      <option selected>{this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['teamId'] && this.state.fieldUpdatePointTable['teamId'].name ? this.state.fieldUpdatePointTable['teamId'].name : 'Plz Select Team'}</option>
                      {
                        teamsItems && teamsItems && teamsItems.length > 0 ?
                          teamsItems.map((elemente, index) => (
                            <option key={index} name={elemente && elemente.name ? elemente.name : null} value={elemente && elemente.id ? elemente.id : null}>{elemente && elemente.name ? elemente.name : "NA"}</option>
                          )) : null
                      }
                      isSearchable
                    </select>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Slug :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["slug"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="slug" name="slug" placeholder="slug"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['slug'] ? this.state.fieldUpdatePointTable['slug'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["slug"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["slug"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Match :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["mat"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="mat" name="mat" placeholder="Match"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['mat'] ? this.state.fieldUpdatePointTable['mat'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["mat"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["mat"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Won :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["won"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="won" name="won" placeholder="Won"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['won'] ? this.state.fieldUpdatePointTable['won'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["won"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["won"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Lost :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["lost"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="lost" name="lost" placeholder="Lost"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['lost'] ? this.state.fieldUpdatePointTable['lost'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["lost"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["lost"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Tied :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["tied"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="tied" name="tied" placeholder="Tied"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['tied'] ? this.state.fieldUpdatePointTable['tied'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["tied"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["tied"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">No Result :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["nR"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="nR" name="nR" placeholder="No Result"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['nR'] ? this.state.fieldUpdatePointTable['nR'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["nR"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["nR"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Points :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["pts"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="pts" name="pts" placeholder="Points"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['pts'] ? this.state.fieldUpdatePointTable['pts'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["pts"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["pts"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">NRR :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.ereorUpdatePointTable && !this.state.ereorUpdatePointTable["nRR"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="nRR" name="nRR" placeholder="NRR"
                        value={this.state.fieldUpdatePointTable && this.state.fieldUpdatePointTable['nRR'] ? this.state.fieldUpdatePointTable['nRR'] : null}
                        type="text" onChange={this.inputUpdateChange} />
                      {this.state.ereorUpdatePointTable && this.state.ereorUpdatePointTable["nRR"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.ereorUpdatePointTable["nRR"]}
                        </div>
                        : null}
                    </div>
                  </div>


                </div>

                <div className="w-64 mx-auto my-8 sm:w-72">
                  <button className="flex justify-center w-full px-4 py-3 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button"
                    onClick={this.updatePointTableSubmit}
                  >Submit</button>
                </div>
              </form>

            </div>
          </div>
        </div >

      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category, pointTable, series, team } = state;
  return {
    news,
    users,
    category,
    pointTable,
    series,
    team,
  };

}
export default connect(mapStateToProps)(UpdatePointTable);
