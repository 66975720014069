import {
  categoryConstants
} from '../_constants';

export function category(state = {}, action) {

  switch (action.type) {
    case categoryConstants.GET_ALL_CATEGORY_PARENT_REQUEST:
      return {
        ...state
      };
    case categoryConstants.GET_ALL_CATEGORY_PARENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        allCategoryParent: action.users.getAllCategoryParent,
      };
    case categoryConstants.GET_ALL_CATEGORY_PARENT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case categoryConstants.ADD_CATEGORY_REQUEST:
      return {
        ...state
      };
    case categoryConstants.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case categoryConstants.ADD_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case categoryConstants.GET_LIST_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryConstants.GET_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        items: action.users.getCategoryList.list,
        total: action.users.getCategoryList.total
      };
    case categoryConstants.GET_LIST_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case categoryConstants.GET_SUB_CATEGORY_BYID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryConstants.GET_SUB_CATEGORY_BYID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        subCategoryItems: action.users.getSubCategoryById,
        // allCategoryTotal: action.users.getAllCategory
      };
    case categoryConstants.GET_SUB_CATEGORY_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case categoryConstants.GET_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryConstants.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getCategoryItems: action.users.getCategory,
        // allCategoryTotal: action.users.getAllCategory
      };
    case categoryConstants.GET_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case categoryConstants.GETALL_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryConstants.GETALL_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allCategory: action.users.getAllCategory,
        // allCategoryTotal: action.users.getAllCategory
      };
    case categoryConstants.GETALL_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case categoryConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case categoryConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case categoryConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case categoryConstants.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryConstants.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case categoryConstants.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case categoryConstants.DISABLE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case categoryConstants.DISABLE_CATEGORY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case categoryConstants.DISABLE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}