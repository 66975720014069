import React, { Component } from 'react';
import { connect } from 'react-redux';
import { teamRankingActions, teamRankingTypeActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AddTeamRankingModal from "./components/AddTeamRankingModal/AddTeamRankingModal";
import UpdateTeamRankingModal from "./components/UpdateTeamRankingModal/UpdateTeamRankingModal";
import ViewTeamRankingModal from "./components/ViewTeamRankingModal/ViewTeamRankingModal";
import LoadingOverlay from 'react-loading-overlay';

class TeamRanking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},

      keyWord: '',
      pageNo: 1,
      size: 10,

      resRankType: false,

      selection: false,

      addTeamRankingModal: false,
      fieldsteamRankingAdd: {},
      errorsTeamRankingTypeAdd: {},

      updateTeamRankingModal: false,
      fieldsteamRankingTypeUpdate: {},
      errorsTeamRankingTypeUpdate: {},

      viewTeamRankingModal: false,
      fieldsteamRankingTypeUpdate: {},
      errorsTeamRankingTypeUpdate: {},
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.teamRanking.addTeamRankingSuccess) {
      return {
        ...nextProps,

        addTeamRankingModal: false,
        fieldsteamRankingAdd: {},
        errorsTeamRankingTypeAdd: {},
        resRankType: false,
        updateTeamRankingModal: false,
        fieldsteamRankingTypeUpdate: {},
        errorsTeamRankingTypeUpdate: {},

      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    this.props.dispatch(teamRankingActions.getTeamRankingList(data));
    this.props.dispatch(teamRankingTypeActions.getAllTeamRankingType());

  }

  handleFilterTeamRankType = (data) => {
    console.log('datadatadatadatadatadatadatadatadatadatadatadata', data);
    let reqData = {
      keyWord: "",
      pageNo: 1,
      size: 10,
      typeId: data
    }

    console.log('reqData__________,reqData', reqData);
    this.setState({ resRankType: true, });
    this.props.dispatch(teamRankingActions.getTeamRankingListByRankingType(reqData));
  }
  handleOpenToAddTeamRankingModal = () => {
    this.setState({ addTeamRankingModal: true });
  }
  handleCloseAddTeamRankingModal = () => {
    this.setState({ addTeamRankingModal: false });
  }

  inputChangeTeamRankingAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsteamRankingAdd = this.state.fieldsteamRankingAdd;
    let errorsTeamRankingTypeAdd = this.state.errorsTeamRankingTypeAdd;
    fieldsteamRankingAdd[name] = value;
    errorsTeamRankingTypeAdd[name] = "";
    this.setState({ fieldsteamRankingAdd, errorsTeamRankingTypeAdd });
  }

  teamRankingAddSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationTeamRankingAdd()) {
      let { teamRanking } = this.props
      let { fieldsteamRankingAdd } = teamRanking
      let resData = {
        "typeId": this.state.fieldsteamRankingAdd.typeId,
        "rank": this.state.fieldsteamRankingAdd.rank,
        "team": this.state.fieldsteamRankingAdd.team,
        "rating": this.state.fieldsteamRankingAdd.rating,
        "point": this.state.fieldsteamRankingAdd.point,
      }
      console.log('resDataresDataresData', resData);
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(teamRankingActions.createTeamRanking(resData, data));
    }
  }

  handleValidationTeamRankingAdd = () => {
    let fieldsteamRankingAdd = this.state.fieldsteamRankingAdd;
    let errorsTeamRankingTypeAdd = {};
    let formIsValid = true;
    if (!fieldsteamRankingAdd["typeId"] || fieldsteamRankingAdd["typeId"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeAdd["typeId"] = "Cannot be empty";
    }
    if (!fieldsteamRankingAdd["rank"] || fieldsteamRankingAdd["rank"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeAdd["rank"] = "Cannot be empty";
    }
    if (!fieldsteamRankingAdd["team"] || fieldsteamRankingAdd["team"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeAdd["team"] = "Cannot be empty";
    }
    if (!fieldsteamRankingAdd["rating"] || fieldsteamRankingAdd["rating"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeAdd["rating"] = "Cannot be empty";
    }
    if (!fieldsteamRankingAdd["point"] || fieldsteamRankingAdd["point"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeAdd["point"] = "Cannot be empty";
    }
    this.setState({ errorsTeamRankingTypeAdd: errorsTeamRankingTypeAdd });
    return formIsValid;
  }


  handleOpenToUpdateTeamRankingModal = (data) => {
    this.setState({ updateTeamRankingModal: true, fieldsteamRankingTypeUpdate: data });
  }
  handleCloseUpdateTeamRankingModal = () => {
    // this.setState({ addteamRankingTypeSuccess: false });
    this.setState({ updateTeamRankingModal: false });
  }
  inputChangeTeamRankingUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsteamRankingTypeUpdate = this.state.fieldsteamRankingTypeUpdate;
    let errorsTeamRankingTypeUpdate = this.state.errorsTeamRankingTypeUpdate;
    fieldsteamRankingTypeUpdate[name] = value;
    errorsTeamRankingTypeUpdate[name] = "";
    this.setState({ fieldsteamRankingTypeUpdate, errorsTeamRankingTypeUpdate });
  }
  teamRankingUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationTeamRankingUpdate()) {
      let { teamRanking } = this.props
      let { fieldsteamRankingTypeUpdate } = teamRanking
      let resData = {
        "id": this.state.fieldsteamRankingTypeUpdate.id,
        "typeId": this.state.fieldsteamRankingTypeUpdate.typeId,
        "rank": this.state.fieldsteamRankingTypeUpdate.rank,
        "team": this.state.fieldsteamRankingTypeUpdate.team,
        "rating": this.state.fieldsteamRankingTypeUpdate.rating,
        "point": this.state.fieldsteamRankingTypeUpdate.point,
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(teamRankingActions.updateTeamRanking(resData, data));
    }
  }
  handleValidationTeamRankingUpdate = () => {
    let fieldsteamRankingTypeUpdate = this.state.fieldsteamRankingTypeUpdate;
    let errorsTeamRankingTypeUpdate = {};
    let formIsValid = true;
    if (!fieldsteamRankingTypeUpdate["typeId"] || fieldsteamRankingTypeUpdate["typeId"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeUpdate["typeId"] = "Cannot be empty";
    }
    if (!fieldsteamRankingTypeUpdate["rank"] || fieldsteamRankingTypeUpdate["rank"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeUpdate["rank"] = "Cannot be empty";
    }
    if (!fieldsteamRankingTypeUpdate["team"] || fieldsteamRankingTypeUpdate["team"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeUpdate["team"] = "Cannot be empty";
    }
    if (!fieldsteamRankingTypeUpdate["rating"] || fieldsteamRankingTypeUpdate["rating"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeUpdate["rating"] = "Cannot be empty";
    }
    if (!fieldsteamRankingTypeUpdate["point"] || fieldsteamRankingTypeUpdate["point"] === "") {
      formIsValid = false;
      errorsTeamRankingTypeUpdate["point"] = "Cannot be empty";
    }
    this.setState({ errorsTeamRankingTypeUpdate: errorsTeamRankingTypeUpdate });
    return formIsValid;
  }
  updateTeamRankingStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.team} TeamRankingType ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teamRankingActions.disableTeamRanking(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenToDeleteTeamRankingTypeModal = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.team} TeamRankingType ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teamRankingActions.deleteTeamRanking(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleOpenToViewTeamRankingModal = (data) => {
    this.setState({ viewTeamRankingModal: true, fieldsteamRankingTypeUpdate: data });
  }
  handleCloseToViewTeamRankingModal = () => {
    this.setState({ viewTeamRankingModal: false });
  }



  render() {

    let { teamRanking, teamRankingType } = this.props;
    let { getTeamRankingList, getTeamRankingByType } = teamRanking;
    let { teamRankingTypeItems } = teamRankingType;
    let selection = this.state.selection
    console.log("RENDER____getTeamRankingTypeListgetTeamRankingTypeList", getTeamRankingByType);

    console.log('selection', this.state.selection);


    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Team Ranking</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Team Ranking Details</h2>
              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    {/* <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddTeamRankingModal()}>ADD</button>
                    </div> */}
                  </div>
                </div>



                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  {/* <div className='flex mb-4 space-x-6'>
                    <div className='flex justify-center items-center space-x-3'>
                      {
                        teamRankingTypeItems && teamRankingTypeItems.length > 0 ?
                          teamRankingTypeItems.map((element, index) => (<React.Fragment key={element.id}>
                            <h2 key={element.id} className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm " onClick={() => this.handleFilterTeamRankType(element.id)}>
                              {element && element.name ? element.name : "-"}
                            </h2>
                          </React.Fragment>))
                          : (<tr className="bg-white bg-opacity-5 " >
                            <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                          </tr>)
                      }
                    </div>
                  </div> */}





                  <div className='flex mb-4 space-x-6'>
                    {/* Add your selection logic and update the state accordingly */}

                    <div className='flex mb-4 space-x-6'>
                      <div className='flex justify-center items-center space-x-3'>
                        {
                          teamRankingTypeItems && teamRankingTypeItems.length > 0 ?
                            teamRankingTypeItems.map((element, index) => (<React.Fragment key={element.id}>
                              <button onClick={() => this.setState({ selection: true })}>
                                <h2 key={element.id} className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer bgheader hover:bg-emerald-600 text-white px-2 py-1.5 rounded-sm " onClick={() => this.handleFilterTeamRankType(element.id)}>
                                  {element && element.name ? element.name : "-"}
                                </h2>
                              </button>
                            </React.Fragment>))
                            : (<tr className="bg-white bg-opacity-5 " >
                              <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                            </tr>)
                        }
                      </div>
                    </div>


                    {/* Render the button conditionally based on the selection state */}
                    {selection && <button

                      className='flex items-center p-1 bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddTeamRankingModal()}


                    >Add</button>}
                  </div>










                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              {/* <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Team Rank Type</th> */}
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rank</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rating</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Point</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">team</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getTeamRankingByType && getTeamRankingByType.length > 0 ?
                                getTeamRankingByType.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    {/* <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.typeId && element.typeId.name ? element.typeId && element.typeId.name : "-"}</td> */}
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.rank ? element.rank : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.rating ? element.rating : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.point ? element.point : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.team ? element.team : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToViewTeamRankingModal(element)}>More Details</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdateTeamRankingModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updateTeamRankingStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToDeleteTeamRankingTypeModal(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  <AddTeamRankingModal
                    addTeamRankingModal={this.state.addTeamRankingModal}
                    handleCloseAddTeamRankingModal={this.handleCloseAddTeamRankingModal}
                    inputChangeTeamRankingAdd={this.inputChangeTeamRankingAdd}
                    teamRankingTypeItems={teamRankingTypeItems}
                    fieldsteamRankingAdd={this.state.fieldsteamRankingAdd}
                    errorsTeamRankingTypeAdd={this.state.errorsTeamRankingTypeAdd}
                    handleFile={this.handleFile}
                    teamRankingAddSubmit={this.teamRankingAddSubmit}
                  />
                  <UpdateTeamRankingModal
                    updateTeamRankingModal={this.state.updateTeamRankingModal}
                    handleCloseUpdateTeamRankingModal={this.handleCloseUpdateTeamRankingModal}
                    inputChangeTeamRankingUpdate={this.inputChangeTeamRankingUpdate}
                    teamRankingTypeItems={teamRankingTypeItems}
                    fieldsteamRankingTypeUpdate={this.state.fieldsteamRankingTypeUpdate}
                    errorsTeamRankingTypeUpdate={this.state.errorsTeamRankingTypeUpdate}
                    handleFile={this.handleFile}
                    teamRankingUpdateSubmit={this.teamRankingUpdateSubmit}
                  />
                  <ViewTeamRankingModal
                    viewTeamRankingModal={this.state.viewTeamRankingModal}
                    handleCloseToViewTeamRankingModal={this.handleCloseToViewTeamRankingModal}
                    teamRankingTypeItems={teamRankingTypeItems}
                    fieldsteamRankingTypeUpdate={this.state.fieldsteamRankingTypeUpdate}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { teamRanking, teamRankingType } = state;
  return {
    teamRanking,
    teamRankingType,
  };
}
export default connect(mapStateToProps)(TeamRanking);