/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerRankTypeActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePlayerDetailsModal from "./components/UpdatePlayerDetailsModal/UpdatePlayerDetailsModal";
import AddPlayerDetailsModal from "./components/AddPlayerDetailsModal/AddPlayerDetailsModal";

class PlayerRankType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},
      keyWord: '',
      pageNo: 1,
      size: 10,
      addPlayerModal: false,
      fieldsplayerAdd: {},
      errorsPlayerAdd: {},
      updatePlayerModal: false,
      fieldsplayerUpdate: {},
      errorsPlayerUpdate: {},
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {


    if (nextProps.playerRankType.addplayeruccess) {

      return {
        ...nextProps,

        addPlayerModal: false,
        fieldsplayerAdd: {},
        errorsPlayerAdd: {},

        updatePlayerModal: false,
        fieldsplayerUpdate: {},
        errorsPlayerUpdate: {},


      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(playerRankTypeActions.getPlayerRankingTypeList(data));
  }


  handleOpenToAddPlayerModal = () => {
    this.setState({ addPlayerModal: true });
  }
  handleCloseAddPlayerModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ addPlayerModal: false });
  }
  inputChangePlayerAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsplayerAdd = this.state.fieldsplayerAdd;
    let errorsPlayerAdd = this.state.errorsPlayerAdd;
    fieldsplayerAdd[name] = value;
    errorsPlayerAdd[name] = "";
    this.setState({ fieldsplayerAdd, errorsPlayerAdd });
  }
  playerAddSubmit = () => {
    if (this.handleValidationPlayerAdd()) {
      let resData = {
        "name": this.state.fieldsplayerAdd.name,
        "type": this.state.fieldsplayerAdd.type,
      }
      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(playerRankTypeActions.createPlayerRankingType(resData, data));
    }
  }


  handleValidationPlayerAdd = () => {
    let fieldsplayerAdd = this.state.fieldsplayerAdd;
    let errorsPlayerAdd = {};
    const fieldNames = ["type", "name"];
    let formValid = true;
    fieldNames.forEach(fieldName => {
      if (!fieldsplayerAdd[fieldName] || fieldsplayerAdd[fieldName] === "") {
        formValid = false;
        errorsPlayerAdd[fieldName] = `${fieldName} Cannot be empty`;
      }
    });

    this.setState({ errorsPlayerAdd }, () => {
      // Callback after state is updated
      console.log("Validation errors updated:", this.state.errorsPlayerAdd);
    });

    return formValid;
  }



  handleOpenToUpdatePlayerModal = (data) => {
    this.setState({ updatePlayerModal: true, fieldsplayerUpdate: data });
  }
  handleCloseUpdatePlayerModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ updatePlayerModal: false });
  }
  inputChangePlayerUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsplayerUpdate = this.state.fieldsplayerUpdate;
    let errorsPlayerUpdate = this.state.errorsPlayerUpdate;
    fieldsplayerUpdate[name] = value;
    errorsPlayerUpdate[name] = "";
    this.setState({ fieldsplayerUpdate, errorsPlayerUpdate });
  }
  playerUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPlayerUpdate()) {
      let resData = {
        "id": this.state.fieldsplayerUpdate.id,
        "name": this.state.fieldsplayerUpdate.name,
        "type": this.state.fieldsplayerUpdate.type
      }

      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(playerRankTypeActions.UpdatePlayerRankingType(resData, data));
    }
  }
  handleValidationPlayerUpdate = () => {
    let fieldsplayerUpdate = this.state.fieldsplayerUpdate;
    let errorsPlayerUpdate = {};
    let formIsValid = true;

    if (!fieldsplayerUpdate["name"] || fieldsplayerUpdate["name"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["name"] = "Name Cannot be empty";
    }
    if (!fieldsplayerUpdate["type"] || fieldsplayerUpdate["type"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["type"] = "Type Cannot be empty";
    }

    this.setState({ errorsPlayerUpdate: errorsPlayerUpdate });
    return formIsValid;
  }


  updatePlayerStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} Player ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(playerRankTypeActions.disablePlayerRankingType(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenToDeletePlayerModal = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} Player ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(playerRankTypeActions.deletePlayerRankingType(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  inputChange = (e) => {

    this.setState({ size: e.target.value }, () => {
      const { keyWord, pageNo, size } = this.state; // Destructure the updated state
      const data = {
        keyWord,
        pageNo,
        size // Use the updated value of size
      };
      this.props.dispatch(playerRankTypeActions.getPlayerRankingTypeList(data));
    });
  }

  handleSearch = (e) => {
    console.log(e.target.value)
    this.setState({ keyWord: e.target.value }, () => {
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(playerRankTypeActions.getPlayerRankingTypeList(data));
    })



  }


  render() {

    let { playerRankType } = this.props;
    let { rankType } = playerRankType;

    return (

      <>
        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Player Rank Type</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Player Rank Type Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={(e) => this.inputChange(e)} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={(e) => this.handleSearch(e)} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddPlayerModal()}>ADD</button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">type</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              rankType && rankType.length > 0 ?
                                rankType.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.type ? element.type : "-"}</td>

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      {/* <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.SFKSHFJKFJKHSJKFHKJSF(element)}>More</button>
                                      </div> */}

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdatePlayerModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updatePlayerStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>



                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToDeletePlayerModal(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  <AddPlayerDetailsModal
                    addPlayerModal={this.state.addPlayerModal}
                    handleCloseAddPlayerModal={this.handleCloseAddPlayerModal}
                    inputChangePlayerAdd={this.inputChangePlayerAdd}
                    fieldsplayerAdd={this.state.fieldsplayerAdd}
                    errorsPlayerAdd={this.state.errorsPlayerAdd}
                    handleFile={this.handleFile}
                    playerAddSubmit={this.playerAddSubmit}
                  />
                  <UpdatePlayerDetailsModal
                    updatePlayerModal={this.state.updatePlayerModal}
                    handleCloseUpdatePlayerModal={this.handleCloseUpdatePlayerModal}
                    inputChangePlayerUpdate={this.inputChangePlayerUpdate}
                    fieldsplayerUpdate={this.state.fieldsplayerUpdate}
                    errorsPlayerUpdate={this.state.errorsPlayerUpdate}
                    handleFile={this.handleFile}
                    playerUpdateSubmit={this.playerUpdateSubmit}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { playerRankType, player } = state;


  return {
    playerRankType,
    player,
  };
}
export default connect(mapStateToProps)(PlayerRankType);