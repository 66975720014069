import {
  pointTableConstants
} from '../_constants';

export function pointTable(state = {}, action) {

  console.log('actionactionactionactionactionactionactionactionactionaction', action);

  switch (action.type) {

    case pointTableConstants.GET_POINT_TABLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case pointTableConstants.GET_POINT_TABLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pointItem: action.point.getPointsTableList.list,
        pointTotal: action.point.getPointsTableList.total
      };
    case pointTableConstants.GET_POINT_TABLE_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case pointTableConstants.GET_POINT_TABLE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case pointTableConstants.GET_POINT_TABLE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        getById: action.point.getPointsTableById,
      };
    case pointTableConstants.GET_POINT_TABLE_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case pointTableConstants.GET_POINT_TABLE_BY_SERIES_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case pointTableConstants.GET_POINT_TABLE_BY_SERIES_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        getBySeriesId: action.point.pointsTableBySeriesId.list,
      };
    case pointTableConstants.GET_POINT_TABLE_BY_SERIES_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case pointTableConstants.GET_ALL_POINT_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case pointTableConstants.GET_ALL_POINT_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        allItem: action.point.getAllPointsTable,
        allTotal: action.point.getAllPointsTable.total
      };
    case pointTableConstants.GET_ALL_POINT_TABLE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case pointTableConstants.UPDATE_POINT_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pointTableConstants.UPDATE_POINT_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case pointTableConstants.UPDATE_POINT_TABLE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case pointTableConstants.ADD_POINT_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pointTableConstants.ADD_POINT_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case pointTableConstants.ADD_POINT_TABLE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case pointTableConstants.UPDATE_POINT_TABLE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pointTableConstants.UPDATE_POINT_TABLE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case pointTableConstants.UPDATE_POINT_TABLE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case pointTableConstants.DELETE_POINT_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pointTableConstants.DELETE_POINT_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case pointTableConstants.DELETE_POINT_TABLE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    default:
      return state
  }
}