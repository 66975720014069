
import { authHeader } from '../_helpers';
import { CONST } from '../_config';


export const imageService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getAllImage,
    createImage,
    updateImage,
    ImageList,
    getImageById,
    deleteImage,
    disableImage,
    createNotification,
    getNotificationList,
    uploadImage
};
function logout() {
    // localStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            console.log("servive_uploadImage", userObj);
            return userObj;
        });
}


function getImageById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getImageById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getImageById: data.data
            }
            console.log("i am in service getImageById", userObj);

            return userObj;
        });
}
function getNotificationList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNotificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNotificationList: data.data
            }
            console.log("i am in service getNotificationList", userObj);

            return userObj;
        });
}
function ImageList(data) {
    console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getImageList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                ImageList: data.data
            }
            console.log("i am in service getImageList", userObj);

            return userObj;
        });
}
function getAllImage(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllImage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllImage: data.data
            }
            console.log("i am in service getAllImage", userObj);

            return userObj;
        });
}

// function verifyEmail(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/validateOtpAdmin`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 verifyEmail: data.data
//             }
//             console.log("i am in service''...>> verifyEmail ::", userObj);

//             return userObj;
//         });
// }

function deleteImage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteImage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteImage: data.data
            }
            console.log("i am in service''...>> deleteImage ::", userObj);

            return userObj;
        });
}
function disableImage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateImageStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateImageStatus: data.data
            }
            console.log("i am in service''...>> updateImageStatus ::", userObj);

            return userObj;
        });
}

function updateImage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateImage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateImage: data.data
            }
            console.log("I am in service updateImage", userObj);

            return userObj;
        });
}
function createImage(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createImage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createImage: data.data
            }
            console.log("I am in service createImage", userObj);

            return userObj;
        });
}
function createNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createNotification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNotification: data.data
            }
            console.log("I am in service createNotification", userObj);

            return userObj;
        });
}









function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}