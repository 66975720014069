import {
  teamRankingConstants
} from '../_constants';

export function teamRanking(state = {}, action) {

  switch (action.type) {


    case teamRankingConstants.GET_LIST_TEAM_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingConstants.GET_LIST_TEAM_RANKING_SUCCESS:
      return {
        ...state,
        addTeamRankingSuccess: false,
        loading: false,
        getTeamRankingList: action.team.getTeamRankingList.list,
        getTeamRankingListTotal: action.team.getTeamRankingList.total
      };
    case teamRankingConstants.GET_LIST_TEAM_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case teamRankingConstants.GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingConstants.GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addTeamRankingSuccess: false,
        loading: false,
        getTeamRankingByType: action.team.getTeamRankingListByRankingType.list,
      };
    case teamRankingConstants.GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case teamRankingConstants.ADD_TEAM_RANKING_REQUEST:
      return {
        ...state
      };
    case teamRankingConstants.ADD_TEAM_RANKING_SUCCESS:
      return {
        ...state,
        addTeamRankingSuccess: true
      };
    case teamRankingConstants.ADD_TEAM_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };





    case teamRankingConstants.UPDATE_TEAM_RANKING_REQUEST:
      return {
        ...state
      };
    case teamRankingConstants.UPDATE_TEAM_RANKING_SUCCESS:
      return {
        ...state,
        addTeamRankingSuccess: true
      };
    case teamRankingConstants.UPDATE_TEAM_RANKING_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case teamRankingConstants.DISABLE_TEAM_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingConstants.DISABLE_TEAM_RANKING_SUCCESS:
      return {
        ...state,
        addTeamRankingSuccess: false,
        loading: false,
      };
    case teamRankingConstants.DISABLE_TEAM_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



      

    case teamRankingConstants.DELETE_TEAM_RANKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingConstants.DELETE_TEAM_RANKING_SUCCESS:
      return {
        ...state,
        addTeamRankingSuccess: false,
        loading: false,
      };
    case teamRankingConstants.DELETE_TEAM_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    // case teamRankingConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case teamRankingConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS:
    //   return {
    //     ...state,
    //     updateUserSuccess: true,
    //     loading: false,
    //     allCityByStateId: action.users.getAllCityByStateId,
    //   };
    // case teamRankingConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error
    //   };
    // case teamRankingConstants.GET_TEAM_RANKING_TYPE_BY_ID_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case teamRankingConstants.GET_TEAM_RANKING_TYPE_BY_ID_SUCCESS:
    //   return {
    //     ...state,
    //     updateUserSuccess: true,
    //     loading: false,
    //     teamRankingTypeRowData: action.users.getTeamRankingTypeById,
    //   };
    // case teamRankingConstants.GET_TEAM_RANKING_TYPE_BY_ID_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error
    //   };




    // case teamRankingConstants.GETALL_TEAM_RANKING_TYPE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case teamRankingConstants.GETALL_TEAM_RANKING_TYPE_SUCCESS:
    //   return {
    //     ...state,
    //     addTeamRankingSuccess: false,
    //     loading: false,
    //     teamRankingTypeItems: action.users.getAllTeamRankingType,
    //     teamRankingTypeTotal: action.users.getAllTeamRankingType
    //   };
    // case teamRankingConstants.GETALL_TEAM_RANKING_TYPE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error
    //   };








    default:
      return state
  }
}