import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const matchService = {
    login,
    logout,
    getAllCustomMatch,
    createCustomMatch,
    updateCustomMatch,
    getCustomMatchList,
    updateMatchStatus,
    updateMatchType,
    deleteCustomMatch,
    updateCustomMatchIsActiveStatus,
    updateCustomMatchStatus,
    getCustomMatchById,
    getAllTeams,
};




function updateMatchType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                matchStatus: data.data
            }
            console.log("i am in service''...>> updateMatchType ::", userObj);

            return userObj;
        });
}







function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getCustomMatchList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCustomMatchList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getCustomMatchList: data.data
            }
            console.log("getCustomMatchList_Data_:::::", userObj);
            return userObj;
        });
}

// function getAllCityByStateId(data) {
//     // console.log("data m kya aa rha h::service:::", data);
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllCityByStateId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 getAllCityByStateId: data.data
//             }
//             console.log("i am in service getAllCityByStateId", userObj);

//             return userObj;
//         });
// }
function getAllCustomMatch(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllCustomMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllCustomMatch: data.data
            }
            console.log("i am in service getAllCustomMatch", userObj);

            return userObj;
        });
}
function getAllTeams() {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify()
    }
    return fetch(CONST.BACKEND_URL + `/getAllTeams`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTeams: data.data
            }
            console.log("i am in service getAllTeams", userObj);

            return userObj;
        });
}

function deleteCustomMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteCustomMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteCustomMatch: data.data
            }
            console.log("i am in service''...>> deleteCustomMatch ::", userObj);

            return userObj;
        });
}

function updateCustomMatchIsActiveStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCustomMatchIsActiveStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateCustomMatchIsActiveStatus: data.data
            }
            console.log("i am in service''...>> updateCustomMatchIsActiveStatus ::", userObj);

            return userObj;
        });
}

function getCustomMatchById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCustomMatchById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getCustomMatchById: data.data
            }
            console.log("i am in service''...>> getCustomMatchById ::", userObj);

            return userObj;
        });
}
function updateCustomMatchStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCustomMatchStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateCustomMatchStatus: data.data
            }
            console.log("i am in service''...>> updateCustomMatchStatus ::", userObj);

            return userObj;
        });
}
function updateMatchStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMatchStaus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                matchStatus: data.data
            }
            console.log("i am in service''...>> updateMatchStatus ::", userObj);

            return userObj;
        });
}
function updateCustomMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCustomMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateCustomMatch: data.data
            }
            return userObj;
        });
}
function createCustomMatch(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createCustomMatch`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createCustomMatch: data.data
            }
            console.log("I am in service createCustomMatch", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}