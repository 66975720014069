import React, { Component } from 'react';
//import QRCode from 'qrcode.react';

import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import { FaSignOutAlt } from 'react-icons/fa';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }



  componentDidMount() {

  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  handleLogout = () => {
    this.props.dispatch(userActions.logout());
  }

  render() {



    return (
      <>

        <div style={{ backgroundColor: "#F3F3F4 " }} class=" flex items-center justify-between flex-wrap gap-4 top-0 z-20 px-3 pt-3">
          <div>
            <label style={{ backgroundColor: "#1FABB5 " }} class="block cursor-pointer  items-center py-2 px-[11px] rounded" for="menu-toggle"><svg class="fill-current h-[14px] w-[14px] text-white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={() => this.onClickMenu('/app/mainpage')}><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg></label>
            {/* <input class="hidden" type="checkbox" id="menu-toggle" /> */}
          </div>

          <div className="flex justify-end space-x-6 pr-2">
            <div className='visible hover:invisible'>
              <p className="text-sm uppercase  text-gray-500/70">Api Provider Cp2 </p>
            </div>
            <div className="flex space-x-2 cursor-pointer">
              <div className="pt-0.5">
                <FaSignOutAlt className='text-gray-500/70' />
              </div>
              <div>
                <p
                  onClick={() => this.onClickMenu("/login")}
                  // onClick={() => this.handleLogout()}
                  className="text-sm font-semibold text-gray-500/70">Sign out</p>
              </div>
            </div>
          </div>

        </div>



      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Header);