import { authHeader, history } from '../_helpers';
import { CONST, } from '../_config';

export const playerRankingService = {
    getPlayerRankingList,
    getAllPlayerRankingList,
    getPlayerRankingListByPlayerRankingTypeId,
    createPlayerRanking,
    updatePlayerRanking,
    disablePlayerRanking,
    deletePlayerRanking,
    getAllPlayerRankingType,

    login,
    logout,

};


function getPlayerRankingList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPlayerRankingList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPlayerRankingList: data.data
            }

            console.log('serviesd_getPlayerRankingList', playerObj);

            return playerObj;
        });
}

function getPlayerRankingListByPlayerRankingTypeId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPlayerRankingListByPlayerRankingTypeId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPlayerRankingListByPlayerRankingTypeId: data.data
            }

            console.log('serviesd_getPlayerRankingList', playerObj);

            return playerObj;
        });
}
function getAllPlayerRankingList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPlayerRankingList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                getAllPlayerRankingList: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function getAllPlayerRankingType() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPlayerRankingTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                getAllPlayerRankingType: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function createPlayerRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPlayerRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                createPlayerRanking: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function updatePlayerRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePlayerRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                updatePlayerRanking: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function disablePlayerRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/disablePlayerRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                disablePlayerRanking: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function deletePlayerRanking(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePlayerRanking`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                deletePlayerRanking: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}










function logout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
}
function onerrorlogout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
    window.location.reload();
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(player => {
            let playerObj = {
                playerinfo: player
            }
            if (player.data) {
                localStorage.setItem('player', JSON.stringify(player.data));
            }
            return playerObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {

                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}