import { pointTableConstants } from '../_constants';
import { pointTableService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';
export const pointTableActions = {
    getPointsTableList,
    getAllPointsTable,
    disablePointsTable,
    deletePointsTable,
    createPointsTable,
    updatePointsTable,
    getPointsTableById,
    getPointsTableBySeriesId,
};

function getPointsTableList(data) {
    return dispatch => {
        dispatch(request());
        pointTableService.getPointsTableList(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.GET_POINT_TABLE_LIST_REQUEST } }
    function success(point) { return { type: pointTableConstants.GET_POINT_TABLE_LIST_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.GET_POINT_TABLE_LIST_FAILURE, error } }
}
function getPointsTableById(data) {
    return dispatch => {
        dispatch(request());
        pointTableService.getPointsTableById(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.GET_POINT_TABLE_BY_ID_REQUEST } }
    function success(point) { return { type: pointTableConstants.GET_POINT_TABLE_BY_ID_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.GET_POINT_TABLE_BY_ID_FAILURE, error } }
}
function getPointsTableBySeriesId(data) {
    return dispatch => {
        dispatch(request());
        pointTableService.getPointsTableBySeriesId(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.GET_POINT_TABLE_BY_SERIES_ID_REQUEST } }
    function success(point) { return { type: pointTableConstants.GET_POINT_TABLE_BY_SERIES_ID_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.GET_POINT_TABLE_BY_SERIES_ID_FAILURE, error } }
}
function getAllPointsTable(data) {
    return dispatch => {
        dispatch(request());
        pointTableService.getAllPointsTable(data)
            .then(
                point => {
                    console.log("getAllPlayerRankingType___players_Action:::", point);
                    dispatch(success(point))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.GET_ALL_POINT_TABLE_REQUEST } }
    function success(point) { return { type: pointTableConstants.GET_ALL_POINT_TABLE_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.GET_ALL_POINT_TABLE_FAILURE, error } }
}

function createPointsTable(data, pagination, props, seriesId) {
    return dispatch => {
        dispatch(request());
        pointTableService.createPointsTable(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPointsTableList(pagination))
                    props.history.push('/app/pointtable/' + seriesId)
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.ADD_POINT_TABLE_REQUEST } }
    function success(point) { return { type: pointTableConstants.ADD_POINT_TABLE_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.ADD_POINT_TABLE_FAILURE, error } }
}
function updatePointsTable(data, pagination, props, seriesId) {
    return dispatch => {
        dispatch(request());
        pointTableService.updatePointsTable(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPointsTableList(pagination))
                    props.history.push('/app/pointtable/' + seriesId)
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.UPDATE_POINT_TABLE_REQUEST } }
    function success(point) { return { type: pointTableConstants.UPDATE_POINT_TABLE_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.UPDATE_POINT_TABLE_FAILURE, error } }
}
function disablePointsTable(data, pagination, resData) {
    return dispatch => {
        dispatch(request());
        pointTableService.disablePointsTable(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point));

                    dispatch(this.getPointsTableList(pagination))
                    dispatch(this.getPointsTableBySeriesId(resData))

                    dispatch(alertActions.success("success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.UPDATE_POINT_TABLE_STATUS_REQUEST } }
    function success(point) { return { type: pointTableConstants.UPDATE_POINT_TABLE_STATUS_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.UPDATE_POINT_TABLE_STATUS_FAILURE, error } }
}
function deletePointsTable(data, pagination, resData) {
    return dispatch => {
        dispatch(request());
        pointTableService.deletePointsTable(data)
            .then(
                point => {
                    console.log("getPlayerList___players_Action:::", point);
                    dispatch(success(point));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPointsTableList(pagination))
                    dispatch(this.getPointsTableBySeriesId(resData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pointTableConstants.DELETE_POINT_TABLE_REQUEST } }
    function success(point) { return { type: pointTableConstants.DELETE_POINT_TABLE_SUCCESS, point } }
    function failure(error) { return { type: pointTableConstants.DELETE_POINT_TABLE_FAILURE, error } }
}