import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerActions, teamsActions, matchMoreDetailActions, alertActions, matchActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import LoadingOverlay from 'react-loading-overlay';
import { authHeader } from '../../_helpers';
import Multiselect from 'multiselect-react-dropdown';
import { MdDeleteOutline } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import { MdDiversity2, MdSaveAs } from 'react-icons/md';
import CreateOutModel from "./components/CreateOutModel/CreateOutModel";
import { BiEdit } from 'react-icons/bi';
import { BiReset } from "react-icons/bi";

class MatchMoreDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sectiontype: '',
      selectedPlayer: {},
      sportAndSeriesId: {},
      rowDetails: {},
      fieldsMatchData: {},
      errorsMatchData: {},
      keyWord: '',
      pageNo: 1,
      size: 10,
      first_circle: '',
      currentBatsmanId: "",
      fieldBatsman: "",
      SecStateField: "",
      statusChange: "",
      matchType: "",
      fastStateField: "",
      fieldBowler: "",
      errorBowler: "",
      fieldBTeam: "",
      fieldATeam: null,
      fieldTeamType: '',
      fieldInning: '',
      errorTeamAType: "",
      errorTeamBType: "",
      fieldsMatchInfo: {},
      battingTeam: '',
      teamAPlayer: '',
      teamBPlayer: '',
      teamAId: '',
      teamBId: '',
      batsmanField: '',
      fieldInningStatus: '',
      batsmanOutFields: false,
      playerOutField: {},
      errorPlayerOut: {},
      teamArray: [],
      CurrentBattingTeam: "",
      currentBowllingTeam: "",
      inning: "",
      deliveryType: "",
      runType: 0,
      overthrowType: 'No',
      eventType: "",
      matchStatus: "",
      batsman1: {},
      batsman2: {},


    }
  }


  // CurrentBattingTeam:nextProps?.matchMoreDetail?.matchInfoData?.battingTeam,
  // currentBowllingTeam:nextProps?.matchMoreDetail?.matchInfoData?.bowlingTeam,
  // matchType:nextProps?.matchMoreDetail?.matchInfoData&&nextProps?.matchMoreDetail?.matchInfoData?.matchType ? nextProps?.matchMoreDetail?.matchInfoData?.matchType:null,

  static getDerivedStateFromProps(nextProps, prevState) {



    if (nextProps.matchMoreDetail.updateUserSuccess) {
      return {
        ...nextProps,



        fieldsMatchInfo: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData ? nextProps.matchMoreDetail.matchInfoData : null,


        battingTeam: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.currentTeamId && nextProps.matchMoreDetail.matchInfoData.currentTeamId.player ? nextProps.matchMoreDetail.matchInfoData.currentTeamId.player : null,

        teamAPlayer: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.teamA && nextProps.matchMoreDetail.matchInfoData.teamA.player ? nextProps.matchMoreDetail.matchInfoData.teamA.player : null,

        teamBPlayer: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.teamB && nextProps.matchMoreDetail.matchInfoData.teamB.player ? nextProps.matchMoreDetail.matchInfoData.teamB.player : null,

        teamAId: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.teamA && nextProps.matchMoreDetail.matchInfoData.teamA.id ? nextProps.matchMoreDetail.matchInfoData.teamA.id : null,

        teamBId: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.teamB && nextProps.matchMoreDetail.matchInfoData.teamB.id ? nextProps.matchMoreDetail.matchInfoData.teamB.id : null,


      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {


    // this.props.dispatch(liveMatch());

    let data = {
      "matchId": this.props.match.params._id,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    console.log("this.props.match.params.matchId", this.props.match.params);

    let temp = {
      "matchId": this.props.match.params.matchId,
    }
    let temp2 = {
      "matchId": this.props.match.params._id,
    }
    this.updateTeamArray()
    console.log("temptemptemptemptemptemptemp", temp2);
    this.props.dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: this.props.match.params._id }));
    this.props.dispatch(matchMoreDetailActions.getAllTeams());
    this.props.dispatch(matchMoreDetailActions.getLastTwentyBallData(data));
  }


  componentDidUpdate(prevProps) {
    const { matchMoreDetail } = this.props;
    const { matchInfoData } = matchMoreDetail || {};
    const { matchMoreDetail: prevMatchMoreDetail } = prevProps;
    const { matchInfoData: prevMatchInfoData } = prevMatchMoreDetail || {};




    // Check if matchInfoData has changed
    // CurrentBattingTeam:nextProps?.matchMoreDetail?.matchInfoData?.battingTeam,
    // currentBowllingTeam:nextProps?.matchMoreDetail?.matchInfoData?.bowlingTeam,
    if (matchInfoData !== prevMatchInfoData && matchInfoData) {
      this.updateTeamArray();
      this.setState({ inning: matchInfoData?.currentInning, currentBowllingTeam: matchInfoData?.bowlingTeam?.id, CurrentBattingTeam: matchInfoData?.battingTeam?.id, matchType: matchInfoData?.matchType, matchStatus: matchInfoData?.matchStatus, batsman1: matchInfoData?.batsman[0]?.playerId?.id, batsman2: matchInfoData?.batsman[1]?.playerId?.id })
    }
  }


  liveMatch = () => {

    // let data = {
    //   "matchId": "55565"
    // }

    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });
    const requestOptions = {
      method: "GET",
      headers: header,
      // body: JSON.stringify(data)
    }
    fetch(`http://38.242.135.119:5059/api/admin/liveMatch?matchId=1312`, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({ fieldsMatchData: result.data });
      }).catch(error => console.log('error_kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', error));

    { { } }


  }

  setMannualStrike = (data) => {
    console.log('akakakakkakakakuuuuuuuuuuuuuuuuuuuuuuuuuuuuu', data);
    let datatemp = {
      "matchId": this.props.match.params._id,
      "batsmanId": data,
    }

    console.log("datatempdatatempdatatempdatatemp", datatemp);
    confirmAlert({

      title: 'Confirm to Set Strike?',
      message: `Are you sure to set mannual strike ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.manualStrike(datatemp,))
        },
        {
          label: 'No'
        }
      ]
    });
  }





  deleteBatsmam = (data) => {
    console.log('datadatadatadatadatadatadatadatadata::::::????', data);
    let datatemp = {
      "id": this.props.match.params._id,
      "playerId": this.state.batsmanOutFields,
      "first_circle": this.state.batsmanOutFields,
      "currentBallStatus": this.state.batsmanOutFields.currentBallStatus,
      "message": this.state.batsmanOutFields.message,
    }

    console.log("datatempdatatempdatatempdatatemp", datatemp);
    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete Player ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.cancelBatsmanInLiveMatch(datatemp,))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  deleteBowler = (data) => {
    let datatemp = {
      // "match_id": this.props.match.params.matchId,
      // "playerId": this.props.match.params.playerId,
      "id": this.props.match.params._id,
      "playerId": data
    }
    // let pdata = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete Bowler ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.cancelBowlerInLiveMatch(datatemp,))
        },
        {
          label: 'No'
        }
      ]
    });
  }




  deleteInning = (data) => {
    console.log('datadatadatadatadatadatadatadatadata::::::????', data);
    let datatemp = {
      "id": this.props.match.params._id,

    }
    let resData = {
      "matchId": this.props.match.params._id,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    console.log("datatempdatatempdatatempdatatemp", datatemp);
    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.deleteInning(datatemp, resData))
        },
        {
          label: 'No'
        }
      ]
    });
  }




  handleSelect = (e) => {
    console.log('1111111111111111111111111111111okd', e.target.value);
    console.log(e.target.value);
    this.setState({ fastStateField: e.target.value, batsman1: e.target.value });
    // let temp = {
    //   "matchId": this.props.match.params.matchId,
    //   "matchData": [this.state.fieldBatsman],
    // }
    // console.log("temptemptemptemptemptemp", temp);
    // this.props.dispatch(matchMoreDetailActions.getBattingTeamList(temp))
    // this.props.dispatch(matchMoreDetailActions.saveBatsmanInLiveMatch(temp))
  }


  handleSelect1 = (e) => {
    console.log('1111111111111111111111111111111okd', e.target);
    // console.log('1234567890123456789', e.target.name, e.target.value);

    this.setState({ SecStateField: e.target.value, batsman2: e.target.value });

  }

  handleSelectStatus = (e) => {
    console.log(e);
    // console.log('12345678901234567890000000000', e.target.name, e.target.value);

    this.setState({ matchStatus: e });

  }


  handleSelectMatch = (e) => {
    this.setState({ matchType: e });
  }




  handleBowlerSelect = (e) => {

    console.log('target____________________________', e.target.name, e.target.value);
    this.setState({ fieldBowler: e.target.value });
  }

  handleBatsmanSelect = (e) => {

    console.log('target____________________________', e.target.name, e.target.value);
    this.setState({ fieldBatsman: e.target.value });
  }

  handleTeamSelectA = (e) => {
    console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeEEEEEEEEEEEEEEEEEAAAAAAAA', e);
    this.setState({ fieldATeam: e.target.value });
  }

  handleTeamSelectB = (e) => {
    console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeEEEEEEEEEEEEEEEEEBBBBBBBBB', e);
    this.setState({ fieldBTeam: e.target.value });
  }

  handleInnings = (data) => {
    console.log('45154548????????????????????', data);
    // console.log('fieldInningfieldInningfieldInning', e.target);
    this.setState({ fieldInning: data });
  }



  submitSave1 = () => {

    let resData = {
      "id": this.props.match.params._id,
      "playerId": this.state.fastStateField ? this.state.fastStateField : null,
    }
    console.log("submitSavesubmitSavesubmitSavesubmitSavesubmitSave", resData);


    this.props.dispatch(matchMoreDetailActions.saveBatsmanInLiveMatch(resData))
  }

  submitSave2 = () => {

    let resData = {
      "id": this.props.match.params._id,
      "playerId": this.state.SecStateField ? this.state.SecStateField : null,
    }
    console.log("submitSave2submitSave2submitSave2submitSave2submitSave2", resData);
    this.props.dispatch(matchMoreDetailActions.saveBatsmanInLiveMatch(resData))
  }


  bowlerSave = () => {
    let resData = {
      "id": this.props.match.params._id,
      "playerId": this.state.fieldBowler ? this.state.fieldBowler : null,
    }
    console.log("bowlerSavebowlerSavebowlerSavebowlerSave", resData);
    this.props.dispatch(matchMoreDetailActions.saveBowlerInLiveMatch(resData))
  }

  sbmitTeamASave = () => {
    let resData = {
      "id": this.props.match.params._id,
      "teamType": 'A',
      "teamId": this.state.fieldATeam,
    }

    confirmAlert({

      title: 'Confirm To Change Team',
      message: `Are you sure you want to change team?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.updateCurrentTeam(resData))
        },
        {
          label: 'No'
        }
      ]
    });




  }

  sbmitTeamBSave = () => {
    let resData = {
      "id": this.props.match.params._id,
      "teamType": 'B',
      "teamId": this.state.fieldBTeam ? this.state.fieldBTeam : null,
    }



    confirmAlert({

      title: 'Confirm to Change Team',
      message: `Are you sure change Team Name?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.updateCurrentTeam(resData))
        },
        {
          label: 'No'
        }
      ]
    });




  }





  updateMatchStatus = () => {

    let resData = {
      "id": this.props.match.params._id,
      "matchStatus": this.state.matchStatus,
    }

    confirmAlert({

      title: 'Confirm to Change Match Status',
      message: `Are you sure change Match Status?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchActions.updateMatchStatus(resData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  updateMatchType = () => {

    let resData = {
      "id": this.props.match.params._id,
      "matchType": this.state.matchType,
    }


    confirmAlert({

      title: 'Confirm to Change Match Type',
      message: `Are you sure change Match Type?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchActions.updateMatchType(resData))
        },
        {
          label: 'No'
        }
      ]
    });


    // }
  }



  updateInnings = () => {

    let resData = {
      "id": this.props.match.params._id,
      "currentInning": this.state.fieldInning,
      "teamType": this.state.fieldTeamType,
    }
    console.log("updateInningsupdateInningsupdateInnings", resData);
    this.props.dispatch(matchMoreDetailActions.updateCurrentInnings(resData))
    // }
  }

  SetTeamA = () => {

    let { matchId } = this.props.match.params._id

    let resData = {
      "id": this.props.match.params._id,
      "teamId": this.state.teamAId ? this.state.teamAId : null,
      "teamType": "A",
      currentInning: this.state.fieldInning
    }
    console.log("SetTeamSetTeamSetTeam", resData);
    this.props.dispatch(matchMoreDetailActions.setCurrentTeam(resData, matchId))
    // }
  }

  SetTeamB = () => {

    let { matchId } = this.props.match.params._id
    // let currentInning = this.state.fieldsMatchInfo['currentInning']
    let resData = {
      "id": this.props.match.params._id,
      "teamId": this.state.teamBId ? this.state.teamBId : null,
      "teamType": "B",
      currentInning: this.state.fieldInning
    }
    console.log("SetTeamSetTeamSetTeam", resData);
    this.props.dispatch(matchMoreDetailActions.setCurrentTeam(resData, matchId))
    // }
  }

  SetInnings = () => {
    let { matchId } = this.props.match.params._id
    let resData = {
      "id": this.props.match.params._id,
      "currentInning": this.state.fieldInning,
      "teamType": this.state.fieldTeamType,
    }
    console.log("SetInningsSetInningsSetInnings", resData);
    this.props.dispatch(matchMoreDetailActions.setCurrentInnings(resData, matchId))
    // }
  }



  handleInningsData = (data) => {

    this.setState({ fieldInningStatus: data, errorPlayerOut: { ...this.state.errorPlayerOut, currentBallStatus: "" } });
  }
  // handleBatsmanOut
  handleBatsmanOutModal = (data) => {

    this.setState({ batsmanOutFields: true, batsmanField: data });
  }
  handleCloseBatsmanOutModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ batsmanOutFields: false });
  }
  inputChangePlayerOut = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let playerOutField = this.state.playerOutField;
    let errorPlayerOut = this.state.errorPlayerOut;
    playerOutField[name] = value;
    errorPlayerOut[name] = "";
    this.setState({ playerOutField, errorPlayerOut });

  }
  playerOutSubmit = () => {

    let { users } = this.props;

    if (this.handleValidationPlayerOut()) {
      let { matchMoreDetail } = this.props
      let { playerOutField } = matchMoreDetail

      let resData = {
        "id": this.props.match.params._id,
        "playerId": this.state.batsmanField,
        "currentBallStatus": this.state.fieldInningStatus,
        "first_circle": 0,
        "message": this.state.playerOutField.message
      }
      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(matchMoreDetailActions.cancelBatsmanInLiveMatch(resData))
      this.setState({ batsmanOutFields: false, errorPlayerOut: {} });


    }
  }
  handleValidationPlayerOut = () => {
    let playerOutField = this.state.playerOutField;
    console.log(this.state.playerOutField)
    let fieldInningStatus = this.state.fieldInningStatus;
    let errorPlayerOut = {};
    let formIsValid = true;
    if (!playerOutField["message"] || (typeof playerOutField["message"] === 'string' && !playerOutField["message"].trim()) || playerOutField["message"] === "") {
      formIsValid = false;
      errorPlayerOut["message"] = "Message Cannot be empty";
    }
    if (!fieldInningStatus || fieldInningStatus === "") {
      formIsValid = false;
      errorPlayerOut["currentBallStatus"] = "Cannot be empty";
    }
    console.log('errorPlayerOuterrorPlayerOuterrorPlayerOuterrorPlayerOut', errorPlayerOut);
    this.setState({ errorPlayerOut: errorPlayerOut });
    return formIsValid;
  }


  // playerOutField: {},
  // errorPlayerOut: {},


  updateMatchInfo = () => {
    if (this.handleValidationMatchInfo()) {
      let reqData = {
        "matchId": this.state.fieldsMatchInfo.matchId,
        "tv_id": this.state.fieldsMatchInfo.tv_id,
        "series": this.state.fieldsMatchInfo.series,
        "matchs": this.state.fieldsMatchInfo.matchs,
        "match_time": this.state.fieldsMatchInfo.match_time,
        "venue": this.state.fieldsMatchInfo.venue,
        "toss": this.state.fieldsMatchInfo.toss,
        "umpire": this.state.fieldsMatchInfo.umpire,
        "third_umpire": this.state.fieldsMatchInfo.third_umpire,
        "referee": this.state.fieldsMatchInfo.referee,
        "man_of_match": this.state.fieldsMatchInfo.man_of_match,
        "match_type": this.state.fieldsMatchInfo.match_type,
        "team_a_id": this.state.fieldsMatchInfo.team_a_id,
        "team_a": this.state.fieldsMatchInfo.team_a,
        "team_a_short": this.state.fieldsMatchInfo.team_a_short,
        "team_a_img": this.state.fieldsMatchInfo.team_a_img,
        "team_b_id": this.state.fieldsMatchInfo.team_b_id,
        "team_b": this.state.fieldsMatchInfo.team_b,
        "team_b_short": this.state.fieldsMatchInfo.team_b_short,
        "team_b_img": this.state.fieldsMatchInfo.team_b_img

      }

      console.log('reqDatareqDatareqDatareqDatareqDatareqData??????', reqData);

      this.props.dispatch(matchMoreDetailActions.updateMatchInfo(reqData, this.props.match.params.matchId));
    }
  }

  handleValidationMatchInfo = () => {
    let fieldsMatchInfo = this.state.fieldsMatchInfo;
    let errorsMatchInfo = {};
    let formIsValid = true;

    //tv_id
    if (!fieldsMatchInfo["tv_id"] || fieldsMatchInfo["tv_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["tv_id"] = "Cannot be empty tv_id";
    }
    //series
    if (!fieldsMatchInfo["series"] || fieldsMatchInfo["series"] === "") {
      formIsValid = false;
      errorsMatchInfo["series"] = "Cannot be empty series";
    }
    //matchs
    if (!fieldsMatchInfo["matchs"] || fieldsMatchInfo["matchs"] === "") {
      formIsValid = false;
      errorsMatchInfo["matchs"] = "Cannot be empty matchs";
    }
    //match_time
    if (!fieldsMatchInfo["match_time"] || fieldsMatchInfo["match_time"] === "") {
      formIsValid = false;
      errorsMatchInfo["match_time"] = "Cannot be empty match_time";
    }
    //venue
    if (!fieldsMatchInfo["venue"] || fieldsMatchInfo["venue"] === "") {
      formIsValid = false;
      errorsMatchInfo["venue"] = "Cannot be empty venue";
    }
    //toss
    if (!fieldsMatchInfo["toss"] || fieldsMatchInfo["toss"] === "") {
      formIsValid = false;
      errorsMatchInfo["toss"] = "Cannot be empty toss";
    }
    //umpire
    if (!fieldsMatchInfo["umpire"] || fieldsMatchInfo["umpire"] === "") {
      formIsValid = false;
      errorsMatchInfo["umpire"] = "Cannot be empty umpire";
    }
    //third_umpire
    if (!fieldsMatchInfo["third_umpire"] || fieldsMatchInfo["third_umpire"] === "") {
      formIsValid = false;
      errorsMatchInfo["third_umpire"] = "Cannot be empty third_umpire";
    }
    //referee
    if (!fieldsMatchInfo["referee"] || fieldsMatchInfo["referee"] === "") {
      formIsValid = false;
      errorsMatchInfo["referee"] = "Cannot be empty referee";
    }
    //man_of_match
    if (!fieldsMatchInfo["man_of_match"] || fieldsMatchInfo["man_of_match"] === "") {
      formIsValid = false;
      errorsMatchInfo["man_of_match"] = "Cannot be empty man_of_match";
    }
    //match_type
    if (!fieldsMatchInfo["match_type"] || fieldsMatchInfo["match_type"] === "") {
      formIsValid = false;
      errorsMatchInfo["match_type"] = "Cannot be empty match_type";
    }
    //team_a_id
    if (!fieldsMatchInfo["team_a_id"] || fieldsMatchInfo["team_a_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_id"] = "Cannot be empty team_a_id";
    }
    //team_a
    if (!fieldsMatchInfo["team_a"] || fieldsMatchInfo["team_a"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a"] = "Cannot be empty team_a";
    }
    //team_a_short
    if (!fieldsMatchInfo["team_a_short"] || fieldsMatchInfo["team_a_short"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_short"] = "Cannot be empty team_a_short";
    }
    //team_a_img
    if (!fieldsMatchInfo["team_a_img"] || fieldsMatchInfo["team_a_img"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_img"] = "Cannot be empty team_a_img";
    }
    //team_b_id
    if (!fieldsMatchInfo["team_b_id"] || fieldsMatchInfo["team_b_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_id"] = "Cannot be empty team_b_id";
    }
    //team_b
    if (!fieldsMatchInfo["team_b"] || fieldsMatchInfo["team_b"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b"] = "Cannot be empty team_b";
    }
    //team_b_short
    if (!fieldsMatchInfo["team_b_short"] || fieldsMatchInfo["team_b_short"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_short"] = "Cannot be empty team_b_short";
    }
    //team_b_img
    if (!fieldsMatchInfo["team_b_img"] || fieldsMatchInfo["team_b_img"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_img"] = "Cannot be empty team_b_img";
    }


    console.log('errorsMatchInfoerrorsMatchInfoerrorsMatchInfo', errorsMatchInfo);

    this.setState({ errorsMatchInfo: errorsMatchInfo });
    return formIsValid;
  }


  handleSectionType = (data) => {
    this.setState({ sectiontype: data })
  }


  handleSelectedPlayer = (selectedPlayer) => {
    this.setState({ selectedPlayer });
    console.log("selectedPlayerselectedPlayer  ", selectedPlayer);
  };


  handleRun = (data) => {

    console.log('data???', data);
    // let reqData = {
    //   "id": this.props.match.params._id,
    //   "first_circle": data
    // }

    let reqData = {
      "id": this.props.match.params._id,
      "marketId": null,
      "overthrow": this.state && this.state.overthrowType ? this.state.overthrowType : 'No',
      "batsmanId": this.state && this.state.fieldBatsman ? this.state.fieldBatsman : null,
      "delivery": this.state.deliveryType,
      "run": Number(this.state.runType),
      "event": this.state.eventType
    }
    let resData = {
      "matchId": this.props.match.params._id,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    console.log('reqDatareqDatareqDatareqDatareqDatareqDatareqDatareqData::::?????', reqData);
    this.props.dispatch(matchMoreDetailActions.getLiveMatchRun(reqData, resData));
  }

  handleExtraRuns = (data) => {

    console.log('data???', data);
    let reqData = {
      "id": this.props.match.params._id,
      "first_circle": data.firstCircle,
      "type": data.type
    }
    console.log('reqDatareqDatareqDatareqDatareqDatareqDatareqDatareqData::::?????', reqData);
    this.props.dispatch(matchMoreDetailActions.getLiveMatchExtraRuns(reqData));
  }
  handleResponseTeamType = (data) => {
    console.log('datadatadatadatadatadatadata', data);
    this.setState({ fieldTeamType: data })

  }
  handleRollBack = () => {
    let reqData = {
      "matchId": this.props.match.params._id,
    }
    console.log('reqData_______________reqData', reqData);
    this.props.dispatch(matchMoreDetailActions.getRollBackLastRunData(reqData));
  }
  updateTeamArray() {
    const { matchMoreDetail } = this.props;
    const { matchInfoData } = matchMoreDetail || {};

    if (matchInfoData && matchInfoData.teamA && matchInfoData.teamB) {
      this.setState({
        teamArray: [{ id: matchInfoData.teamA.id, name: matchInfoData.teamA.name }, { id: matchInfoData.teamB.id, name: matchInfoData.teamB.name }]
      });
    }
  }

  handleBallingteam = (e) => {
    let data = this.state.teamArray.find(team => team.id !== e)
    this.setState({ currentBowllingTeam: e })
    this.setState({ CurrentBattingTeam: data.id })
  }

  handleBattingteam = (e) => {
    // console.log()

    let data = this.state.teamArray.find(team => team.id !== e)
    this.setState({ currentBowllingTeam: data.id })
    this.setState({ CurrentBattingTeam: e })
  }
  handleInningSubmit = (e) => {

    if (this.handleValidationInning()) {
      let Obj = {
        "id": this.props.match.params._id,
        "currentBowling": this.state.currentBowllingTeam,
        "currentBatting": this.state.CurrentBattingTeam,
        "currentInning": this.state.inning
      }
      let id = {
        matchId: this.props.match.params._id
      }
      this.props.dispatch(matchMoreDetailActions.setCurrentTeam(Obj, id))
      // this.setState({ currentBowllingTeam:"",CurrentBattingTeam:"",currentInning:""})

    }


  }
  handleValidationInning = () => {

    let errorsMatchInfo = {};
    let formIsValid = true;

    if (!this.state.currentBowllingTeam || this.state.currentBowllingTeam === "") {

      formIsValid = false;
    }
    if (!this.state.CurrentBattingTeam || this.state.CurrentBattingTeam === "") {
      formIsValid = false;
    }
    if (!this.state.inning || this.state.inning === "") {
      formIsValid = false;
    }
    return formIsValid
  }

  handleLastBallData = async () => {

  }

  inputChange = (e) => {

    this.setState({ size: e.target.value }, () => {
      const { keyWord, pageNo, size } = this.state; // Destructure the updated state
      const data = {
        "matchId": this.props.match.params._id,
        keyWord,
        pageNo,
        size // Use the updated value of size
      };
      this.props.dispatch(matchMoreDetailActions.getLastTwentyBallData(data));
    });



  }



  render() {

    let { matchMoreDetail } = this.props;
    console.log('matchMoreDetail___________________', matchMoreDetail)
    let { matchInfoData, loading, allTeam, lastTwentyBallData, lastTwentyBallTotal } = matchMoreDetail ? matchMoreDetail : {};
    console.log('matchInfoData______________________', lastTwentyBallTotal, lastTwentyBallData)

    let legBye = [1, 2, 3, 4, 5, 6, 7]
    let legByeOut = [0, 1, 2, 3]
    let RunOut = [0, 1, 2, 3]
    let outTypeData = ['CAUGHT', 'HIT_WICKET', 'BOWLED', 'LBW', 'RUN_OUT', 'STUMTED', 'RETIRED_OUT', 'TIME_OUT', 'OBSTUCTING_THE_FIELD', 'HIT_THE_BALL_TWICE', 'NO_BALL_BYE_RUN_OUT', 'NO_BALL_LEG_BYE_RUN_OUT', 'BYE_RUN_OUR', 'LEG_BYE_RUN_OUT'].includes(this.state.eventType)

    let allTypeOutStatus = ['BYE_RUN_OUR', 'LEG_BYE_RUN_OUT', 'CAUGHT', 'HIT_WICKET', 'BOWLED', 'LBW', 'RUN_OUT', 'STUMTED', 'RETIRED_OUT', 'TIME_OUT', 'OBSTUCTING_THE_FIELD', 'HIT_THE_BALL_TWICE', 'NO_BALL_BYE', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE_RUN_OUT', 'NO_BALL_LEG_BYE_RUN_OUT']

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-y-auto" >
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bg-[#1FABB5]">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Live Match Score</h2>
            </div>

            <div className='p-6'>
              <div className='bg-white '>



                <div className='w-full bg-white p-4 border-b border-gray-200 flex justify-center items-center'>
                  <h2 className='text-3xl text-[rgb(31,171,181)] font-bold'>Match : {this.state.fieldsMatchInfo && this.state.fieldsMatchInfo.teamA && this.state.fieldsMatchInfo.teamB && this.state.fieldsMatchInfo.teamA.name && this.state.fieldsMatchInfo.teamB.name ? this.state.fieldsMatchInfo.teamA.name + ' vs ' + this.state.fieldsMatchInfo.teamB.name : "-"}</h2>
                </div>
                <div className=' flex gap-2 justify-center items-center'>
                  <div>
                    <th className="px-6 align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                      <div className='flex flex-col justify-center items-center md:space-x-4 space-x-2'>
                        <div className='pb-1'>Match Status</div>
                        <div className=' flex '>


                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  "
                            onChange={(val) => this.handleSelectStatus(val.target.value)}
                            value={this.state.matchStatus}
                          >
                            <option value="">Select Match Status </option>
                            <option value="Upcoming">Upcoming</option>
                            <option value="Live">Live</option>
                            <option value="Recent">Recent</option>
                          </select>

                          <button onClick={() => this.updateMatchStatus()} > <MdSaveAs title='save' size={28} className='text-[rgb(31,171,181)]' /> </button>
                        </div>
                      </div>
                    </th>
                  </div>

                  <div>
                    <th className="px-6 align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                      <div className='flex flex-col justify-center items-center md:space-x-4 space-x-2'>
                        <div className='pb-1'>Match Type</div>
                        <div className=' flex '>


                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  "
                            onChange={(val) => this.handleSelectMatch(val.target.value)}
                            value={this.state.matchType}
                          >
                            <option value="">Select Match type</option>
                            <option value="T10">T10</option>
                            <option value="T20">T20</option>
                            <option value="TEST">TEST</option>
                            <option value="ODI">ODI</option>
                          </select>
                          {/* <input type="text" value="1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 w-10 text-center" onChange={this.handleInnings} /> */}
                          <button onClick={() => this.updateMatchType()} > <MdSaveAs title='save' size={28} className='text-[#1FABB5]' /> </button>
                        </div>
                      </div>
                    </th>
                  </div>

                </div>

                <div className=' flex flex-col border m-1 justify-center items-center p-3 '>
                  <div className=' font-bold'>Inning Management</div>

                  <div className=' flex  justify-center items-center font-normal'>


                    <div>

                      <th className="px-6 align-middle  py-3 xl:text-sm text-xsuppercase border-0  whitespace-nowrap font-semibold text-center">
                        <div className='flex flex-col justify-center items-center md:space-x-4 space-x-2'>
                          <div> Select Inning</div>
                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   "
                            onChange={(val) => this.setState({ inning: val.target.value })}

                            value={this.state.inning}
                          >
                            <option value="">Select Innings</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            {this.state.matchType === "TEST" && <option value="3">3</option>}
                            {this.state.matchType === "TEST" && <option value="4">4</option>}

                          </select>
                        </div>
                      </th>
                    </div>




                    <div>

                      <th className="px-6 align-middle border-0 border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <div className='flex flex-col justify-center items-center md:space-x-4 space-x-2'>
                          <div>Current Batting Team</div>
                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  "
                            onChange={(e) => this.handleBattingteam(e.target.value)}
                            value={this.state.CurrentBattingTeam || ""}
                          >
                            <option value="">Current Batting Team </option>
                            {
                              this.state.teamArray && this.state.teamArray.length > 0 ?
                                this.state.teamArray.map((element, index) => (
                                  <option value={element && element ? element?.id : "-"}>{element && element ? element?.name : "-"}</option>
                                )) : null
                            }

                          </select>

                        </div>
                      </th>
                    </div>




                    <div>
                      <th className="px-6 align-middle border-0  border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">
                        <div className='flex flex-col justify-center items-center md:space-x-4 space-x-2'>
                          <div>Current Bowling Team</div>
                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  "
                            onChange={(val) => this.handleBallingteam(val.target.value)}
                            value={this.state.currentBowllingTeam || ""}
                          >
                            <option value="">Current Bowling Team </option>
                            {
                              this.state.teamArray && this.state.teamArray.length > 0 ?
                                this.state.teamArray.map((element, index) => (
                                  <option value={element && element ? element?.id : "-"}>{element && element ? element?.name : "-"}</option>
                                )) : null
                            }

                          </select>
                        </div>
                      </th>
                    </div>



                    <div>
                      <button onClick={() => this.handleInningSubmit()} className=' p-2 text-white bg-[#1FABB5] rounded-md '>Save</button>
                    </div>

                  </div>


                </div>





                <div className="rounded-lg overflow-hidden pb-2 p-6 w-full">

                  <div className="w-full  mb-6 px-4 mx-auto" onClick={() => this.handleResponseTeamType('A')}>
                    <div className="relative overflow-x-auto flex flex-col  min-w-0 break-words w-full    rounded bg-white ">
                      <div className="items-center w-full border-collapse text-blueGray-700  ">
                        <table class="min-w-full">
                          <thead className="thead-light w-full ">
                            <tr>
                              <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold bgheader text-white text-center">
                                Team A :
                              </th>
                              <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold flex  justify-center items-center md:space-x-5 space-x-2 text-center">

                                <select id="teamId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleTeamSelectA}


                                  value={
                                    this.state.fieldATeam ? this.state.fieldATeam :
                                      matchInfoData && matchInfoData.teamA ? matchInfoData.teamA.id : ''
                                  }
                                >
                                  <option selected>Choose a Team</option>
                                  {/* <option selected>{Choose a Team}</option> */}

                                  {
                                    allTeam && allTeam.length > 0 ?
                                      allTeam.map((element, index) => (
                                        <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                      )) : null
                                  }


                                </select>

                                <button onClick={() => this.sbmitTeamASave()} > <MdSaveAs size={28} title='save' className='text-[rgb(31,171,181)]' /> </button>
                                {/* <button ><BiEdit size={28} className='text-[rgb(31,171,181)]'/></button> &nbsp; &nbsp; */}
                              </th>


                              {this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamA'] ?
                                <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >
                                  {this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamA'] && this.state.fieldsMatchInfo['teamA'] == null ? '--' : this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamA'] && this.state.fieldsMatchInfo['teamA']?.id == this.state.fieldsMatchInfo?.battingTeam?.id ?
                                    <div className='flex justify-center items-center'>
                                      <div className='bg-green-500 text-white text-sm px-4 py-1.5 rounded-sm' >Batting
                                      </div>
                                    </div>
                                    :
                                    <div className='flex justify-center items-center'>
                                      <div className='bg-red-500 text-white text-sm px-4 py-1.5 rounded-sm' >Bowling
                                      </div>
                                    </div>
                                  }
                                </th>
                                :
                                <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >
                                  <div className='flex justify-center items-center'>
                                    <div className='bgheader text-white text-sm px-4 py-1.5 rounded-sm' >--</div>
                                  </div>
                                </th>}
                              {/* <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >
                                <div className=' justify-center items-center'>
                                  <h1 className='text-[#1FABB5]'>Run-Wicket (over)</h1>
                                  <div className='flex'>
                                    <p className='text-black'>{this.state && this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamAScore'] && this.state.fieldsMatchInfo['teamAScore']["2"] ? this.state.fieldsMatchInfo.teamAScore["2"]['run'] : this.state && this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamAScore'] && this.state.fieldsMatchInfo['teamAScore']["1"] ? this.state.fieldsMatchInfo.teamAScore["1"]['run'] : 0}-{this.state && this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamAScore'] && this.state.fieldsMatchInfo['teamAScore']["2"] ? this.state.fieldsMatchInfo.teamAScore["2"]['wkt'] : this.state && this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamAScore'] && this.state.fieldsMatchInfo['teamAScore']["1"] ? this.state.fieldsMatchInfo.teamAScore["1"]['wkt'] : 0} ({this.state && this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamAScore'] && this.state.fieldsMatchInfo['teamAScore']["2"] ? this.state.fieldsMatchInfo.teamAScore["2"]['over'] : this.state && this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamAScore'] && this.state.fieldsMatchInfo['teamAScore']["1"] ? this.state.fieldsMatchInfo.teamAScore["1"]['over'] : 0})</p>
                                  </div>

                                </div>
                              </th> */}
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="w-full  px-4 mb-6 mx-auto" onClick={() => this.handleResponseTeamType('B')}>
                    <div className="relative overflow-x-auto flex flex-col min-w-0 break-words w-full  rounded bg-white ">
                      <div className=" items-center w-full border-collapse text-blueGray-700  ">
                        <table class="min-w-full">
                          <thead className="thead-light  ">
                            <tr>
                              <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold bgheader text-white text-center">
                                Team B :
                              </th>
                              <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold flex md:space-x-5 space-x-3 text-center justify-center items-center">

                                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleTeamSelectB}

                                  value={
                                    this.state.fieldBTeam ? this.state.fieldBTeam :
                                      matchInfoData && matchInfoData.teamB ? matchInfoData.teamB.id : ''
                                  }
                                >
                                  <option selected>Choose a Team</option>

                                  {
                                    allTeam && allTeam.length > 0 ?
                                      allTeam.map((element, index) => (
                                        <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                      )) : null
                                  }


                                </select>
                                <button onClick={() => this.sbmitTeamBSave()} > <MdSaveAs title='save' size={28} className='text-[rgb(31,171,181)]' /> </button>


                              </th>

                              {this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamB'] ?
                                <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >
                                  {this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamB'] && this.state.fieldsMatchInfo['teamB'] == null ? '--' : this.state.fieldsMatchInfo && this.state.fieldsMatchInfo['teamB'] && this.state.fieldsMatchInfo['teamB']?.id == this.state?.fieldsMatchInfo?.battingTeam?.id ?
                                    <div className='flex justify-center items-center'>
                                      <div className='bg-green-500 text-white text-sm px-4 py-1.5 rounded-sm' >Batting
                                      </div>
                                    </div>
                                    :
                                    <div className='flex justify-center items-center'>
                                      <div className='bg-red-500 text-white text-sm px-4 py-1.5 rounded-sm' >Bowling
                                      </div>
                                    </div>
                                  }
                                </th>
                                :
                                <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >
                                  <div className='flex justify-center items-center'>
                                    <div className='bgheader text-white text-sm px-4 py-1.5 rounded-sm' >--</div>
                                  </div>
                                </th>}

                            </tr>
                          </thead>
                        </table>






                      </div>
                    </div>
                  </div>




                  <>
                    <section className="overflow-y-auto">
                      <div className="w-full  px-4 mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
                          <div className="rounded-t mb-0 px-4 py-3 text-white bg-[rgb(31,171,181)] border-0">
                            <div className="flex flex-wrap  items-center">
                              <div className="relative w-full  px-4 max-w-full flex-grow flex-1">
                                <h3 className="font-semibold text-base text-blueGray-700">
                                  Innings Info
                                </h3>
                              </div>

                            </div>
                          </div>
                          <div className="block w-full overflow-x-auto">
                            <table className="items-center w-full border-collapse text-blueGray-700  ">
                              <thead className="thead-light  ">
                                <tr>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Inning Number
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Batting Team
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Bowling Team
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >  Run / Wicket (Over)
                                  </th>

                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >  Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>

                                <>
                                  {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"]?.isStart && <tr>

                                    {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"]?.isStart && <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      Inning 1
                                    </th>}





                                    {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"] && matchInfoData["inning1"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"] && matchInfoData["inning1"]?.teamId?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"] && matchInfoData["inning1"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"] && matchInfoData["inning1"]?.teamId?.name == matchInfoData?.teamA?.name ? matchInfoData?.teamB?.name : matchInfoData?.teamA?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"]?.isStart && <th className="border-t-0 px-6  flex items-center  gap-5 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      <div>
                                        {matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"]?.run && matchInfoData["inning1"]?.run || "0"}/{matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"]?.wkt && matchInfoData["inning1"]?.wkt || "0"}
                                        <span className=''> </span>
                                        ({matchInfoData && matchInfoData["inning1"] && matchInfoData["inning1"]?.over && matchInfoData["inning1"]?.over || "0"})
                                      </div>





                                    </th>}
                                    {/* <td>{matchInfoData&&matchInfoData["inning2"] &&matchInfoData["inning2"]?.isStart?"": <MdDeleteOutline className=' text-2xl text-[#1FABB5]' title='Delete' />}</td> */}

                                    <th className='border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap  text-left'> {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.isStart ? <div>--</div> : <button onClick={() => this.deleteInning("inning1")} className=' bg-[#1FABB5]  px-3 text-white flex justify-center items-center py-1 rounded-lg'>Delete</button>}</th>

                                  </tr>}







                                  {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.isStart && <tr>

                                    {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.isStart && <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      Inning 2
                                    </th>}





                                    {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"] && matchInfoData["inning2"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"] && matchInfoData["inning2"]?.teamId?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"] && matchInfoData["inning2"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"] && matchInfoData["inning2"]?.teamId?.name == matchInfoData?.teamA?.name ? matchInfoData?.teamB?.name : matchInfoData?.teamA?.name}
                                      </div>
                                    </td>}

                                    {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.isStart && <th className="border-t-0 px-6  flex items-center  gap-5 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      <div>
                                        {matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.run && matchInfoData["inning2"]?.run || "0"}/{matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.wkt && matchInfoData["inning2"]?.wkt || "0"}
                                        <span className=''> </span>
                                        ({matchInfoData && matchInfoData["inning2"] && matchInfoData["inning2"]?.over && matchInfoData["inning2"]?.over || "0"})
                                      </div>



                                      {/* {matchInfoData&&matchInfoData["inning3"] &&matchInfoData["inning3"]?.isStart?"": <MdDeleteOutline className=' text-2xl text-[#1FABB5]' title='Delete' />} */}

                                    </th>}



                                    <th className='border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap  text-left'> {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.isStart ? <div>--</div> : <button onClick={() => this.deleteInning("inning2")} className=' bg-[#1FABB5]  px-3 text-white flex justify-center items-center py-1 rounded-lg'>Delete</button>}</th>


                                  </tr>}






                                  {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.isStart && <tr>

                                    {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.isStart && <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      Inning 3
                                    </th>}





                                    {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"] && matchInfoData["inning3"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"] && matchInfoData["inning3"]?.teamId?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"] && matchInfoData["inning3"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"] && matchInfoData["inning3"]?.teamId?.name == matchInfoData?.teamA?.name ? matchInfoData?.teamB?.name : matchInfoData?.teamA?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.isStart && <th className="border-t-0 px-6  flex items-center  gap-5 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      <div>
                                        {matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.run && matchInfoData["inning3"]?.run || "0"}/{matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.wkt && matchInfoData["inning3"]?.wkt || "0"}
                                        <span className=''> </span>
                                        ({matchInfoData && matchInfoData["inning3"] && matchInfoData["inning3"]?.over && matchInfoData["inning3"]?.over || "0"})
                                      </div>





                                    </th>}

                                    <th className='border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap  text-left'> {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.isStart ? <div>--</div> : <button onClick={() => this.deleteInning("inning3")} className=' bg-[#1FABB5]  px-3 text-white flex justify-center items-center py-1 rounded-lg'>Delete</button>}</th>
                                  </tr>}





                                  {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.isStart && <tr>

                                    {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.isStart && <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      Inning 4
                                    </th>}





                                    {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"] && matchInfoData["inning4"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"] && matchInfoData["inning4"]?.teamId?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"] && matchInfoData["inning4"]?.teamId?.name && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"] && matchInfoData["inning4"]?.teamId?.name == matchInfoData?.teamA?.name ? matchInfoData?.teamB?.name : matchInfoData?.teamA?.name}
                                      </div>
                                    </td>}


                                    {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.isStart && <th className="border-t-0 px-6  flex items-center  gap-5 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      <div>
                                        {matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.run && matchInfoData["inning4"]?.run || "0"}/{matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.wkt && matchInfoData["inning4"]?.wkt || "0"}
                                        <span className=''> </span>
                                        ({matchInfoData && matchInfoData["inning4"] && matchInfoData["inning4"]?.over && matchInfoData["inning4"]?.over || "0"})
                                      </div>





                                    </th>}
                                    <th className='border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap  text-left'>  <button onClick={() => this.deleteInning("inning4")} className=' bg-[#1FABB5]  px-3 text-white flex justify-center items-center py-1 rounded-lg'>Delete</button></th>

                                  </tr>}


                                </>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>


                  <>
                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={(e) => this.inputChange(e)} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value={lastTwentyBallTotal}>All</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <section className="overflow-y-auto">
                      <div className="w-full  px-4 mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
                          <div className="rounded-t mb-0 px-4 py-3 text-white bg-[rgb(31,171,181)] border-0">
                            <div className="flex flex-wrap  items-center">
                              <div className="relative w-full  px-4 max-w-full flex-grow flex-1">
                                <h3 className="font-semibold text-base text-blueGray-700">
                                  Ball By Ball  Format -  Delivery Out Run/Wicket (over)
                                </h3>
                              </div>

                            </div>
                          </div>
                          <div className="grid grid-cols-10">
                            {lastTwentyBallData && lastTwentyBallData.length && lastTwentyBallData.length > 0 ? lastTwentyBallData.map((ele) =>
                              <div className='flex justify-center items-center border shadow-xl p-2'>
                                <p>{ele && ele.delivery && ele.delivery === 'Wides' ? 'WB' : null} {ele && ele.delivery && ele.delivery === 'No-balls' ? 'NB' : null} {allTypeOutStatus.includes(ele.currentBallStatus) ? 'W' : null} {ele && ele.run ? ele.run + "/" + ele.wkt + " " + "(" + ((Number(ele.over) + Number(ele.overWithBall) - (ele && ele.over > 0 ? 1 : 0))).toFixed(1) + ")" : 0 / 0(0)}</p>
                              </div>
                            ) : null}
                            {/* <table className="items-center w-full border-collapse text-blueGray-700  ">
                              <thead className="thead-light  ">
                                <tr>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    1
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    2
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    3
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    4
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    5
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    1
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    2
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    3
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    4
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    5
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    1
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    2
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    3
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    4
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    5
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    1
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    2
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    3
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    4
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    5
                                  </th>
                                </tr>
                              </thead>
                              
                            </table> */}

                          </div>
                        </div>
                      </div>
                    </section>
                  </>














                  <>
                    <section className="overflow-y-auto">
                      <div className="w-full  px-4 mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
                          <div className="rounded-t mb-0 px-4 py-3 text-white bg-[rgb(31,171,181)] border-0">
                            <div className="flex flex-wrap  items-center">
                              <div className="relative w-full  px-4 max-w-full flex-grow flex-1">
                                <h3 className="font-semibold text-base text-blueGray-700">
                                  Batsman
                                </h3>
                              </div>

                            </div>
                          </div>
                          <div className="block w-full overflow-x-auto">
                            <table className="items-center w-full border-collapse text-blueGray-700  ">
                              <thead className="thead-light  ">
                                <tr>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Batsman Name :
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Select Batsman
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Run
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >  Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>

                                <>
                                  <tr>
                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      {

                                        matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[0]?.playerId && matchInfoData?.batsman[0]?.playerId?.name
                                      }


                                    </th>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>

                                        <select id="countries" name='countries' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleSelect}
                                          value={this.state.batsman1}
                                        >
                                          <option value=""> Select Player</option>
                                          {
                                            matchInfoData && matchInfoData.battingTeam && matchInfoData.battingTeam.player && matchInfoData.battingTeam.player.length > 0 ?
                                              matchInfoData.battingTeam.player.filter((element, index) => element.id !== matchInfoData?.batsman[1]?.playerId?.id).map((element, i) => (
                                                <option value={element && element.id ? element.id : ""}>{element && element.id ? element.name : "-"}</option>
                                              )) : null
                                          }
                                        </select>
                                        <button onClick={() => this.submitSave1()} className='bgheader hover:bg-blue-600 text-white text-xs px-4 py-0.5 rounded-sm'>Save</button>
                                      </div>
                                    </td>
                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      {
                                        matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[0]?.run && matchInfoData?.batsman[0]?.run || 0}
                                    </th>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4">
                                      <div className="flex items-center">


                                        {matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[0]?.playerId && matchInfoData?.batsman[0]?.playerId?.name && <button onClick={() => this.handleBatsmanOutModal(matchInfoData.batsman[0].playerId.id)} className='bgheader hover:bg-red-600 text-white text-sm px-4 py-1.5 rounded-sm'> Out </button>} &nbsp; &nbsp;

                                        {matchInfoData && matchInfoData.batsman && matchInfoData.batsman[0] && matchInfoData.batsman[0]?.isStrike ?

                                          <div>
                                            {matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[0]?.playerId && matchInfoData?.batsman[0]?.playerId?.name && <button className=' bg-green-600  text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.setMannualStrike(matchInfoData?.batsman[0]?.id)}>On Strike</button>}
                                          </div>
                                          :
                                          <div>
                                            {matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[0]?.playerId && matchInfoData?.batsman[0]?.playerId?.name &&
                                              <button className=' bg-red-600  text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.setMannualStrike(matchInfoData?.batsman[0]?.id)}>Non Strike</button>}
                                          </div>
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      {

                                        matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[1]?.playerId && matchInfoData?.batsman[1]?.playerId?.name
                                      }


                                    </th>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      <div className='flex space-x-3'>
                                        <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleSelect1}
                                          value={this.state.batsman2}

                                        >
                                          <option value="">select Player</option>
                                          {
                                            matchInfoData && matchInfoData.battingTeam && matchInfoData.battingTeam.player && matchInfoData.battingTeam.player.length > 0 ?
                                              matchInfoData.battingTeam.player.filter((element, index) => element.id !== matchInfoData?.batsman[0]?.playerId?.id).map((element, i) => (
                                                <option value={element && element.id ? element.id : ""}>{element && element.id ? element.name : "-"}</option>
                                              )) : null


                                          }



                                        </select>
                                        <button onClick={() => this.submitSave2()} className='bgheader hover:bg-green-600 text-white text-xs px-4 py-0.5 rounded-sm'>Save</button> &nbsp; &nbsp;
                                      </div>
                                    </td>
                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      {
                                        matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[1]?.run && matchInfoData?.batsman[1]?.run || 0}
                                    </th>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4">
                                      <div className="flex items-center">


                                        {matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[1]?.playerId && matchInfoData?.batsman[1]?.playerId?.name && <button onClick={() => this.handleBatsmanOutModal(matchInfoData.batsman[1].playerId.id)} className='bgheader hover:bg-red-600 text-white text-sm px-4 py-1.5 rounded-sm'> Out </button>} &nbsp; &nbsp;




                                        {matchInfoData && matchInfoData.batsman && matchInfoData.batsman[1] && matchInfoData.batsman[1]?.isStrike ?


                                          <div>
                                            {matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[1]?.playerId && matchInfoData?.batsman[1]?.playerId?.name && <button className='  bg-green-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.setMannualStrike(matchInfoData?.batsman[1]?.id)}>On Strike</button>}
                                          </div>
                                          :
                                          <div>
                                            {matchInfoData && matchInfoData?.batsman && matchInfoData?.batsman[1]?.playerId && matchInfoData?.batsman[1]?.playerId?.name && <button className=' bg-red-600   text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.setMannualStrike(matchInfoData?.batsman[1]?.id)}>Non Strike</button>}

                                          </div>
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                </>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="overflow-y-auto">
                      <div className="w-full  px-4 mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
                          <div className="rounded-t mb-0 px-4 py-3 text-white bg-[rgb(31,171,181)] border-0">
                            <div className="flex flex-wrap  items-center">
                              <div className="relative w-full  px-4 max-w-full flex-grow flex-1">
                                <h3 className="font-semibold text-base text-blueGray-700">
                                  Bowler
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="block w-full overflow-x-auto">
                            <table className="items-center w-full border-collapse text-blueGray-700  ">
                              <thead className="thead-light  ">
                                <tr>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Bowler Name
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Select Bowler
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Over Status
                                  </th>
                                  <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >  Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>

                                <>
                                  <tr>
                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                      {

                                        matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.name ? matchInfoData.bolwer.name : ''
                                      }
                                    </th>

                                    {
                                      matchInfoData && matchInfoData.currentTeamType == 'A' ?

                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleBowlerSelect}

                                            value={
                                              this.state.fieldBowler ? this.state.fieldBowler :
                                                matchInfoData && matchInfoData.bolwer ? matchInfoData.bolwer.playerId : ''
                                            }
                                          >
                                            <option selected>Choose a Bowler A</option>

                                            {
                                              matchInfoData.teamB && matchInfoData.teamB
                                                .player
                                                && matchInfoData.teamB
                                                  .player
                                                  .length > 0 ?
                                                matchInfoData.teamB
                                                  .player
                                                  .map((element, index) => (
                                                    <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                                  )) : null
                                            }

                                          </select>
                                        </td>
                                        : <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                          <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={(e) => this.handleBowlerSelect(e)}

                                            value={
                                              this.state.fieldBowler
                                              // matchInfoData && matchInfoData.bolwer ? matchInfoData.bolwer.playerId : ''
                                            }

                                          >
                                            <option selected>Choose a Bowler B</option>

                                            {
                                              matchInfoData && matchInfoData.teamA && matchInfoData.teamA
                                                .player
                                                && matchInfoData.teamA
                                                  .player
                                                  .length > 0 ?
                                                matchInfoData.teamA
                                                  .player
                                                  .map((element, index) => (
                                                    <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                                  )) : null
                                            }

                                          </select>
                                        </td>
                                    }
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                      {matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.over ? matchInfoData.bolwer.over.toFixed(1) : 0}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4">
                                      <div className="flex items-center">




                                        &nbsp; &nbsp;
                                        {matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.name ? <button onClick={() => this.deleteBowler(matchInfoData?.bolwer?.playerId)} className='bgheader hover:bg-red-600 text-white text-sm px-4 py-1.5 rounded-sm'>Delete</button>

                                          :
                                          <button onClick={() => this.bowlerSave()} className='bgheader hover:bg-green-600 text-white text-sm px-4 py-1.5 rounded-sm'>Save</button>

                                        }&nbsp; &nbsp;
                                      </div>
                                    </td>
                                  </tr>
                                </>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>







                  <div className=' p-4 border-b '>


                    <div className="overflow-y-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className=" ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="rounded-t">
                              <tr className="uppercase">
                                <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white cursor-pointer" onClick={() => this.handleSectionType("MATCH_INFO")}>Ball By Ball Run </th>
                              </tr>
                            </thead>

                          </table>
                        </div>
                      </div>
                    </div>


                    <div className="block w-full overflow-x-auto">
                      <table className="items-center w-full border-collapse text-blueGray-700  ">
                        <thead className="thead-light  ">
                          <tr>
                            <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Delivery
                            </th>
                            <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Over Throw
                            </th>
                            <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Run
                            </th>
                            <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Event
                            </th>
                            <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Batsman
                            </th>
                            <th className="px-6  align-middle border border-solid border-blueGray-100 py-3 xl:text-sm text-xsuppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px" >  Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          <>
                            <tr>
                              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   "
                                  onChange={(val) => this.setState({ deliveryType: val.target.value })}

                                  value={this.state.deliveryType}
                                >
                                  <option value="">Select Delivery</option>
                                  <option value="Legal">Legal</option>
                                  <option value="Wides">Wide</option>
                                  <option value="No-balls">No Ball</option>
                                  <option value="Exception">Exception</option>
                                  {/* {this.state.matchType === "TEST" && <option value="3">3</option>}
                                      {this.state.matchType === "TEST" && <option value="4">4</option>} */}

                                </select>
                              </th>
                              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   "
                                  onChange={(val) => this.setState({ overthrowType: val.target.value })}

                                // value={this.state.overthrowType}
                                >
                                  <option value="">Select Over Throw</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>

                                </select>
                              </th>
                              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   "
                                  onChange={(val) => this.setState({ runType: val.target.value })}

                                  value={this.state.runType}
                                >
                                  <option value="">Select Run</option>
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' ? <option className='text-center' value="0">0</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' ? <option className='text-center' value="1">1</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' ? <option className='text-center' value="2">2</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' ? <option className='text-center' value="3">3</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' ? <option className='text-center' value="4">4</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' && this.state.overthrowType == 'Yes' ? <option className='text-center' value="5">5</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' ? <option className='text-center' value="6">6</option> : null}
                                  {this.state.deliveryType && this.state.deliveryType != 'Exception' && this.state.overthrowType == 'Yes' ? <option className='text-center' value="7">7</option> : null}


                                </select>
                              </th>
                              <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 text-left">
                                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   "
                                  onChange={(val) => this.setState({ eventType: val.target.value })}

                                  value={this.state.eventType}
                                >
                                  <option value="">Select Event</option>

                                  {this.state.deliveryType == 'Exception' ? <option value="DEAD_BALL">DEAD BALL</option> : null}
                                  {this.state.deliveryType == 'Exception' ? <option value="PENALTIES">PENALTIES</option> : null}
                                  {this.state.deliveryType == 'Exception' ? <option value="RETIRED_OUT">RETIRED OUT</option> : null}
                                  {this.state.deliveryType == 'Exception' ? <option value="TIME_OUT">TIME OUT</option> : null}
                                  {this.state.deliveryType == 'Exception' ? <option value="OBSTUCTING_THE_FIELD">OBSTUCTING THE FIELD</option> : null}

                                  {/* {this.state.deliveryType == 'Wides' ? <option value="WIDE">WIDE</option> : null} */}
                                  {this.state.deliveryType == 'No-balls' ? <option value="NO_BALL">NO BALL</option> : null}
                                  {legByeOut.toString().includes(this.state.runType) && this.state.deliveryType == 'No-balls' ? <option value="RUN_OUT">RUN OUT</option> : null}
                                  {legByeOut.toString().includes(this.state.runType) && this.state.deliveryType == 'No-balls' ? <option value="NO_BALL_BYE_RUN_OUT">NO BALL BYE RUN OUT</option> : null}

                                  {legByeOut.toString().includes(this.state.runType) && this.state.deliveryType == 'No-balls' ? <option value="NO_BALL_LEG_BYE_RUN_OUT">NO BALL LEG BYE RUN OUT</option> : null}



                                  {this.state.deliveryType == 'Wides' ? <option value="WIDE">WIDE</option> : null}
                                  {legByeOut.toString().includes(this.state.runType) && this.state.deliveryType == 'Wides' ? <option value="RUN_OUT">RUN OUT</option> : null}
                                  {this.state.deliveryType == 'Wides' ? <option value="STUMTED">STUMTED</option> : null}


                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="DOT_BALL">DOT BALL</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 1 ? <option value="SINGLE">SINGLE</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 2 ? <option value="DOUBLE">DOUBLE</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 3 ? <option value="THREE">THREE</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 4 && this.state.overthrowType == 'No' || this.state.deliveryType == 'Legal' && this.state.runType == 7 ? <option value="FOUR">FOUR</option> : null}
                                  {this.state.deliveryType == 'Legal' && this.state.overthrowType == 'Yes' && this.state.runType == 4 || this.state.deliveryType == 'Legal' && this.state.overthrowType == 'Yes' && this.state.runType == 5 || this.state.deliveryType == 'Legal' && this.state.overthrowType == 'Yes' && this.state.runType == 6 || this.state.deliveryType == 'Legal' && this.state.runType == 7 ? <option value="FOUR">FOUR</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 6 && this.state.overthrowType == 'No' ? <option value="SIX">SIX</option> : null}

                                  {legBye.toString().includes(this.state.runType) && this.state.deliveryType == 'Legal' ? <option value="BYE">BYE</option> : null}

                                  {legBye.toString().includes(this.state.runType) && this.state.deliveryType == 'Legal' ? <option value="LEG_BYE">LEG BYE</option> : null}



                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="CAUGHT">CAUGHT</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="HIT_WICKET">HIT WICKET</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="BOWLED">BOWLED</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="LBW">LBW</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 || this.state.deliveryType == 'Legal' && this.state.runType == 1 || this.state.deliveryType == 'Legal' && this.state.runType == 2 || this.state.deliveryType == 'Legal' && this.state.runType == 3 ? <option value="RUN_OUT">RUN OUT</option> : null}


                                  {legByeOut.toString().includes(this.state.runType) && this.state.deliveryType == 'Legal' ? <option value="BYE_RUN_OUR">BYE RUN OUR</option> : null}

                                  {legByeOut.toString().includes(this.state.runType) && this.state.deliveryType == 'Legal' ? <option value="LEG_BYE_RUN_OUT">LEG BYE RUN OUT</option> : null}



                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="STUMTED">STUMTED</option> : null}

                                  {this.state.deliveryType == 'Legal' && this.state.runType == 0 ? <option value="HIT_THE_BALL_TWICE"> HIT THE BALL TWICE</option> : null}




                                </select>
                              </th>



                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                                <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={(e) => this.handleBatsmanSelect(e)}

                                >
                                  <option selected>Choose A Batsman</option>

                                  {
                                    matchInfoData && matchInfoData.batsman && outTypeData
                                      && matchInfoData.batsman
                                        .length > 0 ?
                                      matchInfoData.batsman
                                        .map((element, index) => (
                                          <option value={element && element.id ? element.id : "-"}>{element && element.playerId && element.playerId
                                            .name ? element.playerId
                                            .name : "-"}</option>
                                        )) : null
                                  }

                                </select>
                              </td>




                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4">
                                <div className="flex items-center">

                                  <button onClick={() => this.handleRun()} className='bgheader hover:bg-green-600 text-white text-sm px-4 py-1.5 rounded-sm'>Submit</button>

                                </div>
                              </td>
                            </tr>
                          </>

                        </tbody>
                      </table>
                    </div>


                    {/* <div className='flex'>
                          <div>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                              <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleBowlerSelect}

                                value={
                                  this.state.fieldBowler ? this.state.fieldBowler :
                                    matchInfoData && matchInfoData.bolwer ? matchInfoData.bolwer.playerId : ''
                                }
                              >
                                <option selected>Choose a Bowler A</option>

                                {
                                  matchInfoData.teamB && matchInfoData.teamB
                                    .player
                                    && matchInfoData.teamB
                                      .player
                                      .length > 0 ?
                                    matchInfoData.teamB
                                      .player
                                      .map((element, index) => (
                                        <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                      )) : null
                                }

                              </select>
                            </td>
                          </div>
                          <div>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                              <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleBowlerSelect}

                                value={
                                  this.state.fieldBowler ? this.state.fieldBowler :
                                    matchInfoData && matchInfoData.bolwer ? matchInfoData.bolwer.playerId : ''
                                }
                              >
                                <option selected>Choose a Bowler A</option>

                                {
                                  matchInfoData.teamB && matchInfoData.teamB
                                    .player
                                    && matchInfoData.teamB
                                      .player
                                      .length > 0 ?
                                    matchInfoData.teamB
                                      .player
                                      .map((element, index) => (
                                        <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                      )) : null
                                }

                              </select>
                            </td>
                          </div>
                          <div>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                              <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleBowlerSelect}

                                value={
                                  this.state.fieldBowler ? this.state.fieldBowler :
                                    matchInfoData && matchInfoData.bolwer ? matchInfoData.bolwer.playerId : ''
                                }
                              >
                                <option selected>Choose a Bowler A</option>

                                {
                                  matchInfoData.teamB && matchInfoData.teamB
                                    .player
                                    && matchInfoData.teamB
                                      .player
                                      .length > 0 ?
                                    matchInfoData.teamB
                                      .player
                                      .map((element, index) => (
                                        <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                      )) : null
                                }

                              </select>
                            </td>
                          </div>
                          <div>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 xl:text-sm text-xswhitespace-nowrap p-4 ">
                              <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  " onChange={this.handleBowlerSelect}

                                value={
                                  this.state.fieldBowler ? this.state.fieldBowler :
                                    matchInfoData && matchInfoData.bolwer ? matchInfoData.bolwer.playerId : ''
                                }
                              >
                                <option selected>Choose a Bowler A</option>

                                {
                                  matchInfoData.teamB && matchInfoData.teamB
                                    .player
                                    && matchInfoData.teamB
                                      .player
                                      .length > 0 ?
                                    matchInfoData.teamB
                                      .player
                                      .map((element, index) => (
                                        <option value={element && element.id ? element.id : "-"}>{element && element.id ? element.name : "-"}</option>
                                      )) : null
                                }

                              </select>
                            </td>
                          </div>

                          <button onClick={() => this.bowlerSave()} className='bgheader hover:bg-green-600 text-white text-sm px-4 rounded-sm'>Submit</button>

                        </div> */}

                    {/* <div>
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 0, type: 'WIDE' })}>Wd</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 1, type: 'WIDE' })}>W 1</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 2, type: 'WIDE' })}>W 2</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 3, type: 'WIDE' })}>W 3</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 4, type: 'WIDE' })}>W 4</button> &nbsp; &nbsp;

                        </div>

                        <div>
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 0, type: 'NO_BALL' })} >NB</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 1, type: 'NO_BALL' })} >NB 1</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 2, type: 'NO_BALL' })} >NB 2</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 3, type: 'NO_BALL' })} >NB 3</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 4, type: 'NO_BALL' })} >NB 4</button> &nbsp; &nbsp;
                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleExtraRuns({ firstCircle: 6, type: 'NO_BALL' })} >NB 6</button> &nbsp; &nbsp;
                        </div> */}
                  </div>



                  <div>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 my-1 rounded-sm' onClick={() => this.handleRollBack()}>
                      Rollback
                    </button>
                  </div>



                  {/* <div className='p-4 border-b '>

                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="rounded-t">
                              <tr className="uppercase">
                                <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white cursor-pointer" onClick={() => this.handleSectionType("MATCH_INFO")}>Extra Run</th>
                              </tr>
                            </thead>

                          </table>
                        </div>
                      </div>
                    </div>

                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("Leg By")} >Leg By</button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("No Bal")} >No Ball</button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("Wide")} >Wide</button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("OverThow")} >OverThow</button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("Penelty Run")} >Penelty Run</button> &nbsp; &nbsp;

                  </div>



                  <div className=' p-4 border-b '>

                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="rounded-t">
                              <tr className="uppercase">
                                <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white cursor-pointer" onClick={() => this.handleSectionType("MATCH_INFO")}>Wicket</th>
                              </tr>
                            </thead>

                          </table>
                        </div>
                      </div>
                    </div>

                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 my-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Catch Out")} >Catch Out</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Bowled")} >Bowled</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("LBW")} >LBW</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Run Out")} >Run Out</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Stumted")} >Stumted</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Hit Wicket")} >Hit Wicket</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Retired Out")} >Retired Out</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 m-2  py-1.5 rounded-sm' onClick={() => this.handleRun("Time Out")} >Time Out</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm m-2 px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("Third Empire Decision")} >Third Empire Decision</button>
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm m-2  px-4 py-1.5 rounded-sm' onClick={() => this.handleRun("Obstacting The Field")} >Obstacting The Field</button>


                  </div>


                  <div className=' p-4 border-b '>

                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full divide-y divide-gray-800 border-0">
                            <thead className="rounded-t">
                              <tr className="uppercase">
                                <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white cursor-pointer" onClick={() => this.handleSectionType("MATCH_INFO")}>Umpire Decision</th>
                              </tr>
                            </thead>

                          </table>
                        </div>
                      </div>
                    </div>

                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'>Dead Ball</button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'>Refer To 3rd Umpire</button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'>Umpire Desicion Out </button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'>Umpire Desicion wide </button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'>DRS To Batsman </button> &nbsp; &nbsp;
                    <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm'>DRS To Boller </button> &nbsp; &nbsp;

                  </div> */}





                  <CreateOutModel
                    batsmanOutFields={this.state.batsmanOutFields}
                    handleCloseBatsmanOutModal={this.handleCloseBatsmanOutModal}
                    inputChangePlayerOut={this.inputChangePlayerOut}
                    handleInningsData={this.handleInningsData}
                    playerOutField={this.state.playerOutField}
                    errorPlayerOut={this.state.errorPlayerOut}
                    playerOutSubmit={this.playerOutSubmit}
                  />

                </div>

              </div>
            </div >

          </main >

          <div className="bg-white py-3 border-t">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div >

      </>

    );
  }
}

function mapStateToProps(state) {
  const { matchMoreDetail } = state;
  // console.log('matchMoreDetail:::::mapStateToProps:', matchMoreDetail);
  return {
    matchMoreDetail
  };
}

export default connect(mapStateToProps)(MatchMoreDetail);
