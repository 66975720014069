import React, { Component } from 'react';
import { connect } from 'react-redux';
import { imageActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddDealerModal from "./components/CreateAddGameModal/CreateAddGameModal";
import UpdateImageModal from "./components/UpdateImage/UpdateImage";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd, HiEye, HiOutlineServer, HiArrowNarrowLeft } from "react-icons/hi";
// import { image } from '../../_reducers/image.reducer';
// import { BiSearch } from "react-icons/bi";
// import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";



class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageRowData: {},
      fieldsAddUser: {},
      errorsAddUser: {},
      fieldsImageUpdate: {},
      errorsUpdateImage: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addImageModal: false,
      moreDetailsCreateModal: false,
      updateImageModal: false,
      loginToThisAccountModal: false,
      imageName: null,
      imageURL: null,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(imageActions.ImageList(data));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("STATIC_____nextProps.image.addImageSuccess", nextProps.image.addImageSuccess);
    console.log("STATIC_____nextProps.image.updateUserSuccess", nextProps.image.updateUserSuccess);

    if (nextProps.image.addImageSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsAddUser: {},
        errorsAddUser: {},
        addImageModal: false,
        // updateImageModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    }

    if (nextProps.image.updateUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsImageUpdate: {},
        errorsUpdateImage: {},
        updateImageModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    }

    if (nextProps.users.imageUploadSuccess) {
      return {
        ...nextProps,
        imageName: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageURL: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null,
      }
    }

    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "type": this.state.fieldsAddUser.type,
      "gameType": this.state.fieldsAddUser.gameType,
      "rankType": this.state.fieldsAddUser.rankType
    }
    this.props.dispatch(imageActions.getImageList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size,
      "type": this.state.fieldsAddUser.type,
      "gameType": this.state.fieldsAddUser.gameType,
      "rankType": this.state.fieldsAddUser.rankType
    }
    this.props.dispatch(imageActions.ImageList(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(imageActions.disableImage(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteImage = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(imageActions.deleteImage(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //   "id": data.id
    // }
    // this.props.dispatch(imageActions.getProfile(req));
    this.setState({ moreDetailsCreateModal: true, imageRowData: data });
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ updateImageModal: true, fieldsImageUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ updateImageModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(imageActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addImageModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ addImageModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChangeUpdateImage = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsImageUpdate = this.state.fieldsImageUpdate;
    let errorsUpdateImage = this.state.errorsUpdateImage;
    fieldsImageUpdate[name] = value;
    errorsUpdateImage[name] = "";
    // console.log("name", value);createImage
    this.setState({ fieldsImageUpdate, errorsUpdateImage });
  }

  createImageSubmit = () => {
    // let { users } = this.props;
    // let { filesDetails } = users;
    console.log("this.state.fieldsAddUser  ", this.state.fieldsAddUser);

    if (this.handleValidationAddUser()) {
      let reqData = {
        "name": this.state.fieldsAddUser.name,
        "imageName": this.state.imageName
      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(imageActions.createImage(reqData, paginationData));
    }

  }

  updateImageSubmit = () => {
    // let { users } = this.props;
    // let { filesDetails } = users;

    if (this.handleValidationUpdateimage()) {
      let reqData = {
        "id": this.state.fieldsImageUpdate.id,
        "name": this.state.fieldsImageUpdate.name,
        "imageName": this.state.imageName ? this.state.imageName : this.state.fieldsImageUpdate.imageName
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>", reqData);

      this.props.dispatch(imageActions.updateImage(reqData, paginationData));
    }

  }
  handleValidationUpdateimage = () => {
    let fieldsImageUpdate = this.state.fieldsImageUpdate;
    let errorsUpdateImage = {};
    let formIsValid = true;

    //name
    if (!fieldsImageUpdate["name"] || fieldsImageUpdate["name"] === "") {
      formIsValid = false;
      errorsUpdateImage["name"] = "Cannot be empty";
    }

    this.setState({ errorsUpdateImage: errorsUpdateImage });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //Email
    if (!fieldsAddUser["name"] || fieldsAddUser["name"] === "") {
      formIsValid = false;
      errorsAddUser["name"] = "Cannot be empty name";
    }

    // if (!fieldsAddUser["imageSquad"] || fieldsAddUser["imageSquad"] === "") {
    //   formIsValid = false;
    //   errorsAddUser["imageSquad"] = "Cannot be empty imageSquad";
    // }


    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  // handleSelectType = (e) => {
  //   console.log("handleSelectType::::#", e.target.value);
  //   let { value } = e.target;
  //   this.setState({ type: value });
  // }

  // handleSelectGameType = (e) => {
  //   console.log("handleSelectGameType:::#", e.target.value);
  //   let { value } = e.target;
  //   this.setState({ gameType: value });
  // }

  // handleSelectRankType = (e) => {
  //   console.log("handleSelectRankType::::#", e.target.value);
  //   let { value } = e.target;
  //   this.setState({ rankType: value });
  // }

  // handleUpdateSelectType = (e) => {
  //   console.log("handleUpdateSelectType::::#", e.target.value);
  //   let { value } = e.target;
  //   let { fieldsImageUpdate } = this.state;
  //   fieldsImageUpdate["type"] = value;
  //   this.setState({ fieldsImageUpdate });
  // }

  // handleUpdateSelectGameType = (e) => {
  //   console.log("handleUpdateSelectGameType:::#", e.target.value);
  //   let { value } = e.target;
  //   let { fieldsImageUpdate } = this.state;
  //   fieldsImageUpdate["gameType"] = value;
  //   this.setState({ fieldsImageUpdate });
  // }

  // handleUpdateSelectRankType = (e) => {
  //   console.log("handleUpdateSelectRankType::::#", e.target.value);
  //   let { value } = e.target;
  //   let { fieldsImageUpdate } = this.state;
  //   fieldsImageUpdate["rankType"] = value;
  //   this.setState({ fieldsImageUpdate });
  // }

  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  inputimageFilter = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    this.setState({ fieldsAddUser, errorsAddUser });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "type": this.state.fieldsAddUser.type,
      "gameType": this.state.fieldsAddUser.gameType,
      "rankType": this.state.fieldsAddUser.rankType
    }
    this.props.dispatch(imageActions.ImageList(data));
  }




  render() {

    let { image,
      // users 
    } = this.props;
    let {
      //  items,
      total, loading, getImageList,
      // getImageTotal 
    } = image;
    // console.log("render___getImageList", getImageList);

    // let { filesDetails } = users;

    // console.log("RENDER_____this.state.imageName::", this.state.imageName);
    console.log("RENDER_____this.state.imageURL:::30112022:", this.state.imageURL);
    console.log("RENDER_____this.state.addImageModal:::", this.state.addImageModal);

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>
        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="flex-col col-span-6 px-2 py-5 bg-white rounded-md 2xl:py-7 2xl:px-10 sm:px-5">
                  <div className="text-left">
                    <h3 className="text-lg font-semibold leading-6 tracking-widest md:text-2xl md:leading-9 text-dark-400">Image</h3>
                  </div>
                  <div className="flex flex-wrap items-center justify-between w-full">
                    <div className="grid grid-cols-2 gap-4 xl:grid-cols-3 md:grid-cols-3">
                    </div>


                    {/* <div className="flex mt-3 space-x-2">
                      <div className="relative items-center lg:mt-0 ">
                        <div className="absolute mt-3 text-gray-600 left-5"><svg className="w-4" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.75 15.75L11.25 11.25L15.75 15.75ZM12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </div>
                        <input className="w-full px-3 py-2 pl-10 font-normal placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-400 rounded-md appearance-none sm:w-64 text-dark-400 focus:outline-none focus:border-gray-800 focus:text-gray-600 text-normal sm:leading-5" id="amount" placeholder="Search.." type="amount" onChange={this.handleSearch} required />
                      </div>
                      <div className="flex justify-end">
                        <button className="py-1 font-bold text-white bg-blue-500 border border-blue-700 rounded hover:bg-blue-700 px-7 "
                          onClick={() => this.handleOpenCreateModal()}> ADD </button>
                      </div>
                    </div> */}


                    <div className='space-x-0 md:flex md:space-x-4'>
                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>
                      <button className="flex items-center justify-center w-64 px-6 py-2 mt-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md md:mt-0 " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" /> Add Image </button>
                    </div>



                  </div>
                  <div className="pb-2 mt-4 overflow-hidden rounded-lg shadow-md ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border-0 divide-y divide-gray-800">
                            <thead className="bg-gray-100 rounded-t">
                              <tr className="">
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Image Name</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">Image URL</th>
                                {/* <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">status</th> */}
                                <th scope="col" className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">ACTIONS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                getImageList && getImageList.length > 0 ?
                                  getImageList.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>
                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        {element && element.name ? element.name : "NA"}</td>



                                      <td className="object-cover h-10 rounded-sm w-14">
                                        <img className='w-16 h-16 text-xs rounded-full whitespace-nowrap' src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="Img not found" />
                                      </td>


                                      {/* <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.disableUser(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>Enable/Disable</span>
                                          </div>
                                        </label>
                                      </td> */}



                                      <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex space-x-2'>
                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " title="More Details" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span> */}
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4  rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" title="Edit" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span> */}

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" title="Delete"
                                            onClick={() => this.deleteImage(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span> */}
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>






                                      {/* <td className="flex px-2 py-3 text-gray-600 whitespace-nowrap">

                                        <span className="pl-1 mt-4">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400 cursor-pointer" onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>View Details</span>
                                            <HiEye className='block object-contain w-5 h-5' />
                                          </div>
                                        </span>

                                        <span className="pl-1 mt-4">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer" onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Edit</span>
                                            <HiPencilAlt className='block object-contain w-5 h-5' />
                                          </div>
                                        </span>

                                        <span className="pl-1 mt-4">
                                          <div class="targetablepx-4 tooltip px-3 py-1 font-medium tracking-wider text-blue-100 bg-red-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-500 cursor-pointer" onClick={() => this.deleteImage(element)}>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-12 text-white text-sm rounded'>Delete</span>
                                            <HiTrash className='block object-contain w-5 h-5' />
                                          </div>
                                        </span>


                                      </td> */}



                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not Found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddDealerModal
          addImageModal={this.state.addImageModal}
          fieldsAddUser={this.state.fieldsAddUser}
          errorsAddUser={this.state.errorsAddUser}
          imageURL={this.state.imageURL}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          createImageSubmit={this.createImageSubmit}
          // handleSelectType={this.handleSelectType}
          // handleSelectGameType={this.handleSelectGameType}
          // handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
        />

        <UpdateImageModal
          updateImageModal={this.state.updateImageModal}
          fieldsImageUpdate={this.state.fieldsImageUpdate}
          errorsUpdateImage={this.state.errorsUpdateImage}
          imageURL={this.state.imageURL}
          inputChangeUpdateImage={this.inputChangeUpdateImage}
          updateImageSubmit={this.updateImageSubmit}
          handleFile={this.handleFile}
          // handleUpdateSelectType={this.handleUpdateSelectType}
          // handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          // handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          imageRowData={this.state.imageRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />
      </>

    );
  }
}
function mapStateToProps(state) {
  const { image, users, sport } = state;
  return {
    image,
    users,
    sport
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Image);
