import { userConstants, teamRankingTypeConstants } from '../_constants';
import {  teamRankingTypeService } from '../_services';
import { alertActions } from '.';
// import { toast } from 'react-toastify';
export const teamRankingTypeActions = {
    disableTeamRankingType,
    getAllTeamRankingType,
    createTeamRankingType,
    updateTeamRankingType,
    getTeamRankingTypeList,
    deleteTeamRankingType,
    uploadImageClear,
    getTeamRankingTypeById,
};




function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createTeamRankingType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamRankingTypeService.createTeamRankingType(data)
            .then(

                teamRankingType => {
                    dispatch(this.getTeamRankingTypeList(paginationData));
                    dispatch(alertActions.success("Team Ranking Type Add Successfully."));
                    dispatch(success(teamRankingType));
                    // dispatch(this.uploadImageClear());
                    // props.history.push({ pathname: '/app/teamRankingType' });


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.ADD_TEAM_RANKING_TYPE_REQUEST } }
    function success(teamRankingType) { return { type: teamRankingTypeConstants.ADD_TEAM_RANKING_TYPE_SUCCESS, teamRankingType } }
    function failure(error) { return { type: teamRankingTypeConstants.ADD_TEAM_RANKING_TYPE_FAILURE, error } }
}
function updateTeamRankingType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamRankingTypeService.updateTeamRankingType(data)
            .then(
                teamRankingType => {
                    dispatch(this.getTeamRankingTypeList(paginationData));
                    dispatch(alertActions.success("TeamRankingType Update Successfully."));
                    dispatch(success(teamRankingType));
                    // props.history.push({ pathname: '/app/teamRankingType' });
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.UPDATE_TEAM_RANKING_TYPE_REQUEST } }
    function success(teamRankingType) { return { type: teamRankingTypeConstants.UPDATE_TEAM_RANKING_TYPE_SUCCESS, teamRankingType } }
    function failure(error) { return { type: teamRankingTypeConstants.UPDATE_TEAM_RANKING_TYPE_FAILURE, error } }
}
function getAllTeamRankingType() {
    return dispatch => {
        dispatch(request());
        teamRankingTypeService.getAllTeamRankingType()
            .then(
                teamRankingType => {
                    console.log("getAllTeamRankingType $$$$ action:", teamRankingType);
                    dispatch(success(teamRankingType));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.GETALL_TEAM_RANKING_TYPE_REQUEST } }
    function success(teamRankingType) { return { type: teamRankingTypeConstants.GETALL_TEAM_RANKING_TYPE_SUCCESS, teamRankingType } }
    function failure(error) { return { type: teamRankingTypeConstants.GETALL_TEAM_RANKING_TYPE_FAILURE, error } }
}
function getTeamRankingTypeList(data) {
    return dispatch => {
        dispatch(request());
        teamRankingTypeService.getTeamRankingTypeList(data)
            .then(
                team => {
                    dispatch(success(team));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_REQUEST } }
    function success(team) { return { type: teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_SUCCESS, team } }
    function failure(error) { return { type: teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_FAILURE, error } }
}

function deleteTeamRankingType(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        teamRankingTypeService.deleteTeamRankingType(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTeamRankingTypeList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.DELETE_TEAM_RANKING_TYPE_REQUEST } }
    function success(users) { return { type: teamRankingTypeConstants.DELETE_TEAM_RANKING_TYPE_SUCCESS, users } }
    function failure(error) { return { type: teamRankingTypeConstants.DELETE_TEAM_RANKING_TYPE_FAILURE, error } }
}

function getTeamRankingTypeById(data) {

    return dispatch => {
        dispatch(request());
        teamRankingTypeService.getTeamRankingTypeById(data)
            .then(
                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.GET_TEAM_RANKING_TYPE_BY_ID_REQUEST } }
    function success(users) { return { type: teamRankingTypeConstants.GET_TEAM_RANKING_TYPE_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: teamRankingTypeConstants.GET_TEAM_RANKING_TYPE_BY_ID_FAILURE, error } }
}
function disableTeamRankingType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamRankingTypeService.disableTeamRankingType(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTeamRankingTypeList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamRankingTypeConstants.DISABLE_TEAM_RANKING_TYPE_REQUEST } }
    function success(users) { return { type: teamRankingTypeConstants.DISABLE_TEAM_RANKING_TYPE_SUCCESS, users } }
    function failure(error) { return { type: teamRankingTypeConstants.DISABLE_TEAM_RANKING_TYPE_FAILURE, error } }
}
