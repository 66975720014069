import { authHeader, history } from '../_helpers';
import { CONST, } from '../_config';

export const playerService = {
    getPlayerList,
    getAllPlayerList,
    createPlayer,
    UpdatePlayerDetails,
    disablePlayer,
    deletePlayer,
    login,
    // getTransactions,
    // loginValidateOtp,
    // forgotPassword,
    // sendOtpTX,
    // sendFromWithOTP,
    // resendVerificationLink,
    // register,
    logout,
    // sendFrom,
    // getplayerDetails,
    // verifyEmail,
    // createNotification,
    // validateId,
    // forgotUpdatePassword,
    // addplayer,
    // uploadImage,
    // statics,
    // disableplayer,
    // updateplayer,
    // deleteplayer,
    // changePassword,
    // getplayerList,
    // createplayer,
    // getAllMatchActiveBySportId,
    // changeOwnPassword,
    // getBets,
    // getStatement,
    // deposit,
    // withdraw,
    // getOwnChild,
    // getplayerDetailsById,
    // getAllMatchActive,
    // declareResult,
    // tossResultDeclare,
    // fancyResultDeclare,
    // getSelectionByMarketId,
    // getPL,
    // getMatchByMatchIdV2,
    // getplayerSetting,
    // saveBetData,
    // getWorldFancy,
    // getplayerListByplayerType,
    // getProfile,
    // getAllSport,
    // getTossData,
    // createSeries,
    // getAllSeries,
    // getAllMatch,
    // getAllMarket,
    // getAllFancy,
    // createMatch,
    // createMarket,
    // createTossMarket,
    // updateFancyStatus,
    // updateMatchStatus,
    // createAppSetting,
    // getAppSettingList,
    // updateAppSetting,
    // deleteAppSetting,
    // disableAppSetting,
    // getMyShare,
    // getAllMatchBySportId,
    // getAllFancyByMatchId,
    // getBetsByMatchId,
    // getAllMatchSession,
    // upcomingMatches,
    // liveMatchList,
    // updateMatchfmId,
    // getAllActiveFancy
};


function getPlayerList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPlayerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPlayerList: data.data
            }
            return playerObj;
        });
}
function getAllPlayerList() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPlayer`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                getAllPlayerList: data.data
            }


            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);



            return playerObj;
        });
}
function createPlayer(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPlayer`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                createPlayer: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function UpdatePlayerDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/UpdatePlayerDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                UpdatePlayerDetails: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function disablePlayer(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/disablePlayer`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                disablePlayer: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function deletePlayer(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePlayer`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                deletePlayer: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}










function logout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
}
function onerrorlogout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
    window.location.reload();
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(player => {
            let playerObj = {
                playerinfo: player
            }
            if (player.data) {
                localStorage.setItem('player', JSON.stringify(player.data));
            }
            return playerObj;
        });
}
// function loginValidateOtp(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/loginValidateOtp`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             if (player.data) {
//                 localStorage.setItem('player', JSON.stringify(player.data));
//             }

//             return playerObj;
//         });
// }
// function verifyEmail(data) {

//     const requestOptions = {
//         method: "GET",
//     }
//     return fetch(CONST.BACKEND_URL + `/verifyEmail?id=${data.id}&token=${data.token}`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }
// function validateId(data) {
//     const requestOptions = {
//         method: "GET",
//     }
//     return fetch(CONST.BACKEND_URL + `/validateId?id=${data.id}`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfotoken: player.data
//             }
//             return playerObj;
//         });
// }
// function getBetsByMatchId(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getBetsByMatchId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getBetsByMatchId: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }
// function getAllFancyByMatchId(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllFancyByMatchId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAllFancyByMatchId: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }
// function updateMatchStatus(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateMatchStatus?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 updateMatchStatus: data.data
//             }
//             // console.log("i am in service updateMatchStatus ::", playerObj);

//             return playerObj;
//         });
// }

// function updateMatchfmId(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateMatchfmId?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 updateMatchfmId: data.data
//             }
//             // console.log("i am in service updateMatchfmId ::", playerObj);

//             return playerObj;
//         });
// }

// function createAppSetting(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createAppSetting`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 createAppSetting: data.data
//             }
//             // console.log("i am in service createAppSetting ::", playerObj);

//             return playerObj;
//         });
// }

// function updateAppSetting(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateAppSetting`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 updateAppSetting: data.data
//             }
//             // console.log("i am in service updateAppSetting ::", playerObj);

//             return playerObj;
//         });
// }

// function deleteAppSetting(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/deleteAppSetting`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 deleteAppSetting: data.data
//             }
//             // console.log("i am in service deleteAppSetting ::", playerObj);

//             return playerObj;
//         });
// }

// function disableAppSetting(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/disableAppSetting`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 disableAppSetting: data.data
//             }
//             // console.log("i am in service disableAppSetting ::", playerObj);

//             return playerObj;
//         });
// }

// function getAllMatchBySportId(data) {

//     console.log("datadatadatadatadatadatadatadata===>", data);

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllMatchBySportId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAllMatchBySportId: data.data
//             }
//             console.log("!!!!!!!!!!!!!123i am in service getAllMatchBySportId ::", playerObj);

//             return playerObj;
//         });
// }


// function getMyShare(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getPartnership`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getMyShare: data.data
//             }
//             // console.log("i am in service getMyShare ::", playerObj);

//             return playerObj;
//         });
// }

// function getAppSettingList(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAppSettingList`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAppSettingList: data.data
//             }
//             // console.log("i am in service getAppSettingList ::", playerObj);

//             return playerObj;
//         });
// }

// function getAllSport(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "GET",
//         headers: header,
//     }
//     // return fetch(CONST.BACKEND_URL + `/getActiveSport`, requestOptions)
//     return fetch(`https://api5.bagpackkar.com/sp7/getGames`)
//         .then(handleResponse)
//         .then(data => {
//             let sportArray = [];
//             sportArray = data.map(element => ({ id: element.eventType.id, name: element.eventType.name }));

//             let playerObj = {
//                 AllSport: sportArray
//             }
//             console.log("i am in service allSportallSportallSportallSport ::", playerObj);

//             return playerObj;
//         });
// }

// function getTossData(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "GET",
//         headers: header,
//     }
//     // return fetch(CONST.BACKEND_URL + `/getTossData?matchId=${data.matchId}`, requestOptions)
//     return fetch(`https://owner.nice999.fun/api/v1/getTossData?matchId=${data.matchId}&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getTossData: data
//             }
//             console.log("i am in service allSportallSportallSportallSport ::", playerObj);

//             return playerObj;
//         });
// }

// function createSeries(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createSeries?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 createSerie: data.data
//             }
//             console.log("i am in service createSeriescreateSeriescreateSeriescreateSeries ::", playerObj);

//             return playerObj;
//         });
// }

// function getAllSeries(sportId) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "GET",
//         headers: header,
//         // body: JSON.stringify(data)
//     }


//     // console.log(CONST.BACKEND_URL + `/getAllSeries?sportId=${sportId}&token=gakFpm4T9F8Wnthd2okV0Qdh62ZdZadWYFId4t9D`);

//     return fetch(CONST.BACKEND_URL + `/getAllSeries?sportId=${sportId}&token=` + CONST.API_TOKEN, requestOptions)
//         // return fetch(`https://api5.bagpackkar.com/sp7/getSeries?sport_id=${sportId}`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             // let seriesArray = [];
//             // seriesArray = data.map(element => ({ seriesId: element.competition.id, name: element.competition.name, competitionRegion: element.competitionRegion, marketCount: element.marketCount }));

//             // console.log('seriesArrayseriesArrayseriesArrayseriesArray', seriesArray);

//             let playerObj = {
//                 allSeries: data.data
//             }
//             // console.log("i am in service getAllSeriesgetAllSeriesgetAllSeries ::", playerObj);

//             return playerObj;
//         });
// }

// function getAllMatch(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "GET",
//         headers: header,
//         // body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllMatch?series_id=${data.series_id}&game_id=${data.game_id}&token=` + CONST.API_TOKEN, requestOptions)
//         // return fetch(`https://api5.bagpackkar.com/sp7/getMatches?series_id=${data.series_id}&game_id=${data.game_id}`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             // let matchArray = [];
//             // matchArray = data.map(element => (
//             //     {
//             //         sportId: element.eventType.id,
//             //         seriesId: element.competition.id,
//             //         matchId: element.event.id,
//             //         marketId: element.marketId,
//             //         marketName: element.marketName,
//             //         countryCode: element.event.countryCode,
//             //         name: element.event.name,
//             //         openDate: element.event.openDate,
//             //         timezone: element.event.timezone,
//             //     }
//             // ));

//             // console.log('matchArraymatchArraymatchArraymatchArray', matchArray);

//             let playerObj = {
//                 allMatch: data.data
//             }
//             console.log("i am in service getAllMatchgetAllMatchgetAllMatchgetAllMatch:::::::::::", playerObj);

//             return playerObj;
//         });
// }

// function getAllMarket(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "GET",
//         headers: header,
//         // body: JSON.stringify(data)
//     }

//     return fetch(CONST.BACKEND_URL + `/getAllMarket?matchId=${data.matchId}&token=` + CONST.API_TOKEN, requestOptions)
//         // return fetch(`https://api5.bagpackkar.com/sp7/getMarket?match_id=${data.matchId}&game_id=${data.sportId}`, requestOptions)
//         .then(handleResponse)
//         .then(data => {


//             // let marketArray = [];
//             // marketArray = data.map(element => (
//             //     {
//             //         sportId: element.eventType.id,
//             //         seriesId: element.competition.id,
//             //         matchId: element.event.id,
//             //         marketId: element.marketId,
//             //         name: element.marketName,
//             //         marketStartTime: element.marketStartTime,
//             //         // countryCode: element.event.countryCode,
//             //         // name: element.event.name,
//             //         // openDate: element.event.openDate,
//             //         // timezone: element.event.timezone,
//             //     }
//             // ));


//             let playerObj = {
//                 allMarket: data.data
//             }
//             console.log("i am in service getAllMarket:::::::::::123", playerObj);

//             return playerObj;
//         });
// }

// function getAllFancy(data) {

//     console.log("i am in service getAllFancy:::::::::::123__data", data.marketId);

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         // body: JSON.stringify(data)
//     }

//     return fetch(CONST.BACKEND_URL + `/getAllFancy?marketId=${data.marketId}&token=` + CONST.API_TOKEN, requestOptions)
//         // return fetch(`https://kuberexchange.com/frank/bookmaker.php?market_id=${data.marketId}`)
//         .then(handleResponse)
//         .then(data => {

//             // let fancyArray = [];
//             // fancyArray = data.map(element => (
//             //     {
//             //         sportId: element.eventType.id,
//             //         seriesId: element.competition.id,
//             //         matchId: element.event.id,
//             //         marketId: element.marketId,
//             //         name: element.marketName,
//             //         marketStartTime: element.marketStartTime,
//             //         // countryCode: element.event.countryCode,
//             //         // name: element.event.name,
//             //         // openDate: element.event.openDate,
//             //         // timezone: element.event.timezone,
//             //     }
//             // ));

//             let playerObj = {
//                 allFancy: data.data
//             }
//             console.log("i am in service getAllFancy:::::::::::123", playerObj);

//             return playerObj;
//         });
// }

// function createMatch(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createMatch?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 createSerie: data.data
//             }
//             console.log("i am in service createMatchcreateMatchcreateMatchcreateMatch ::", playerObj);

//             return playerObj;
//         });
// }

// function createMarket(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createMarket?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 createMarket: data.data
//             }
//             console.log("i am in service createMarket ::", playerObj);

//             return playerObj;
//         });
// }

// function createTossMarket(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createTossMarket?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 createTossMarket: data.data
//             }
//             console.log("i am in service createTossMarket ::", playerObj);

//             return playerObj;
//         });
// }

// function updateFancyStatus(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateFancyStatus?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 updateFancy: data.data
//             }
//             // console.log("i am in service allSportallSportallSportallSport ::", playerObj);

//             return playerObj;
//         });
// }

// function forgotPassword(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/forgotPassword`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }
// function resendVerificationLink(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/resendVerificationLink`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }
// function createNotification(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createNotification`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }
// function register(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/register`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }
// function forgotUpdatePassword(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/forgotUpdatePassword`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }
// function getplayerDetails(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/overView`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getplayerDetails: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }
// function getBets(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getBets`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getBets: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }
// function withdraw(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/withdraw`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 withdraw: data
//             }
//             //console.log();
//             return playerObj;
//         });
// }
// // function deposit(data) {

// //     console.log("deposit____________Service:::", data);


// //     let header = new Headers({
// //         'Content-Type': 'application/json',
// //         "Authorization": authHeader().Authorization
// //     });
// //     const requestOptions = {
// //         method: "POST",
// //         headers: header,
// //         body: JSON.stringify(data)
// //     }

// //     console.log("deposit____________requestOptions:::", requestOptions);


// //     return fetch(CONST.BACKEND_URL + `/deposit`, requestOptions)
// //         .then(handleResponse)
// //         .then(data => {

// //             let playerObj = {
// //                 deposit: data.data
// //             }
// //             //console.log();
// //             return playerObj;
// //         });
// // }

// function deposit(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     console.log("requestOptions_deposit:", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/deposit`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 deposit: data
//             }
//             console.log("SERVICE______--deposit::::", playerObj);
//             return playerObj;
//         });
// }


// function saveBetData(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     // return fetch(CONST.BACKEND_URL + `/saveBet`, requestOptions)
//     return fetch(`http://localhost:5099/api/v1/saveBet`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 saveBetData: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }


// function getplayerDetailsById(data) {
//     console.log("getplayerDetailsById__service::", data);
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });

//     // console.log("getplayerDetailsById_____header:^^^::", header.Authorization);

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     // console.log("getplayerDetailsById_____requestOptions:#$%^::", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/getplayerDetailsById`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getplayerDetailsById: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getOwnChild(data) {
//     console.log("getOwnChild__service::", data);
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     console.log("requestOptions____requestOptions:::", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/getOwnChild`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getOwnChild: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getStatement(data) {
//     console.log("getStatement___service::", data);
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     console.log("requestOptions____requestOptions:::", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/getStatement`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getStatement: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getSelectionByMarketId(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     console.log("requestOptions_getplayerList:", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/getSelectionByMarketId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getSelectionByMarketId: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }

// function declareResult(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     console.log("requestOptions_getplayerList:", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/resultDeclare`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 declareResult: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }

// function tossResultDeclare(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     console.log("requestOptions_getplayerList:", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/tossResultDeclare`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 tossResultDeclare: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }

// function fancyResultDeclare(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }

//     return fetch(CONST.BACKEND_URL + `/fancyResultDeclare`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 fancyResultDeclare: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }

// function getplayerSetting() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         // body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getplayerSetting`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getplayerSetting: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getProfile(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getProfile`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getProfile: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function upcomingMatches() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         // body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/upcomingMatches?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 upcomingMatches: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function liveMatchList() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         // body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/liveMatchList?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 liveMatchList: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getplayerListByplayerType(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getplayerListByplayerType`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getplayerListByplayerType: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getWorldFancy(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "GET",
//         headers: header,
//         // body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getWorldFancy?marketid=${data.marketid}`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getWorldFancy: data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getAllMatchSession(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllMatchSession?marketId=${data.marketId}`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAllMatchSession: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getMatchByMatchIdV2(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getMatchByMatchIdV2`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getMatchByMatchIdV2: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }

// function getPL() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//     }

//     console.log("requestOptions_getplayerList:", requestOptions);

//     return fetch(CONST.BACKEND_URL + `/getPL`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getPL: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }

// function getAllMatchActive() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//     }

//     console.log("requestOptions_getplayerList:", requestOptions);function getPlayerList(data) {
//         let header = new Headers({
//             'Content-Type': 'application/json',
//             "Authorization": authHeader().Authorization
//         });
//         const requestOptions = {
//             method: "POST",
//             headers: header,
//             body: JSON.stringify(data)
//         }

//         console.log("requestOptions_getPlayerList:", requestOptions);

//         return fetch(CONST.BACKEND_URL + `/getPlayerList`, requestOptions)
//             .then(handleResponse)
//             .then(data => {

//                 let playerObj = {
//                     getPlayerList: data.data
//                 }
//                 //console.log();
//                 return playerObj;
//             });
//     }

//     return fetch(CONST.BACKEND_URL + `/getAllMatchActive?&token=` + CONST.API_TOKEN, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAllMatchActive: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }

// function getAllActiveFancy(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//     }

//     // console.log("requestOptions_getplayerList:", requestOptions);
//     console.log("datadatadatadata:Service", data);

//     return fetch(CONST.BACKEND_URL + `/getAllActiveFancy?marketId=${data.marketId}`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAllActiveFancy: data.data
//             }
//             console.log("playerObj::", playerObj);
//             return playerObj;
//         });
// }


// function getTransactions(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getTransactions`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getTxData: data.data
//             }
//             //console.log();
//             return playerObj;
//         });
// }
// function sendFrom(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/sendFrom`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player.data
//             }
//             return playerObj;
//         });
// }
// function sendFromWithOTP(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/sendFromWithOTP`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player.data
//             }
//             return playerObj;
//         });
// }
// function sendOtpTX(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/sendOtpTX`, requestOptions)
//         .then(handleResponse)
//         .then(player => {
//             let playerObj = {
//                 playerinfo: player
//             }
//             return playerObj;
//         });
// }

// function getAllMatchActiveBySportId(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/getAllMatchActiveBySportId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 getAllMatchActiveBySportId: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }

// function createplayer(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/createplayer`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 createplayer: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }

// function addplayer(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/addplayer`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 addplayer: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }
// function disableplayer(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/disableplayer`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 addplayer: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }
// function deleteplayer(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/deleteplayer`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 addplayer: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }
// function changeOwnPassword(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/changeOwnPassword`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 changeOwnPassword: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }

// function changePassword(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/changePassword`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 addplayer: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }

// function updateplayer(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/updateplayer`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let playerObj = {
//                 addplayer: data.data
//             }
//             console.log();

//             return playerObj;
//         });
// }
// function uploadImage(filedata) {

//     let header = new Headers({
//         "Authorization": authHeader().Authorization
//     });
//     var data = new FormData();
//     data.append('image', filedata);

//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: data
//     }
//     return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
//         .then(handleResponse)
//         .then(res => {
//             let playerObj = {
//                 filesDetails: res.data
//             }
//             return playerObj;
//         });
// }
// function statics() {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header
//     }
//     return fetch(CONST.BACKEND_URL + `/statics`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             let playerObj = {
//                 statics: data.data
//             }
//             return playerObj;
//         });
// }
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {

                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}