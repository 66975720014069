export const playerRankingConstants = {
    GET_PLAYER_RANKING_LIST_REQUEST: 'GET_PLAYER_RANKING_LIST_REQUEST',
    GET_PLAYER_RANKING_LIST_SUCCESS: 'GET_PLAYER_RANKING_LIST_SUCCESS',
    GET_PLAYER_RANKING_LIST_FAILURE: 'GET_PLAYER_RANKING_LIST_FAILURE',
    
    ADD_PLAYER_RANKING_REQUEST: 'ADD_PLAYER_RANKING_REQUEST',
    ADD_PLAYER_RANKING_SUCCESS: 'ADD_PLAYER_RANKING_SUCCESS',
    ADD_PLAYER_RANKING_FAILURE: 'ADD_PLAYER_RANKING_FAILURE',
   
    // getPlayerRankingListByPlayerRankingTypeId
    GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_REQUEST: 'GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_REQUEST',
    GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_SUCCESS: 'GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_SUCCESS',
    GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_FAILURE: 'GET_PLAYER_RANKING_LIST_BY_PLAYER_RANKING_TYPE_ID_FAILURE',
   
    GET_ALL_PLAYER_RANKING_TYPE_REQUEST: 'GET_ALL_PLAYER_RANKING_TYPE_REQUEST',
    GET_ALL_PLAYER_RANKING_TYPE_SUCCESS: 'GET_ALL_PLAYER_RANKING_TYPE_SUCCESS',
    GET_ALL_PLAYER_RANKING_TYPE_FAILURE: 'GET_ALL_PLAYER_RANKING_TYPE_FAILURE',
   
    GET_ALL_PLAYER_RANKING_REQUEST: 'GET_ALL_PLAYER_RANKING_REQUEST',
    GET_ALL_PLAYER_RANKING_SUCCESS: 'GET_ALL_PLAYER_RANKING_SUCCESS',
    GET_ALL_PLAYER_RANKING_FAILURE: 'GET_ALL_PLAYER_RANKING_FAILURE',

    UPDATE_PLAYER_RANKING_REQUEST: 'UPDATE_PLAYER_RANKING_REQUEST',
    UPDATE_PLAYER_RANKING_SUCCESS: 'UPDATE_PLAYER_RANKING_SUCCESS',
    UPDATE_PLAYER_RANKING_FAILURE: 'UPDATE_PLAYER_RANKING_FAILURE',

    UPDATE_PLAYER_RANKING_STATUS_REQUEST: 'UPDATE_PLAYER_RANKING_STATUS_REQUEST',
    UPDATE_PLAYER_RANKING_STATUS_SUCCESS: 'UPDATE_PLAYER_RANKING_STATUS_SUCCESS',
    UPDATE_PLAYER_RANKING_STATUS_FAILURE: 'UPDATE_PLAYER_RANKING_STATUS_FAILURE',

    DELETE_PLAYER_RANKING_REQUEST: 'DELETE_PLAYER_RANKING_REQUEST',
    DELETE_PLAYER_RANKING_SUCCESS: 'DELETE_PLAYER_RANKING_SUCCESS',
    DELETE_PLAYER_RANKING_FAILURE: 'DELETE_PLAYER_RANKING_FAILURE',


};
