import React from "react";
import Modal from 'react-modal';
import Multiselect from 'multiselect-react-dropdown';
// import ReactQuill from 'react-quill';
// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.bubble.css";


export default function AddPlayerDetailsModal(props) {

  let { addSeriesModal, handleCloseAddCustomSeriesModal, fieldsSeriesAdd, inputChangeSeriesAdd, filesDetails, handleFile, customSeriesAddSubmit, getTeamsList, handleContentChange, fieldsAddUser } = props;


  let options = []


  if (getTeamsList && getTeamsList.length > 0) {
    getTeamsList.forEach(element => (
      options.push(
        {
          value: element.id,
          label: element.name
        }
      )
    ));

  }

  return (

    < >


      <div className={addSeriesModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">



          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Add Venue</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddCustomSeriesModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-4">
            <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">
              <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Venue Id : </label>
                  <input type="text" name="venueId" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["venueId"] ? fieldsSeriesAdd["venueId"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Name : </label>
                  <input type="text" name="name" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["name"] ? fieldsSeriesAdd["name"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Place : </label>
                  <input type="text" name="place" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["place"] ? fieldsSeriesAdd["place"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Opened : </label>
                  <input type="text" name="opened" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["opened"] ? fieldsSeriesAdd["opened"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Capacity : </label>
                  <input type="text" name="capacity" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["capacity"] ? fieldsSeriesAdd["capacity"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Known As : </label>
                  <input type="text" name="knownAs" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["knownAs"] ? fieldsSeriesAdd["knownAs"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>




                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Ends : </label>
                  <input type="text" name="ends" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["ends"] ? fieldsSeriesAdd["ends"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Location : </label>
                  <input type="text" name="location" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["location"] ? fieldsSeriesAdd["location"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>

                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Time Zone : </label>
                  <input type="date" name="timeZone" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["timeZone"] ? fieldsSeriesAdd["timeZone"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Home To : </label>
                  <input type="text" name="homeTo" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["homeTo"] ? fieldsSeriesAdd["homeTo"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Floodlights : </label>
                  <input type="text" name="floodlights" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["floodlights"] ? fieldsSeriesAdd["floodlights"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Curator : </label>
                  <input type="text" name="curator" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsSeriesAdd && fieldsSeriesAdd["curator"] ? fieldsSeriesAdd["curator"] : ""}
                    onChange={inputChangeSeriesAdd}
                  ></input>
                </div>

              </div>




              {/* <div className="relative mt-1 rounded-md shadow-sm">
                <ReactQuill
                  theme="snow"
                  // modules={this.modules}
                  // formats={formats}
                  rows={5}
                  // onChange={this.rteChange}
                  value={this.state.fieldsAddUser.story}

                  // value={this.state.content}
                  onChange={this.handleContentChange}
                  modules={this.modules}
                  formats={this.formats}
                  ref={this.quillRef}
                />
              </div>

              <div className='mt-2 mr-2'>
                <label className="mt-2 mr-2 text-sm font-medium text-black">HTML as text: :</label>
                <span className=''>
                  <textarea className='border border-gray-400' name='story'
                    onChange={this.rteChangeText}
                    value={this.state.fieldsAddUser.story}
                    rows={5}
                    // readOnly
                    style={{ width: '100%' }} />
                </span>
              </div> */}




              <div className="flex mt-1 space-x-4">
                {
                  filesDetails && filesDetails.imageLinkUrl ?
                    <>
                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }
              </div>

              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Image
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <p className="pl-2">Note :128 pixels – 128 pixels</p>
              </div>






              <div className="mx-auto">
                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={customSeriesAddSubmit}
                >
                  Submit</button>
              </div>
            </form>
          </div>

        </div >
      </div >


    </>
  );
}
