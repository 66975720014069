// import React from "react";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pointTableActions, userActions, seriesActions, teamsActions } from '../../_actions';

// import { alertActions, pointTableActions, userActions } from '../../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import "./style.css"
import { HiArrowSmLeft } from "react-icons/hi";


class CreatePointTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      seriesId: "",
      teamId: "",
      slug: "",
      mat: "",
      won: "",
      lost: "",
      tied: "",
      nR: "",
      pts: "",
      nRR: "",
      fieldPointTable: {
        pointTable: [{
          teams: "",
          image: "",
          P: "",
          W: "",
          L: "",
          NR: "",
          Pts: "",
          NRR: ""
        }]
      },
      errorsPointTable: {
        pointTable: [{
          teams: "",
          flag: "",
          P: "",
          W: "",
          L: "",
          NR: "",
          Pts: "",
          NRR: "",
          image: "",
          // description: "",
          // link: ""
        }],
      },
      inputAddUserChange: [],
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      newsContent: [],

      fieldsImageUrl: {
        options: [{
          image: "",
          // imageUrlLink: "",
        }]
      },
    };
  }


  componentDidMount() {
    let newsType = this.props.match.params.newsType;
    // console.log("Create___news___this.props.match.params.newsType:::", newsType);
    let data = {

      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(seriesActions.getAllCustomSeries());
    this.props.dispatch(teamsActions.getAllTeams());

  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.addUserSuccess) {
      return {
        ...nextProps,
        fieldPointTable: {
          pointTable: [""],
          options: [""],
          tag: [""]
        },
        errorsPointTable: {
          pointTable: [""],
          options: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldPointTable = this.state.fieldPointTable;
    let errorsPointTable = this.state.errorsPointTable;
    fieldPointTable[name] = value;
    errorsPointTable[name] = "";
    console.log(name, value);
    this.setState({ fieldPointTable, errorsPointTable });
  }

  addPointTableSubmit = () => {

    if (this.handleValidationWebStory()) {
      // if (this.state.isImageSet) {
      let reqData = {
        "seriesId": this.props.match.params.id,
        // "pointTable": fieldPointTableOptionData ? fieldPointTableOptionData : []
        "teamId": this.state.fieldPointTable.teamId,
        "slug": this.state.fieldPointTable.slug,
        "mat": this.state.fieldPointTable.mat,
        "won": this.state.fieldPointTable.won,
        "lost": this.state.fieldPointTable.lost,
        "tied": this.state.fieldPointTable.tied,
        "nR": this.state.fieldPointTable.nR,
        "pts": this.state.fieldPointTable.pts,
        "nRR": this.state.fieldPointTable.nRR
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      console.log("reqData______:::", reqData);
      this.props.dispatch(pointTableActions.createPointsTable(reqData, data, this.props, this.props.match.params.id));
      // this.handleBack()
      // } else {
      //   this.props.dispatch(alertActions.error("Please Set The Image."));
      // }
    }
  }

  handleValidationWebStory = () => {
    let fieldPointTable = this.state.fieldPointTable;
    let errorsPointTable = {};
    let formIsValid = true;

    // //seriesId
    // if (!fieldPointTable["seriesId"] || fieldPointTable["seriesId"] === "") {
    //   formIsValid = false;
    //   errorsPointTable["seriesId"] = "Cannot be empty";
    // }
    //teamId
    if (!fieldPointTable["teamId"] || fieldPointTable["teamId"] === "") {
      formIsValid = false;
      errorsPointTable["teamId"] = "Cannot be empty";
    }
    //slug
    if (!fieldPointTable["slug"] || fieldPointTable["slug"] === "") {
      formIsValid = false;
      errorsPointTable["slug"] = "Cannot be empty";
    }
    //mat
    if (!fieldPointTable["mat"] || fieldPointTable["mat"] === "") {
      formIsValid = false;
      errorsPointTable["mat"] = "Cannot be empty";
    }
    //won
    if (!fieldPointTable["won"] || fieldPointTable["won"] === "") {
      formIsValid = false;
      errorsPointTable["won"] = "Cannot be empty";
    }
    //lost
    if (!fieldPointTable["lost"] || fieldPointTable["lost"] === "") {
      formIsValid = false;
      errorsPointTable["lost"] = "Cannot be empty";
    }
    //tied
    if (!fieldPointTable["tied"] || fieldPointTable["tied"] === "") {
      formIsValid = false;
      errorsPointTable["tied"] = "Cannot be empty";
    }
    //nR
    if (!fieldPointTable["nR"] || fieldPointTable["nR"] === "") {
      formIsValid = false;
      errorsPointTable["nR"] = "Cannot be empty";
    }
    //pts
    if (!fieldPointTable["pts"] || fieldPointTable["pts"] === "") {
      formIsValid = false;
      errorsPointTable["pts"] = "Cannot be empty";
    }
    //nRR
    if (!fieldPointTable["nRR"] || fieldPointTable["nRR"] === "") {
      formIsValid = false;
      errorsPointTable["nRR"] = "Cannot be empty";
    }

    console.log("errorsAddUser_errorsAddUser_::::", errorsPointTable);

    this.setState({ errorsPointTable: errorsPointTable });
    return formIsValid;
  }

  addContentField = () => {
    const { fieldPointTable, fieldsImageUrl } = this.state;
    // fieldPointTable.options.push('')
    fieldPointTable.pointTable.push({
      "teams": "",
      "P": "",
      "W": "",
      "L": "",
      "NR": "",
      "Pts": "",
      "NRR": "",
      "image": "",
      // "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.filesDetails.image,

    });
    fieldsImageUrl.options.push({
      "image": "",
      // "imageUrlLink": "",
    });
    this.setState({ fieldPointTable, fieldsImageUrl });
    console.log("tttttttttttttttttttttttttttttttttttttttttttttt", fieldPointTable);
  }

  deleteContentField = (index) => {

    console.log('deleteContentField____index?????//', index);

    const { fieldPointTable, fieldsImageUrl } = this.state;
    fieldPointTable.pointTable.splice(index, 1)
    fieldsImageUrl.options.splice(index, 1)
    this.setState({ fieldPointTable, fieldsImageUrl });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value, name } = e.target;
    let fieldPointTable = this.state.fieldPointTable;
    console.log("fieldsAddUserfieldsAddUser  ", fieldPointTable);
    fieldPointTable["pointTable"][this.state.currentConetntIndex][name] = value;

    this.setState({ fieldPointTable });
  }
  // handleFile = (event) => {
  //   console.log("handleFileevent", event);

  //   this.setState({ selectedFile: event.target.files[0] });

  //   if (event.target.files[0]) {
  //     this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
  //     this.setState({ selectedFile: null });

  //   }
  //   else {
  //     console.log("No File To Upload!")
  //   }

  // }
  handleFile = (event) => {

    console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJ', event);

    this.setState({ selectedFile: event.target.files[event.target.files.length - 1] });
    if (event.target.files[event.target.files.length - 1]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }

  handleUpload = () => {
    console.log('______________________', this.state.imageWebLink);
    const { fieldPointTable, imageWebLink, imageUrl, fieldsImageUrl } = this.state;


    // console.log('fieldPointTable["options"][this.state.currentConetntIndex]image', fieldPointTable["options"][this.state.currentConetntIndex]['image']);
    // console.log('.............................00000', imageWebLink);

    console.log('llllllllllllllllllllll222222', fieldPointTable.options[this.state.currentConetntIndex]);

    fieldPointTable.options[this.state.currentConetntIndex]['image'] = imageWebLink;
    fieldsImageUrl.options[this.state.currentConetntIndex]['imageUrlLink'] = imageUrl;
    this.setState({ fieldPointTable, isImageSet: true });
  }

  handleBack = () => {
    this.props.history.goBack()
  }


  handleFileSingle = (event) => {

    console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJ');

    this.setState({ selectedFile: event.target.files[event.target.files.length - 1] });
    if (event.target.files[event.target.files.length - 1]) {
      this.props.dispatch(userActions.uploadImageSingle(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }


  render() {
    let { series, team } = this.props;
    let { allSeries } = series
    let { teamsItems } = team



    console.log('getAllCustomSeriesgetAllCustomSeriesgetAllCustomSeriesgetAllCustomSeries', allSeries);
    console.log('getAllCustomSeriesgetAllCustomSeriesgetAllCustomSeriesgetAllCustomSeries', teamsItems);
    // console.log("RENDER______this.state.fieldPointTable**:", this.state.fieldPointTable);


    return (

      <>
        <div className="h-screen m-4  overflow-y-auto">

          <div className="overflow-y-auto bg-white w-full">
            <div className="w-full px-6 py-4 text-left">
              <div className="flex pb-3">
                <div onClick={this.handleBack} className='p-1.5 bg-gray-200 border rounded-full hover:bg-blue-300 hover:text-white'>
                  <HiArrowSmLeft className='w-5 h-5 ' />
                </div>
                <p className="pl-4 mt-0.5 text-2xl font-bold">Add Point Table</p>
              </div>

              <form autoComplete="on">
                <div className=" w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">

                  <div class="">
                    <label className="block text-gray-700 text-base font-bold mb-2">Teams :</label>
                    <select class="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" id="name"
                      name="teamId" value={this.state.fieldPointTable.teamId}
                      // value={fieldPointTable && fieldPointTable["seriesId"] ? fieldPointTable["seriesId"] : ""}
                      onChange={this.inputChange}
                    >
                      <option selected>Plz Select Teams</option>
                      {
                        teamsItems && teamsItems && teamsItems.length > 0 ?
                          teamsItems.map((elemente, index) => (
                            <option key={index} name={elemente && elemente.name ? elemente.name : null} value={elemente && elemente.id ? elemente.id : null}>{elemente && elemente.name ? elemente.name : "NA"}</option>
                          )) : null
                      }
                      isSearchable
                    </select>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Match :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["mat"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="mat" name="mat" placeholder="Match" value={this.state.fieldPointTable.mat} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["mat"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["mat"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Slug :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["slug"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="slug" name="slug" placeholder="Slug" value={this.state.fieldPointTable.slug} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["slug"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["slug"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Won :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["won"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="won" name="won" placeholder="Won" value={this.state.fieldPointTable.won} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["won"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["won"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Lost :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["lost"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="lost" name="lost" placeholder="Lost" value={this.state.fieldPointTable.lost} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["lost"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["lost"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Tied :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["tied"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="tied" name="tied" placeholder="Tied" value={this.state.fieldPointTable.tied} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["tied"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["tied"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">No Result :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["nR"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="nR" name="nR" placeholder="No Result" value={this.state.fieldPointTable.nR} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["nR"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["nR"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Points :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["pts"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="pts" name="pts" placeholder="Points" value={this.state.fieldPointTable.pts} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["pts"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["pts"]}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="">
                    <div className="relative mt-1 ">
                      <label class="block text-gray-700 text-base font-bold mb-2" for="username">Net Run Rate :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsPointTable && !this.state.errorsPointTable["nRR"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="nRR" name="nRR" placeholder="Net Run Rate" value={this.state.fieldPointTable.nRR} type="text" onChange={this.inputChange} />
                      {this.state.errorsPointTable && this.state.errorsPointTable["nRR"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsPointTable["nRR"]}
                        </div>
                        : null}
                    </div>
                  </div>


                </div>

                <div className="w-64 mx-auto my-8 sm:w-72">
                  <button className="flex justify-center w-full px-4 py-3 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button"
                    onClick={this.addPointTableSubmit}
                  >Submit</button>
                </div>
              </form>

            </div>
          </div>
        </div >

      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category, series, team } = state;
  return {
    news,
    series,
    users,
    team,
    category
  };

}
export default connect(mapStateToProps)(CreatePointTable);
