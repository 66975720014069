import { matchConstants } from '../_constants';
import { matchService } from '../_services';
import { alertActions } from './';
// import { toast } from 'react-toastify';
export const matchActions = {
    // login,
    // logout,
    disableCustomMatch,
    getAllCustomMatch,
    createCustomMatch,
    updateCustomMatch,
    updateCustomMatchStatus,
    getCustomMatchList,
    updateMatchStatus,
    updateMatchType,
    deleteCustomMatch,
    updateCustomMatchIsActiveStatus,
    uploadImageClear,
    getCustomMatchById,
    getAllTeams,
};


// function login(data, props) {
//     return dispatch => {
//         dispatch(request({ data }));
//         userService.login(data)
//             .then(
//                 user => {
//                     dispatch(success(user));
//                     props.history.push({ pathname: 'app/dashboard' });
//                 },
//                 error => {
//                     console.log("errorerror ", error);
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: matchConstants.LOGIN_REQUEST, user } }
//     function success(user) { return { type: matchConstants.LOGIN_SUCCESS, user } }
//     function failure(error) { return { type: matchConstants.LOGIN_FAILURE, error } }
// }
// function logout() {
//     // userService.logout();
//     return { type: matchConstants.LOGOUT };
// }

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: matchConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createCustomMatch(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchService.createCustomMatch(data)
            .then(

                customMatch => {
                    dispatch(success(customMatch));
                    dispatch(alertActions.success("CustomMatch Add Successfully."));
                    dispatch(this.getCustomMatchList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.ADD_CUSTOM_MATCH_REQUEST } }
    function success(customMatch) { return { type: matchConstants.ADD_CUSTOM_MATCH_SUCCESS, customMatch } }
    function failure(error) { return { type: matchConstants.ADD_CUSTOM_MATCH_FAILURE, error } }
}
function updateCustomMatch(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchService.updateCustomMatch(data)
            .then(

                customMatch => {
                    dispatch(success(customMatch));
                    dispatch(alertActions.success("Custom Match Update Successfully."));
                    dispatch(this.getCustomMatchList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.UPDATE_CUSTOM_MATCH_REQUEST } }
    function success(customMatch) { return { type: matchConstants.UPDATE_CUSTOM_MATCH_SUCCESS, customMatch } }
    function failure(error) { return { type: matchConstants.UPDATE_CUSTOM_MATCH_FAILURE, error } }
}
function updateCustomMatchStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchService.updateCustomMatchStatus(data)
            .then(

                updateMatch => {
                    dispatch(success(updateMatch));
                    dispatch(alertActions.success("CustomMatch Update Successfully."));
                    dispatch(this.getCustomMatchList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.UPDATE_CUSTOM_MATCH_REQUEST } }
    function success(updateMatch) { return { type: matchConstants.UPDATE_CUSTOM_MATCH_SUCCESS, updateMatch } }
    function failure(error) { return { type: matchConstants.UPDATE_CUSTOM_MATCH_FAILURE, error } }
}


function updateMatchType(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchService.updateMatchType(data)
            .then(

                updateMatch => {
                    dispatch(success(updateMatch));
                    dispatch(alertActions.success("Match Type Update Successfully."));
                    // dispatch(this.getCustomMatchList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.UPDATE_MATCH_STATUS_REQUEST } }
    function success(updateMatch) { return { type: matchConstants.UPDATE_MATCH_STATUS_SUCCESS, updateMatch } }
    function failure(error) { return { type: matchConstants.UPDATE_MATCH_STATUS_FAILURE, error } }
}








function updateMatchStatus(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchService.updateMatchStatus(data)
            .then(

                updateMatch => {
                    dispatch(success(updateMatch));
                    dispatch(alertActions.success("Match Status Update Successfully."));
                    // dispatch(this.getCustomMatchList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.UPDATE_MATCH_STATUS_REQUEST } }
    function success(updateMatch) { return { type: matchConstants.UPDATE_MATCH_STATUS_SUCCESS, updateMatch } }
    function failure(error) { return { type: matchConstants.UPDATE_MATCH_STATUS_FAILURE, error } }
}
function getAllCustomMatch(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchService.getAllCustomMatch(data)
            .then(
                users => {
                    console.log("getAllCustomMatch $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.GETALL_CustomMatch_REQUEST } }
    function success(users) { return { type: matchConstants.GETALL_CustomMatch_SUCCESS, users } }
    function failure(error) { return { type: matchConstants.GETALL_CustomMatch_FAILURE, error } }
}
function getAllTeams() {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchService.getAllTeams()
            .then(
                teams => {
                    console.log("getAllTeams $$$$ action:", teams);
                    dispatch(success(teams));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.GET_ALL_TEAM_REQUEST } }
    function success(teams) { return { type: matchConstants.GET_ALL_TEAM_SUCCESS, teams } }
    function failure(error) { return { type: matchConstants.GET_ALL_TEAM_FAILURE, error } }
}
function getCustomMatchList(data) {
    return dispatch => {
        dispatch(request());
        matchService.getCustomMatchList(data)
            .then(
                customMatch => {
                    dispatch(success(customMatch));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.GET_LIST_CUSTOM_MATCH_REQUEST } }
    function success(customMatch) { return { type: matchConstants.GET_LIST_CUSTOM_MATCH_SUCCESS, customMatch } }
    function failure(error) { return { type: matchConstants.GET_LIST_CUSTOM_MATCH_FAILURE, error } }
}
// function getAllCityByStateId(data) {
//     return dispatch => {
//         dispatch(request());
//         matchService.getAllCityByStateId(data)
//             .then(
//                 users => {
//                     console.log("getAllCityByStateId $$$$ action:", users);
//                     dispatch(success(users));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: matchConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST } }
//     function success(users) { return { type: matchConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS, users } }
//     function failure(error) { return { type: matchConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE, error } }
// }

function deleteCustomMatch(data, paginationData) {
    return dispatch => {
        dispatch(request());
        matchService.deleteCustomMatch(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCustomMatchList(paginationData));
                    dispatch(alertActions.success("CustomMatch Delete Successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.DELETE_CUSTOM_MATCH_REQUEST } }
    function success(users) { return { type: matchConstants.DELETE_CUSTOM_MATCH_SUCCESS, users } }
    function failure(error) { return { type: matchConstants.DELETE_CUSTOM_MATCH_FAILURE, error } }
}

function updateCustomMatchIsActiveStatus(data, paginationData) {
    return dispatch => {
        dispatch(request());
        matchService.updateCustomMatchIsActiveStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getCustomMatchList(paginationData));
                    // dispatch(alertActions.success("CustomMatch Delete Successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_REQUEST } }
    function success(users) { return { type: matchConstants.UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_SUCCESS, users } }
    function failure(error) { return { type: matchConstants.UPDATE_CUSTOM_MATCH_ISACTIVE_STATUS_FAILURE, error } }
}

function getCustomMatchById(data) {

    return dispatch => {
        dispatch(request());
        matchService.getCustomMatchById(data)
            .then(
                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.GET_CustomMatch_BY_ID_REQUEST } }
    function success(users) { return { type: matchConstants.GET_CustomMatch_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: matchConstants.GET_CustomMatch_BY_ID_FAILURE, error } }
}
function disableCustomMatch(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchService.disableCustomMatch(data)
            .then(
                disableMatch => {
                    dispatch(success(disableMatch));
                    dispatch(this.getCustomMatchList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchConstants.DISABLE_CUSTOM_MATCH_REQUEST } }
    function success(disableMatch) { return { type: matchConstants.DISABLE_CUSTOM_MATCH_SUCCESS, disableMatch } }
    function failure(error) { return { type: matchConstants.DISABLE_CUSTOM_MATCH_FAILURE, error } }
}
