import React from "react";
import Modal from 'react-modal';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';




export default function CreateTeamModal(props) {

  let { addTeamModal, handleCloseAddTeamModal, errorsTeamAdd, inputChangeTeamAdd, teamAddSubmit, fieldsTeamAdd, getAllPlayer, selectedPlayer, handleSelectedPlayer, handleChange, handleFile, filesDetails } = props;
  console.log("MODAL_________getAllPlayerList???????????", getAllPlayer);

  // const options = [
  //   { value: 'apple', label: 'Apple' },
  //   { value: 'banana', label: 'Banana' },
  //   { value: 'cherry', label: 'Cherry' },
  //   // Add more options as needed
  // ];

  let options = []


  if (getAllPlayer && getAllPlayer.length > 0) {
    getAllPlayer.forEach(element => (
      options.push(
        {
          value: element.id,
          label: element.name
        }
      )
    ));

  }

  console.log('optionsoptionsoptionsoptions', options);

  return (

    < >

      <div className={addTeamModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
      <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">

       
            <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Create Team</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddTeamModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-4">
          <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">

             
                <div class="mb-3 w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Team Name : </label>
                  <input type="text" name="name" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsTeamAdd && fieldsTeamAdd["name"] ? fieldsTeamAdd["name"] : ""}
                    onChange={inputChangeTeamAdd}
                  ></input>
                </div>
           

             
                <div class="mb-3 w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left">Short Name : </label>
                  <input type="text" name="short_name" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsTeamAdd && fieldsTeamAdd["short_name"] ? fieldsTeamAdd["short_name"] : ""}
                    onChange={inputChangeTeamAdd}
                  ></input>
                </div>
            

              {/* <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  <label className="text-black text-base font-medium ">flag : </label> &nbsp;
                  <input type="file" name="flag"
                    value={fieldsTeamAdd && fieldsTeamAdd["flag"] ? fieldsTeamAdd["flag"] : ""}
                    onChange={inputChangeTeamAdd}
                  ></input>
                </div>
              </div> */}

              <div class="flex justify-center w-full">
                <div class="mb-3 w-full">
                  {/* <label className="text-black text-base font-medium ">Player : </label> &nbsp; */}
                  <label for="countries"  className="block text-xs font-medium text-gray-500 md:text-left">Select Palyers</label>
                  {/* <Select
                    isMulti
                    options={options}
                    onSelect={handleSelectedPlayer}
                    selected={selectedPlayer}
                    // value={selectedPlayer}
                    // onChange={handleChange}
                    placeholder="Player"
                    isSearchable
                  /> */}
                  <Multiselect
                    options={options}
                    onSelect={handleSelectedPlayer}
                    onRemove={handleSelectedPlayer}
                    selected={selectedPlayer}
                    isObject={true}
                    displayValue="label"
                    
                  />

                </div>
              </div>


              <div className="flex mt-1 space-x-4">
                {
                  filesDetails && filesDetails.imageLinkUrl ?
                    <>
                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }
              </div>

              <div className="flex flex-wrap w-full py-2">
                <label htmlFor="image">
                  Upload Flag Image
                </label>
                <input id="image" name="image" type="file" onChange={handleFile} />
                <p className="pl-2">Note :128 pixels – 128 pixels</p>
              </div>


              <div className=" mx-auto">
                <button className="bg-[rgb(44,171,181)] mx-auto flex justify-center py-2.5 uppercase px-7  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={teamAddSubmit}
                >
                  Submit</button>
              </div>
            </form>
        </div>
        </div>
      </div>

    </>



  );
}
