import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
    }
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        userName: this.state.fieldslogin.userName,
        password: this.state.fieldslogin.password,
      }
      this.props.dispatch(userActions.login(data, this.props));
    }

  }



  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    console.log("hello validation");

    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["userName"]) {
      formIsValid = false;
      errorslogin["userName"] = "User Name Cannot Be Blank.";
    }
    //password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Password Cannot Be Blank.";
    }
 
    console.log("errorsloginerrorsloginerrorsloginerrorslogin:::", errorslogin
    );

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  render() {

    console.log("this.state.errorslogin::::", this.state.errorslogin);

    return (
      <>

        <div className="w-full min-h-screen bg-gray-100">
          <div className="lg:py-6 flex justify-center px-4 md:px-0 w-full">
            <div className=" w-[350px] lg:w-[400px]  bg-gray-100 rounded-xl lg:py-20 overflow-x-hidden">
              <div className="space-y-2">
                <div className="pb-4 px-2">
                  <img src="images/N10.png" className="w-full h-full" alt="logo"/>
                </div>
                <div>
                  <h2 className="text-center text-[18px] text-gray-500/90 font-semibold tracking-tight">Welcome to Api Provider Cp2</h2>
                  <h2 className="text-center text-[18px] font-semibold text-gray-500/90">Sign In</h2>
                </div>
                <div className="form-group py-1">
                  <input type="userName" name="userName" id="userName"
                    value={this.state.fieldslogin.userName}
                    placeholder="User Name" className="bg-white w-full  px-2.5 py-1.5 rounded-sm text-black border border-gray-100 focus:outline-none focus:ring-1 ring-green-300 text-sm" onChange={this.inputChange} />
                  {this.state.errorslogin && this.state.errorslogin["userName"] ?
                    <div className="text-red-400">
                      {this.state.errorslogin["userName"]}
                    </div>
                    : null}
                </div>
                <div className="form-group py-1">
                  <input type="password" name="password" id="password"
                    value={this.state.fieldslogin.password}
                    placeholder="Password" className="bg-white w-full  px-2.5 py-1.5 rounded-sm text-black focus:outline-none border border-gray-100 focus:ring-1 ring-green-400 text-sm" onChange={this.inputChange} />
                  {this.state.errorslogin && this.state.errorslogin["password"] ?
                    <div className="text-red-400">
                      {this.state.errorslogin["password"]}
                    </div>
                    : null}
                </div>
                <div className="flex justify-center w-full">
                  <button type="button" className=" bg-[rgb(44,171,181)] text-white text-[15px] font-serif text-center py-[5px] rounded-sm w-full focus:outline-none scale-100 hover:scale-105 transition duration-100" onClick={this.loginSubmit}>Login</button>
                </div>
                <div className="pt-2">
                  <p className="text-sm text-gray-600 text-center">Api Provider Cp2 &#169; 2022</p>
                  </div>

              </div>
            </div>
          </div>
        </div>


      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default connect(mapStateToProps)(Login);
