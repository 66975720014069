import {
  teamRankingTypeConstants
} from '../_constants';

export function teamRankingType(state = {}, action) {

  switch (action.type) {
    case teamRankingTypeConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingTypeConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        allCityByStateId: action.users.getAllCityByStateId,
      };
    case teamRankingTypeConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamRankingTypeConstants.GET_TEAM_RANKING_TYPE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingTypeConstants.GET_TEAM_RANKING_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        teamRankingTypeRowData: action.users.getTeamRankingTypeById,
      };
    case teamRankingTypeConstants.GET_TEAM_RANKING_TYPE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamRankingTypeConstants.ADD_TEAM_RANKING_TYPE_REQUEST:
      return {
        ...state
      };
    case teamRankingTypeConstants.ADD_TEAM_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teamRankingTypeConstants.ADD_TEAM_RANKING_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getTeamRankingTypeList: action.team.getTeamRankingTypeList.list,
        getTeamRankingTypeListTotal: action.team.getTeamRankingTypeList.total
      };
    case teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // case teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_SUCCESS:
    //   return {
    //     ...state,
    //     addUserSuccess: false,
    //     loading: false,
    //     getTeamRankingTypeList: action.team.getTeamRankingTypeList.list,
    //     getTeamRankingTypeListTotal: action.team.getTeamRankingTypeList.total
    //   };
    // case teamRankingTypeConstants.GET_LIST_TEAM_RANKING_TYPE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error
    //   };
    case teamRankingTypeConstants.GETALL_TEAM_RANKING_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingTypeConstants.GETALL_TEAM_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        teamRankingTypeItems: action.teamRankingType.getAllTeamRankingType
      };
    case teamRankingTypeConstants.GETALL_TEAM_RANKING_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case teamRankingTypeConstants.UPDATE_TEAM_RANKING_TYPE_REQUEST:
      return {
        ...state
      };
    case teamRankingTypeConstants.UPDATE_TEAM_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case teamRankingTypeConstants.UPDATE_TEAM_RANKING_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teamRankingTypeConstants.DELETE_TEAM_RANKING_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingTypeConstants.DELETE_TEAM_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case teamRankingTypeConstants.DELETE_TEAM_RANKING_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamRankingTypeConstants.DISABLE_TEAM_RANKING_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRankingTypeConstants.DISABLE_TEAM_RANKING_TYPE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case teamRankingTypeConstants.DISABLE_TEAM_RANKING_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}