import { playerRankTypeConstants } from '../_constants';
import { playerRankTypeService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';
export const playerRankTypeActions = {
    getPlayerRankingTypeList,
    getAllPlayerRankingType,
    disablePlayerRankingType,
    deletePlayerRankingType,
    createPlayerRankingType,
    UpdatePlayerRankingType,
};

function getPlayerRankingTypeList(data) {
    return dispatch => {
        dispatch(request());
        playerRankTypeService.getPlayerRankingTypeList(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankTypeConstants.GET_PLAYER_RANK_TYPE_LIST_REQUEST } }
    function success(player) { return { type: playerRankTypeConstants.GET_PLAYER_RANK_TYPE_LIST_SUCCESS, player } }
    function failure(error) { return { type: playerRankTypeConstants.GET_PLAYER_RANK_TYPE_LIST_FAILURE, error } }
}
function getAllPlayerRankingType() {
    return dispatch => {
        dispatch(request());
        playerRankTypeService.getAllPlayerRankingType()
            .then(
                player => {
                    console.log("getAllPlayerRankingType___players_Action:::", player);
                    dispatch(success(player))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankTypeConstants.GET_ALL_PLAYER_RANK_TYPE_REQUEST } }
    function success(player) { return { type: playerRankTypeConstants.GET_ALL_PLAYER_RANK_TYPE_SUCCESS, player } }
    function failure(error) { return { type: playerRankTypeConstants.GET_ALL_PLAYER_RANK_TYPE_FAILURE, error } }
}

function createPlayerRankingType(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankTypeService.createPlayerRankingType(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingTypeList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankTypeConstants.ADD_PLAYER_RANK_TYPE_REQUEST } }
    function success(player) { return { type: playerRankTypeConstants.ADD_PLAYER_RANK_TYPE_SUCCESS, player } }
    function failure(error) { return { type: playerRankTypeConstants.ADD_PLAYER_RANK_TYPE_FAILURE, error } }
}
function UpdatePlayerRankingType(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankTypeService.UpdatePlayerRankingType(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingTypeList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_REQUEST } }
    function success(player) { return { type: playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_SUCCESS, player } }
    function failure(error) { return { type: playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_FAILURE, error } }
}
function disablePlayerRankingType(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankTypeService.disablePlayerRankingType(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingTypeList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_STATUS_REQUEST } }
    function success(player) { return { type: playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_STATUS_SUCCESS, player } }
    function failure(error) { return { type: playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_STATUS_FAILURE, error } }
}
function deletePlayerRankingType(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerRankTypeService.deletePlayerRankingType(data)
            .then(
                player => {
                    console.log("getPlayerList___players_Action:::", player);
                    dispatch(success(player));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerRankingTypeList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerRankTypeConstants.DELETE_PLAYER_RANK_TYPE_REQUEST } }
    function success(player) { return { type: playerRankTypeConstants.DELETE_PLAYER_RANK_TYPE_SUCCESS, player } }
    function failure(error) { return { type: playerRankTypeConstants.DELETE_PLAYER_RANK_TYPE_FAILURE, error } }
}