import {
  teamsConstants
} from '../_constants';

export function team(state = {}, action) {

  switch (action.type) {
    case teamsConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamsConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        allCityByStateId: action.users.getAllCityByStateId,
      };
    case teamsConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamsConstants.GET_TEAMS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamsConstants.GET_TEAMS_BY_ID_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        loading: false,
        teamsRowData: action.users.getTeamsById,
      };
    case teamsConstants.GET_TEAMS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamsConstants.ADD_TEAMS_REQUEST:
      return {
        ...state
      };
    case teamsConstants.ADD_TEAMS_SUCCESS:
      return {
        ...state,
        addTeamSuccess: true,
      };
    case teamsConstants.ADD_TEAMS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teamsConstants.GET_LIST_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
        addTeamSuccess: false,
      };
    case teamsConstants.GET_LIST_TEAMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getTeamsList: action.team.getTeamsList.list,
        getTeamsListTotal: action.team.getTeamsList.total
      };
    case teamsConstants.GET_LIST_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamsConstants.GETALL_TEAMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamsConstants.GETALL_TEAMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        teamsItems: action.users.getAllTeams
      };
    case teamsConstants.GETALL_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case teamsConstants.UPDATE_TEAMS_REQUEST:
      return {
        ...state
      };
    case teamsConstants.UPDATE_TEAMS_SUCCESS:
      return {
        ...state,
        addTeamSuccess: true,
      };
    case teamsConstants.UPDATE_TEAMS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case teamsConstants.DELETE_TEAMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamsConstants.DELETE_TEAMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case teamsConstants.DELETE_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case teamsConstants.DISABLE_TEAMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamsConstants.DISABLE_TEAMS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case teamsConstants.DISABLE_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}