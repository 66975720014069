import {
  playerConstants
} from '../_constants';

export function player(state = {}, action) {

  // console.log('actionactionactionactionactionactionactionactionactionaction', action);

  switch (action.type) {

    case playerConstants.GET_PLAYER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case playerConstants.GET_PLAYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getPlayerList: action.players.getPlayerList.list,
        total: action.players.getPlayerList.total
      };
    case playerConstants.GET_PLAYER_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerConstants.GET_ALL_PLAYER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case playerConstants.GET_ALL_PLAYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPlayer: action.players.getAllPlayerList
        
      };
    case playerConstants.GET_ALL_PLAYER_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerConstants.UPDATE_PLAYER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerConstants.UPDATE_PLAYER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerConstants.UPDATE_PLAYER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerConstants.ADD_PLAYER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerConstants.ADD_PLAYER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerConstants.ADD_PLAYER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerConstants.UPDATE_PLAYER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerConstants.UPDATE_PLAYER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerConstants.UPDATE_PLAYER_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case playerConstants.UPDATE_PLAYER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerConstants.UPDATE_PLAYER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerConstants.UPDATE_PLAYER_DELETE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    default:
      return state
  }
}