import React from 'react';
import Modal from 'react-modal';


export default function SendNotification(props) {

  let { notificationModal, handleNotificationHideModal, fieldsNotification, errorsNotification, addNotificationSubmit, handleFile, inputNotification, filesDetails } = props;

  // console.log("MODAL____newsRowData:", fieldsNotification);
  console.log("filesDetails:", filesDetails);

  return (

    <>
      <Modal
        isOpen={notificationModal}
      // `content`Label="Example Modal"
      >

        <div className="fixed inset-0 z-auto flex items-center justify-center w-full h-screen mt-10 overflow-hidden overflow-y-auto main-modal newsItems-center animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
          <div className="z-50 w-3/6 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container">
            <div className="w-full px-6 py-4 text-left modal-content ">
              {/*Title*/}
              <div className="flex justify-between newsItems-center">
                <p className="text-2xl font-bold">Create Notification</p>
                <div className="z-50 cursor-pointer modal-close">
                  <svg onClick={() => handleNotificationHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                    </path>
                  </svg>
                </div>
              </div>
              {/*Body*/}
              <div className="">
                <form autoComplete="on">
                  <div className="grid grid-cols-2 gap-2">

                    {/* <div class="mr-2 w-full">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">News :</label>
                      <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" onChange={inputNotification} id="category" name="newsId"
                        value={fieldsNotification && fieldsNotification["newsId"] ? fieldsNotification["newsId"] : null} >
                        <option selected>Plz Select Category</option>
                        {
                          newsItems && newsItems && newsItems.length > 0 ?
                            newsItems.map((element, index) => (
                              <option value={element.id}>{element && element.title ? element.title : "NA"}</option>
                            )) : null
                        }
                      </select>
                      {errorsNotification && errorsNotification["newsId"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {errorsNotification["newsId"]}
                        </div>
                        : null}
                    </div> */}



                    <div className="flex justify-between w-full">
                      <div className="w-full mr-2">
                        <div className="relative mt-1 shadow-sm">
                          <label class="block text-gray-700 text-sm" for="username">newsId</label>
                          <input className="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                            placeholder="newsId" value={fieldsNotification.news_id} type="text" disabled />
                          {/* {errorsNotification && errorsNotification["newsId"] ?
                            <div className="text-yellow-600 invalid-feedback">
                              {errorsNotification["newsId"]}
                            </div>
                            : null} */}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="w-full mr-2">
                        <div className="relative mt-1 shadow-sm">
                          <label class="block text-gray-700 text-sm" for="username">Title</label>
                          <input className="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                            id="title" name="title" placeholder="Title" value={fieldsNotification.title} type="title" onChange={inputNotification} />
                          {errorsNotification && errorsNotification["title"] ?
                            <div className="text-yellow-600 invalid-feedback">
                              {errorsNotification["title"]}
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between w-full">
                      <div className="w-full mr-2">
                        <div className="relative mt-1 shadow-sm">
                          <label class="block text-gray-700 text-sm" for="username">Body</label>
                          <input className="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                            id="title" name="title" placeholder="Title" value={fieldsNotification.title} type="title" onChange={inputNotification} />
                          {errorsNotification && errorsNotification["title"] ?
                            <div className="text-yellow-600 invalid-feedback">
                              {errorsNotification["title"]}
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex justify-between w-full mt-2">
                      <div className="w-full mr-2">
                        <div className="relative mt-1 shadow-sm">
                          <label class="block text-gray-700 text-sm" for="username">Body</label>
                          <input className="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                            id="body" name="body" placeholder="body" value={fieldsNotification.title} type="body" onChange={inputNotification} />
                          {errorsNotification && errorsNotification["body"] ?
                            <div className="text-yellow-600 invalid-feedback">
                              {errorsNotification["body"]}
                            </div>
                            : null}
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="flex justify-between w-full mt-2">
                      <div className="w-full mr-2">
                        <div className="relative mt-1 shadow-sm">
                          <label class="block text-gray-700 text-sm" for="username">Color</label>
                          <input className="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                            id="color" name="color" placeholder="color" value={fieldsNotification.color} type="color" onChange={inputNotification} />
                          {errorsNotification && errorsNotification["color"] ?
                            <div className="text-yellow-600 invalid-feedback">
                              {errorsNotification["color"]}
                            </div>
                            : null}
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="flex justify-between w-full mt-2">
                      <div className="w-full mr-2">
                        <div className="relative mt-1 shadow-sm">
                          <label class="block text-gray-700 text-sm" for="username">Priority</label>
                          <input className="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
                            id="priority" name="priority" placeholder="priority" value={fieldsNotification.priority} type="priority" onChange={inputNotification} />
                          {errorsNotification && errorsNotification["priority"] ?
                            <div className="text-yellow-600 invalid-feedback">
                              {errorsNotification["priority"]}
                            </div>
                            : null}
                        </div>
                      </div>
                    </div> */}

                  </div>


                  <div className='pt-2'>
                    {
                      filesDetails && filesDetails.imageURL ?
                        <>
                          <img src={filesDetails.imageURL} alt=" " width="100" height="200"></img>
                        </> :
                        <img src={fieldsNotification.imageLinkUrl} alt=" " width="100" height="200"></img>
                    }
                  </div>


                  <div className="flex flex-wrap w-full mt-2">
                    <label htmlFor="image">Upload Image </label>
                    <div className="w-full">
                      <input id="image" name="image" type="file" onChange={handleFile} />
                    </div>
                  </div>
                  <div className="mx-auto mt-2 w-60">
                    <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border rounded-full border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button" onClick={addNotificationSubmit}>Submit</button>
                  </div>

                </form>

              </div>
              {/*Footer*/}
            </div>
          </div>
        </div >


      </Modal >






    </>

  );
}


