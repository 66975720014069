import React from "react";

import Modal from 'react-modal';


export default function UpdateImage(props) {

  let { updateImageModal, handleAppSeetingHideModal, errorsUpdateImage, updateImageSubmit, fieldsImageUpdate, handleFile, inputChangeUpdateImage, imageURL } = props;

  console.log("fieldsImageUpdate_MODAL::", fieldsImageUpdate);

  return (

    <Modal
      isOpen={updateImageModal}
    >

      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between pb-3">
              <p className="text-2xl font-bold">Update Image</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleAppSeetingHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <input className={`px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateImage && !errorsUpdateImage["imageName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="imageName" name="imageName" placeholder="Image Name" value={fieldsImageUpdate.imageName} type="imageName" onChange={inputChangeUpdateImage} />
                    {errorsUpdateImage && errorsUpdateImage["imageName"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsUpdateImage["imageName"]}
                      </div>
                      : null}
                    {/* icon email */}
                    <span className="absolute text-gray-500 top-4 left-5"><svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span>
                  </div>
                </div>

                <div className="object-cover h-40 rounded-sm w-40 mt-2">
                  <img src={imageURL ? imageURL : fieldsImageUpdate.imageLinkUrl}
                    alt=" " />
                </div>

                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">
                    Upload Image
                  </label>
                  <input
                    // style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />
                </div>

                <div className="w-64 py-6 mx-auto mt-6 sm:w-72">
                  <button className="flex justify-center w-full px-4 py-3 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out border rounded-full bg-shine-400 xl border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button" onClick={updateImageSubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>


  );
}
