import React, { Component } from 'react';
import { connect } from 'react-redux';
import { seriesActions, userActions, teamsActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePlayerDetailsModal from "./components/UpdatePlayerDetailsModal/UpdatePlayerDetailsModal";
import AddPlayerDetailsModal from "./components/AddPlayerDetailsModal/AddPlayerDetailsModal";
import ViewSeriesDetailsModal from "./components/ViewSeriesDetailsModal/ViewSeriesDetailsModal";
import LoadingOverlay from 'react-loading-overlay';
import { HiArrowSmLeft } from "react-icons/hi";
import { MdAdd } from "react-icons/md";

class Series extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      fieldsTeamUpdate: {},
      rowDetails: {},
      viewSeriesModal: false,

      keyWord: '',
      pageNo: 1,
      size: 10,
      rowData: {},
      addSeriesModal: false,
      fieldsSeriesAdd: {},
      errorsSeriesAdd: {},

      hasError: false,
      updateSeriesModal: false,
      fieldSeriesUpdate: {},
      errorsSeriesUpdate: {},
      selectedPlayer: [],
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {


    if (nextProps.series.addplayeruccess) {

      return {
        ...nextProps,

        addSeriesModal: false,
        fieldsSeriesAdd: {},
        errorsSeriesAdd: {},

        updateSeriesModal: false,
        handleCloseViewSeriesModal: false,
        fieldSeriesUpdate: {},
        errorsSeriesUpdate: {},


      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }


  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error(error, errorInfo);
  }


  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(seriesActions.getVenuesList(data));

  }


  handleOpenToAddPlayerModal = () => {
    this.setState({ addSeriesModal: true });
  }
  handleCloseAddCustomSeriesModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ addSeriesModal: false });
  }
  inputChangeSeriesAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsSeriesAdd = this.state.fieldsSeriesAdd;
    let errorsSeriesAdd = this.state.errorsSeriesAdd;
    fieldsSeriesAdd[name] = value;
    errorsSeriesAdd[name] = "";
    this.setState({ fieldsSeriesAdd, errorsSeriesAdd });
  }


  navigate = (url) => {
    this.props.history.push(url);
  }


  handleSelectedPlayer = (selectedPlayer) => {
    console.log(selectedPlayer)

    this.setState({ selectedPlayer });
  };



  // handleOpenToUpdatePlayerModal = (data) => {
  //   this.setState({ updateSeriesModal: true, fieldSeriesUpdate: data });
  // }
  handleOpenToUpdatePlayerModal = (data) => {
    // this.setState({ viewSeriesModal: true, fieldsTeamUpdate: data })
    // updateVenue
    this.props.history.push(`/app/updateVenue/${data.id}`)

  }
  handleCloseUpdateSeriesModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ updateSeriesModal: false });
  }
  inputChangeSeriesUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldSeriesUpdate = this.state.fieldSeriesUpdate;
    let errorsSeriesUpdate = this.state.errorsSeriesUpdate;
    fieldSeriesUpdate[name] = value;
    errorsSeriesUpdate[name] = "";
    this.setState({ fieldSeriesUpdate, errorsSeriesUpdate });
  }
  seriesUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationSeriesUpdate()) {
      let { series, users } = this.props
      let { fieldSeriesUpdate } = series
      let { filesDetails } = users
      let resData = {

        "id": this.state.fieldSeriesUpdate.id,
        "sportId": this.state.fieldSeriesUpdate.sportId,
        "series_id": this.state.fieldSeriesUpdate.series_id,
        "seriesId": this.state.fieldSeriesUpdate.seriesId,
        "series": this.state.fieldSeriesUpdate.series,
        "series_date": this.state.fieldSeriesUpdate.series_date,
        "total_matches": this.state.fieldSeriesUpdate.total_matches,
        "start_date": this.state.fieldSeriesUpdate.start_date,
        "end_date": this.state.fieldSeriesUpdate.end_date,
        "month_wise": this.state.fieldSeriesUpdate.month_wise,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.filesDetails.image,
        // "image": fieldSeriesUpdate && fieldSeriesUpdate.imageName ? fieldSeriesUpdate.imageName : this.state.fieldSeriesUpdate.image,
      }

      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(seriesActions.updateCustomSeries(resData, data));
    }
  }
  handleValidationSeriesUpdate = () => {
    let fieldSeriesUpdate = this.state.fieldSeriesUpdate;
    let errorsSeriesUpdate = {};
    let formIsValid = true;
    if (!fieldSeriesUpdate["sportId"] || fieldSeriesUpdate["sportId"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["sportId"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["series_id"] || fieldSeriesUpdate["series_id"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["series_id"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["seriesId"] || fieldSeriesUpdate["seriesId"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["seriesId"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["series"] || fieldSeriesUpdate["series"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["series"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["series_date"] || fieldSeriesUpdate["series_date"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["series_date"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["total_matches"] || fieldSeriesUpdate["total_matches"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["total_matches"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["start_date"] || fieldSeriesUpdate["start_date"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["start_date"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["end_date"] || fieldSeriesUpdate["end_date"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["end_date"] = "Cannot be empty";
    }
    if (!fieldSeriesUpdate["month_wise"] || fieldSeriesUpdate["month_wise"] === "") {
      formIsValid = false;
      errorsSeriesUpdate["month_wise"] = "Cannot be empty";
    }
    this.setState({ errorsSeriesUpdate: errorsSeriesUpdate });
    return formIsValid;
  }
  handleOpenToViewSeriesModal = (data) => {
    this.setState({ viewSeriesModal: true, rowData: data });
  }
  handleCloseViewSeriesModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ viewSeriesModal: false });
  }
  // inputChangeHandleVeiwMore = (e) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   let fieldSeriesUpdate = this.state.fieldSeriesUpdate;
  //   let errorsSeriesUpdate = this.state.errorsSeriesUpdate;
  //   fieldSeriesUpdate[name] = value;
  //   errorsSeriesUpdate[name] = "";
  //   this.setState({ fieldSeriesUpdate, errorsSeriesUpdate });
  // }
  updateCustomSeriesStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    let resData = data && data.isDisable && data.isDisable ? 'Enable' : 'Disable'

    confirmAlert({

      title: `Confirm to ${resData}?`,
      message: `Are you sure to ${resData} ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(seriesActions.disableVenues(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteCustomSeries = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(seriesActions.deleteVenues(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleMoreDetails = (data) => {
    this.setState({ viewSeriesModal: true, fieldsTeamUpdate: data })

  }

  handleCloseViewSeriesModal = (data) => {
    this.setState({ viewSeriesModal: false, fieldsTeamUpdate: {} })

  }




  onClickMenu = (url) => {
    this.props.history.push(url)
  }






  render() {

    let { series, users, team } = this.props;

    let { filesDetails } = users;
    let { getTeamsList } = team;
    // console.log('RENDER___serieshis.propsthis.propsthis.propsthis.props',this.props.users);

    let { venuesList, venuesTotal } = series;
    // console.log('this.propsthis.propsthis.propsthis.props', this.props.player);
    console.log("RENDER____venuesListvenuesTotalvenuesListvenuesTotal::::", venuesList, venuesTotal);
    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}  
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Venues</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Venues Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className="bg-blue-500 text-white py-2 w-64 text-sm px-6 mt-2 md:mt-0 rounded-md font-medium capitalize flex items-center justify-center " onClick={() => this.onClickMenu('/app/createVenue')}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" /> ADD </button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Venue Id</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Location Id</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              venuesList && venuesList.length > 0 ?
                                venuesList.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.venueId ? element.venueId : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.location ? element.location : "-"}</td>

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleMoreDetails(element)}>More</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdatePlayerModal(element)}>Edit</button>
                                      </div>

                                      {element.isDisable ? <div className='flex items-center p-1'>
                                        <button className='bg-red-600 hover:bg-red-900 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updateCustomSeriesStatus(element)}>Enable</button>
                                      </div>
                                        :
                                        <div className='flex items-center p-1'>
                                          <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updateCustomSeriesStatus(element)}>Disable</button>
                                        </div>}



                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.deleteCustomSeries(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  <AddPlayerDetailsModal
                    fieldsAddUser={this.state.fieldsAddUser}
                    selectedPlayer={this.state.selectedPlayer}
                    addSeriesModal={this.state.addSeriesModal}
                    handleCloseAddCustomSeriesModal={this.handleCloseAddCustomSeriesModal}
                    inputChangeSeriesAdd={this.inputChangeSeriesAdd}
                    handleContentChange={this.handleContentChange}
                    fieldsSeriesAdd={this.state.fieldsSeriesAdd}
                    errorsSeriesAdd={this.state.errorsSeriesAdd}
                    filesDetails={filesDetails}
                    getTeamsList={getTeamsList}
                    handleFile={this.handleFile}
                    handleSelectedPlayer={this.handleSelectedPlayer}
                    customSeriesAddSubmit={this.customSeriesAddSubmit}
                  />
                  <UpdatePlayerDetailsModal
                    updateSeriesModal={this.state.updateSeriesModal}
                    handleCloseUpdateSeriesModal={this.handleCloseUpdateSeriesModal}
                    inputChangeSeriesUpdate={this.inputChangeSeriesUpdate}
                    fieldSeriesUpdate={this.state.fieldSeriesUpdate}
                    errorsSeriesUpdate={this.state.errorsSeriesUpdate}
                    filesDetails={filesDetails}
                    handleFile={this.handleFile}
                    seriesUpdateSubmit={this.seriesUpdateSubmit}
                  />
                  <ViewSeriesDetailsModal
                    viewSeriesModal={this.state.viewSeriesModal}
                    fieldsTeamUpdate={this.state.fieldsTeamUpdate}
                    handleCloseViewSeriesModal={this.handleCloseViewSeriesModal}
                    rowData={this.state.rowData}
                    filesDetails={filesDetails}
                    handleFile={this.handleFile}
                  />

                </div>
              </div>
            </div>
          </main >
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div >

      </>

    );
  }
}
function mapStateToProps(state) {
  const { series, users, team } = state;

  return {
    team,
    series,
    users
  };
}
export default connect(mapStateToProps)(Series);