import React from "react";

import Modal from 'react-modal';


export default function UpdateCategoryModal(props) {

  let { UpdateCategoryCreateModal, handleAppSeetingHideModal, errorsUpdateCategory, inputChangeUpdateCategory, updateCategorySubmit, fieldsCategoryUpdate, handleFile,
    //  allCategory,
      filesDetails,allCategoryParent
  } = props;
  console.log("MODAL__fieldsCategoryUpdate::", fieldsCategoryUpdate);


  return (

    <Modal
      isOpen={UpdateCategoryCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold">Update Category</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleAppSeetingHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="">
              <form autoComplete="off">
                <div class="flex justify-center">
                  <div class="mb-3 w-full">
                    <label className="text-sm font-medium text-black ">Category :</label>
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example"
                      onChange={inputChangeUpdateCategory} id="parentId" name="parentId"
                      value={fieldsCategoryUpdate && fieldsCategoryUpdate.parentId && fieldsCategoryUpdate.parentId.id ? fieldsCategoryUpdate.parentId.id : null} >
                      <option value={"NA"}>Not Select Any Category</option>
                      {
                        allCategoryParent && allCategoryParent && allCategoryParent.length > 0 ?
                          allCategoryParent.map((element, index) => (
                            <option value={element.id}>{element && element.name ? element.name : "NA"}</option>
                          ))
                          : null
                      }
                    </select>
                    {errorsUpdateCategory && errorsUpdateCategory["parentId"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsUpdateCategory["parentId"]}
                      </div>
                      : null}
                  </div>
                </div>


                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label className="mt-2 mr-2 text-sm font-medium text-black">Name :</label>
                    <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdateCategory && !errorsUpdateCategory["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="name" name="name" placeholder="Enter Name" value={fieldsCategoryUpdate.name} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateCategory && errorsUpdateCategory["name"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsUpdateCategory["name"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label className="mt-2 mr-2 text-sm font-medium text-black">Short Name :</label>
                    <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdateCategory && !errorsUpdateCategory["shortName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="shortName" name="shortName" placeholder="Enter Short Name" value={fieldsCategoryUpdate.shortName} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateCategory && errorsUpdateCategory["shortName"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsUpdateCategory["shortName"]}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <label className="mt-2 mr-2 text-sm font-medium text-black">Index Category :</label>
                    <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdateCategory && !errorsUpdateCategory["indexCategory"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                      id="indexCategory" name="indexCategory" placeholder="indexCategory" value={fieldsCategoryUpdate.indexCategory} type="text" onChange={inputChangeUpdateCategory} />
                    {errorsUpdateCategory && errorsUpdateCategory["indexCategory"] ?
                      <div className="text-yellow-600 invalid-feedback">
                        {errorsUpdateCategory["indexCategory"]}
                      </div>
                      : null}
                  </div>
                </div>


                <div className="">
                  <div className="relative mt-1 border rounded-md shadow-sm">
                    {
                      filesDetails && filesDetails.imageURL ?
                        <>
                          <div className="w-full mt-1 mr-2 ">
                            <img src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </>
                        :
                        <>
                          <div className="w-full mt-1 mr-2 ">
                            <img src={fieldsCategoryUpdate && fieldsCategoryUpdate.imageLinkUrl ? fieldsCategoryUpdate.imageLinkUrl : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </>
                    }
                  </div>
                </div>

                <div className="flex flex-wrap w-full ">
                  <label htmlFor="image">Upload Image </label>
                  <input style={{ display: 'none' }} id="image" name="image" type="file" onChange={handleFile} />
                  <p className="pl-2">Note :128 pixels – 128 pixels</p>
                </div>

                <div className="mx-auto mt-6 w-60">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button" onClick={updateCategorySubmit}>Submit</button>
                </div>
              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div >


    </Modal >



  );
}
