import React from "react";
import Modal from 'react-modal';

export default function UpdateMatchModal(props) {
  let { updateMatchModal, handleCloseUpdateMatchModal, inputChangeMatchUpdate, allSeries, allTeam, fieldsMatchUpdate, matchUpdateSubmit, fieldsUpdatePassword } = props;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding leading zero if needed
    let day = date.getDate().toString().padStart(2, "0"); // Adding leading zero if needed
    return `${year}-${month}-${day}`;
  };




  return (

    < >
      <div className={updateMatchModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-2/3 w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">

          {/*Title*/}

          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Update Match Details</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseUpdateMatchModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <div className="p-3">

            <form autoComplete="off" className="space-y-3 capitalize  ">


              <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium">Series:</label>
                  <select class="form-select bg-white w-full px-2  py-[10px] text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  " aria-label="Default select example" id="typeId"
                    name="teamBdetail"
                    value={fieldsMatchUpdate && fieldsMatchUpdate["teamBdetail"] ? fieldsMatchUpdate["teamBdetail"] : ""}
                    onChange={inputChangeMatchUpdate}
                  >
                    <option selected>Plz Select Series</option>
                    {
                      allSeries && allSeries && allSeries.length > 0 ?
                        allSeries.map((element, index) => (
                          <option key={index} name={element && element.series ? element.series : null} value={element && element.id ? element.id : null}>{element && element.series ? element.series : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>

                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Name : </label> &nbsp;
                  <input type="text" name="name" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["name"] ? fieldsMatchUpdate["name"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>
                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Sport Id : </label> &nbsp;
                  <input type="text" name="sportId" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["sportId"] ? fieldsMatchUpdate["sportId"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Series Id : </label> &nbsp;
                  <input type="text" name="seriesId" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["seriesId"] ? fieldsMatchUpdate["seriesId"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class="w-full">
                  <label className="text-black text-sm font-medium ">Match Id : </label> &nbsp;
                  <input type="text" name="matchId" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["matchId"] ? fieldsMatchUpdate["matchId"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>


                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Matchs : </label> &nbsp;
                  <input type="text" name="matchs" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["matchs"] ? fieldsMatchUpdate["matchs"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>

                <div class="w-full">
                  <label className="text-black text-sm font-medium">Open Date : </label> &nbsp;
                  <input
                    type="date"
                    name="openDate"
                    className={`w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["openDate"] ? formatDate(fieldsMatchUpdate["openDate"]) : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>




                {/* <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Match Date : </label> &nbsp;
                  <input type="date" name="match_date" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["match_date"] ? fieldsMatchUpdate["match_date"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div> */}

                <div class="w-full">
                  <label className="text-black text-sm font-medium">Match Date: </label> &nbsp;
                  <input
                    type="date"
                    name="match_date"
                    className={`w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500`}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["match_date"] ? formatDate(fieldsMatchUpdate["match_date"]) : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Match Time : </label> &nbsp;
                  <input type="text" name="match_time" className={` w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["match_time"] ? fieldsMatchUpdate["match_time"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Venue : </label> &nbsp;
                  <input type="text" name="venue" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["venue"] ? fieldsMatchUpdate["venue"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Toss : </label> &nbsp;
                  <input type="text" name="toss" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["toss"] ? fieldsMatchUpdate["toss"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Umpire : </label> &nbsp;
                  <input type="text" name="umpire" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["umpire"] ? fieldsMatchUpdate["umpire"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Third Umpire : </label> &nbsp;
                  <input type="text" name="third_umpire" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["third_umpire"] ? fieldsMatchUpdate["third_umpire"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Referee : </label> &nbsp;
                  <input type="text" name="referee" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["referee"] ? fieldsMatchUpdate["referee"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Man Of Match : </label> &nbsp;
                  <input type="text" name="man_of_match" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["man_of_match"] ? fieldsMatchUpdate["man_of_match"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>



                <div class=" w-full">
                  <label className="text-black text-sm font-medium ">Match Type : </label> &nbsp;
                  <input type="text" name="match_type" className={` w-full p-2  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  `}
                    value={fieldsMatchUpdate && fieldsMatchUpdate["match_type"] ? fieldsMatchUpdate["match_type"] : ""}
                    onChange={inputChangeMatchUpdate}
                  ></input>
                </div>


                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium">Team A :</label>
                  <select class="form-select bg-white  w-full px-2 py-[11px]  text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  " aria-label="Default select example" id="typeId"
                    name="teamA"
                    value={fieldsMatchUpdate && fieldsMatchUpdate["teamA"] ? fieldsMatchUpdate["teamA"] : ""}
                    onChange={inputChangeMatchUpdate}
                  >
                    <option selected>Plz Select Team A</option>
                    {
                      allTeam && allTeam && allTeam.length > 0 ?
                        allTeam.map((element, index) => (
                          <option key={index} name={element && element.name ? element.name : null} value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>

                <div class="mr-2 w-full">
                  <label className="text-black text-sm font-medium">Team B:</label>
                  <select class="form-select bg-white w-full px-2  py-[10px] text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  " aria-label="Default select example" id="typeId"
                    name="teamB"
                    value={fieldsMatchUpdate && fieldsMatchUpdate["teamB"] ? fieldsMatchUpdate["teamB"] : ""}
                    onChange={inputChangeMatchUpdate}
                  >
                    <option selected>Plz Select Team B</option>
                    {
                      allTeam && allTeam && allTeam.length > 0 ?
                        allTeam.map((element, index) => (
                          <option key={index} name={element && element.name ? element.name : null} value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}</option>
                        )) : null
                    }
                  </select>
                </div>
              </div>


              <div className="w-64 pt-2 sm:w-72 mx-auto">

                <button className="bg-[rgb(44,171,181)]  mx-auto flex justify-center py-2.5 uppercase px-8  text-base font-semibold rounded-md text-white focus:outline-none" type="button"
                  onClick={matchUpdateSubmit}
                >
                  Submit</button>
              </div>


            </form>

          </div>

        </div>
      </div>
    </>
  );
}
