import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions, userActions, categoryActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import SendNotification from "../../components/SendNotification/SendNotification";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail, BiSearch, BiBell } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

import "./style.css"


class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsNotification: {},
      errorsNotification: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        // tag: [""]
      },
      errorsAddUser: {
        content: [""],
        // tag: [""]
      },
      fieldsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      errorsUpdatePassword: {
        content: [""],
        // tag: [""]
      },
      viewRowData: {},

      notificationModal: false,
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      newsType: "",
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      newsContent: [],
    };

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];
  }
  componentDidMount() {
    let data = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }
    this.setState({ "newsType": this.props.match.params.newsType })
    this.props.dispatch(newsActions.getNewsListManual(data));
    this.props.dispatch(categoryActions.getAllCategory());

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        notificationModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.news.updateUserSuccess) {
      console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    // if (nextProps.news.notificationSuccess) {
    //   console.log('Called______');
    //   return {
    //     ...nextProps,
    //     fieldsNotification: {},
    //     errorsNotification: {},
    //     notificationModal: false,

    //   }
    // }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }




  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      // "isActive": true
    }
    this.props.dispatch(newsActions.getNewsListManual(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "newsType": "MANUAL",
      "keyWord": value,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      // "isActive": true
    }
    this.props.dispatch(newsActions.getNewsListManual(data));
  }

  disableNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      // "isActive": true
    }
    confirmAlert({


      title: 'Confirm to Disable News?',
      // message: `Are you sure to disable ${data.title} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.disableActiveManualNews(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNewsManual(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/viewdetails/" + data.id)
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.props.history.push("/app/updatenews/" + data.id)
  }



  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(newsActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['news_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addNewsSubmit = () => {
    if (this.handleValidationAddNews()) {
      let reqData = {
        "news_id": this.state.fieldsAddUser.news_id.toString(),
        "title": this.state.fieldsAddUser.title,
        "author": this.state.fieldsAddUser.author,
        // "descirption": this.state.fieldsAddUser.descirption,
        "image": this.state.fieldsAddUser && this.state.fieldsAddUser.image ? this.state.fieldsAddUser.image : null,
        "tag": this.state.selectedTag ? this.state.selectedTag : [],
        // "slug": this.state.fieldsAddUser.slug,
        "category": this.state.fieldsAddUser.category,
        "subcategory": this.state.fieldsAddUser.subcategory,
        "story": this.state.fieldsAddUser.story,
      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqDatareqDatareqData>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(newsActions.createNews(reqData, paginationData));
    }
  }

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //news_id
    if (!fieldsAddUser["news_id"] || fieldsAddUser["news_id"] === "") {
      formIsValid = false;
      errorsAddUser["news_id"] = "Cannot be empty";
    }

    //title
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //author
    if (!fieldsAddUser["author"] || fieldsAddUser["author"] === "") {
      formIsValid = false;
      errorsAddUser["author"] = "Cannot be empty";
    }

    //category
    if (!fieldsAddUser["category"] || fieldsAddUser["category"] === "") {
      formIsValid = false;
      errorsAddUser["category"] = "Cannot be empty";
    }

    //subcategory
    if (!fieldsAddUser["subcategory"] || fieldsAddUser["subcategory"] === "") {
      formIsValid = false;
      errorsAddUser["subcategory"] = "Cannot be empty";
    }

    //story
    if (!fieldsAddUser["story"] || fieldsAddUser["story"] === "") {
      formIsValid = false;
      errorsAddUser["story"] = "Cannot be empty";
    }


    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "news_id": this.state.fieldsUpdatePassword.news_id,
        "title": this.state.fieldsUpdatePassword.title,
        "author": this.state.fieldsUpdatePassword.author,
        "image": this.state.fieldsUpdatePassword && this.state.fieldsUpdatePassword.image ? this.state.fieldsUpdatePassword.image : null,
        "tag": this.state.updateSelectedTag ? this.state.updateSelectedTag : this.state.fieldsUpdatePassword.content,
        "category": this.state.fieldsUpdatePassword.category,
        "subcategory": this.state.fieldsUpdatePassword.subcategory,
        "story": this.state.fieldsUpdatePassword.story,
      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(newsActions.updateNews(reqData, paginationData));
    }
  }

  handleValidationUpdateNews = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //news_id
    if (!fieldsUpdatePassword["news_id"] || fieldsUpdatePassword["news_id"] === "") {
      formIsValid = false;
      errorsUpdatePassword["news_id"] = "Cannot be empty";
    }
    //title
    if (!fieldsUpdatePassword["title"] || fieldsUpdatePassword["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //author
    if (!fieldsUpdatePassword["author"] || fieldsUpdatePassword["author"] === "") {
      formIsValid = false;
      errorsUpdatePassword["author"] = "Cannot be empty";
    }
    //category
    if (!fieldsUpdatePassword["category"] || fieldsUpdatePassword["category"] === "") {
      formIsValid = false;
      errorsUpdatePassword["category"] = "Cannot be empty";
    }
    //subcategory
    if (!fieldsUpdatePassword["subcategory"] || fieldsUpdatePassword["subcategory"] === "") {
      formIsValid = false;
      errorsUpdatePassword["subcategory"] = "Cannot be empty";
    }
    //story
    if (!fieldsUpdatePassword["story"] || fieldsUpdatePassword["story"] === "") {
      formIsValid = false;
      errorsUpdatePassword["story"] = "Cannot be empty";
    }
    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);
    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }



  handleFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {
    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;
    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {
    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;
    this.setState({ fieldsUpdatePassword });
  }

  handleUpload = () => {
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });
  }

  handleUploadImageUser = () => {
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });

  }

  addTagField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }


  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }

  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;

    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }


  handleSelectedTag = (selectedTag) => {


    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {

    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;

    fieldsAddUser["story"] = editor.getHTML();

    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters

    this.setState({ fieldsAddUser });
  };

  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["story"] = editor.getHTML();
    this.setState({ fieldsUpdatePassword });
  };

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  // handleNewsFilter = (e) => {
  //   e.preventDefault();
  //   let { value } = e.target;

  //   this.setState({ newsType: value, });
  //   let data = {
  //     "keyWord": this.state.keyWord,
  //     "newsType": value && value === "ALL" ? "" : value,
  //     // "newsType": value,
  //     "pageNo": this.state.pageNo,
  //     "size": this.state.size
  //   }
  //   this.props.dispatch(newsActions.getNewsListManual(data));


  //   console.log("value___handleNewsFilter::I", value);
  // }


  handleNewsFilter = (e) => {
    e.preventDefault();
    let { value } = e.target;
    this.props.history.push('/app/news/' + value)
    this.setState({ newsType: value });
    // e.preventDefault();
    // let { value } = e.target;

    // this.setState({ newsType: value, });
    // let data = {
    //   "keyWord": this.state.keyWord,
    //   "newsType": value && value === "ALL" ? "" : value,
    //   // "newsType": value,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    // this.props.dispatch(newsActions.getNewsListManual(data));


    // console.log("value___handleNewsFilter::I", value);
  }

  handleNotificationModal = (data) => {
    this.setState({ notificationModal: true, fieldsNotification: data });
  }

  handleNotificationHideModal = () => {
    this.setState({ notificationModal: false });
  }





  inputNotification = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsNotification = this.state.fieldsNotification;
    let errorsNotification = this.state.errorsNotification;
    fieldsNotification[name] = value;
    console.log(name, value);
    errorsNotification[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsNotification, errorsNotification });
  }


  addNotificationSubmit = () => {
    let { users } = this.props;


    let { filesDetails,
    } = users;
    console.log('filesDetails__________________', filesDetails);
    if (this.handleValidationAddNotification()) {
      let reqData = {
        "newsId": this.state.fieldsNotification.id,
        "title": this.state.fieldsNotification.title,
        "body": this.state.fieldsNotification.title,
        "color": "#fff5df",
        // "priority": this.state.fieldsNotification.priority,
        "image": filesDetails && filesDetails.imageURL ? filesDetails.imageURL : this.state.fieldsNotification.imageLinkUrl
      }
      let paginationData = {
        "newsType": "MANUAL",
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size,
      }

      console.log("reqData__createNotification>>>>>>>>>>>", reqData);
      this.props.dispatch(newsActions.createNotificationManual(reqData, paginationData));
    }
  }

  handleValidationAddNotification = () => {
    let fieldsNotification = this.state.fieldsNotification;
    let errorsNotification = {};
    let formIsValid = true;


    //title
    if (!fieldsNotification["title"] || fieldsNotification["title"] === "") {
      formIsValid = false;
      errorsNotification["title"] = "Cannot be empty";
    }

    //body
    // if (!fieldsNotification["body"] || fieldsNotification["body"] === "") {
    //   formIsValid = false;
    //   errorsNotification["body"] = "Cannot be empty";
    // }

    //color
    // if (!fieldsNotification["color"] || fieldsNotification["color"] === "") {
    //   formIsValid = false;
    //   errorsNotification["color"] = "Cannot be empty";
    // }

    //priority
    // if (!fieldsNotification["priority"] || fieldsNotification["priority"] === "") {
    //   formIsValid = false;
    //   errorsNotification["priority"] = "Cannot be empty";
    // }

    console.log("errorsAddUser_errorsAddUser_::::", errorsNotification);

    this.setState({ errorsNotification: errorsNotification });
    return formIsValid;
  }


  render() {

    let { news
      // , category
    } = this.props;
    let { items, total, loading, filesDetails } = news;

    console.log("RENDER_________this.state.fieldsNotification:::", this.state.fieldsNotification);
    // console.log("RENDER_________this.state.pageNo:::", this.state.pageNo);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl">

                <section className="p-6 space-y-6 bg-white border rounded-md">
                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold">News</h3>
                    </div>

                    {/* <div className='flex space-x-6'>
                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>
                      <button className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md" onClick={() => this.onClickMenu('/app/createnews')}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" />  Add News </button>
                    </div> */}




                    <div className='md:flex md:space-x-4 space-x-0'>
                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>
                      <button className="bg-blue-500 text-white py-2 w-64 text-sm px-6 mt-2 md:mt-0 rounded-md font-medium capitalize flex items-center justify-center " onClick={() => this.onClickMenu('/app/createnews')}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" /> Add News </button>
                    </div>





                  </div>

                  <div className="pb-2 mt-4 overflow-hidden">
                    <div className="max-w-full overflow-x-auto">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">NewsType</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">title</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">author</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">newsId</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">tag</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">created At</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">updated At</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">image</th>
                                {/* <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">status</th> */}
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.title} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.title && element.title.length > 50 ? element.title.substring(0, 20) + "..." : element.title}
                                      </td>

                                      <td className="px-6 py-3 text-sm font-small capitalize whitespace-nowrap" title={element.author} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.author && element.author.length > 50 ? element.author.substring(0, 20) + "..." : element.author}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.tag ? element.tag + " " : "NA"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.updatedAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                          <img className="object-cover h-10 rounded-sm w-14" src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="not found" />
                                        </div>
                                      </td>

                                      {/* <td className="px-2 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <label class="flex justify-center items-center cursor-pointer targetablepx-4 tooltip">
                                          <div class="relative" onClick={() => this.disableNews(element)}>
                                            <input type="checkbox" id="toggleB" class="sr-only" />
                                            <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
                                            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                            <span class='tooltip-text bg-pink-600 p-6 -mt-16 text-white text-sm rounded'>Enable/Disable</span>
                                          </div>
                                        </label>
                                      </td> */}

                                      <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex items-center space-x-2'>
                                          <span className="relative ">
                                            <div class="targetablepx-4  rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " title='More Detail' onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span> */}
                                              <BiDetail style={{ fontSize: "2rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4  rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" title='More Detail' onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span> */}

                                              <MdOutlineEdit style={{ fontSize: "2rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4  rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" title='Delete' onClick={() => this.deleteNews(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span> */}
                                              <RiDeleteBinLine style={{ fontSize: "2rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative ">
                                            <div class="targetablepx-4  rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " title='Notification' onClick={() => this.handleNotificationModal(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>Notification</span> */}
                                              <BiBell style={{ fontSize: "2rem" }} />
                                            </div>
                                          </span>

                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>



        <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        />

        <SendNotification
          notificationModal={this.state.notificationModal}
          fieldsNotification={this.state.fieldsNotification}
          errorsNotification={this.state.errorsNotification}
          handleNotificationHideModal={this.handleNotificationHideModal}
          inputNotification={this.inputNotification}
          addNotificationSubmit={this.addNotificationSubmit}
          handleFile={this.handleFile}
          filesDetails={filesDetails}
        />


      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category } = state;
  return {
    news,
    users,
    category
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(News);
