import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerActions, teamsActions, matchMoreDetailActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import LoadingOverlay from 'react-loading-overlay';
import { authHeader } from '../../_helpers';
import Multiselect from 'multiselect-react-dropdown';
import logger from 'redux-logger';
import Ripples from 'react-ripples';
import { RiArrowDropDownLine } from 'react-icons/ri';

class MatchMoreDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sectiontype: '',
      selectedPlayer: {},
      sportAndSeriesId: {},
      rowDetails: {},
      fieldsMatchData: {},
      errorsMatchData: {},
      // teamAdetail: {},
      // teamBdetail: {},
      keyWord: '',
      pageNo: 1,
      size: 10,
      first_circle: '',
      batsman: [
        {
          "name": "Virat",
          "run": 55,
          "ball": 30,
          "fours": "1",
          "sixes": "1",
          "strike_rate": "150.00",
          "playerA": "6475a26cc2c0dad69158f8b9",
          "isStrike": false,
        },
        {
          "name": "Max Holden",
          "run": 25,
          "ball": 13,
          "fours": 0,
          "sixes": 0,
          "strike_rate": "100.00",
          "playerB": "647475adf5b53e778ffeeb29",
          "isStrike": true,
        }
      ],

      currentBatsmanId: "",
      fieldBatsman: "",
      SecStateField: "",
      fastStateField: "",
      fieldBowler: "",
      errorBowler: "",
      activeTab: 1,
      dropdown1: false,
      dropdown2: false,

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.matchMoreDetail.updateUserSuccess) {

      return {
        ...nextProps,
        fieldsMatchInfo: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData ? nextProps.matchMoreDetail.matchInfoData : null,
        // teamAdetail: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.teamAdetail ? nextProps.matchMoreDetail.matchInfoData.teamAdetail : null,
        // teamBdetail: nextProps.matchMoreDetail && nextProps.matchMoreDetail.matchInfoData && nextProps.matchMoreDetail.matchInfoData.teamBdetail ? nextProps.matchMoreDetail.matchInfoData.teamBdetail : null,
        // errorsMatchInfo: {},
      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {

    this.liveMatch();

    // this.props.dispatch(liveMatch());


    let data = {
      "match_id": this.props.match.params.matchId,
      "current_inning": "1"
    }

    let temp = {
      "matchId": this.props.match.params.matchId,
    }
    console.log("temptemptemptemptemptemptemp", temp);
    // this.props.dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: this.props.match.params.matchId }));
    // this.props.dispatch(matchMoreDetailActions.getBattingTeamList(temp));
    // this.props.dispatch(matchMoreDetailActions.getBowlingTeamList(temp));
    // this.props.dispatch(matchMoreDetailActions.currentTeamStatus(data));

  }

  liveMatch = () => {

    // let data = {
    //   "matchId": "55565"
    // }

    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });
    const requestOptions = {
      method: "GET",
      headers: header,
      // body: JSON.stringify(data)
    }
    fetch(`http://38.242.135.119:5059/api/admin/liveMatch?matchId=1312`, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({ fieldsMatchData: result.data });
      }).catch(error => console.log('error_kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', error));

    { { } }

    // var formdata = new FormData();
    // formdata.append("match_id", "3152");

    // var requestOptions = {
    //   method: 'POST',
    //   body: formdata,
    //   redirect: 'follow'
    // };

    // fetch("http://apicricketchampion.in/webservices/liveMatch/9d96312d669cd8bb0aa156a5479c9246", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));


  }

  deleteBatsmam = () => {
    let datatemp = {
      // "match_id": this.props.match.params.matchId,
      // "playerId": this.props.match.params.playerId,
      "match_id": 4141,
      "playerId": "647f1cfeeea9aadcda6a1c85"
    }
    // let pdata = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    console.log("datatempdatatempdatatempdatatemp", datatemp);
    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete Player ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.cancelBatsmanInLiveMatch(datatemp,))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  deleteBowler = () => {
    let datatemp = {
      // "match_id": this.props.match.params.matchId,
      // "playerId": this.props.match.params.playerId,
      "match_id": 4141,
      "playerId": "647f1cfeeea9aadcda6a1c85"
    }
    // let pdata = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": this.state.pageNo,
    //   "size": this.state.size
    // }
    console.log("datatempdatatempdatatempdatatemp", datatemp);
    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete Bowler ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(matchMoreDetailActions.cancelBowlerInLiveMatch(datatemp,))
        },
        {
          label: 'No'
        }
      ]
    });
  }



  handleSelect = (e) => {
    console.log('1111111111111111111111111111111okd', e.target);
    console.log('123456789', e.target.name, e.target.value);
    this.setState({ fastStateField: e.target.value });
    // let temp = {
    //   "matchId": this.props.match.params.matchId,
    //   "matchData": [this.state.fieldBatsman],
    // }
    // console.log("temptemptemptemptemptemp", temp);
    // this.props.dispatch(matchMoreDetailActions.getBattingTeamList(temp))
    // this.props.dispatch(matchMoreDetailActions.saveBatsmanInLiveMatch(temp))
  }


  handleSelect1 = (e) => {
    console.log('1111111111111111111111111111111okd', e.target);
    console.log('1234567890123456789', e.target.name, e.target.value);

    this.setState({ SecStateField: e.target.value });

  }


  handleBowlerSelect = (e) => {
    console.log('45154548????????????????????', e.target);
    console.log('target____________________________', e.target.name, e.target.value);
    this.setState({ fieldBowler: e.target.value });
  }

  submitSave = () => {

    let resData = {
      "match_id": this.props.match.params.matchId,
      "playerId": [this.state.fastStateField ? this.state.fastStateField : null, this.state.SecStateField ? this.state.SecStateField : null],
    }
    console.log("submitSavesubmitSavesubmitSavesubmitSavesubmitSave", resData);
    this.props.dispatch(matchMoreDetailActions.saveBatsmanInLiveMatch(resData))
  }


  bowlerSave = () => {
    // if (this.handleBowlerSelect()) {
    let resData = {
      "match_id": this.props.match.params.matchId,
      "playerId": this.state.fieldBowler ? this.state.fieldBowler : null,
    }
    console.log("submitSavesubmitSavesubmitSavesubmitSavesubmitSave", resData);
    this.props.dispatch(matchMoreDetailActions.saveBowlerInLiveMatch(resData))
    // }
  }

  // handleValidationBowler = () => {
  //   let fieldBowler = this.state.fieldBowler;
  //   let errorBowler = "";
  //   let formIsValid = true;

  //   //tv_id
  //   if (!fieldBowler["playerId"] || fieldBowler["playerId"] === "") {
  //     formIsValid = false;
  //     errorBowler["playerId"] = "Cannot be empty playerId";
  //   }
  //   console.log('errorBowlererrorBowlererrorBowler', errorBowler);

  //   this.setState({ errorBowler: errorBowler });
  //   return formIsValid;
  // }



  updateMatchInfo = () => {
    if (this.handleValidationMatchInfo()) {
      let reqData = {
        "matchId": this.state.fieldsMatchInfo.matchId,
        "tv_id": this.state.fieldsMatchInfo.tv_id,
        "series": this.state.fieldsMatchInfo.series,
        "matchs": this.state.fieldsMatchInfo.matchs,
        "match_time": this.state.fieldsMatchInfo.match_time,
        "venue": this.state.fieldsMatchInfo.venue,
        "toss": this.state.fieldsMatchInfo.toss,
        "umpire": this.state.fieldsMatchInfo.umpire,
        "third_umpire": this.state.fieldsMatchInfo.third_umpire,
        "referee": this.state.fieldsMatchInfo.referee,
        "man_of_match": this.state.fieldsMatchInfo.man_of_match,
        "match_type": this.state.fieldsMatchInfo.match_type,
        "team_a_id": this.state.fieldsMatchInfo.team_a_id,
        "team_a": this.state.fieldsMatchInfo.team_a,
        "team_a_short": this.state.fieldsMatchInfo.team_a_short,
        "team_a_img": this.state.fieldsMatchInfo.team_a_img,
        "team_b_id": this.state.fieldsMatchInfo.team_b_id,
        "team_b": this.state.fieldsMatchInfo.team_b,
        "team_b_short": this.state.fieldsMatchInfo.team_b_short,
        "team_b_img": this.state.fieldsMatchInfo.team_b_img

      }

      console.log('reqDatareqDatareqDatareqDatareqDatareqData??????', reqData);

      this.props.dispatch(matchMoreDetailActions.updateMatchInfo(reqData, this.props.match.params.matchId));
    }
  }

  handleValidationMatchInfo = () => {
    let fieldsMatchInfo = this.state.fieldsMatchInfo;
    let errorsMatchInfo = {};
    let formIsValid = true;

    //tv_id
    if (!fieldsMatchInfo["tv_id"] || fieldsMatchInfo["tv_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["tv_id"] = "Cannot be empty tv_id";
    }
    //series
    if (!fieldsMatchInfo["series"] || fieldsMatchInfo["series"] === "") {
      formIsValid = false;
      errorsMatchInfo["series"] = "Cannot be empty series";
    }
    //matchs
    if (!fieldsMatchInfo["matchs"] || fieldsMatchInfo["matchs"] === "") {
      formIsValid = false;
      errorsMatchInfo["matchs"] = "Cannot be empty matchs";
    }
    //match_time
    if (!fieldsMatchInfo["match_time"] || fieldsMatchInfo["match_time"] === "") {
      formIsValid = false;
      errorsMatchInfo["match_time"] = "Cannot be empty match_time";
    }
    //venue
    if (!fieldsMatchInfo["venue"] || fieldsMatchInfo["venue"] === "") {
      formIsValid = false;
      errorsMatchInfo["venue"] = "Cannot be empty venue";
    }
    //toss
    if (!fieldsMatchInfo["toss"] || fieldsMatchInfo["toss"] === "") {
      formIsValid = false;
      errorsMatchInfo["toss"] = "Cannot be empty toss";
    }
    //umpire
    if (!fieldsMatchInfo["umpire"] || fieldsMatchInfo["umpire"] === "") {
      formIsValid = false;
      errorsMatchInfo["umpire"] = "Cannot be empty umpire";
    }
    //third_umpire
    if (!fieldsMatchInfo["third_umpire"] || fieldsMatchInfo["third_umpire"] === "") {
      formIsValid = false;
      errorsMatchInfo["third_umpire"] = "Cannot be empty third_umpire";
    }
    //referee
    if (!fieldsMatchInfo["referee"] || fieldsMatchInfo["referee"] === "") {
      formIsValid = false;
      errorsMatchInfo["referee"] = "Cannot be empty referee";
    }
    //man_of_match
    if (!fieldsMatchInfo["man_of_match"] || fieldsMatchInfo["man_of_match"] === "") {
      formIsValid = false;
      errorsMatchInfo["man_of_match"] = "Cannot be empty man_of_match";
    }
    //match_type
    if (!fieldsMatchInfo["match_type"] || fieldsMatchInfo["match_type"] === "") {
      formIsValid = false;
      errorsMatchInfo["match_type"] = "Cannot be empty match_type";
    }
    //team_a_id
    if (!fieldsMatchInfo["team_a_id"] || fieldsMatchInfo["team_a_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_id"] = "Cannot be empty team_a_id";
    }
    //team_a
    if (!fieldsMatchInfo["team_a"] || fieldsMatchInfo["team_a"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a"] = "Cannot be empty team_a";
    }
    //team_a_short
    if (!fieldsMatchInfo["team_a_short"] || fieldsMatchInfo["team_a_short"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_short"] = "Cannot be empty team_a_short";
    }
    //team_a_img
    if (!fieldsMatchInfo["team_a_img"] || fieldsMatchInfo["team_a_img"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_a_img"] = "Cannot be empty team_a_img";
    }
    //team_b_id
    if (!fieldsMatchInfo["team_b_id"] || fieldsMatchInfo["team_b_id"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_id"] = "Cannot be empty team_b_id";
    }
    //team_b
    if (!fieldsMatchInfo["team_b"] || fieldsMatchInfo["team_b"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b"] = "Cannot be empty team_b";
    }
    //team_b_short
    if (!fieldsMatchInfo["team_b_short"] || fieldsMatchInfo["team_b_short"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_short"] = "Cannot be empty team_b_short";
    }
    //team_b_img
    if (!fieldsMatchInfo["team_b_img"] || fieldsMatchInfo["team_b_img"] === "") {
      formIsValid = false;
      errorsMatchInfo["team_b_img"] = "Cannot be empty team_b_img";
    }


    console.log('errorsMatchInfoerrorsMatchInfoerrorsMatchInfo', errorsMatchInfo);

    this.setState({ errorsMatchInfo: errorsMatchInfo });
    return formIsValid;
  }


  handleSectionType = (data) => {
    this.setState({ sectiontype: data })
  }


  handleSelectedPlayer = (selectedPlayer) => {
    this.setState({ selectedPlayer });
    console.log("selectedPlayerselectedPlayer  ", selectedPlayer);
  };


  handleRun = (data) => {

    console.log('data???', data);

    switch (data) {
      case 1:

        break;
      case 2:

        break;
      case 3:

        break;
      case 4:

        break;
      case 5:

        break;
      case 6:

        break;

      default:
        break;
    }


    // if (data % 2 === 0) {

    //   this.setState({ first_circle: data })

    // } else {

    //   // let batsman = this.state.batsman;

    //   let batsmanData = this.state.batsman.map((element, index) => ({
    //     "name": element.name,
    //     // "run":  element.run + data,
    //     "run": element.isStrike ? element.run + data : element.run,
    //     "ball": element.ball,
    //     "fours": element.fours,
    //     "sixes": element.sixes,
    //     "strike_rate": element.strike_rate,
    //     "isStrike": !element.isStrike,
    //   }))

    //   console.log('batsmanDatabatsmanDatabatsmanDatabatsmanData', batsmanData);

    //   this.setState({ first_circle: data, batsman: batsmanData })

    // }


  }


  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handledropdown1 = () => {
    this.setState({ dropdown1: true });
  };
  handledropdown2 = () => {
    this.setState({  dropdown2:true});
  };


  render() {

    let { matchMoreDetail } = this.props;
    let { matchInfoData , loading} = matchMoreDetail;

    console.log("RENDER_DATA", matchInfoData);
    const { activeTab } = this.state;


    return (

       <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="bg-[#EEF0F3] "  >
          <div className="2xl:w-5/6 xl:w-5/6  md:w-11/12 w-full md:px-0 px-4 py-7  mx-auto">
            <div className='overflow-x-auto '>
              <div className='border-b w-full text-base capitalize flex font-medium whitespace-nowrap'>


                <div className='flex justify-between pt-2 '>
                  <div className='w-full space-x-5 overflow-hidden overflow-x-auto whitespace-nowrap'>
                    <div className="inline-flex w-full px-1 pt-2 text-sm border-b ">
                      <button className={`px-4 py-2 mr-2 ${activeTab === 1 ? 'py-4 border-b-4 border-b-[#253159] px-8 cursor-pointer' : 'py-4 border-b-4 border-b-transparent px-8 cursor-pointer'}`} onClick={() => this.handleTabChange(1)}>Score</button>
                      <button className={`px-4 py-2 ${activeTab === 2 ? 'py-4 border-b-4 border-b-[#253159] px-8 cursor-pointer' : 'py-4 border-b-4 border-b-transparent px-8 cursor-pointer'}`} onClick={() => this.handleTabChange(2)}>Team</button>
                    </div>
                  </div>
                </div>
              </div>

              {
                this.state.activeTab === 1 ?
                  <>
                    <div className="w-full md:px-0 px-4  mx-auto py-7 space-y-5 bg-[#fff]">
                      <div className='space-y-5 px-6'>
                        <div className='flex justify-between item'>
                          <div className='flex justify-center space-x-5'>
                            <img src="/images/ipl-chennai-super-kings-logo-E534CFAF4A-seeklogo.com.png" alt="" className='w-14 h-14 p-2 rounded-full border ' />
                            <p className='flex justify-center items-center'>RCB</p>
                          </div>
                          <div className='text-[#B0B0B0] translate-y-4'>v</div>
                          <div className='flex justify-center space-x-5'>
                            <p className='flex justify-center items-center'>RCB</p>
                            <img src="/images/ipl-chennai-super-kings-logo-E534CFAF4A-seeklogo.com.png" alt="" className='w-14 h-14 p-2 rounded-full border ' />
                          </div>
                        </div>
                        <div>
                          <div className='flex justify-between items-center bg-[#253159] p-3 rounded text-white'>
                            <p>RCB</p>
                            <p>189(20)</p>
                          </div>
                        </div>
                        <div className='border-1 rounded-lg'>
                          <div className='flex justify-between p-2 text-sm lg:text-lg border-b bg-[#D4D4D8] rounded-t-lg'>
                            <div>
                              <h3>Player:</h3>
                            </div>
                            <div className='flex space-x-7 lg:space-x-16'>
                              <h3>R(B)</h3>
                              <h3>4s</h3>
                              <h3>6s</h3>
                              <h3>SR</h3>
                            </div>
                          </div>
                          <div className='flex justify-between p-2 text-sm lg:text-lg border-b'>
                            <div>
                              <h3>Player:</h3>
                            </div>
                            <div className='flex space-x-7 lg:space-x-16'>
                              <h3>0(1)</h3>
                              <h3>00</h3>
                              <h3>00</h3>
                              <h3>00</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : null
              }

              {
                this.state.activeTab === 2 ?
                  <>
                    <div className="w-full md:px-0 px-4  mx-auto py-7 space-y-5 bg-[#fff]">
                      <div className='space-y-5 px-6'>
                        <div className='flex justify-between item'>
                          <div className='flex justify-center space-x-5'>
                            <img src="/images/ipl-chennai-super-kings-logo-E534CFAF4A-seeklogo.com.png" alt="" className='w-14 h-14 p-2 rounded-full border ' />
                            <p className='flex justify-center items-center'>RCB</p>
                          </div>
                          <div className='text-[#B0B0B0] translate-y-4'>v</div>
                          <div className='flex justify-center space-x-5'>
                            <p className='flex justify-center items-center'>RCB</p>
                            <img src="/images/ipl-chennai-super-kings-logo-E534CFAF4A-seeklogo.com.png" alt="" className='w-14 h-14 p-2 rounded-full border ' />
                          </div>
                        </div>
                        <div className='flex justify-center items-center bg-[#253159] p-3 rounded text-white'>
                          <p>Match Status</p>
                        </div>
                        <div className='border border-black rounded-lg p-2 space-y-2'>
                          <div className='flex justify-between'>
                            <p>Series:</p>
                            <p>Indian Premier League 2023</p>
                          </div>
                        </div>

                    
                      {matchInfoData && matchInfoData.teamA ?
                       <>
                      <div onClick={() => this.handledropdown1()} className='flex justify-between items-center bg-[#253159] font-semibold p-3 rounded text-white'>
                          <p>{matchInfoData.teamA.name}({matchInfoData.teamA.short_name})</p>
                          <p><RiArrowDropDownLine size={35} /></p>
                        </div>

                        {this.state.dropdown1 === true ?
                      <div class="p-3 sm:p-12 bg-white shadow-lg">
                      <div class="flex flex-col  space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                        <img src="https://source.unsplash.com/75x75/?portrait" alt="" class="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start bg-gray-500 border-gray-700" />
                        <div class="flex ">
                          <h4 class="text-lg font-semibold text-center md:text-left">Leroy Jenkins</h4>
                        </div>
                      </div>
                    </div>: null
                        }
                      </>
                      : null}  
                      <>
                      <div onClick={() => this.handledropdown2()} className='flex justify-between items-center bg-[#253159] font-semibold p-3 rounded text-white'>
                          <p>Shri Lanka</p>
                          <p ><RiArrowDropDownLine size={35} /></p>
                        </div>

                        {this.state.dropdown2 === true ?
                          <div class="p-3 sm:p-12 bg-white shadow-lg">
                          <div class="flex flex-col  space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                            <img src="https://source.unsplash.com/75x75/?portrait" alt="" class="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start bg-gray-500 border-gray-700" />
                            <div class="flex ">
                              <h4 class="text-lg font-semibold text-center md:text-left">Leroy Jenkins</h4>
                            </div>
                          </div>
                        </div> : null
                        }
                      </>  
                      </div>
                    </div>
                  </>
                  : null
              }


            </div>
          </div>
        </div>
        {/* <div className='2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto bg-gray-200 object-cover m-6' >
                      <img alt='fiverr' src="/images/blog-cta-ad-specs-reference-guide-b.webp"  width="200%" height="80%" layout="responsive" className='object-cover' unoptimized={true} />
                    </div> */}

      </>

    );
  }
}

function mapStateToProps(state) {
  const { matchMoreDetail } = state;
  // console.log('matchMoreDetail:::::mapStateToProps:', matchMoreDetail);
  return {
    matchMoreDetail
  };
}

export default connect(mapStateToProps)(MatchMoreDetail);
