import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const teamRankingTypeService = {
    login,
    logout,
    getAllTeamRankingType,
    createTeamRankingType,
    updateTeamRankingType,
    getTeamRankingTypeList,
    // getAllCityByStateId,
    deleteTeamRankingType,
    disableTeamRankingType,
    getTeamRankingTypeById,
    getTeamRankingTypeList
};
function logout() {
    // localStorage.removeItem('adminuser');
    // // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getTeamRankingTypeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamRankingTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getTeamRankingTypeList: data.data
            }
            console.log("getTeamRankingTypeList_Data_:::::", userObj);
            return userObj;
        });
}

function getAllTeamRankingType() {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify()
    }
    return fetch(CONST.BACKEND_URL + `/getAllTeamRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllTeamRankingType: data.data
            }
            console.log("i am in service getAllTeamRankingType", userObj);

            return userObj;
        });
}

function deleteTeamRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTeamRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteTeamRankingType: data.data
            }
            console.log("i am in service''...>> deleteTeamRankingType ::", userObj);

            return userObj;
        });
}

function getTeamRankingTypeById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTeamRankingTypeById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getTeamRankingTypeById: data.data
            }
            console.log("i am in service''...>> getTeamRankingTypeById ::", userObj);

            return userObj;
        });
}
function disableTeamRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTeamRankingTypeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateTeamRankingTypeStatus: data.data
            }
            console.log("i am in service''...>> updateTeamRankingTypeStatus ::", userObj);

            return userObj;
        });
}

function updateTeamRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/UpdateTeamRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateTeamRankingType: data.data
            }
            console.log("I am in service updateTeamRankingType", userObj);

            return userObj;
        });
}
function createTeamRankingType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createTeamRankingType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createTeamRankingType: data.data
            }
            console.log("I am in service createTeamRankingType", userObj);

            return userObj;
        });
}

function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}