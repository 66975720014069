export const newsConstants = {

    GETALL_NEWS_REQUEST: 'GETALL_NEWS_REQUEST',
    GETALL_NEWS_SUCCESS: 'GETALL_NEWS_SUCCESS',
    GETALL_NEWS_FAILURE: 'GETALL_NEWS_FAILURE',

    GET_LIST_NEWS_REQUEST: 'GET_LIST_NEWS_REQUEST',
    GET_LIST_NEWS_SUCCESS: 'GET_LIST_NEWS_SUCCESS',
    GET_LIST_NEWS_FAILURE: 'GET_LIST_NEWS_FAILURE',

    UPDATE_VENUES_REQUEST: 'UPDATE_VENUES_REQUEST',
    UPDATE_VENUES_SUCCESS: 'UPDATE_VENUES_SUCCESS',
    UPDATE_VENUES_FAILURE: 'UPDATE_VENUES_FAILURE',

    GET_LIST_NEWS_MANUAL_REQUEST: 'GET_LIST_NEWS_MANUAL_REQUEST',
    GET_LIST_NEWS_MANUAL_SUCCESS: 'GET_LIST_NEWS_MANUAL_SUCCESS',
    GET_LIST_NEWS_MANUAL_FAILURE: 'GET_LIST_NEWS_MANUAL_FAILURE',

    GET_LIST_NEWS_BY_ID_REQUEST: 'GET_LIST_NEWS_BY_ID_REQUEST',
    GET_LIST_NEWS_BY_ID_SUCCESS: 'GET_LIST_NEWS_BY_ID_SUCCESS',
    GET_LIST_NEWS_BY_ID_FAILURE: 'GET_LIST_NEWS_BY_ID_FAILURE',

    GET_VENUES_BY_ID_REQUEST: 'GET_VENUES_BY_ID_REQUEST',
    GET_VENUES_BY_ID_SUCCESS: 'GET_VENUES_BY_ID_SUCCESS',
    GET_VENUES_BY_ID_FAILURE: 'GET_VENUES_BY_ID_FAILURE',

    ADD_NEWS_REQUEST: 'ADD_NEWS_REQUEST',
    ADD_NEWS_SUCCESS: 'ADD_NEWS_SUCCESS',
    ADD_NEWS_FAILURE: 'ADD_NEWS_FAILURE',

    ADD_VENUES_REQUEST: 'ADD_VENUES_REQUEST',
    ADD_VENUES_SUCCESS: 'ADD_VENUES_SUCCESS',
    ADD_VENUES_FAILURE: 'ADD_VENUES_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    DELETE_NEWS_REQUEST: 'DELETE_NEWS_REQUEST',
    DELETE_NEWS_SUCCESS: 'DELETE_NEWS_SUCCESS',
    DELETE_NEWS_FAILURE: 'DELETE_NEWS_FAILURE',

    DELETE_NEWS_MANUAL_REQUEST: 'DELETE_NEWS_MANUAL_REQUEST',
    DELETE_NEWS_MANUAL_SUCCESS: 'DELETE_NEWS_MANUAL_SUCCESS',
    DELETE_NEWS_MANUAL_FAILURE: 'DELETE_NEWS_MANUAL_FAILURE',

    UPDATE_NEWS_REQUEST: 'UPDATE_NEWS_REQUEST',
    UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
    UPDATE_NEWS_FAILURE: 'UPDATE_NEWS_FAILURE',

    UPDATE_NEWS_PTI_REQUEST: 'UPDATE_NEWS_PTI_REQUEST',
    UPDATE_NEWS_PTI_SUCCESS: 'UPDATE_NEWS_PTI_SUCCESS',
    UPDATE_NEWS_PTI_FAILURE: 'UPDATE_NEWS_PTI_FAILURE',

    DISABLE_NEWS_REQUEST: 'DISABLE_NEWS_REQUEST',
    DISABLE_NEWS_SUCCESS: 'DISABLE_NEWS_SUCCESS',
    DISABLE_NEWS_FAILURE: 'DISABLE_NEWS_FAILURE',


    GET_LIST_NOTIFICATION_REQUEST: 'GET_LIST_NOTIFICATION_REQUEST',
    GET_LIST_NOTIFICATION_SUCCESS: 'GET_LIST_NOTIFICATION_SUCCESS',
    GET_LIST_NOTIFICATION_FAILURE: 'GET_LIST_NOTIFICATION_FAILURE',


    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    DISABLE_ACTIVE_PTI_NEWS_REQUEST: 'DISABLE_ACTIVE_PTI_NEWS_REQUEST',
    DISABLE_ACTIVE_PTI_NEWS_SUCCESS: 'DISABLE_ACTIVE_PTI_NEWS_SUCCESS',
    DISABLE_ACTIVE_PTI_NEWS_FAILURE: 'DISABLE_ACTIVE_PTI_NEWS_FAILURE',

    DISABLE_ACTIVE_MANUAL_NEWS_REQUEST: 'DISABLE_ACTIVE_MANUAL_NEWS_REQUEST',
    DISABLE_ACTIVE_MANUAL_NEWS_SUCCESS: 'DISABLE_ACTIVE_MANUAL_NEWS_SUCCESS',
    DISABLE_ACTIVE_MANUAL_NEWS_FAILURE: 'DISABLE_ACTIVE_MANUAL_NEWS_FAILURE',

};
