import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerActions, teamsActions, userActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AddTeamDetailsModal from "./components/CreateTeamModal/CreateTeamModal";
import UpdateTeamModal from "./components/UpdateTeamModal/UpdateTeamModal";
import ViewMoreModal from "./components/ViewMore/viewMoreModel";
import LoadingOverlay from 'react-loading-overlay';

class Team extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},

      keyWord: '',
      pageNo: 1,
      size: 10,

      addTeamModal: false,
      updateTeamModalMore: false,
      fieldsTeamAdd: {},
      errorsTeamAdd: {},

      updateTeamModal: false,
      fieldsTeamUpdate: {},
      errorsTeamUpdate: {},
      selectedPlayer: [],

    }
  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(teamsActions.getTeamsList(data));
    this.props.dispatch(playerActions.getAllPlayerList());
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPlayer,updateTeamModal, fieldsTeamUpdate } = this.state;

    // Check if updateTeamModal is true and fieldsTeamUpdate is not an empty object
    if (selectedPlayer.length==0&&updateTeamModal && Object.keys(fieldsTeamUpdate).length > 0) {
      const selectedPlayers = fieldsTeamUpdate.player.map((player) => ({
        value: player.id,
        label: player.name
      }));

      if (JSON.stringify(selectedPlayers) !== JSON.stringify(prevState.selectedPlayer)) {
        this.setState({ selectedPlayer: selectedPlayers });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.team.addTeamSuccess) {
      return {
        ...nextProps,
        addTeamModal: false,
        fieldsTeamAdd: {},
        errorsTeamAdd: {},


        updateTeamModal: false,
        fieldsTeamUpdate: {},
        errorsTeamUpdate: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  handleOpenToAddTeamModal = () => {
    this.setState({ addTeamModal: true });

    this.props.dispatch(playerActions.getAllPlayerList());
  }


  handleCloseAddTeamModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ addTeamModal: false });
  }
  inputChangeTeamAdd = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTeamAdd = this.state.fieldsTeamAdd;
    let errorsTeamAdd = this.state.errorsTeamAdd;
    fieldsTeamAdd[name] = value;
    errorsTeamAdd[name] = "";
    this.setState({ fieldsTeamAdd, errorsTeamAdd });
  }


  teamAddSubmit = () => {

    let { users } = this.props;
    let { filesDetails,
      //  filesDetailsVideo
    } = users;


    if (this.handleValidationTeamAdd()) {
      let { team } = this.props
      let { fieldsTeamAdd } = team;


      let playerArray = [];

      this.state.selectedPlayer.forEach(element => (
        playerArray.push(
          element.value
        )
      ));

      // console.log('playerArray_playerArray?????', playerArray);

      let resData = {
        "name": this.state.fieldsTeamAdd.name,
        "short_name": this.state.fieldsTeamAdd.short_name,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.filesDetails.image,
        "player": playerArray
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }


      console.log('resData???????????', resData);

      this.props.dispatch(teamsActions.createTeams(resData, data));

    }
  }


  handleValidationTeamAdd = () => {
    let fieldsTeamAdd = this.state.fieldsTeamAdd;
    let errorsTeamAdd = {};
    let formIsValid = true;
    if (!fieldsTeamAdd["name"] || fieldsTeamAdd["name"] === "") {
      formIsValid = false;
      errorsTeamAdd["name"] = "Cannot be empty";
    }
    if (!fieldsTeamAdd["short_name"] || fieldsTeamAdd["short_name"] === "") {
      formIsValid = false;
      errorsTeamAdd["short_name"] = "Cannot be empty";
    }
    console.log('errorsTeamAdderrorsTeamAdderrorsTeamAdderrorsTeamAdderrorsTeamAdd', errorsTeamAdd);
    this.setState({ errorsTeamAdd: errorsTeamAdd });
    return formIsValid;
  }



  handleOpenToUpdateTeamModal = (data) => {
    this.setState({ updateTeamModal: true, fieldsTeamUpdate: data });
  }
  handleCloseUpdateTeamModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ updateTeamModal: false });
  }

  handleOpenToUpdateTeamModalMore = (data) => {
    this.setState({ updateTeamModalMore: true, fieldsTeamUpdate: data });
  }
  handleCloseUpdateTeamModalMore = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ updateTeamModalMore: false });
  }
  inputChangeTeamUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTeamUpdate = this.state.fieldsTeamUpdate;
    let errorsTeamUpdate = this.state.errorsTeamUpdate;
    fieldsTeamUpdate[name] = value;
    errorsTeamUpdate[name] = "";
    this.setState({ fieldsTeamUpdate, errorsTeamUpdate });
  }

  teamUpdateSubmit = (e) => {
 
    
    e.preventDefault();
    if (this.handleValidationTeamUpdate()) {
      let { team, users } = this.props
      let { fieldsTeamUpdate } = team
      let { filesDetails } = users

      let playerArray = [];

      this.state.selectedPlayer.forEach(element => (
        playerArray.push(
          element.value
        )
      ));

      let resData = {
        "id": this.state.fieldsTeamUpdate.id,
        "name": this.state.fieldsTeamUpdate.name,
        "player": playerArray,
        "short_name": this.state.fieldsTeamUpdate.short_name,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsTeamUpdate.image,
      }
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log(resData)
      

      this.props.dispatch(teamsActions.updateTeams(resData, data));
    }
  }
  handleValidationTeamUpdate = () => {
    let fieldsTeamUpdate = this.state.fieldsTeamUpdate;
    let errorsTeamUpdate = {};
    let formIsValid = true;
    if (!fieldsTeamUpdate["name"] || fieldsTeamUpdate["name"] === "") {
      formIsValid = false;
      errorsTeamUpdate["name"] = "Cannot be empty";
    }
    if (!fieldsTeamUpdate["short_name"] || fieldsTeamUpdate["short_name"] === "") {
      formIsValid = false;
      errorsTeamUpdate["short_name"] = "Cannot be empty";
    }
    this.setState({ errorsTeamUpdate: errorsTeamUpdate });
    return formIsValid;
  }


  updateTeamStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Disable?',
      message: `Are you sure to Disable ${data.name} Team ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teamsActions.disableTeams(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  handleOpenToDeleteTeamModal = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} Team ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(teamsActions.deleteTeams(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleSelectedPlayer = (selectedPlayer) => {
console.log(selectedPlayer)

    this.setState({ selectedPlayer });
  };

 handleRemovePlayer = (selectedList, removedItem) => {

    const updatedSelectedPlayers = selectedList.filter(player => player.value !== removedItem.value);

    this.setState({selectedPlayer:updatedSelectedPlayers});
  };



  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  // handleChange = (selectedPlayer) => {
  //   console.log("selectedPlayerselectedPlayer  ", selectedPlayer);
  //   this.setState({ selectedPlayer });
  // };

  render() {
    let { team, player, users } = this.props;
    let { getTeamsList } = team;
    let { getAllPlayer } = player;
    let { filesDetails } = users;
// if(this.state.fieldsTeamUpdate?.player)

    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Teams</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Teams Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={this.inputChange} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddTeamModal()}>ADD</button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2 p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">
                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Short_Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-center text-sm font-semibold text-gray-600 bg-white">Flag</th>
                              {/* <th scope="col" className="whitespace-nowrap p-2 text-lef text-sm font-semibold text-gray-600 bg-white">Image</th> */}
                              {/* <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Image</th> */}
                              <th scope="col" className="whitespace-nowrap p-2 text-center text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getTeamsList && getTeamsList.length > 0 ?
                                getTeamsList.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.short_name ? element.short_name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.name ? element.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap items-center ">
                                      <div className='object-cover h-14 w-14 flex items-center justify-center'>
                                        <img className='h-14 w-14 object-cover' src={element && element.imageLinkUrl ? element.imageLinkUrl : 0} alt="" />
                                      </div>
                                    </td>
                            

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex justify-center space-x-2">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdateTeamModalMore(element)}>More</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdateTeamModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updateTeamStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToDeleteTeamModal(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  <AddTeamDetailsModal
                    addTeamModal={this.state.addTeamModal}
                    handleCloseAddTeamModal={this.handleCloseAddTeamModal}
                    inputChangeTeamAdd={this.inputChangeTeamAdd}
                    teamAddSubmit={this.teamAddSubmit}
                    fieldsTeamAdd={this.state.fieldsTeamAdd}
                    errorsTeamAdd={this.state.errorsTeamAdd}
                    handleFile={this.handleFile}
                    filesDetails={filesDetails}
                    getAllPlayer={getAllPlayer}
                    selectedPlayer={this.state.selectedPlayer}
                    handleSelectedPlayer={this.handleSelectedPlayer}
                  />
                  <UpdateTeamModal
                    updateTeamModal={this.state.updateTeamModal}
                    handleCloseUpdateTeamModal={this.handleCloseUpdateTeamModal}
                    inputChangeTeamUpdate={this.inputChangeTeamUpdate}
                    handleSelectedPlayer={this.handleSelectedPlayer}
                    getAllPlayer={getAllPlayer}
                    selectedPlayer={this.state.selectedPlayer}
                    fieldsTeamUpdate={this.state.fieldsTeamUpdate}
                    errorsTeamUpdate={this.state.errorsTeamUpdate}
                    handleFile={this.handleFile}
                    filesDetails={filesDetails}
                    teamUpdateSubmit={this.teamUpdateSubmit}
                    handleRemovePlayer={this.handleRemovePlayer}
                  />
                  <ViewMoreModal
                    updateTeamModal={this.state.updateTeamModalMore}
                    handleCloseUpdateTeamModal={this.handleCloseUpdateTeamModalMore}
                    inputChangeTeamUpdate={this.inputChangeTeamUpdate}
                    handleSelectedPlayer={this.handleSelectedPlayer}
                    getAllPlayer={getAllPlayer}
                    selectedPlayer={this.state.selectedPlayer}
                    fieldsTeamUpdate={this.state.fieldsTeamUpdate}
                    errorsTeamUpdate={this.state.errorsTeamUpdate}
                    handleFile={this.handleFile}
                    filesDetails={filesDetails}
                    teamUpdateSubmit={this.teamUpdateSubmit}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { team, player, users } = state;
  return {
    team,
    player,
    users,
  };
}
// filesDetails
export default connect(mapStateToProps)(Team);