import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { toast } from 'react-toastify';

//import { history } from '../_helpers';
export const userActions = {
    getTransactions,
    login,
    loginValidateOtp,
    verifyEmail,
    resendVerificationLink,
    createNotification,
    sendFromWithOTP,
    forgotPassword,
    register,
    logout,
    sendFrom,
    sendOtpTX,
    validateId,
    forgotUpdatePassword,
    uploadImage,
    uploadImageClear,
    uploadImageClearSingle,

    addUser,
    statics,
    disableUser,
    updateUser,
    deleteUser,
    getUserDetails,
    changePassword,
    getAllUser,
    getUserList,
    createUser,
    getAllMatchActiveBySportId,
    changeOwnPassword,
    getBets,
    getStatement,
    deposit,
    withdraw,
    getOwnChild,
    getUserDetailsById,
    getAllMatchActive,
    declareResult,
    tossResultDeclare,
    fancyResultDeclare,
    getSelectionByMarketId,
    getPL,
    getMatchByMatchIdV2,
    getUserSetting,
    saveBetData,
    getWorldFancy,
    getUserListByUserType,
    getProfile,
    getAllSport,
    getTossData,
    createSeries,
    getAllSeries,
    getAllMatch,
    getAllMarket,
    getAllFancy,
    createMatch,
    createMarket,
    createTossMarket,
    updateFancyStatus,
    updateMatchStatus,
    createAppSetting,
    updateAppSetting,
    getAppSettingList,
    deleteAppSetting,
    disableAppSetting,
    getMyShare,
    getAllMatchBySportId,
    getAllFancyByMatchId,
    getBetsByMatchId,
    getAllMatchSession,
    upcomingMatches,
    liveMatchList,
    updateMatchfmId,
    getAllActiveFancy
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));

                    console.log("useruseruser ", user);

                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/mainpage`)
                    // history.push({ pathname: '#/app/mainpage' });
                    // window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        dispatch(request());
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                    // dispatch(this.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.FILE_UPLOAD_STATUS_REQUEST } }
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function uploadImageClearSingle() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SINGLE_SUCCESS, uploadImage } }
}
function loginValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.loginValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    console.log("useruseruser ", user);
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/mainpage`)
                    // history.push({ pathname: '#/app/mainpage' });
                    // window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function createAppSetting(data, tempData) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.createAppSetting(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAppSettingList(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_APP_SETTING_FAILURE, error } }
}

function getAllFancyByMatchId(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllFancyByMatchId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_FANCY_BY_MATCH_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_FANCY_BY_MATCH_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_FANCY_BY_MATCH_ID_FAILURE, error } }
}

function getBetsByMatchId(data) {
    return dispatch => {
        dispatch(request());
        userService.getBetsByMatchId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_BETS_BY_MATCH_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_BETS_BY_MATCH_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_BETS_BY_MATCH_ID_FAILURE, error } }
}

function updateAppSetting(data, tempData) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.updateAppSetting(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAppSettingList(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_APP_SETTING_FAILURE, error } }
}

function getAppSettingList(data) {

    console.log("getAppSettingList_________1234:::", data);

    return dispatch => {
        dispatch(request());
        userService.getAppSettingList(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_APP_SETTING_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_APP_SETTING_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_APP_SETTING_LIST_FAILURE, error } }
}

function deleteAppSetting(data, tempData) {

    console.log("deleteAppSetting_________1234:::", data);

    return dispatch => {
        dispatch(request());
        userService.deleteAppSetting(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAppSettingList(tempData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DELETE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.DELETE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DELETE_APP_SETTING_FAILURE, error } }
}

function disableAppSetting(data, tempData) {

    console.log("disableAppSetting_________1234:::", data);

    return dispatch => {
        dispatch(request());
        userService.disableAppSetting(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAppSettingList(tempData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_APP_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.DISABLE_APP_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DISABLE_APP_SETTING_FAILURE, error } }
}

function getMyShare(data, tempData) {

    console.log("disableAppSetting_________1234:::", data);

    return dispatch => {
        dispatch(request());
        userService.getMyShare(data)
            .then(
                users => {
                    console.log("ACTION___ownShare:", users);
                    dispatch(success(users))

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_MY_SHARE_REQUEST } }
    function success(users) { return { type: userConstants.GET_MY_SHARE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_MY_SHARE_FAILURE, error } }
}

function updateMatchStatus(data) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.updateMatchStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatchActive());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_STATUS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_STATUS_FAILURE, error } }
}

function updateMatchfmId(data) {
    console.log("statusmatch::", data);
    return dispatch => {
        dispatch(request());
        userService.updateMatchfmId(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllMatchActive());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_MATCH_FIM_ID_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_MATCH_FIM_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_MATCH_FIM_ID_FAILURE, error } }
}

function getAllSport(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllSport(data)
            .then(
                users => {

                    console.log('I AM in Action getAllSport:', users);

                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_SPORT_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SPORT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_SPORT_FAILURE, error } }
}

function getTossData(data) {
    return dispatch => {
        dispatch(request());
        userService.getTossData(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_TOSS_DATA_REQUEST } }
    function success(users) { return { type: userConstants.GET_TOSS_DATA_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_TOSS_DATA_FAILURE, error } }
}

function createSeries(data) {
    return dispatch => {
        dispatch(request());
        userService.createSeries(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllSeries(data.sportId));
                },
                error => {
                    dispatch(alertActions.error(error));

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_SERIES_REQUEST } }
    function success(users) { return { type: userConstants.ADD_SERIES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_SERIES_FAILURE, error } }
}

function getAllSeries(data) {

    console.log("getAllSeries_________1234:::", data);

    return dispatch => {
        dispatch(request());
        userService.getAllSeries(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_SERIES_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SERIES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_SERIES_FAILURE, error } }
}

function getAllMatch(data) {

    console.log("$$$$$___data120922", data);

    return dispatch => {
        dispatch(request());
        userService.getAllMatch(data)
            .then(
                users => {
                    console.log("ACTION________users::", users);
                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MATCH_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MATCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MATCH_FAILURE, error } }
}

function getAllMarket(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMarket(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_MARKET_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_MARKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_MARKET_FAILURE, error } }
}

function getAllFancy(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllFancy(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_FANCY_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_FANCY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_FANCY_FAILURE, error } }
}


function createMatch(data, tempData) {
    console.log("tempData:::", tempData);
    return dispatch => {
        dispatch(request());
        userService.createMatch(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllMatch(tempData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_MATCH_REQUEST } }
    function success(users) { return { type: userConstants.ADD_MATCH_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_MATCH_FAILURE, error } }
}

function createMarket(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.createMarket(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllMarket(data));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_MARKET_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_MARKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_MARKET_FAILURE, error } }
}

function createTossMarket(data, tempData) {
    return dispatch => {
        dispatch(request());
        userService.createTossMarket(data)
            .then(
                users => {
                    dispatch(success(users))
                    dispatch(this.getAllMarket(data));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_TOSS_MARKET_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_TOSS_MARKET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_TOSS_MARKET_FAILURE, error } }
}

function updateFancyStatus(data) {

    let tempData = {
        marketId: data.marketId
    }

    return dispatch => {
        dispatch(request());
        userService.updateFancyStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllFancy(tempData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_FANCY_STATUS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_FANCY_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_FANCY_STATUS_FAILURE, error } }
}

function forgotPassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotPassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push(`/login`)
                    console.log("user  ", user.userinfo.message);

                    dispatch(alertActions.success(user.userinfo.message));
                    // history.push({ pathname: '#/app/mainpage' });
                    // window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function resendVerificationLink(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.resendVerificationLink(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push(`/login`)
                    console.log("user  ", user.userinfo.message);

                    dispatch(alertActions.success(user.userinfo.message));
                    // history.push({ pathname: '#/app/mainpage' });
                    // window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function register(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.register(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    setTimeout(() => {
                        props.history.push(`/login`)
                    }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function forgotUpdatePassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotUpdatePassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    setTimeout(() => {
                        props.history.push(`/login`)
                    }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getAllUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllUser(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getBets(data) {
    return dispatch => {
        dispatch(request());
        userService.getBets(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_BETS_REQUEST } }
    function success(users) { return { type: userConstants.GET_BETS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_BETS_FAILURE, error } }
}

function getStatement(data) {

    console.log("getStatement_action::", data);

    return dispatch => {
        dispatch(request());
        userService.getStatement(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_STATEMENT_REQUEST } }
    function success(users) { return { type: userConstants.GET_STATEMENT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_STATEMENT_FAILURE, error } }
}

function getOwnChild(data) {

    console.log("getOwnChild_action::", data);

    return dispatch => {
        dispatch(request());
        userService.getOwnChild(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_OWN_CHILD_REQUEST } }
    function success(users) { return { type: userConstants.GET_OWN_CHILD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_OWN_CHILD_FAILURE, error } }
}

function getUserDetailsById(data) {

    console.log("getUserDetailsById_action::", data);

    return dispatch => {
        dispatch(request());
        userService.getUserDetailsById(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_DETAILS_BY_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_DETAILS_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_DETAILS_BY_ID_FAILURE, error } }
}

function deposit(data) {
    // console.log("deposit____________ACTION:::", data);
    return dispatch => {
        dispatch(request());
        userService.deposit(data)
            .then(
                users => {
                    console.log("ACTION___deposit::-----------------------:", users);

                    dispatch(success(users));
                    dispatch(alertActions.success(users.deposit.message));
                    dispatch(getUserDetailsById({ id: data.id }));
                }
                ,
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DEPOSIT_REQUEST } }
    function success(users) { return { type: userConstants.DEPOSIT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DEPOSIT_FAILURE, error } }
}

function withdraw(data) {
    return dispatch => {
        dispatch(request());
        userService.withdraw(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.withdraw.message));
                    dispatch(getUserDetailsById({ id: data.id }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.WITHDRAW_REQUEST } }
    function success(users) { return { type: userConstants.WITHDRAW_SUCCESS, users } }
    function failure(error) { return { type: userConstants.WITHDRAW_FAILURE, error } }
}

function getUserList(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserList(data)
            .then(
                users => {
                    console.log("getUserList___users_Action:::", users);
                    dispatch(success(users))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_LIST_FAILURE, error } }
}

function declareResult(data) {
    return dispatch => {
        dispatch(request());
        userService.declareResult(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DECLARE_RESULT_REQUEST } }
    function success(users) { return { type: userConstants.DECLARE_RESULT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.DECLARE_RESULT_FAILURE, error } }
}

function tossResultDeclare(data) {
    return dispatch => {
        dispatch(request());
        userService.tossResultDeclare(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.TOSS_DECLARE_RESULT_REQUEST } }
    function success(users) { return { type: userConstants.TOSS_DECLARE_RESULT_SUCCESS, users } }
    function failure(error) { return { type: userConstants.TOSS_DECLARE_RESULT_FAILURE, error } }
}

function fancyResultDeclare(data) {
    let temp = {
        "marketId": data.marketId
    }
    return dispatch => {
        dispatch(request());
        userService.fancyResultDeclare(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllActiveFancy(temp))
                }
                ,
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.FANCY_RESULT_DECLARE_REQUEST } }
    function success(users) { return { type: userConstants.FANCY_RESULT_DECLARE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FANCY_RESULT_DECLARE_FAILURE, error } }
}

function getSelectionByMarketId(data) {

    console.log("ACTION_getSelectionByMarketId", data);

    return dispatch => {
        dispatch(request());
        userService.getSelectionByMarketId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_SELECTION_BY_MARKET_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_SELECTION_BY_MARKET_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_SELECTION_BY_MARKET_ID_FAILURE, error } }
}

function getPL() {
    return dispatch => {
        dispatch(request());
        userService.getPL()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PL_REQUEST } }
    function success(users) { return { type: userConstants.GET_PL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PL_FAILURE, error } }
}

function getAllMatchBySportId(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMatchBySportId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_MATCH_BY_SPORTID_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_MATCH_BY_SPORTID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_MATCH_BY_SPORTID_FAILURE, error } }
}

function getAllMatchSession(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllMatchSession(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_MATCH_SESSION_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_MATCH_SESSION_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_MATCH_SESSION_FAILURE, error } }
}

function upcomingMatches() {
    return dispatch => {
        dispatch(request());
        userService.upcomingMatches()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPCOMING_MATCHES_REQUEST } }
    function success(users) { return { type: userConstants.UPCOMING_MATCHES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPCOMING_MATCHES_FAILURE, error } }
}

function liveMatchList() {
    return dispatch => {
        dispatch(request());
        userService.liveMatchList()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.LIVE_MATCHES_REQUEST } }
    function success(users) { return { type: userConstants.LIVE_MATCHES_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LIVE_MATCHES_FAILURE, error } }
}

function getMatchByMatchIdV2(data) {
    return dispatch => {
        dispatch(request());
        userService.getMatchByMatchIdV2(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_MATCH_BY_MATCH_ID_V2_REQUEST } }
    function success(users) { return { type: userConstants.GET_MATCH_BY_MATCH_ID_V2_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_MATCH_BY_MATCH_ID_V2_FAILURE, error } }
}


function getWorldFancy(data) {
    return dispatch => {
        dispatch(request());
        userService.getWorldFancy(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_WORLD_FANCY_REQUEST } }
    function success(users) { return { type: userConstants.GET_WORLD_FANCY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_WORLD_FANCY_FAILURE, error } }
}

function getUserListByUserType(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserListByUserType(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_LIST_BY_USER_TYPE_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_LIST_BY_USER_TYPE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_LIST_BY_USER_TYPE_FAILURE, error } }
}

function getProfile(data) {
    return dispatch => {
        dispatch(request());
        userService.getProfile(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_PROFILE_REQUEST } }
    function success(users) { return { type: userConstants.GET_PROFILE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}

function getUserSetting() {
    return dispatch => {
        dispatch(request());
        userService.getUserSetting()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_SETTING_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_SETTING_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_SETTING_FAILURE, error } }
}

function getAllMatchActive() {
    return dispatch => {
        dispatch(request());
        userService.getAllMatchActive()
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_MATCH_ACTIVE_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_MATCH_ACTIVE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_MATCH_ACTIVE_FAILURE, error } }
}

function getAllActiveFancy(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllActiveFancy(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_ACTIVE_FANCY_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_ACTIVE_FANCY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_ACTIVE_FANCY_FAILURE, error } }
}
function sendFrom(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.sendFrom(data)
            .then(
                user => {
                    dispatch(this.getUserDetails());
                    dispatch(success(user));
                    dispatch(alertActions.error("Send successfully."));
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.SEND_REQUEST, user } }
    function success(user) { return { type: userConstants.SEND_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SEND_FAILURE, error } }
}
function sendFromWithOTP(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.sendFromWithOTP(data)
            .then(
                user => {
                    dispatch(this.getUserDetails());
                    dispatch(this.getTransactions());
                    dispatch(success(user));
                    dispatch(alertActions.error("Send successfully."));
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.SEND_REQUEST, user } }
    function success(user) { return { type: userConstants.SEND_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SEND_FAILURE, error } }
}
function sendOtpTX(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.sendOtpTX(data)
            .then(
                user => {
                    //dispatch(this.getUserDetails());
                    dispatch(success(user));
                    dispatch(alertActions.error(user.userinfo.message));
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.SEND_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.SEND_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SEND_OTP_FAILURE, error } }
}
function createNotification(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.createNotification(data)
            .then(
                user => {
                    dispatch(success(user));
                    //console.log("user  ",user.userinfo.message);
                    dispatch(alertActions.success(user.userinfo.message));
                    setTimeout(() => {
                        props.history.push(`/app/mainpage`);
                    }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function verifyEmail(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.verifyEmail(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.error(user.userinfo.message));
                    //props.history.push(`/login`)
                    //console.log("user.userinfo  ",user.userinfo);
                    setTimeout(() => {
                        props.history.push(`/login`)
                    }, 3000);
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function validateId(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateId(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error("Link is expired"));
                    setTimeout(() => {
                        props.history.push(`/login`)
                    }, 3000);
                }
            );
    };

    function request(users) { return { type: userConstants.TOKEN_REQUEST, users } }
    function success(users) { return { type: userConstants.TOKEN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.TOKEN_FAILURE, error } }
}



function addUser(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.addUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllUser(tempdata));
                    dispatch(alertActions.success("User addded successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}

function getAllMatchActiveBySportId(data) {

    return dispatch => {
        dispatch(request());
        userService.getAllMatchActiveBySportId(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_MATCH_BY_SPORT_ID_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_MATCH_BY_SPORT_ID_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_MATCH_BY_SPORT_ID_FAILURE, error } }
}

function createUser(data, props) {
    // let tempdata = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        userService.createUser(data, props)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(this.getAllUser(tempdata));
                    dispatch(alertActions.success("User addded successfully."));
                    // console.log(`/app/AdminList/` + data.userType);
                    props.history.push(`/app/AdminList/` + data.userType)

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CREATE_USER_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_USER_FAILURE, error } }
}

function disableUser(data) {
    let tempdata = {

        "keyWord": data.keyWord,
        "pageNo": data.page,
        "size": data.size
    }
    let maindata = {
        "id": data.id,
    }
    return dispatch => {
        dispatch(request());
        userService.disableUser(maindata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllUser(tempdata));
                    dispatch(alertActions.success("User status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}


function saveBetData(data, event) {
    let tempdata = {
        "matchId": event
    }
    console.log("ACTION____saveBetData:::", tempdata);
    return dispatch => {
        dispatch(request());
        userService.saveBetData(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMatchByMatchIdV2(tempdata));
                    dispatch(alertActions.success("Save Bet Successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_BET_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_BET_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_BET_FAILURE, error } }
}

function deleteUser(data) {
    let tempdata = {

        "keyWord": data.keyWord,
        "pageNo": data.page,
        "size": data.size
    }
    let maindata = {
        "id": data.id,
    }
    return dispatch => {
        dispatch(request());
        userService.deleteUser(maindata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllUser(tempdata));
                    dispatch(alertActions.success("User deleted successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function getUserDetails() {

    return dispatch => {
        dispatch(request());
        userService.getUserDetails()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_DETAILS_FAILURE, error } }
}

function getTransactions() {

    return dispatch => {
        dispatch(request());
        userService.getTransactions()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_TX_REQUEST } }
    function success(users) { return { type: userConstants.USER_TX_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_TX_FAILURE, error } }
}

function updateUser(mainData, props, userType) {

    return dispatch => {
        dispatch(request());
        userService.updateUser(mainData)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(this.getAllUser(listUserData));
                    dispatch(alertActions.success("User updated successfully."));
                    props.history.push(`/app/AdminList/` + userType)

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}
function changePassword(mainData, props, userType) {

    return dispatch => {
        dispatch(request());
        userService.changePassword(mainData)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Password updated successfully."));
                    props.history.push(`/app/AdminList/` + userType)
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function changeOwnPassword(mainData, props) {

    return dispatch => {
        dispatch(request());
        userService.changeOwnPassword(mainData)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Password updated successfully."));
                    props.history.push(`/app/mainpage`)
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.CHANGE_OWN_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.CHANGE_OWN_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CHANGE_OWN_PASSWORD_FAILURE, error } }
}
function statics(data) {
    return dispatch => {
        dispatch(request());
        userService.statics()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.STATS_REQUEST } }
    function success(users) { return { type: userConstants.STATS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.STATS_FAILURE, error } }
}