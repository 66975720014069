import { userConstants, matchMoreDetailConstants } from '../_constants';

import { userService, matchMoreDetailService } from '../_services';
import { alertActions } from './';
// import { toast } from 'react-toastify';
export const matchMoreDetailActions = {
    // login,
    // logout,
    updateMatch_Status,
    updateMatchInfo,
    disableMatchMoreDetail,
    getAllMatchMoreDetail,
    getBattingTeamList,
    getBowlingTeamList,
    getAllTeams,
    createMatchMoreDetail,
    updateMatchMoreDetail,
    getMatchMoreDetailList,
    deleteMatchMoreDetail,
    uploadImageClear,
    getFancyListForFancyResult,
    updateFancyResultDeclare,
    getMatchDetailsByMatchId,
    cancelBatsmanInLiveMatch,
    saveBatsmanInLiveMatch,
    saveBowlerInLiveMatch,
    cancelBowlerInLiveMatch,
    updateCurrentInnings,
    setCurrentInnings,
    getLiveMatchRun,
    getRollBackLastRunData,
    getLastTwentyBallData,
    getLiveMatchExtraRuns,
    setCurrentTeam,
    updateCurrentTeam,
    manualStrike,
    getBatsmanOrBowlerStatus,
    getMatchInfoById,
    getLiveMatchBatsmanById,
    getLiveMatchBowlerById,

    deleteInning
};


// function login(data, props) {
//     return dispatch => {
//         dispatch(request({ data }));
//         userService.login(data)
//             .then(
//                 user => {
//                     dispatch(success(user));
//                     props.history.push({ pathname: 'app/dashboard' });
//                 },
//                 error => {
//                     console.log("errorerror ", error);
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
//     function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
// }
// function logout() {
//     // userService.logout();
//     return { type: userConstants.LOGOUT };
// }

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createMatchMoreDetail(data, props) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.createMatchMoreDetail(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("MatchMoreDetail Add Successfully."));
                    dispatch(success(users));
                    // dispatch(this.getMatchMoreDetailList(paginationData));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/matchMoreDetail' });


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.ADD_MATCH_MORE_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.ADD_MATCH_MORE_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.ADD_MATCH_MORE_DETAIL_FAILURE, error } }
}











function deleteInning(data, resData) {
    console.log(data)

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.deleteInning(data)
            .then(

                users => {

                    dispatch(alertActions.success("Inning Deleted."));
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));
                    dispatch(matchMoreDetailActions.getLastTwentyBallData(resData));



                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.DELETE_INNING_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.DELETE_INNING_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.DELETE_INNING_FAILURE, error } }
}


function saveBatsmanInLiveMatch(data, props, paginationData) {
    console.log('datadatadatadatadatadatadatadatadatadatadatadatadatasaveBatsmanInLiveMatch', data);

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.saveBatsmanInLiveMatch(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")

                    dispatch(success(users));
                    dispatch(alertActions.success("Batsman Add Successfully."));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));
                    dispatch(this.uploadImageClear());
                    // props.history.push({ pathname: '/app/matchMoreDetail' });


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.ADD_BATSMAN_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.ADD_BATSMAN_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.ADD_BATSMAN_DETAIL_FAILURE, error } }
}


function saveBowlerInLiveMatch(data, props, paginationData) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.saveBowlerInLiveMatch(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Bowler Add Successfully."));
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));
                    // dispatch(matchMoreDetailActions.getMatchDetailsByMatchId(paginationData));
                    dispatch(this.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.ADD_BOWLER_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.ADD_BOWLER_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.ADD_BOWLER_DETAIL_FAILURE, error } }
}
function updateMatchMoreDetail(data, paginationData, props) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.updateMatchMoreDetail(data)
            .then(

                users => {
                    dispatch(alertActions.success("MatchMoreDetail Update Successfully."));
                    dispatch(success(users));
                    // dispatch(this.getMatchMoreDetailList(paginationData));
                    dispatch(this.uploadImageClear());
                    props.history.push({ pathname: '/app/matchMoreDetail' });


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.UPDATE_MATCH_MORE_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.UPDATE_MATCH_MORE_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.UPDATE_MATCH_MORE_DETAIL_FAILURE, error } }
}

function getAllMatchMoreDetail(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getAllMatchMoreDetail(data)
            .then(
                users => {
                    console.log("getAllMatchMoreDetail $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GETALL_MATCH_MORE_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GETALL_MATCH_MORE_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GETALL_MATCH_MORE_DETAIL_FAILURE, error } }
}

function getBattingTeamList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getBattingTeamList(data)
            .then(
                users => {
                    console.log("getBattingTeamList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIST_BATSMAN_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIST_BATSMAN_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIST_BATSMAN_FAILURE, error } }
}
function getMatchInfoById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getMatchInfoById(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_MATCHINFO_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_MATCHINFO_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_MATCHINFO_FAILURE, error } }
}

function getBowlingTeamList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getBowlingTeamList(data)
            .then(
                users => {
                    console.log("getBowlingTeamList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIST_BOWLER_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIST_BOWLER_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIST_BOWLER_FAILURE, error } }
}

function getAllTeams(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getAllTeams(data)
            .then(
                users => {
                    console.log("getAllTeams $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    console.log('errorerrorerrorerrorerrorerror', error);
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIST_TEAM_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIST_TEAM_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIST_TEAM_FAILURE, error } }
}

function updateCurrentInnings(data, matchId) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.updateCurrentInnings(data)
            .then(
                users => {
                    console.log("getBowlingTeamList $$$$ action:", users);
                    dispatch(alertActions.success("Innings Update Successfully."));
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_CURRENT_STATUS_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_CURRENT_STATUS_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_CURRENT_STATUS_FAILURE, error } }
}

function setCurrentTeam(data, matchId, currentInning) {
    console.log(matchId)
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.setCurrentTeam(data)
            .then(
                users => {
                    // console.log("getBowlingTeamList $$$$ action:", users);
                    dispatch(alertActions.success("Team Saved Successfully."));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: matchId?.matchId }));
                    dispatch(success(users));
                    // dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: matchId }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_CURRENT_TEAM_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_CURRENT_TEAM_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_CURRENT_TEAM_FAILURE, error } }
}

function getLiveMatchRun(data,resData) {
    // console.log("data m kya aa rha h::action:::", data);
    console.log(data)
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getLiveMatchRun(data)
            .then(
                users => {
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }))
                    dispatch(matchMoreDetailActions.getLastTwentyBallData(resData))
                    dispatch(alertActions.success("Success"));
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_RUN_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_RUN_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_RUN_FAILURE, error } }
}

function getRollBackLastRunData(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getRollBackLastRunData(data)
            .then(
                users => {
                    console.log("getBowlingTeamList $$$$ action:", users);
                    dispatch(alertActions.success("Success"));
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId(data))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_ROLL_BACK_RUN_DATA_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_ROLL_BACK_RUN_DATA_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_ROLL_BACK_RUN_DATA_FAILURE, error } }
}

function getLiveMatchExtraRuns(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {

        dispatch(request());
        matchMoreDetailService.getLiveMatchExtraRuns(data)
            .then(
                users => {

                    dispatch(alertActions.success("Success"));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }))
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_EXTRA_RUNS_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_EXTRA_RUNS_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_EXTRA_RUNS_FAILURE, error } }
}

function updateCurrentTeam(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.updateCurrentTeam(data)
            .then(
                users => {
                    console.log("getBowlingTeamList $$$$ action:", users);
                    // dispatch(alertActions.success("Team Set Successfully."));
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_AB_TEAM_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_AB_TEAM_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_AB_TEAM_FAILURE, error } }
}


function manualStrike(data) {
    console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.manualStrike(data)
            .then(
                users => {
                    // console.log("getBowlingTeamList $$$$ action:", users);
                    dispatch(alertActions.success("Strike Set Successfully."));
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.matchId }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_MANNUAL_TEAM_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_MANNUAL_TEAM_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_MANNUAL_TEAM_FAILURE, error } }
}

function getBatsmanOrBowlerStatus(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getBatsmanOrBowlerStatus(data)
            .then(
                users => {
                    console.log("getBowlingTeamList $$$$ action:", users);
                    // dispatch(alertActions.success("Team Set Successfully."));
                    dispatch(success(users));
                },
                error => {
                    console.log('errorerrorerrorerror11111111122222222222333333333', error);
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_STATUS_TEAM_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_STATUS_TEAM_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_STATUS_TEAM_FAILURE, error } }
}

function setCurrentInnings(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.setCurrentInnings(data)
            .then(
                users => {
                    console.log("getBowlingTeamList $$$$ action:", users);
                    dispatch(alertActions.success("Innings Save Successfully."));
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_CURRENT_INNIGNS_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_CURRENT_INNIGNS_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_CURRENT_INNIGNS_FAILURE, error } }
}
function getMatchMoreDetailList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getMatchMoreDetailList(data)
            .then(
                users => {
                    console.log("getMatchMoreDetailList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIST_MATCH_MORE_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIST_MATCH_MORE_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIST_MATCH_MORE_DETAIL_FAILURE, error } }
}

function deleteMatchMoreDetail(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.deleteMatchMoreDetail(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMatchMoreDetailList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.DELETE_MATCH_MORE_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.DELETE_MATCH_MORE_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.DELETE_MATCH_MORE_DETAIL_FAILURE, error } }
}


function cancelBatsmanInLiveMatch(data) {
    console.log('datadatadatadatadata122121212121212121222', data);
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.cancelBatsmanInLiveMatch(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Batsman Out Successfully."));
                    // dispatch(this.getMatchMoreDetailList(matchId));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.CANCEL_BATSMEN_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.CANCEL_BATSMEN_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.CANCEL_BATSMEN_DETAIL_FAILURE, error } }
}

function cancelBowlerInLiveMatch(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.cancelBowlerInLiveMatch(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Bowler Delete Successfully."));
                    // dispatch(this.getMatchMoreDetailList(paginationData));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: data.id }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.DELETE_BOWLER_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.DELETE_BOWLER_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.DELETE_BOWLER_DETAIL_FAILURE, error } }
}

function getMatchDetailsByMatchId(data) {

    console.log('ACTION_____getMatchDetailsByMatchId???', data);

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getMatchDetailsByMatchId(data)
            .then(
                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_MATCH_MORE_DETAIL_BY_ID_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_MATCH_MORE_DETAIL_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_MATCH_MORE_DETAIL_BY_ID_FAILURE, error } }
}

function getLastTwentyBallData(data) {

    // console.log('ACTION_____getLastTwentyBallData???', data);

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getLastTwentyBallData(data)
            .then(
                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LAST_TWENTY_BALL_DATA_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LAST_TWENTY_BALL_DATA_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LAST_TWENTY_BALL_DATA_FAILURE, error } }
}

function getFancyListForFancyResult(data) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getFancyListForFancyResult(data)
            .then(
                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_FANCY_LIST_FOR_FANCY_RESULT_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_FANCY_LIST_FOR_FANCY_RESULT_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_FANCY_LIST_FOR_FANCY_RESULT_FAILURE, error } }
}

function updateFancyResultDeclare(data) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.updateFancyResultDeclare(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getFancyListForFancyResult({ matchId: 32183614 }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.UPDATE_FANCY_RESULT_DECLARE_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.UPDATE_FANCY_RESULT_DECLARE_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.UPDATE_FANCY_RESULT_DECLARE_FAILURE, error } }
}
function disableMatchMoreDetail(data, paginationData) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.disableMatchMoreDetail(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getMatchMoreDetailList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.DISABLE_MATCH_MORE_DETAIL_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.DISABLE_MATCH_MORE_DETAIL_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.DISABLE_MATCH_MORE_DETAIL_FAILURE, error } }
}
function updateMatchInfo(data, matchId) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.updateMatchInfo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: matchId }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.UPDATE_MATCH_INFO_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.UPDATE_MATCH_INFO_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.UPDATE_MATCH_INFO_FAILURE, error } }
}

function updateMatch_Status(data, matchId) {

    return dispatch => {
        dispatch(request());
        matchMoreDetailService.updateMatch_Status(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: matchId }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_FAILURE, error } }
}

function getLiveMatchBatsmanById(data) {
    console.log('ACTION______DATA________________:::', data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getLiveMatchBatsmanById(data)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: matchId }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_BATSMAN_BY_ID_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_BATSMAN_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_BATSMAN_BY_ID_FAILURE, error } }
}
function getLiveMatchBowlerById(data) {
    console.log('ACTION______DATA________________:::', data);
    return dispatch => {
        dispatch(request());
        matchMoreDetailService.getLiveMatchBowlerById(data)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(matchMoreDetailActions.getMatchDetailsByMatchId({ matchId: matchId }));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_BOWLER_BY_ID_REQUEST } }
    function success(users) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_BOWLER_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: matchMoreDetailConstants.GET_LIVE_MATCH_BOWLER_BY_ID_FAILURE, error } }
}
