import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';

// import axios from 'axios';
import ImageUploadModal from "./ImageUploadModal";
import { imageActions } from '../../../_actions';


class QuillEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      imageUploadModal: false,
      uploadImageOption: 'selectImage'
    };
    this.quillRef = React.createRef();
  }


  handleContentChange = (value) => {
    this.setState({ content: value });
  };

  handleOpenModalImageUpload = (file) => {
    console.log('file______', file);
    // const formData = new FormData();
    // formData.append('image', file);

    // axios.post('/api/uploadImage', formData).then((response) => {


    this.setState({ imageUploadModal: true })






    /*

    const range = this.quillRef.current.getEditor().getSelection(true);
    //   const imageUrl = response.data.url;

    this.quillRef.current.getEditor().insertEmbed(
      range.index,
      'image',
      "https://www.comfygen.com/img/one-of-the-fastest-way-to-business-growth.webp",
    );


    */




  };
  hideModalImageUpload = () => {
    this.setState({ imageUploadModal: false })
  }

  selectImageUpload = (link) => {
    const range = this.quillRef.current.getEditor().getSelection(true);

    this.quillRef.current.getEditor().insertEmbed(
      range.index,
      'image',
      link,
    );
    this.props.dispatch(imageActions.uploadImageClear());
    this.setState({ imageUploadModal: false })
  }

  modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block'],
      ],
      handlers: {
        image: () => this.handleOpenModalImageUpload(),
      },
    },
  };

  formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'video',
    'code-block',
  ];

  render() {
    return (
      <>

        <ReactQuill
          value={this.state.content}
          onChange={this.handleContentChange}
          modules={this.modules}
          formats={this.formats}
          ref={this.quillRef}
        />
        <ImageUploadModal
          imageUploadModal={this.state.imageUploadModal}
          uploadImageOption={this.state.uploadImageOption}
          hideModalImageUpload={this.hideModalImageUpload}
          selectImageUpload={this.selectImageUpload}
          formats={this.formats}
          ref={this.quillRef}
        />
      </>
    );
  }
}
// export default QuillEditor;

export default connect()(QuillEditor);

