export const teamRankingConstants = {

    GETALL_TEAM_RANKING_REQUEST: 'GETALL_TEAM_RANKING_REQUEST',
    GETALL_TEAM_RANKING_SUCCESS: 'GETALL_TEAM_RANKING_SUCCESS',
    GETALL_TEAM_RANKING_FAILURE: 'GETALL_TEAM_RANKING_FAILURE',

    GET_LIST_TEAM_RANKING_REQUEST: 'GET_LIST_TEAM_RANKING_REQUEST',
    GET_LIST_TEAM_RANKING_SUCCESS: 'GET_LIST_TEAM_RANKING_SUCCESS',
    GET_LIST_TEAM_RANKING_FAILURE: 'GET_LIST_TEAM_RANKING_FAILURE',
    // getTeamRankingListByRankingType
    GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_REQUEST: 'GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_REQUEST',
    GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_SUCCESS: 'GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_SUCCESS',
    GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_FAILURE: 'GET_TEAM_RANKING_LIST_BY_RANKING_TYPE_FAILURE',


    ADD_TEAM_RANKING_REQUEST: 'ADD_TEAM_RANKING_REQUEST',
    ADD_TEAM_RANKING_SUCCESS: 'ADD_TEAM_RANKING_SUCCESS',
    ADD_TEAM_RANKING_FAILURE: 'ADD_TEAM_RANKING_FAILURE',

    DELETE_TEAM_RANKING_REQUEST: 'DELETE_TEAM_RANKING_REQUEST',
    DELETE_TEAM_RANKING_SUCCESS: 'DELETE_TEAM_RANKING_SUCCESS',
    DELETE_TEAM_RANKING_FAILURE: 'DELETE_TEAM_RANKING_FAILURE',

    UPDATE_TEAM_RANKING_REQUEST: 'UPDATE_TEAM_RANKING_REQUEST',
    UPDATE_TEAM_RANKING_SUCCESS: 'UPDATE_TEAM_RANKING_SUCCESS',
    UPDATE_TEAM_RANKING_FAILURE: 'UPDATE_TEAM_RANKING_FAILURE',

    DISABLE_TEAM_RANKING_REQUEST: 'DISABLE_TEAM_RANKING_REQUEST',
    DISABLE_TEAM_RANKING_SUCCESS: 'DISABLE_TEAM_RANKING_SUCCESS',
    DISABLE_TEAM_RANKING_FAILURE: 'DISABLE_TEAM_RANKING_FAILURE',

    GET_TEAM_RANKING_BY_ID_REQUEST: 'GET_TEAM_RANKING_BY_ID_REQUEST',
    GET_TEAM_RANKING_BY_ID_SUCCESS: 'GET_TEAM_RANKING_BY_ID_SUCCESS',
    GET_TEAM_RANKING_BY_ID_FAILURE: 'GET_TEAM_RANKING_BY_ID_FAILURE',

    GET_ALL_CITY_BY_STATE_ID_REQUEST: 'GET_ALL_CITY_BY_STATE_ID_REQUEST',
    GET_ALL_CITY_BY_STATE_ID_SUCCESS: 'GET_ALL_CITY_BY_STATE_ID_SUCCESS',
    GET_ALL_CITY_BY_STATE_ID_FAILURE: 'GET_ALL_CITY_BY_STATE_ID_FAILURE',

};
