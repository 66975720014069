export const seriesConstants = {
    GET_CUSTOME_SERIES_LIST_REQUEST: 'GET_CUSTOME_SERIES_LIST_REQUEST',
    GET_CUSTOME_SERIES_LIST_SUCCESS: 'GET_CUSTOME_SERIES_LIST_SUCCESS',
    GET_CUSTOME_SERIES_LIST_FAILURE: 'GET_CUSTOME_SERIES_LIST_FAILURE',

    GET_ALL_CUSTOM_SERIES_REQUEST: 'GET_ALL_CUSTOM_SERIES_REQUEST',
    GET_ALL_CUSTOM_SERIES_SUCCESS: 'GET_ALL_CUSTOM_SERIES_SUCCESS',
    GET_ALL_CUSTOM_SERIES_FAILURE: 'GET_ALL_CUSTOM_SERIES_FAILURE',

    ADD_CUSTOM_SERIES_REQUEST: 'ADD_CUSTOM_SERIES_REQUEST',
    ADD_CUSTOM_SERIES_SUCCESS: 'ADD_CUSTOM_SERIES_SUCCESS',
    ADD_CUSTOM_SERIES_FAILURE: 'ADD_CUSTOM_SERIES_FAILURE',

    GET_VENUES_LIST_REQUEST: 'GET_VENUES_LIST_REQUEST',
    GET_VENUES_LIST_SUCCESS: 'GET_VENUES_LIST_SUCCESS',
    GET_VENUES_LIST_FAILURE: 'GET_VENUES_LIST_FAILURE',

    DELETE_VENUES_REQUEST: 'DELETE_VENUES_REQUEST',
    DELETE_VENUES_SUCCESS: 'DELETE_VENUES_SUCCESS',
    DELETE_VENUES_FAILURE: 'DELETE_VENUES_FAILURE',

    UPDATE_VENUE_STATUS_REQUEST: 'UPDATE_VENUE_STATUS_REQUEST',
    UPDATE_VENUE_STATUS_SUCCESS: 'UPDATE_VENUE_STATUS_SUCCESS',
    UPDATE_VENUE_STATUS_FAILURE: 'UPDATE_VENUE_STATUS_FAILURE',

    UPDATE_CUSTOM_SERIES_REQUEST: 'UPDATE_CUSTOM_SERIES_REQUEST',
    UPDATE_CUSTOM_SERIES_SUCCESS: 'UPDATE_CUSTOM_SERIES_SUCCESS',
    UPDATE_CUSTOM_SERIES_FAILURE: 'UPDATE_CUSTOM_SERIES_FAILURE',

    UPDATE_CUSTOM_SERIES_STATUS_REQUEST: 'UPDATE_CUSTOM_SERIES_STATUS_REQUEST',
    UPDATE_CUSTOM_SERIES_STATUS_SUCCESS: 'UPDATE_CUSTOM_SERIES_STATUS_SUCCESS',
    UPDATE_CUSTOM_SERIES_STATUS_FAILURE: 'UPDATE_CUSTOM_SERIES_STATUS_FAILURE',

    DELETE_CUSTOM_SERIES_REQUEST: 'DELETE_CUSTOM_SERIES_REQUEST',
    DELETE_CUSTOM_SERIES_SUCCESS: 'DELETE_CUSTOM_SERIES_SUCCESS',
    DELETE_CUSTOM_SERIES_FAILURE: 'DELETE_CUSTOM_SERIES_FAILURE',


};
