import React from "react";

import Modal from 'react-modal';
// import {
//   // Dialog,
//   // AppBar,
//   // Toolbar,
//   // IconButton,
//   // Container,
//   // CssBaseline,
//   // Grid,
//   Typography,
//   Box
// } from "@material-ui/core";
const customStyles = {
  content: {
    background: '(255,255,255,0.005)',
    border: 'none',
  },
};

export default function ViewMoreDetailsModal(props) {

  let { moreDetailsCreateModal, handleMoreDetailsHideModal, imageRowData
  } = props;
  // console.log("imageRowData__MODAL", imageRowData);

  return (

    // <Modal
    //   isOpen={moreDetailsCreateModal}
    //   style={customStyles}
    //   ariaHideApp={false}
    // >
    //   <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
    //     <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
    //       <div className="px-6 py-4 text-left modal-content">
    //         <div className="flex items-center justify-between pb-3">
    //           <p className="text-2xl font-bold">View Ranking</p>
    //           <div className="z-50 cursor-pointer modal-close">
    //             <svg onClick={() => handleMoreDetailsHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
    //               <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
    //               </path>
    //             </svg>
    //           </div>
    //         </div>
    //         <div className="my-5">

    //           <form autoComplete="off">
    //             <div className="{otpSent?'disableArea':''}">
    //               <div className="relative mt-1 shadow-sm">
    //                 <input className="w-full px-12 py-5 text-sm placeholder-gray-500 transition-all duration-150 ease-linear bg-white border border-red-500 rounded shadow placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
    //                   value={imageRowData.name} />
    //               </div>
    //             </div>

    //             <div className="mt-6">
    //               <div className="relative mt-1 rounded-md shadow-sm">
    //                 <input className="w-full px-12 py-5 text-sm placeholder-gray-500 transition-all duration-150 ease-linear bg-white border border-red-500 rounded shadow placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
    //                   value={imageRowData.rating} disabled />

    //               </div>
    //             </div>

    //             <div className="mt-6">
    //               <div className="relative mt-1 rounded-md shadow-sm">
    //                 <input className="w-full px-12 py-5 text-sm placeholder-gray-500 transition-all duration-150 ease-linear bg-white border border-red-500 rounded shadow placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
    //                   value={imageRowData.rank} disabled />

    //               </div>
    //             </div>

    //             <div className="mt-6">
    //               <div className="relative mt-1 rounded-md shadow-sm">

    //                 <div className="flex justify-center">
    //                   <div className="mb-3 xl:w-96">
    //                     <select className="form-select appearance-none block  w-full  px-3  py-1.5 text-base  font-normal text-gray-700  bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300  rounded  transition  ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" >
    //                       <option disabled selected value="" >{imageRowData.type}</option>
    //                     </select>
    //                   </div>
    //                 </div>

    //               </div>
    //             </div>

    //             <div className="mt-6">
    //               <div className="relative mt-1 rounded-md shadow-sm">

    //                 <div className="flex justify-center">
    //                   <div className="mb-3 xl:w-96">
    //                     <select className="form-select appearance-none block  w-full  px-3  py-1.5 text-base  font-normal text-gray-700  bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300  rounded  transition  ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" >
    //                       <option disabled selected>{imageRowData.gameType}</option>
    //                     </select>
    //                   </div>
    //                 </div>

    //               </div>
    //             </div>

    //             <div className="mt-6">
    //               <div className="relative mt-1 rounded-md shadow-sm">
    //                 <div className="flex justify-center">
    //                   <div className="mb-3 xl:w-96">
    //                     <select className="form-select appearance-none block  w-full  px-3  py-1.5 text-base  font-normal text-gray-700  bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300  rounded  transition  ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" >
    //                       <option disabled selected>{imageRowData.rankType}</option>
    //                     </select>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="mt-6">
    //               <div className="relative mt-1 rounded-md shadow-sm">
    //                 <input className="w-full px-12 py-5 text-sm placeholder-gray-500 transition-all duration-150 ease-linear bg-white border border-red-500 rounded shadow placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring"
    //                   value={imageRowData.image} />

    //               </div>
    //             </div>


    //           </form>

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Modal>


    <Modal
      isOpen={moreDetailsCreateModal}
    >

      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between pb-3">
              <p className="text-2xl font-bold">View Image</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleMoreDetailsHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="my-5">

              <form autoComplete="off">
                <div className="{otpSent?'disableArea':''}">
                  <div className="relative mt-1 shadow-sm">
                    <input className="px-12 py-5 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-opacity-100 border-red-500 "
                      value={imageRowData.imageName} type="imageName"
                    />
                    <span className="absolute text-gray-500 top-4 left-5"><svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg></span>
                  </div>
                </div>

                <div className="object-cover h-40 rounded-sm w-40 mt-2">
                  <img src={imageRowData.imageLinkUrl}
                    alt=" " />
                </div>

              </form>

            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>





  );
}
