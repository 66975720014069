import {
  seriesConstants
} from '../_constants';

export function series(state = {}, action) {

  console.log('actionactionactionactionactionactionactionactionactionaction', action);

  switch (action.type) {

    case seriesConstants.GET_CUSTOME_SERIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case seriesConstants.GET_CUSTOME_SERIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        seriesList: action.series.getCustomSeriesList.list,
        seriesTotal: action.series.getCustomSeriesList.total
      };
    case seriesConstants.GET_CUSTOME_SERIES_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case seriesConstants.GET_VENUES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case seriesConstants.GET_VENUES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        venuesList: action.series.getVenuesList.list,
        venuesTotal: action.series.getVenuesList.total
      };
    case seriesConstants.GET_VENUES_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case seriesConstants.GET_ALL_CUSTOM_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case seriesConstants.GET_ALL_CUSTOM_SERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allSeries: action.series.getAllCustomSeries
      };
    case seriesConstants.GET_ALL_CUSTOM_SERIES_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case seriesConstants.UPDATE_CUSTOM_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case seriesConstants.UPDATE_CUSTOM_SERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case seriesConstants.UPDATE_CUSTOM_SERIES_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case seriesConstants.UPDATE_VENUE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case seriesConstants.UPDATE_VENUE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case seriesConstants.UPDATE_VENUE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case seriesConstants.ADD_CUSTOM_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case seriesConstants.ADD_CUSTOM_SERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case seriesConstants.ADD_CUSTOM_SERIES_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case seriesConstants.UPDATE_CUSTOM_SERIES_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case seriesConstants.UPDATE_CUSTOM_SERIES_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case seriesConstants.UPDATE_CUSTOM_SERIES_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case seriesConstants.DELETE_CUSTOM_SERIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case seriesConstants.DELETE_CUSTOM_SERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case seriesConstants.DELETE_CUSTOM_SERIES_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case seriesConstants.DELETE_VENUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case seriesConstants.DELETE_VENUES_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case seriesConstants.DELETE_VENUES_FAILURE:
      return {
        ...state,
        error: action.error
      };


    default:
      return state
  }
}