import React from "react";
import { Route, Switch, withRouter, } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar/Sidebar";
import ActiveMatch from "../../pages/ActiveMatch/ActiveMatch";
import MainPage from "../../pages/MainPage/MainPage";
import Player from "../../pages/Player/Player";
import Teams from "../../pages/Teams/Teams";
import MatchMoreDetail from "../../pages/MatchMoreDetail/MatchMoreDetail";
import CreateNewsModal from "../../pages/news/CreateAddGameModal"
import Match from "../../pages/Match/Match";
import Series from "../../pages/Series/Series";
import CreateUpdatePasswordGame from "../../pages/news/CreateUpdatePasswordGame";
import News from "../../pages/news/news";
import ViewMoreDetailsModal from "../../pages/news/ViewMoreDetailsModal";
import TeamRankType from "../../pages/TeamRankType/TeamRankType";
import LiveMatchScore from "../../pages/LiveMatchScore/LiveMatchScore";
import PlayerRankType from "../../pages/PlayerRankType/PlayerRankType";
import PointsTable from "../../pages/PointsTable/PointsTable";
import CreatePointTable from "../../pages/PointsTable/CreatePointTable";
import UpdatePointTable from "../../pages/PointsTable/UpdatePointTable";
import PlayerRanking from "../../pages/PlayerRanking/PlayerRanking";
import TeamRanking from "../../pages/TeamRanking/TeamRanking";
import FancyResultDeclare from "../../pages/FancyResultDeclare/FancyResultDeclare";
import CreateAddGameModal from "../../pages/news/CreateAddGameModal";
import category from "../../pages/category/category";
import ScoreDesign from "../../pages/LiveMatchScore/ScoreDesign";
import LiveScoreBoard from "../../pages/LiveScoreBoard/LiveScoreBoard";
import MatchInfo from "../../pages/MatchInfo/MatchInfo";
import Image from "../../pages/image/image";

import UpdateBatsmanScore from "../../pages/MatchMoreDetail/updatePages/UpdateBatsmanScore";
import UpdateBowlerScore from "../../pages/MatchMoreDetail/updatePages/UpdateBowlerScore";
import UpdateCommentry from "../../pages/MatchMoreDetail/updatePages/UpdateCommentry";
import UpdateExtraRuns from "../../pages/MatchMoreDetail/updatePages/UpdateExtraRuns";
import UpdateFallOfWicket from "../../pages/MatchMoreDetail/updatePages/UpdateFallOfWicket";
import UpdateMatch from "../../pages/MatchMoreDetail/updatePages/UpdateMatch";
import UpdatePartnership from "../../pages/MatchMoreDetail/updatePages/UpdatePartnership";
import UpdateBallByBall from "../../pages/MatchMoreDetail/updatePages/UpdateBallByBall";
import Venue from "../../pages/Venues/Venues";
import createVenue from "../../pages/Venues/CreateAddVenuesModal"
import updateVenue from "../../pages/Venues/UpdatePasswordVenue"



function Layout(props) {
  return (
    <div >
      <>
        <div className="flex h-screen overflow-hidden ">
          <Sidebar className="lg:w-[30%] md:h-full" />
          <div className="flex flex-col w-full h-screen overflow-y-auto bg-[#f3f3f4] ">
            <Header history={props.history} />
            <Switch>
              <Route path="/app/activeMatch" component={withRouter(ActiveMatch)} />
              <Route path="/app/mainpage" component={withRouter(MainPage)} />
              <Route path="/app/player" component={withRouter(Player)} />
              <Route path="/app/teams" component={withRouter(Teams)} />
              <Route path="/app/match" component={withRouter(Match)} />
              <Route path="/app/series" component={withRouter(Series)} />
              <Route path="/app/Venue" component={withRouter(Venue)} />
              <Route path="/app/createVenue" component={withRouter(createVenue)} />
              <Route path="/app/updateVenue/:id" component={withRouter(updateVenue)} />
              <Route path="/app/news" component={withRouter(News)} />
              <Route path="/app/image" component={withRouter(Image)} />
              <Route path="/app/viewdetails/:id" component={withRouter(ViewMoreDetailsModal)} />
              <Route path="/app/teamrankingtype" component={withRouter(TeamRankType)} />
              <Route path="/app/createnews" component={withRouter(CreateAddGameModal)} />
              <Route path="/app/updatenews/:id" component={withRouter(CreateUpdatePasswordGame)} />
              <Route path="/app/createNewsModal" component={withRouter(CreateNewsModal)} />
              <Route path="/app/category" component={withRouter(category)} />
              <Route path="/app/playerranktype" component={withRouter(PlayerRankType)} />
              <Route path="/app/playerranking" component={withRouter(PlayerRanking)} />
              <Route path="/app/pointtable/:id" component={withRouter(PointsTable)} />
              <Route path="/app/createpointtable/:id" component={withRouter(CreatePointTable)} />
              <Route path="/app/updatepointtable/:seriesId?/:id?" component={withRouter(UpdatePointTable)} />
              <Route path="/app/teamRanking" component={withRouter(TeamRanking)} />
              <Route path="/app/ScoreDesign" component={withRouter(ScoreDesign)} />
              <Route path="/app/matchMoreDetail/:sportId/:seriesId/:matchId/:marketId/:_id/" component={withRouter(MatchMoreDetail)} />
              <Route path="/app/fancyResultDeclare/:sportId/:seriesId/:matchId/:marketId" component={withRouter(FancyResultDeclare)} />
              <Route path="/app/liveMatchScore/:sportId/:seriesId/:matchId/:_id/:marketId" component={withRouter(LiveMatchScore)} />
              <Route path="/app/LiveScoreBoard/:_id" component={withRouter(LiveScoreBoard)} />
              <Route path="/app/matchInfo/:_id" component={withRouter(MatchInfo)} />

              {/* live match score update  */}
              <Route path="/app/updatebatsmanscore/:innings/:matchId/:teamId/" component={withRouter(UpdateBatsmanScore)} />
              <Route path="/app/updatebolwerscore/:innings/:matchId/:teamId/" component={withRouter(UpdateBowlerScore)} />
              <Route path="/app/updatecommentry" component={withRouter(UpdateCommentry)} />
              <Route path="/app/updateextrarun" component={withRouter(UpdateExtraRuns)} />
              <Route path="/app/updatefallofwicket" component={withRouter(UpdateFallOfWicket)} />
              <Route path="/app/updatematch" component={withRouter(UpdateMatch)} />
              <Route path="/app/updatepartnership" component={withRouter(UpdatePartnership)} />
              <Route path="/app/updateballbyball" component={withRouter(UpdateBallByBall)} />

            </Switch>
          </div>
        </div>
      </>
    </div >
  );
}

export default withRouter(Layout);
