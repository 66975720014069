import React, { Component } from 'react';
import { connect } from 'react-redux';
import { playerRankingActions, playerRankTypeActions, playerActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePlayerDetailsModal from "./components/UpdatePlayerDetailsModal/UpdatePlayerDetailsModal";
import AddPlayerDetailsModal from "./components/AddPlayerDetailsModal/AddPlayerDetailsModal";
import ViewPlayerRankingDetailsModal from "./components/ViewPlayerRankingDetailsModal/ViewPlayerRankingDetailsModal";

import LoadingOverlay from 'react-loading-overlay';

class PlayerRanking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      sportId: '',
      seriesId: '',
      matchId: '',
      marketName: '',
      fancyMarketId: '',
      fancyMatchId: '',
      tossMarketId: '',
      tossMatchId: '',
      sportAndSeriesId: {},
      rowDetails: {},
      keyWord: '',
      pageNo: 1,
      size: 10,
      rowData: {},
      // viewPlayerRankModal: '',

      resRankType: false,

      addPlayerModal: false,
      fieldsplayerAdd: {},
      errorsPlayerAdd: {},
      fieldsplayerAdd01: {},

      updatePlayerModal: false,
      fieldsplayerUpdate: {},
      errorsPlayerUpdate: {},
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {


    if (nextProps.playerRankType.addplayersuccess) {

      return {
        ...nextProps,

        resRankType: false,

      }
    }
    if (nextProps.playerRanking.addplayeruccess) {

      return {
        ...nextProps,

        addPlayerModal: false,
        fieldsplayerAdd: {},
        errorsPlayerAdd: {},
        viewPlayerRankModal: false,

        updatePlayerModal: false,
        fieldsplayerUpdate: {},
        errorsPlayerUpdate: {},


      }
    }
    else {
      return {
        ...nextProps
      }
    }

  }

  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(playerRankingActions.getPlayerRankingList(data));
    this.props.dispatch(playerRankTypeActions.getAllPlayerRankingType());
    this.props.dispatch(playerActions.getAllPlayerList());
  }


  handleFilterPlayerRankType = (data) => {
    console.log('datadatadatadatadatadatadatadatadatadatadatadata', data);
    let reqData = {
      keyWord: "",
      pageNo: 1,
      size: 10,
      playerRankingTypeId: data
    }
    console.log('reqData__________,reqData', reqData);
    this.setState({ resRankType: true, });
    this.props.dispatch(playerRankingActions.getPlayerRankingListByPlayerRankingTypeId(reqData));
  }


  handleOpenToViewPlayerRankModal = (data) => {
    this.setState({ viewPlayerRankModal: true, rowData: data });
    // console.log('rowDatarowData__________________________', data);
  }
  handleCloseViewPlayerRankModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ viewPlayerRankModal: false });
  }


  handleOpenToAddPlayerModal = () => {
    this.setState({ addPlayerModal: true });
  }
  handleCloseAddPlayerModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ addPlayerModal: false });
  }
  inputChangePlayerAdd01 = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsplayerAdd = this.state.fieldsplayerAdd;
    let errorsPlayerAdd = this.state.errorsPlayerAdd;
    fieldsplayerAdd[name] = value;
    errorsPlayerAdd[name] = "";
    this.setState({ fieldsplayerAdd, errorsPlayerAdd });
  }
  // inputChangePlayerAdd = (e, selectedOption) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   let fieldsplayerAdd = this.state.fieldsplayerAdd;
  //   let errorsPlayerAdd = this.state.errorsPlayerAdd;
  //   fieldsplayerAdd[name] = value;
  //   errorsPlayerAdd[name] = "";
  //   fieldsplayerAdd["playerRankingTypeId"] = selectedOption.value;
  //   fieldsplayerAdd["playerId"] = selectedOption.value;
  //   this.setState({ fieldsplayerAdd, errorsPlayerAdd });
  // }
  inputChangePlayerAdd = (selectedOption) => {
    let { fieldsplayerAdd } = this.state;
    console.log('selectedOption  ', selectedOption);
    fieldsplayerAdd["playerRankingTypeId"] = selectedOption.value;
    fieldsplayerAdd["playerId"] = selectedOption.value;
    this.setState({ fieldsplayerAdd });
  }


  playerAddSubmit = () => {
    if (this.handleValidationPlayerAdd()) {
      let { player } = this.props
      let { fieldsplayerAdd } = player
      let resData = {
        "playerRankingTypeId": this.state.fieldsplayerAdd.playerRankingTypeId,
        "playerRankingTypeSlug": this.state.fieldsplayerAdd.playerRankingTypeSlug,
        "playerId": this.state.fieldsplayerAdd.playerId,
        "country": this.state.fieldsplayerAdd.country,
        "rank": this.state.fieldsplayerAdd.rank,
        "rating": this.state.fieldsplayerAdd.rating,

      }
      console.log('resDataresDataresData', resData);

      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      this.props.dispatch(playerRankingActions.createPlayerRanking(resData, data));
    }
  }
  handleValidationPlayerAdd = () => {
    let fieldsplayerAdd = this.state.fieldsplayerAdd;
    let errorsPlayerAdd = {};
    let formIsValid = true;

    if (!fieldsplayerAdd["playerRankingTypeId"] || fieldsplayerAdd["playerRankingTypeId"] === "") {
      formIsValid = false;
      errorsPlayerAdd["playerRankingTypeId"] = "Cannot be empty";
    }

    if (!fieldsplayerAdd["playerRankingTypeSlug"] || fieldsplayerAdd["playerRankingTypeSlug"] === "") {
      formIsValid = false;
      errorsPlayerAdd["playerRankingTypeSlug"] = "Cannot be empty";
    }
    if (!fieldsplayerAdd["playerId"] || fieldsplayerAdd["playerId"] === "") {
      formIsValid = false;
      errorsPlayerAdd["playerId"] = "Cannot be empty";
    }
    if (!fieldsplayerAdd["country"] || fieldsplayerAdd["country"] === "") {
      formIsValid = false;
      errorsPlayerAdd["country"] = "Cannot be empty";
    }
    if (!fieldsplayerAdd["rank"] || fieldsplayerAdd["rank"] === "") {
      formIsValid = false;
      errorsPlayerAdd["rank"] = "Cannot be empty";
    }
    if (!fieldsplayerAdd["rating"] || fieldsplayerAdd["rating"] === "") {
      formIsValid = false;
      errorsPlayerAdd["rating"] = "Cannot be empty";
    }

    this.setState({ errorsPlayerAdd: errorsPlayerAdd });
    console.log('errorsPlayerAdderrorsPlayerAdderrorsPlayerAdderrorsPlayerAdderrorsPlayerAdd', errorsPlayerAdd);
    return formIsValid;
  }



  handleOpenToUpdatePlayerModal = (data) => {
    this.setState({ updatePlayerModal: true, fieldsplayerUpdate: data });
  }
  handleCloseUpdatePlayerModal = () => {
    // this.setState({ addplayerSuccess: false });
    this.setState({ updatePlayerModal: false });
  }
  inputChangePlayerUpdate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsplayerUpdate = this.state.fieldsplayerUpdate;
    let errorsPlayerUpdate = this.state.errorsPlayerUpdate;
    fieldsplayerUpdate[name] = value;
    errorsPlayerUpdate[name] = "";
    this.setState({ fieldsplayerUpdate, errorsPlayerUpdate });
  }
  playerUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationPlayerUpdate()) {
      let { player } = this.props
      let { fieldsplayerUpdate } = player
      let resData = {
        "id": this.state.fieldsplayerUpdate.id,
        "playerRankingTypeId": this.state.fieldsplayerUpdate.playerRankingTypeId,
        "playerRankingTypeSlug": this.state.fieldsplayerUpdate.playerRankingTypeSlug,
        "playerId": this.state.fieldsplayerUpdate.playerId,
        "country": this.state.fieldsplayerUpdate.country,
        "rank": this.state.fieldsplayerUpdate.rank,
        "rating": this.state.fieldsplayerUpdate.rating,
      }

      console.log('resDataresDataresData', resData);
      let data = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(playerRankingActions.updatePlayerRanking(resData, data));
    }
  }
  handleValidationPlayerUpdate = () => {
    let fieldsplayerUpdate = this.state.fieldsplayerUpdate;
    let errorsPlayerUpdate = {};
    let formIsValid = true;

    if (!fieldsplayerUpdate["playerRankingTypeId"] || fieldsplayerUpdate["playerRankingTypeId"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["playerRankingTypeId"] = "Cannot be empty";
    }
    if (!fieldsplayerUpdate["playerRankingTypeSlug"] || fieldsplayerUpdate["playerRankingTypeSlug"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["playerRankingTypeSlug"] = "Cannot be empty";
    }
    if (!fieldsplayerUpdate["playerId"] || fieldsplayerUpdate["playerId"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["playerId"] = "Cannot be empty";
    }
    if (!fieldsplayerUpdate["country"] || fieldsplayerUpdate["country"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["country"] = "Cannot be empty";
    }
    if (!fieldsplayerUpdate["rank"] || fieldsplayerUpdate["rank"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["rank"] = "Cannot be empty";
    }
    if (!fieldsplayerUpdate["rating"] || fieldsplayerUpdate["rating"] === "") {
      formIsValid = false;
      errorsPlayerUpdate["rating"] = "Cannot be empty";
    }

    console.log('errorsPlayerUpdateerrorsPlayerUpdateerrorsPlayerUpdate', errorsPlayerUpdate);
    this.setState({ errorsPlayerUpdate: errorsPlayerUpdate });
    return formIsValid;
  }


  updatePlayerRankingStatus = (data) => {
    // console.log("delete function inside data:::", data);
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} Player ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(playerRankingActions.disablePlayerRanking(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deletePlayerRankig = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let pdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name} Player ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(playerRankingActions.deletePlayerRanking(datatemp, pdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  inputChange =(e)=>{

    this.setState({ size:e.target.value }, () => {
      const { keyWord, pageNo, size } = this.state; // Destructure the updated state
      const data = {
        keyWord,
        pageNo,
        size // Use the updated value of size
      };
      this.props.dispatch(playerRankingActions.getPlayerRankingList(data));
    });


   
  }

  render() {

    let { player, playerRanking, playerRankType } = this.props;
    let { rankingItem, rankingTotal, rankingTypeListItem } = playerRanking;
    let { allRankType } = playerRankType
    let { getAllPlayer } = player
    return (

      <>
        {/* <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        <div className="flex flex-col flex-1 overflow-y-auto">
          <main className="relative flex-1 ">
            <div className='py-2'>
              <marquee className="text-white text-sm py-2 bgheader">!! Welcome To Api Provider CP2 !!</marquee>
            </div>
            <div className='w-full bg-white p-4 border-b border-gray-200'>
              <h2 className='text-3xl text-gray-400 font-noraml'>Player Ranking</h2>
            </div>

            <div className='p-6'>
              <div className='w-full flex justify-between bg-white border-b border-t border-gray-200 p-3'>
                <h2 className='text-base font-semibold text_color'>Player Ranking Details</h2>

              </div>
              <div className='bg-white pb-10'>
                <div className='md:flex justify-between px-4 pt-2 md:space-y-0 space-y-4'>
                  <div className='flex items-center space-x-4'>

                    <div className="">
                      <label className='text-sm text-gray-500 mr-2'>Show:</label>
                      <div className="dropdown inline-block relative">
                        <div className="border border-gray-200 w-full py-1.5 inline-flex items-center">
                          <select onChange={(e)=>this.inputChange(e)} className="text-sm bg-white focus:outline-none w-full" value={this.state.size}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between whitespace-nowrap space-x-4'>
                    <div>
                      <label className="mt-1 text-sm text-gray-500 mx-2">Search:</label>
                      <input className=" px-2 py-1 placeholder-gray-600 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none md:w-52   text-gray-700 focus:outline-none focus:border-2 focus:border-green-400 focus:bg-gray-50 focus:text-gray-600 text-sm sm:leading-5" id="exampleSearch2" type="amount" required onChange={this.handleSearch} />
                    </div>
                    <div className='flex items-center p-1'>
                      <button className='bgheader hover:bg-emerald-600 text-white text-sm px-4 py-1.5 rounded-sm' onClick={() => this.handleOpenToAddPlayerModal()}>ADD</button>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg overflow-hidden pb-2  p-6">

                  <div className='flex mb-4 space-x-6 w-full overflow-x-auto px-2'>
                    <div className='flex justify-center items-center space-x-3 pb-2'>
                      {
                        allRankType && allRankType.length > 0 ?
                          allRankType.map((element, index) => (<React.Fragment key={element.id}>
                            <h2 key={element.id} className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer bgheader hover:bg-emerald-600 text-white  px-2 py-1.5 rounded-sm " onClick={() => this.handleFilterPlayerRankType(element.id)}>
                              {/* onClick={() => this.handleFilterPlayerRankType(element && element.name ? element.name : "-")} */}
                              {element && element.name ? element.name : "-"}
                            </h2>
                          </React.Fragment>))
                          : (<tr className="bg-white bg-opacity-5 " >
                            <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                          </tr>)
                      }
                    </div>
                  </div>


                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">
                        <table className="min-w-full divide-y divide-gray-800 border-0">
                          <thead className="rounded-t">



                            <tr className="uppercase">
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-gray-100">#</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Player Name</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rank Type</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rank</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Rating</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Country</th>
                              <th scope="col" className="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-600 bg-white">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              rankingTypeListItem && rankingTypeListItem.length > 0 ?
                                rankingTypeListItem.map((element, index) => (<React.Fragment key={element.id}>
                                  <tr key={element.id} className=" border-b border-black border-opacity-10 ">

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{index + 1}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.playerId && element.playerId.name ? element.playerId.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm tabletext cursor-pointer">{element && element.playerRankingTypeId && element.playerRankingTypeId.name ? element.playerRankingTypeId.name : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.rank ? element.rank : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.rating ? element.rating : "-"}</td>
                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600">{element && element.country ? element.country : "-"}</td>

                                    <td className="p-2 whitespace-nowrap text-sm text-gray-600 flex">

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToViewPlayerRankModal(element)}>More</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.handleOpenToUpdatePlayerModal(element)}>Edit</button>
                                      </div>

                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.updatePlayerRankingStatus(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                      </div>



                                      <div className='flex items-center p-1'>
                                        <button className='bgheader hover:bg-emerald-600 text-white text-sm px-2 py-1.5 rounded-sm' onClick={() => this.deletePlayerRankig(element)}>Delete</button>
                                      </div>

                                    </td>
                                  </tr>

                                </React.Fragment>))
                                : (<tr className="bg-white bg-opacity-5 " >
                                  <td className="col-span-8 px-6 py-2 whitespace-nowrap font-medium text-sm text-gray-600">Record Not Found</td>
                                </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}

                  <AddPlayerDetailsModal
                    addPlayerModal={this.state.addPlayerModal}
                    allRankType={allRankType}
                    getAllPlayer={getAllPlayer}
                    handleCloseAddPlayerModal={this.handleCloseAddPlayerModal}
                    inputChangePlayerAdd={this.inputChangePlayerAdd}
                    inputChangePlayerAdd01={this.inputChangePlayerAdd01}
                    inputChangePlayerType={this.inputChangePlayerType}
                    fieldsplayerAdd={this.state.fieldsplayerAdd}
                    fieldsplayerAdd01={this.state.fieldsplayerAdd01}
                    errorsPlayerAdd={this.state.errorsPlayerAdd}
                    handleFile={this.handleFile}
                    playerAddSubmit={this.playerAddSubmit}
                  />
                  <UpdatePlayerDetailsModal
                    updatePlayerModal={this.state.updatePlayerModal}
                    allRankType={allRankType}
                    getAllPlayer={getAllPlayer}
                    handleCloseUpdatePlayerModal={this.handleCloseUpdatePlayerModal}
                    inputChangePlayerUpdate={this.inputChangePlayerUpdate}
                    fieldsplayerUpdate={this.state.fieldsplayerUpdate}
                    errorsPlayerUpdate={this.state.errorsPlayerUpdate}
                    handleFile={this.handleFile}
                    playerUpdateSubmit={this.playerUpdateSubmit}
                  />

                  <ViewPlayerRankingDetailsModal
                    viewPlayerRankModal={this.state.viewPlayerRankModal}
                    handleCloseViewPlayerRankModal={this.handleCloseViewPlayerRankModal}
                    rowData={this.state.rowData}
                    handleFile={this.handleFile}
                  />

                </div>
              </div>
            </div>
          </main>
          <div className="bg-white py-3 border-t mt-10">
            <h3 className=" text-sm ml-3 text-[#676a6c]"> <strong>API PROVIDER CP2</strong> | Powered By API PROVIDER CP2 Gaming | Copyright © 2014-2022</h3>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  const { playerRanking, player, playerRankType } = state;

  console.log('player:::::mapStateToProps:', playerRanking, player);

  return {
    playerRanking,
    player,
    playerRankType,
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(PlayerRanking);