export const teamsConstants = {

    GETALL_TEAMS_REQUEST: 'GETALL_TEAMS_REQUEST',
    GETALL_TEAMS_SUCCESS: 'GETALL_TEAMS_SUCCESS',
    GETALL_TEAMS_FAILURE: 'GETALL_TEAMS_FAILURE',

    GET_LIST_TEAMS_REQUEST: 'GET_LIST_TEAMS_REQUEST',
    GET_LIST_TEAMS_SUCCESS: 'GET_LIST_TEAMS_SUCCESS',
    GET_LIST_TEAMS_FAILURE: 'GET_LIST_TEAMS_FAILURE',

    ADD_TEAMS_REQUEST: 'ADD_TEAMS_REQUEST',
    ADD_TEAMS_SUCCESS: 'ADD_TEAMS_SUCCESS',
    ADD_TEAMS_FAILURE: 'ADD_TEAMS_FAILURE',

    DELETE_TEAMS_REQUEST: 'DELETE_TEAMS_REQUEST',
    DELETE_TEAMS_SUCCESS: 'DELETE_TEAMS_SUCCESS',
    DELETE_TEAMS_FAILURE: 'DELETE_TEAMS_FAILURE',

    UPDATE_TEAMS_REQUEST: 'UPDATE_TEAMS_REQUEST',
    UPDATE_TEAMS_SUCCESS: 'UPDATE_TEAMS_SUCCESS',
    UPDATE_TEAMS_FAILURE: 'UPDATE_TEAMS_FAILURE',

    DISABLE_TEAMS_REQUEST: 'DISABLE_TEAMS_REQUEST',
    DISABLE_TEAMS_SUCCESS: 'DISABLE_TEAMS_SUCCESS',
    DISABLE_TEAMS_FAILURE: 'DISABLE_TEAMS_FAILURE',

    GET_TEAMS_BY_ID_REQUEST: 'GET_TEAMS_BY_ID_REQUEST',
    GET_TEAMS_BY_ID_SUCCESS: 'GET_TEAMS_BY_ID_SUCCESS',
    GET_TEAMS_BY_ID_FAILURE: 'GET_TEAMS_BY_ID_FAILURE',

    GET_ALL_CITY_BY_STATE_ID_REQUEST: 'GET_ALL_CITY_BY_STATE_ID_REQUEST',
    GET_ALL_CITY_BY_STATE_ID_SUCCESS: 'GET_ALL_CITY_BY_STATE_ID_SUCCESS',
    GET_ALL_CITY_BY_STATE_ID_FAILURE: 'GET_ALL_CITY_BY_STATE_ID_FAILURE',

};
