import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions, userActions, matchMoreDetailActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import SendNotification from "../../components/SendNotification/SendNotification";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiArrowDropDownLine } from 'react-icons/ri';

import "./style.css"


class LiveScoreBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsNotification: {},
      activeTab: 1,
      dropdown1: false,
      dropdown2: false,

    };


  }
  componentDidMount() {
    let data = {
      "matchId": this.props.match.params._id,
      // "matchId": "64844bea3b574f8a6243ecba",
    }
   console.log("datadatadatadatadatadatadata", data);
    // this.props.dispatch(matchMoreDetailActions.getBatsmanOrBowlerStatus(data));

    this.interval = setInterval(() => {
      this.callAPI();
    }, 1000);
  }

  callAPI() {
    let data = {
      "matchId": this.props.match.params._id,
      // "matchId": "64844bea3b574f8a6243ecba",
    }
    this.props.dispatch(matchMoreDetailActions.getMatchDetailsByMatchId(data));
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.interval);
  }


  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handledropdown1 = () => {
    this.setState({ dropdown1: !this.state.dropdown1 });
  };
  handledropdown2 = () => {
    this.setState({
      // dropdown2: true 
      dropdown2: !this.state.dropdown2
    });
  };




  render() {

    let { matchMoreDetail } = this.props;
    let { matchInfoData, loading , allTeamStatus} = matchMoreDetail;
    const { activeTab } = this.state;
    console.log("RENDER_DATA", matchInfoData);
    console.log("allTeamStatusallTeamStatusallTeamStatusallTeamStatus", allTeamStatus);

    return (



      <>
        {/* <div>
        <LoadingOverlay
          active={loading}
          className="temp001"
          spinner
          text='Please wait...' />
      </div> */}

        <div className="bg-[#EEF0F3] "  >
          <div className="2xl:w-5/6 xl:w-5/6  md:w-11/12 w-full md:px-0 px-4 py-7  mx-auto">
            <div className='overflow-x-auto '>
              <div className='border-b w-full text-base capitalize flex font-medium whitespace-nowrap'>


                <div className='flex justify-between pt-2 '>
                  <div className='w-full space-x-5 overflow-hidden overflow-x-auto whitespace-nowrap'>
                    <div className="inline-flex w-full px-1 pt-2 text-sm border-b ">
                      <button className={`px-4 py-2 mr-2 ${activeTab === 1 ? 'py-4 border-b-4 border-b-[rgb(31,171,181)] px-8 cursor-pointer' : 'py-4 border-b-4 border-b-transparent px-8 cursor-pointer'}`} onClick={() => this.handleTabChange(1)}>Score</button>
                      <button className={`px-4 py-2 ${activeTab === 2 ? 'py-4 border-b-4 border-b-[rgb(31,171,181)] px-8 cursor-pointer' : 'py-4 border-b-4 border-b-transparent px-8 cursor-pointer'}`} onClick={() => this.handleTabChange(2)}>Squad</button>
                      <button className={`px-4 py-2 ${activeTab === 3 ? 'py-4 border-b-4 border-b-[rgb(31,171,181)] px-8 cursor-pointer' : 'py-4 border-b-4 border-b-transparent px-8 cursor-pointer'}`} onClick={() => this.handleTabChange(3)}>Team Status</button>
                    </div>
                  </div>
                </div>
              </div>

              {
                this.state.activeTab === 1 ?
                  <>
                    <div className="w-full md:px-0 px-4  mx-auto py-7 space-y-5 bg-[#fff]">
                      <div className='space-y-5 px-6'>
                        <div className='flex justify-between item'>
                          <div className='flex justify-center space-x-5'>
                            <img src={matchInfoData && matchInfoData.teamA && matchInfoData.teamA.imageLinkUrl ? matchInfoData.teamA.imageLinkUrl : null} alt="" className='w-14 h-14 p-2 rounded-full border ' />
                            <p className='flex justify-center items-center'>{matchInfoData && matchInfoData.teamA && matchInfoData.teamA.name ? matchInfoData.teamA.name : null}({matchInfoData && matchInfoData.teamA && matchInfoData.teamA.short_name ? matchInfoData.teamA.short_name : null})</p>
                          </div>
                          <div className='text-[#B0B0B0] translate-y-4'>v</div>
                          <div className='flex justify-center space-x-5'>
                            <p className='flex justify-center items-center'>{matchInfoData && matchInfoData.teamB && matchInfoData.teamB.name ? matchInfoData.teamB.name : null}({matchInfoData && matchInfoData.teamB && matchInfoData.teamB.short_name ? matchInfoData.teamB.short_name : null})</p>
                            <img src={matchInfoData && matchInfoData.teamA && matchInfoData.teamA.imageLinkUrl ? matchInfoData.teamA.imageLinkUrl : null} alt="" className='w-14 h-14 p-2 rounded-full border ' />
                          </div>
                        </div>
                        <div>
                          <div className='flex justify-between items-center bg-[rgb(31,171,181)] px-4 py-2 rounded font-semibold text-sm xl:text-lg text-white'>
                            <p>{matchInfoData && matchInfoData.currentTeamType && matchInfoData.currentTeamType == 'A' ? matchInfoData.teamA.name : matchInfoData && matchInfoData.currentTeamType && matchInfoData.currentTeamType == 'B' ? matchInfoData.teamB.name : null}</p>
                            <p>
                              {matchInfoData && matchInfoData.currentTeamType && matchInfoData.currentTeamType == 'A' ?
                                <>
                                  <p> Run :
                                    {matchInfoData && matchInfoData.teamAScore && matchInfoData.teamAScore[matchInfoData.currentInning + ""] ? matchInfoData.teamAScore[matchInfoData.currentInning + ""]['run'] : '0'}
                                  </p>
                                  <p> Ball:
                                    {matchInfoData && matchInfoData.teamAScore && matchInfoData.teamAScore[matchInfoData.currentInning + ""] ? (matchInfoData.teamAScore[matchInfoData.currentInning + ""]['over']) : '0'}
                                  </p>
                                </>
                                : matchInfoData && matchInfoData.currentTeamType && matchInfoData.currentTeamType == 'B' ?
                                  <>
                                    <p> Run :
                                      {matchInfoData && matchInfoData.teamBScore && matchInfoData.teamBScore[matchInfoData.currentInning + ""] ? matchInfoData.teamBScore[matchInfoData.currentInning + ""]['run'] : '0'}
                                    </p>
                                    <p> Ball:
                                      {matchInfoData && matchInfoData.teamBScore && matchInfoData.teamBScore[matchInfoData.currentInning + ""] ? (matchInfoData.teamBScore[matchInfoData.currentInning + ""]['over']) : '0'}
                                    </p>
                                  </>
                                  : null
                              }
                            </p>

                          </div>
                        </div>
                        <div className=''>
                          <table className="w-full table">
                            <thead className='p-2 text-sm lg:text-lg w-full'>
                              <tr className='w-full'>
                                <th colSpan={4}>Current Batsman:</th>
                                <th>R(B)</th>
                                <th>4s</th>
                                <th>6s</th>
                                <th>SR</th>
                                <th>Batsman Strike</th>
                              </tr>
                            </thead>
                            {matchInfoData && matchInfoData.batsman ?
                              <tbody >
                                <>  {matchInfoData.batsman.map((element) => (
                                  <tr>
                                    <td colSpan={4}>{element && element.playerId && element.playerId.name ? element.playerId.name : null}</td>
                                    <td>{element && element.run ? element.run : "00"}({element && element.ball ? element.ball : "00"})</td>
                                    <td>{element && element.fours ? element.fours : "00"}</td>
                                    <td>{element && element.sixes ? element.sixes : "00"}</td>
                                    <td>{element && element.strike_rate ? (element.strike_rate).toFixed(2) : "00"}</td>
                                    <td>{element && element.isStrike && element.isStrike == true ? "Striker" : 'Non Striker'}</td>
                                  </tr>
                                ))}
                                </>
                              </tbody> : null}
                          </table>
                        </div>

                        <div className='border-1 rounded-lg'>
                          <div className='flex justify-between p-2 text-sm lg:text-lg border-b bg-[#D4D4D8] rounded-t-lg'>
                            <div>
                              <h3>Current Bowler:</h3>
                            </div>
                            <div className='flex space-x-7 lg:space-x-16'>
                              <h3>O(B)</h3>
                              <h3>R</h3>
                              <h3>W</h3>
                              <h3>4s</h3>
                              <h3>6s</h3>
                              <h3>ER</h3>
                            </div>
                          </div>
                          <>
                            {matchInfoData && matchInfoData.bolwer ?

                              <>
                                <div className='flex justify-between p-2 text-sm lg:text-lg border-b'>
                                  <div>
                                    <h3>  {matchInfoData.bolwer.name} :</h3>
                                  </div>
                                  <div className='flex space-x-7 lg:space-x-16'>
                                    <h3>{matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.over ? matchInfoData.bolwer.over.toFixed(1) : "00"}({matchInfoData.bolwer.ball})</h3>
                                    <h3>{matchInfoData.bolwer.run}</h3>
                                    <h3>{matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.wicket ? matchInfoData.bolwer.wicket : "00"}</h3>
                                    <h3>{matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.fours ? matchInfoData.bolwer.fours : "00"}</h3>
                                    <h3>{matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.sixes ? matchInfoData.bolwer.sixes : "00"}</h3>
                                    <h3>{matchInfoData && matchInfoData.bolwer && matchInfoData.bolwer.strike_rate ? matchInfoData.bolwer.strike_rate : "00"}</h3>
                                  </div>
                                </div>
                              </> : null}
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                  : null
              }

              {
                this.state.activeTab === 2 ?
                  <>
                    <div className="w-full md:px-0 px-4  mx-auto py-7 space-y-5 bg-[#fff]">
                      <div className='space-y-5 px-6'>
                        <div className='flex justify-between item'>
                          <div className='flex justify-center space-x-5'>
                            <img src={matchInfoData && matchInfoData.teamA && matchInfoData.teamA.imageLinkUrl ? matchInfoData.teamA.imageLinkUrl : null} alt="" className='w-14 h-14 p-2 rounded-full border ' />
                            <p className='flex justify-center items-center'>{matchInfoData && matchInfoData.teamA && matchInfoData.teamA.name ? matchInfoData.teamA.name : null}({matchInfoData && matchInfoData.teamA && matchInfoData.teamA.short_name ? matchInfoData.teamA.short_name : null})</p>
                          </div>
                          <div className='text-[#B0B0B0] translate-y-4'>v</div>
                          <div className='flex justify-center space-x-5'>
                            <p className='flex justify-center items-center'>{matchInfoData && matchInfoData.teamB && matchInfoData.teamB.name ? matchInfoData.teamB.name : null}({matchInfoData && matchInfoData.teamB && matchInfoData.teamB.short_name ? matchInfoData.teamB.short_name : null})</p>
                            <img src={matchInfoData && matchInfoData.teamA && matchInfoData.teamA.imageLinkUrl ? matchInfoData.teamA.imageLinkUrl : null} alt="" className='w-14 h-14 p-2 rounded-full border ' />
                          </div>
                        </div>
                        <div className='flex justify-center items-center bg-[rgb(31,171,181)] p-3 rounded text-white'>
                          <p>Match Status</p>
                        </div>
                        <div className='border border-black rounded-lg p-2 space-y-2'>
                          <div className='flex justify-between'>
                            <p>Series:</p>
                            <p>Indian Premier League 2023</p>
                          </div>
                        </div>


                        {matchInfoData && matchInfoData.teamA ?
                          <>

                            <div onClick={() => this.handledropdown1()} className='flex justify-between items-center bg-[rgb(31,171,181)] font-semibold p-3 rounded text-white cursor-pointer'>
                              <p>{matchInfoData.teamA.name}({matchInfoData.teamA.short_name})</p>
                              <p><RiArrowDropDownLine size={35} /></p>
                            </div>

                            {this.state.dropdown1 === true ?
                              <>
                                {matchInfoData.teamA.player.map((element) => (
                                  <div class="p-3 sm:p-12 bg-white shadow-lg">
                                    <div class="flex flex-col  space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                                      <img src={element && element.imageLinkUrl ? element.imageLinkUrl : null} alt="" class="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start bg-gray-500 border-gray-700" />
                                      <div class="flex ">
                                        <h4 class="text-lg font-semibold text-center md:text-left flex justify-center items-center">{element.name}</h4>
                                      </div>
                                    </div>
                                  </div>))}
                              </> : null
                            }
                          </>
                          : null}

                        {matchInfoData && matchInfoData.teamB ?
                          <>
                            <div onClick={() => this.handledropdown2()} className='flex justify-between items-center bg-[rgb(31,171,181)] font-semibold p-3 rounded text-white cursor-pointer'>
                              <p>  {matchInfoData.teamB.name}({matchInfoData.teamB.short_name})</p>
                              <p ><RiArrowDropDownLine size={35} /></p>
                            </div>

                            {this.state.dropdown2 === true ?

                              <>
                                {matchInfoData.teamB.player.map((element) => (
                                  <div class="p-3 sm:p-12 bg-white shadow-lg">
                                    <div class="flex flex-col  space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                                      <img src={element && element.imageLinkUrl ? element.imageLinkUrl : null} alt="" class="self-center object-cover flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start bg-gray-500 border-gray-700" />
                                      <div class="flex ">
                                        <h4 class="text-lg font-semibold text-center md:text-left flex justify-center items-center">{element.name}</h4>
                                      </div>
                                    </div>
                                  </div>))}
                              </> : null
                            }
                          </> : null}
                      </div>
                    </div>
                  </>
                  : null
              }

              {
                this.state.activeTab === 3 ?
                  <>
                    <div className="w-full md:px-0 px-4  mx-auto py-7 space-y-5 bg-[#fff]">
                      <div className='space-y-5 px-6'>
                        <div className='flex justify-between item'>
                          <div className='flex justify-center space-x-5'>
                            <img src={matchInfoData && matchInfoData.teamA && matchInfoData.teamA.imageLinkUrl ? matchInfoData.teamA.imageLinkUrl : null} alt="" className='w-14 h-14 p-2 rounded-full border ' />
                            <p className='flex justify-center items-center'>{matchInfoData && matchInfoData.teamA && matchInfoData.teamA.name ? matchInfoData.teamA.name : null}({matchInfoData && matchInfoData.teamA && matchInfoData.teamA.short_name ? matchInfoData.teamA.short_name : null})</p>
                          </div>
                          <div className='text-[#B0B0B0] translate-y-4'>v</div>
                          <div className='flex justify-center space-x-5'>
                            <p className='flex justify-center items-center'>{matchInfoData && matchInfoData.teamB && matchInfoData.teamB.name ? matchInfoData.teamB.name : null}({matchInfoData && matchInfoData.teamB && matchInfoData.teamB.short_name ? matchInfoData.teamB.short_name : null})</p>
                            <img src={matchInfoData && matchInfoData.teamA && matchInfoData.teamA.imageLinkUrl ? matchInfoData.teamA.imageLinkUrl : null} alt="" className='w-14 h-14 p-2 rounded-full border ' />
                          </div>
                        </div>
                        <div className='flex justify-center items-center bg-[rgb(31,171,181)] p-3 rounded text-white'>
                          <p>Match Status</p>
                        </div>
                        <div className='border border-black rounded-lg p-2 space-y-2'>
                          <div className='flex justify-between'>
                            <p>Series:</p>
                            <p>Indian Premier League 2023</p>
                          </div>
                        </div>


                        {allTeamStatus && allTeamStatus ?
                          <>

                            <div className='flex justify-between items-center bg-[rgb(31,171,181)] font-semibold p-3 rounded text-white cursor-pointer'>
                              <p>Player</p>
                              {/* <p><RiArrowDropDownLine size={35} /></p> */}
                            </div>

                          
                              <>
                                {allTeamStatus.map((element) => (
                                  <div class="p-3 sm:p-12 bg-white shadow-lg">
                                    <div class="flex flex-col  space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                                      <img src={element && element.imageLinkUrl ? element.imageLinkUrl : null} alt="" class="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start bg-gray-500 border-gray-700" />
                                      <div class=" ">
                                        <h4 class="xl:text-lg text-sm uppercase  text-black font-semibold text-center md:text-left flex justify-start items-center">{element && element.name ? element.name : "-"}</h4>
                                        <h4 class="xl:text-base text-sm  text-gray-700  font-semibold text-center md:text-left flex justify-start items-center">{element &&element.play_role ? element.play_role : "-"}</h4>
                                        <h4 class="xl:text-base text-sm  text-black font-semibold text-center md:text-left flex justify-start items-center">Status :
                                        <span className='text-gray-700'>
                                         {element && element.Out === false ? "Upcomming"  : element && element.Play === false ? "Upcomming" : "Striker" }
                                          </span></h4>
                                      </div>
                                    </div>
                                  </div>))}
                              </>
                            
                          </>
                          : null}

                        {/* {matchInfoData && matchInfoData.teamB ?
                          <>
                            <div onClick={() => this.handledropdown2()} className='flex justify-between items-center bg-[rgb(31,171,181)] font-semibold p-3 rounded text-white cursor-pointer'>
                              <p>  {matchInfoData.teamB.name}({matchInfoData.teamB.short_name})</p>
                              <p ><RiArrowDropDownLine size={35} /></p>
                            </div>

                            {this.state.dropdown2 === true ?

                              <>
                                {matchInfoData.teamB.player.map((element) => (
                                  <div class="p-3 sm:p-12 bg-white shadow-lg">
                                    <div class="flex flex-col  space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                                      <img src={element && element.imageLinkUrl ? element.imageLinkUrl : null} alt="" class="self-center object-cover flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start bg-gray-500 border-gray-700" />
                                      <div class="flex ">
                                        <h4 class="text-lg font-semibold text-center md:text-left flex justify-center items-center">{element.name}</h4>
                                      </div>
                                    </div>
                                  </div>))}
                              </> : null
                            }
                          </> : null} */}
                      </div>
                    </div>
                  </>
                  : null
              }


            </div>
          </div>
        </div>
        {/* <div className='2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto bg-gray-200 object-cover m-6' >
                    <img alt='fiverr' src="/images/blog-cta-ad-specs-reference-guide-b.webp"  width="200%" height="80%" layout="responsive" className='object-cover' unoptimized={true} />
                  </div> */}

      </>

    );
  }
}
function mapStateToProps(state) {
  const { matchMoreDetail } = state;
  return {
    matchMoreDetail,

  };
}
export default connect(mapStateToProps)(LiveScoreBoard);
