export const imageConstants = {

    GETALL_IMAGE_REQUEST: 'GETALL_IMAGE_REQUEST',
    GETALL_IMAGE_SUCCESS: 'GETALL_IMAGE_SUCCESS',
    GETALL_IMAGE_FAILURE: 'GETALL_IMAGE_FAILURE',

    GET_LIST_IMAGE_REQUEST: 'GET_LIST_IMAGE_REQUEST',
    GET_LIST_IMAGE_SUCCESS: 'GET_LIST_IMAGE_SUCCESS',
    GET_LIST_IMAGE_FAILURE: 'GET_LIST_IMAGE_FAILURE',

    GET_LIST_IMAGE_BY_ID_REQUEST: 'GET_LIST_IMAGE_BY_ID_REQUEST',
    GET_LIST_IMAGE_BY_ID_SUCCESS: 'GET_LIST_IMAGE_BY_ID_SUCCESS',
    GET_LIST_IMAGE_BY_ID_FAILURE: 'GET_LIST_IMAGE_BY_ID_FAILURE',

    ADD_IMAGE_REQUEST: 'ADD_IMAGE_REQUEST',
    ADD_IMAGE_SUCCESS: 'ADD_IMAGE_SUCCESS',
    ADD_IMAGE_FAILURE: 'ADD_IMAGE_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',

    DELETE_IMAGE_REQUEST: 'DELETE_IMAGE_REQUEST',
    DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'DELETE_IMAGE_FAILURE',

    UPDATE_IMAGE_REQUEST: 'UPDATE_IMAGE_REQUEST',
    UPDATE_IMAGE_SUCCESS: 'UPDATE_IMAGE_SUCCESS',
    UPDATE_IMAGE_FAILURE: 'UPDATE_IMAGE_FAILURE',

    DISABLE_IMAGE_REQUEST: 'DISABLE_IMAGE_REQUEST',
    DISABLE_IMAGE_SUCCESS: 'DISABLE_IMAGE_SUCCESS',
    DISABLE_IMAGE_FAILURE: 'DISABLE_IMAGE_FAILURE',


    GET_LIST_NOTIFICATION_REQUEST: 'GET_LIST_NOTIFICATION_REQUEST',
    GET_LIST_NOTIFICATION_SUCCESS: 'GET_LIST_NOTIFICATION_SUCCESS',
    GET_LIST_NOTIFICATION_FAILURE: 'GET_LIST_NOTIFICATION_FAILURE',


    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


};
