import {
  userConstants, imageConstants
} from '../_constants';

export function image(state = {}, action) {

  switch (action.type) {

    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        imageUploadSuccess: true,
        filesDetails: action.uploadImage.filesDetails
      };
    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };
    case imageConstants.ADD_IMAGE_REQUEST:
      return {
        ...state
      };
    case imageConstants.ADD_IMAGE_SUCCESS:
      return {
        ...state,
        addImageSuccess: true
      };
    case imageConstants.ADD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case imageConstants.ADD_NOTIFICATION_REQUEST:
      return {
        ...state
      };
    case imageConstants.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        // addUserSuccess: true
      };
    case imageConstants.ADD_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case imageConstants.GET_LIST_IMAGE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case imageConstants.GET_LIST_IMAGE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        // updateUserSuccess: true,
        imageRowData: action.users.getNewsById,
        // total: action.users.getNewsById
      };
    case imageConstants.GET_LIST_IMAGE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case imageConstants.GET_LIST_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess: false,
        updateUserSuccess: false,
      };
    case imageConstants.GET_LIST_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        addImageSuccess: false,
        imageUploadSuccess: false,
        getImageList: action.users.ImageList.list,
        getImageTotal: action.users.ImageList.total
      };
    case imageConstants.GET_LIST_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case imageConstants.GETALL_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case imageConstants.GETALL_IMAGE_SUCCESS:
      return {
        ...state,
        // addUserSuccess: false,
        loading: false,
        imageItems: action.users.getAllNews,
        imageTotal: action.users.getAllNews
      };
    case imageConstants.GETALL_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case imageConstants.UPDATE_IMAGE_REQUEST:
      return {
        ...state
      };
    case imageConstants.UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        imageUploadSuccess: false,
      };
    case imageConstants.UPDATE_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case imageConstants.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case imageConstants.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case imageConstants.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case imageConstants.DISABLE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case imageConstants.DISABLE_IMAGE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case imageConstants.DISABLE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case imageConstants.GET_LIST_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case imageConstants.GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        notificationItems: action.users.getNotificationList.list,
        notificationTotal: action.users.getNotificationList.total
      };
    case imageConstants.GET_LIST_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}