import React from "react";

import Modal from 'react-modal';


export default function CreateOutModel(props) {

  let { batsmanOutFields, handleCloseBatsmanOutModal, errorPlayerOut, inputChangePlayerOut, playerOutSubmit, playerOutField, handleInningsData } = props;
  console.log("errorPlayerOuterrorPlayerOut  ", errorPlayerOut);

  return (




    <div className={batsmanOutFields ? `main-modal fixed w-full  inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster` : "hidden"} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="border border-teal-500 modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-4 text-left px-6">
          {/*Title*/}
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold">Player Out Model</p>
            <div className="modal-close cursor-pointer z-50">
              <svg onClick={() => handleCloseBatsmanOutModal()} className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <div className="my-1">

            <form autoComplete="off">
              <div className="{otpSent?'disableArea':''}">
                <div className=" mt-1  shadow-sm relative">

                  <div >
                    <label>Run</label>
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-large rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   w-full"
                      id="currentBallStatus"
                      // value={playerOutField.currentBallStatus}
                      // name="currentBallStatus"
                      onChange={(val) => handleInningsData(val.target.value)}
                    >
                      <option >{playerOutField && playerOutField.currentBallStatus ? playerOutField.currentBallStatus : "Select Player Run"} </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                  <div >
                    <label>Event</label>
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-large rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   w-full"
                      id="currentBallStatus"
                      // value={playerOutField.currentBallStatus}
                      // name="currentBallStatus"
                      onChange={(val) => handleInningsData(val.target.value)}
                    >
                      <option >{playerOutField && playerOutField.currentBallStatus ? playerOutField.currentBallStatus : "Select Player Event"} </option>
                      <option value="Fair Delivery">Fair Delivery</option>
                      <option value="No Ball">No Ball</option>
                      <option value="Wide">Wide</option>
                    </select>
                  </div>

                  <div >
                    <label>Type Of Out</label>
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-large rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5   w-full"
                      id="currentBallStatus"
                      // value={playerOutField.currentBallStatus}
                      // name="currentBallStatus"
                      onChange={(val) => handleInningsData(val.target.value)}
                    >
                      <option >{playerOutField && playerOutField.currentBallStatus ? playerOutField.currentBallStatus : "Select Player Out Type"} </option>
                      <option value="Bowled">Bowled</option>
                      <option value="Caught">Caught</option>
                      <option value="Leg Before Wicket (LBW)">Leg Before Wicket (LBW)</option>
                      <option value="Stumped">Stumped</option>
                      <option value="Run Out">Run Out</option>
                      <option value="Hit Wicket">Hit Wicket</option>
                      <option value="Handle The Ball">Handle The Ball</option>
                      <option value="Obstructing The Field">Obstructing The Field</option>
                      <option value="Hit The Ball Twice">Hit The Ball Twice</option>
                      <option value="Timed Out">Timed Out</option>
                    </select>
                  </div>

                  {errorPlayerOut && errorPlayerOut["currentBallStatus"] ?
                    <div className=" text-red-600">
                      {errorPlayerOut["currentBallStatus"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="mt-2">
                <div className="mt-1 rounded-md  relative">
                  <textarea row={4} style={{ resize: 'none' }} className={`border-1  p-4  placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorPlayerOut && !errorPlayerOut["message"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                    id="message" name="message" placeholder="Message" value={playerOutField?.message || ""} type="message" onChange={inputChangePlayerOut} />
                  {errorPlayerOut && errorPlayerOut["message"] ?
                    <div className=" text-red-600">
                      {errorPlayerOut["message"]}
                    </div>
                    : null}

                </div>
              </div>

              <div className="mt-6 w-64 sm:w-72 mx-auto">
                <button className="bg-shine-400 w-full mx-auto flex justify-center py-3 uppercase px-4 border text-lg xl font-semibold rounded-full text-white border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out" type="button" onClick={playerOutSubmit}>Submit</button>
              </div>
            </form>

          </div>
          {/*Footer*/}
        </div>
      </div>
    </div>






  );
}
