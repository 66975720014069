import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions, userActions, categoryActions, alertActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Multiselect from 'multiselect-react-dropdown';
// import ReactQuill from "react-quill";
import ReactQuill, { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { HiArrowSmLeft } from "react-icons/hi";
import "./style.css"
import ImageUploader from "quill-image-uploader";
import MyEditor from "./components/MyEditor";
// Quill.register("modules/imageUploader", ImageUploader);

import ImageUploadModal from "./components/ImageUploadModal";
import VideoUploadModal from "./components/VideoUploadModal";
import { imageActions } from '../../_actions';



class CreateAddGameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

      content: '',
      imageUploadModal: false,
      videoUploadModal: false,
      uploadImageOption: 'selectImage',
      uploadVideoOption: 'selectVideo',



      authorName: "News",
      newsRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        story: ``
      },
      errorsAddUser: {
        content: [""],
      },
      fieldsUpdatePassword: {
        content: [""],
      },
      errorsUpdatePassword: {
        content: [""],
      },
      viewRowData: {},
      inputAddUserChange: [],
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      isImageSet: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      newsContent: [],
    };

    this.quillRef = React.createRef();


  }


  componentDidMount() {


    let newsType = this.props.match.params.newsType;

    // console.log("Create___news___this.props.match.params.newsType:::", newsType);

    let data = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      "isActive": false
    }
    this.handleOpenCreateModal();
    this.props.dispatch(newsActions.getNewsList(data));
    this.props.dispatch(categoryActions.getAllCategory());
    this.props.dispatch(categoryActions.getCategory());
    // this.props.dispatch(categoryActions.getSubCategoryById());
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        // UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }

    if (nextProps.news.updateUserSuccess) {
      // console.log('Called______');
      return {
        ...nextProps,
        fieldsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        errorsUpdatePassword: {
          content: [""],
          tag: [""]
        },
        // addUserCreateModal: false,
        UpdatePasswordCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "isActive": false
    }
    this.props.dispatch(newsActions.getNewsList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "newsType": "MANUAL",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size,
      "isActive": false
    }
    this.props.dispatch(newsActions.getNewsList(data));
  }
  disableNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "newsType": "MANUAL",
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size,
      "isActive": false
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.author} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.disableNews(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNews(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    console.log("data_____?", data);
    this.props.history.push("/app/viewdetails/" + data.id)
  }


  handleOpenCreateModalUpdatePassword = (data) => {

    console.log("==========================>data:", data);

    this.setState({ UpdatePasswordCreateModal: true, fieldsUpdatePassword: data, updateSelectedTag: data.tag });
  }
  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }
  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(newsActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({
      addUserCreateModal: false,
      fieldsAddUser: {
        content: [""],
        tag: [""]
      }
    });
    // this.setState({ appsettingUpdateModal: false });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdatePasswordCreateModal: false });
  }
  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['news_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addNewsSubmit = () => {

    if (this.handleValidationAddNews()) {
      if (this.state.isImageSet) {
        let reqData = {
          "news_id": this.state.fieldsAddUser.news_id.toString(),
          "title": this.state.fieldsAddUser.title,
          "titleTwo": this.state.fieldsAddUser.titleTwo,
          "metaTitle": this.state.fieldsAddUser.metaTitle,
          "author": this.state.authorName,
          "description": this.state.fieldsAddUser.description ? this.state.fieldsAddUser.description : this.state.fieldsAddUser.story.slice(0,100),
          "canonicalUrl": this.state.fieldsAddUser.titleTwo,
          "image": this.state.fieldsAddUser && this.state.fieldsAddUser.image ? this.state.fieldsAddUser.image : null,
          "tag": this.state.selectedTag ? this.state.selectedTag : null,
          "category": this.state.fieldsAddUser.category,
          "subcategory": this.state.fieldsAddUser.subcategory,
          "story": this.state.fieldsAddUser.story,
          "newsType": "MANUAL",
          "isActive": true
        }
        // let paginationData = {
        //   "newsType": "MANUAL",
        //   "keyWord": this.state.keyWord,
        //   "pageNo": this.state.pageNo,
        //   "size": this.state.size,
        // }

        console.log("reqDatareqDatareqData>>>>>CREATE_MANUAL_NEWS>>>>>>>>>", reqData);
        this.props.dispatch(newsActions.createNews(reqData, this.props));

      } else {
        this.props.dispatch(alertActions.error("Please Set The Image."));
      }

    }
    // if (this.state.isImageSet) {

  }

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //news_id
    if (!fieldsAddUser["news_id"] || fieldsAddUser["news_id"] === "") {
      formIsValid = false;
      errorsAddUser["news_id"] = "Cannot be empty";
    }

    //title
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //metaTitle
    if (!fieldsAddUser["metaTitle"] || fieldsAddUser["metaTitle"] === "") {
      formIsValid = false;
      errorsAddUser["metaTitle"] = "Cannot be empty";
    }

    //titleTwo
    if (!fieldsAddUser["titleTwo"] || fieldsAddUser["titleTwo"] === "") {
      formIsValid = false;
      errorsAddUser["titleTwo"] = "Cannot be empty";
    }

    //description
    // if (!fieldsAddUser["description"] || fieldsAddUser["description"] === "") {
    //   formIsValid = false;
    //   errorsAddUser["description"] = "Cannot be empty";
    // }

    //category
    if (!fieldsAddUser["category"] || fieldsAddUser["category"] === "") {
      formIsValid = false;
      errorsAddUser["category"] = "Cannot be empty";
    }

    // newsType
    // if (!fieldsAddUser["newsType"] || fieldsAddUser["newsType"] === "") {
    //   formIsValid = false;
    //   errorsAddUser["newsType"] = "Cannot be empty";
    // }

    //story
    if (!fieldsAddUser["story"] || fieldsAddUser["story"] === "") {
      formIsValid = false;
      errorsAddUser["story"] = "Cannot be empty";
    }


    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "news_id": this.state.fieldsUpdatePassword.news_id,
        "title": this.state.fieldsUpdatePassword.title,
        "author": this.state.fieldsUpdatePassword.author,
        "image": this.state.fieldsUpdatePassword && this.state.fieldsUpdatePassword.image ? this.state.fieldsUpdatePassword.image : null,
        "tag": this.state.updateSelectedTag ? this.state.updateSelectedTag : this.state.fieldsUpdatePassword.content,
        "category": this.state.fieldsUpdatePassword.category,
        "subcategory": this.state.fieldsUpdatePassword.subcategory,
        "story": this.state.fieldsUpdatePassword.story,
        "newsType": this.state.fieldsUpdatePassword.newsType,
      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("reqNewsUpdate>>>>>>>>>>>...............", reqData);

      this.props.dispatch(newsActions.updateNews(reqData, paginationData));
    }

  }


  handleValidationUpdateNews = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    //news_id
    if (!fieldsUpdatePassword["news_id"] || fieldsUpdatePassword["news_id"] === "") {
      formIsValid = false;
      errorsUpdatePassword["news_id"] = "Cannot be empty";
    }
    //title
    if (!fieldsUpdatePassword["title"] || fieldsUpdatePassword["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //author
    if (!fieldsUpdatePassword["author"] || fieldsUpdatePassword["author"] === "") {
      formIsValid = false;
      errorsUpdatePassword["author"] = "Cannot be empty";
    }
    //category
    if (!fieldsUpdatePassword["category"] || fieldsUpdatePassword["category"] === "") {
      formIsValid = false;
      errorsUpdatePassword["category"] = "Cannot be empty";
    }
    //subcategory
    if (!fieldsUpdatePassword["subcategory"] || fieldsUpdatePassword["subcategory"] === "") {
      formIsValid = false;
      errorsUpdatePassword["subcategory"] = "Cannot be empty";
    }
    //story
    if (!fieldsUpdatePassword["story"] || fieldsUpdatePassword["story"] === "") {
      formIsValid = false;
      errorsUpdatePassword["story"] = "Cannot be empty";
    }
    //newsType
    if (!fieldsUpdatePassword["newsType"] || fieldsUpdatePassword["newsType"] === "") {
      formIsValid = false;
      errorsUpdatePassword["newsType"] = "Cannot be empty";
    }


    console.log("errorsUpdatePassword_errorsUpdatePassword___!!!!!_::::", errorsUpdatePassword);

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  addContentField = () => {
    const { fieldsAddUser } = this.state;

    fieldsAddUser.content.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteContentField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.content.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  setCurrentIndex = (currentConetntIndex) => {

    this.setState({ currentConetntIndex: currentConetntIndex });
    console.log('kkkkkkkkkk  ', currentConetntIndex);
  }

  inputAddUserChangeContent = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsAddUser);
    fieldsAddUser["content"][this.state.currentConetntIndex] = value;

    this.setState({ fieldsAddUser });
  }


  contentFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;

    fieldsUpdatePassword.content.push('')
    this.setState({ fieldsUpdatePassword })
    console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deleteContentFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.content.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  setCurrentIndexUpdate = (currentConetntUpdateIndex) => {

    this.setState({ currentConetntUpdateIndex: currentConetntUpdateIndex });
    console.log('kkkkkkkkkk  ', currentConetntUpdateIndex);
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }

  handleUploadImageUser = () => {
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['imageUser'] = imageWebLink;
    fieldsUpdatePassword['imageUser'] = imageWebLink;
    fieldsAddUser['imageUserUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUserUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword });
  }

  addTagField = () => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.push('')
    this.setState({ fieldsAddUser })
    console.log("fieldsAddUser  ", fieldsAddUser);
  }

  deleteTagField = (index) => {
    const { fieldsAddUser } = this.state;
    fieldsAddUser.tag.splice(index, 1)
    this.setState({ fieldsAddUser });
  }

  inputAddUserChangeTag = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["tag"][this.state.currentConetntIndex] = value;
    console.log("fieldsAddUser***:::", fieldsAddUser);
    this.setState({ fieldsAddUser });
  }

  tagFieldUpdate = () => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.push('')
    this.setState({ fieldsUpdatePassword })
    // console.log("fieldsUpdatePassword  ", fieldsUpdatePassword);
  }

  deletetagFieldUpdate = (index) => {
    const { fieldsUpdatePassword } = this.state;
    fieldsUpdatePassword.tag.splice(index, 1)
    this.setState({ fieldsUpdatePassword });
  }

  inputChangetagUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["tag"][this.state.currentConetntUpdateIndex] = value;
    console.log("fieldsTag*****:  ", fieldsUpdatePassword);
    this.setState({ fieldsUpdatePassword });
  }

  handleSelectedTag = (selectedTag) => {
    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {
    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };

  rteChange = (content, delta, source, editor) => {

    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["story"] = editor.getHTML();
    errorsAddUser["story"] = "";
    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters
    this.setState({ fieldsAddUser, errorsAddUser });
  };


  rteChangeText = (e) => {

    e.preventDefault();
    let { value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    fieldsAddUser["story"] = value;
    this.setState({ fieldsAddUser });
  };


  rteChangeUpdate = (content, delta, source, editor) => {

    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    fieldsUpdatePassword["story"] = editor.getHTML();
    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters
    this.setState({ fieldsUpdatePassword });
  };


  handleFile = (event) => {
    // console.log("handleFileevent",event);

    console.log('event.target.files[0]', event.target.files[0]);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleUpload = () => {
    console.log('______________________', this.state.imageWebLink);
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword, isImageSet: true });
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  handleCategorySelect = (e) => {
    e.preventDefault();
    // let { value, id } = e.target;
    const { fieldsAddUser, errorsAddUser } = this.state;

    let { category } = this.props;

    let { getCategoryItems
      // , subCategoryItems 
    } = category;


    console.log("idididididid::", getCategoryItems[e.target.selectedIndex - 1].shortName);
    // shortName
    // fieldsAddUser['category'] = value;
    fieldsAddUser['category'] = getCategoryItems[e.target.selectedIndex - 1].shortName;
    errorsAddUser['category'] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
    this.props.dispatch(categoryActions.getSubCategoryById({ parentId: getCategoryItems[e.target.selectedIndex - 1].id }));
  }

  handleNewstypeSelect = (e) => {
    e.preventDefault();
    let { value } = e.target;
    const { fieldsAddUser, errorsAddUser } = this.state;
    console.log("&&&&&&&&&&&&&&&&&&&&&::", value);

    fieldsAddUser['newsType'] = value;
    errorsAddUser['newsType'] = "";
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  handleSubCategorySelect = (e) => {
    e.preventDefault();
    let { value } = e.target;
    const { fieldsAddUser } = this.state;
    console.log("&&&&&&&&&&&&&&&&&&&&&::", value);

    fieldsAddUser['subcategory'] = value;
    this.setState({ fieldsAddUser });
  }




  handleContentChange = (content, delta, source, editor) => {
    // this.setState({ content: value });


    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser["story"] = editor.getHTML();
    errorsAddUser["story"] = "";
    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters
    this.setState({ fieldsAddUser, errorsAddUser });

  };

  handleOpenModalImageUpload = (file) => {
    console.log('file______', file);
    this.setState({ imageUploadModal: true })
    // const formData = new FormData();
    // formData.append('image', file);
    // axios.post('/api/uploadImage', formData).then((response) => {
    /*
    const range = this.quillRef.current.getEditor().getSelection(true);
    //   const imageUrl = response.data.url;
    this.quillRef.current.getEditor().insertEmbed(
      range.index,
      'image',
      "https://www.comfygen.com/img/one-of-the-fastest-way-to-business-growth.webp",
    );
    */
  };
  hideModalImageUpload = () => {
    this.setState({ imageUploadModal: false })
  }
  handleOpenModalVideoUpload = (file) => {
    console.log('file______', file);
    this.setState({ videoUploadModal: true })
  };
  hideModalVideoUpload = () => {
    this.setState({ videoUploadModal: false })
  }

  selectImageUpload = (link) => {
    const range = this.quillRef.current.getEditor().getSelection(true);

    this.quillRef.current.getEditor().insertEmbed(
      range.index,
      'image',
      link,
    );
    this.props.dispatch(imageActions.uploadImageClear());
    this.setState({ imageUploadModal: false })
  }
  selectVideoUpload = (link) => {
    const range = this.quillRef.current.getEditor().getSelection(true);

    this.quillRef.current.getEditor().insertEmbed(
      range.index,
      'video',
      link,
    );
    this.props.dispatch(imageActions.uploadImageClear());
    this.setState({ videoUploadModal: false })
  }

  modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block'],
      ],
      handlers: {
        image: () => this.handleOpenModalImageUpload(),
        video: () => this.handleOpenModalVideoUpload(),
      },
    },
  };

  formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'video',
    'code-block',
  ];



  render() {

    let { errorsAddUser,
      //  modules, 
      formats } = this.props;
    let {
      //  news,
      category } = this.props;
    // let { items, total, loading } = news;
    let { getCategoryItems, subCategoryItems } = category;


    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "WHATS_NEW", "CATEGORIES", "MOSTREAD", "DONT_MISS", "SLIDER", "BUSINESS", "NATIONAL", "FOREIGN", "SPORTS", "INTERNATIONAL", "INDIA", "CRIME", "EDUCATION", "PHOTOS"]


    console.log("RENDER_________this.state.fieldsAddUser::", category);


    return (

      <>
        <div className="h-screen m-4 overflow-hidden overflow-y-auto">

          {/* <MyEditor /> */}
          <div className="overflow-y-auto bg-white ">
            <div className="w-full px-6 py-4 text-left">
              <div className="flex pb-3">
                <div onClick={this.handleBack} className='p-1.5 bg-gray-200 border rounded-full hover:bg-blue-300 hover:text-white'>
                  <HiArrowSmLeft className='w-5 h-5 ' />
                </div>
                <p className="pl-4 mt-0.5 text-2xl font-bold">Add News</p>
              </div>

              <form autoComplete="on">
                <div className="justify-between w-full lg:flex">

                  <div class="mr-2 w-full flex flex-col">
                    <label className="mt-2 text-sm font-medium text-black md:mr-2 whitespace-nowrap">News Type:</label>
                    {/* <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" onChange={this.handleNewstypeSelect} id="newsType" name="newsType"
                      // value={this.state.fieldsAddUser && this.state.fieldsAddUser["newsType"] ? this.state.fieldsAddUser["newsType"] : null}
                      value="MANUAL"
                      disabled
                    >
                      
                      <option selected="selected" value="MANUAL">MANUAL</option>
                    </select> */}
                    <input disabled type='text' className='form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring' value="MANUAL"  />
                    {this.state.errorsAddUser && this.state.errorsAddUser["newsType"] ?
                      <div className="text-xs text-red-600 invalid-feedback">
                        {this.state.errorsAddUser["newsType"]}
                      </div>
                      : null}
                  </div>
                  <div class="mr-2 w-full">
                    <label className="mt-2 mr-2 text-sm font-medium text-black whitespace-nowrap">Category :</label>
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" id="category"
                      name="category"
                      value={this.state.fieldsAddUser && this.state.fieldsAddUser["category"] ? this.state.fieldsAddUser["category"] : null}
                      // name="courseId"
                      onChange={this.handleCategorySelect}
                    >
                      <option selected>Plz Select Category</option>
                      {
                        getCategoryItems && getCategoryItems && getCategoryItems.length > 0 ?
                          getCategoryItems.map((element, index) => (
                            <option key={index} name={element && element.shortName ? element.shortName : null} value={element && element.shortName ? element.shortName : null}>{element && element.name ? element.name : "NA"}</option>
                          )) : null
                      }
                    </select>
                    {this.state.errorsAddUser && this.state.errorsAddUser["category"] ?
                      <div className="text-xs text-red-600 invalid-feedback">
                        {this.state.errorsAddUser["category"]}
                      </div>
                      : null}
                  </div>

                  <div class="mr-2 w-full">
                    <label className="mt-2 text-sm font-medium text-black truncate md:mr-2 whitespace-nowrap">Sub Category :</label>
                    <select class="form-select w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring" aria-label="Default select example" onChange={this.handleSubCategorySelect} id="subcategory" name="subcategory"
                      value={this.state.fieldsAddUser && this.state.fieldsAddUser["subcategory"] ? this.state.fieldsAddUser["subcategory"] : null} >
                      <option selected>Plz Select Sub-Category</option>
                      {
                        subCategoryItems && subCategoryItems && subCategoryItems.length > 0 ?
                          subCategoryItems.map((element, index) => (
                            <option value={element && element.shortName ? element.shortName : null}>{element && element.name ? element.name : "NA"}</option>
                          )) : null
                      }
                    </select>
                    {errorsAddUser && errorsAddUser["subcategory"] ?
                      <div className="text-xs text-red-600 invalid-feedback">
                        {errorsAddUser["subcategory"]}
                      </div>
                      : null}
                  </div>
                  <div className="w-full mr-2">
                    <div className="relative rounded-md shadow-sm">
                      <label className="mr-2 text-sm font-medium text-black">News Id :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsAddUser && !errorsAddUser["news_id"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="news_id" name="news_id" placeholder="News Id" value={this.state.fieldsAddUser.news_id} type="text"
                        // onChange={this.inputAddUserChange} 
                        disabled
                      />
                      {errorsAddUser && errorsAddUser["news_id"] ?
                        <div className="text-xs text-red-600 invalid-feedback">
                          {errorsAddUser["news_id"]}
                        </div>
                        : null}
                    </div>
                  </div>

                </div>


                <div className="justify-between w-full mt-2 lg:flex">

                  <div className="w-full mr-2">
                    <div className="relative shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">News Title :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["title"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="title" name="title" placeholder="Title" value={this.state.fieldsAddUser.title} type="title" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["title"] ?
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorsAddUser["title"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Url Title :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["titleTwo"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="titleTwo" name="titleTwo" placeholder="Title" value={this.state.fieldsAddUser.titleTwo} type="titleTwo" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["titleTwo"] ?
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorsAddUser["titleTwo"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Meta Title :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["metaTitle"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="metaTitle" name="metaTitle" placeholder="Meta Title" value={this.state.fieldsAddUser && this.state.fieldsAddUser["metaTitle"] ? this.state.fieldsAddUser["metaTitle"] : ""} type="text" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["metaTitle"] ?
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorsAddUser["metaTitle"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative rounded-md shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Author :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["author"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="author" name="author" placeholder="Author" value={this.state.authorName} type="text" disabled />

                    </div>
                  </div>

                </div>

                <div className="justify-between w-full mt-2 lg:flex">
                  <div className="w-full mr-2">
                    <div className="relative shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Description :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["description"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="description" name="description" placeholder="Title" value={this.state.fieldsAddUser.description} type="text" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["description"] ?
                        <div className="text-xs text-red-600 invalid-feedback">
                          {this.state.errorsAddUser["description"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className="w-full mt-2">

                  <div className="w-full mr-2">
                    <label className="mt-2 mr-2 text-sm font-medium text-black">Story :</label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <ReactQuill
                        theme="snow"
                        // modules={this.modules}
                        // formats={formats}
                        rows={5}
                        // onChange={this.rteChange}
                        value={this.state.fieldsAddUser.story}

                        // value={this.state.content}
                        onChange={this.handleContentChange}
                        modules={this.modules}
                        formats={this.formats}
                        ref={this.quillRef}
                      />

                      <ImageUploadModal
                        imageUploadModal={this.state.imageUploadModal}
                        uploadImageOption={this.state.uploadImageOption}
                        hideModalImageUpload={this.hideModalImageUpload}
                        selectImageUpload={this.selectImageUpload}
                        formats={this.formats}
                        ref={this.quillRef}
                      />
                      <VideoUploadModal
                        videoUploadModal={this.state.videoUploadModal}
                        uploadVideoOption={this.state.uploadVideoOption}
                        hideModalVideoUpload={this.hideModalVideoUpload}
                        selectVideoUpload={this.selectVideoUpload}
                        formats={this.formats}
                        ref={this.quillRef}
                      />
                    </div>
                  </div>
                  {this.state.errorsAddUser && this.state.errorsAddUser["story"] ?
                    <div className="text-xs text-red-600 invalid-feedback">
                      {this.state.errorsAddUser["story"]}
                    </div>
                    : null}
                </div>

                <div className='mt-2 mr-2'>
                  <label className="mt-2 mr-2 text-sm font-medium text-black">HTML as text: :</label>
                  <span className=''>
                    <textarea className='border border-gray-400' name='story'
                      onChange={this.rteChangeText}
                      value={this.state.fieldsAddUser.story}
                      rows={5}
                      // readOnly
                      style={{ width: '100%' }} />
                  </span>
                </div>

                <div className='mt-2'>
                  <label className="mr-2 text-sm font-medium text-black">Tag :</label>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                    <Multiselect
                      options={options}
                      onSelect={this.handleSelectedTag}
                      selected={this.state.selectedTag}
                      isObject={false}
                      displayValue="label"
                    />
                  </div>
                </div>

                <div className="grid w-full grid-cols-1 gap-4 mt-2 lg:grid-cols-2">
                  <div className="w-full mr-2">
                    {
                      this.state.fieldsAddUser && this.state.fieldsAddUser.imageUrlLink ?
                        <>
                          <div className="w-full mt-1 mr-2 ">
                            <img src={this.state.fieldsAddUser && this.state.fieldsAddUser.imageUrlLink ? this.state.fieldsAddUser.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        null
                    }
                    <div className="w-full mt-1 shadow-md">
                      <div className="flex flex-wrap justify-between w-full p-2 border border-gray-300">
                        <div className="w-full">
                          <label class="block text-gray-700 text-sm" for="username"> News Image </label>
                          <input type="file" name="image" onChange={this.handleFile} />
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>
                        <div className="w-20 mt-1 space-x-4">
                          <button className="px-4 py-1 text-base font-semibold text-white bg-blue-500 border rounded-lg cursor-pointer" type="button" onClick={() => this.handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-auto mt-6 w-60">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out border rounded-full bg-shine-400 border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button" onClick={this.addNewsSubmit}>Submit</button>
                </div>

              </form>

            </div>
          </div>
        </div >

      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category } = state;
  return {
    news,
    users,
    category
  };

}
export default connect(mapStateToProps)(CreateAddGameModal);
