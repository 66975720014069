import { authHeader, history } from '../_helpers';
import { CONST, } from '../_config';

export const seriesService = {
    getVenuesList,
    getCustomSeriesList,
    getPointTableBySeriesId,
    getAllCustomSeries,
    createCustomSeries,
    updateCustomSeries,
    disableCustomSeries,
    deleteCustomSeries,
    deleteVenues,
    disableVenues,

    login,
    logout,

};


function getVenuesList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getVenuesList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getVenuesList: data.data
            }

            console.log('serviesd_getVenuesList', playerObj);

            return playerObj;
        });
}

function getCustomSeriesList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCustomSeriesList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getCustomSeriesList: data.data
            }

            console.log('serviesd_getCustomSeriesList', playerObj);

            return playerObj;
        });
}

function getPointTableBySeriesId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPointTableBySeriesId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let playerObj = {
                getPointTableBySeriesId: data.data
            }

            console.log('serviesd_getPointTableBySeriesId', playerObj);

            return playerObj;
        });
}
function getAllCustomSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllCustomSeries`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                getAllCustomSeries: data.data
            }


            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);



            return playerObj;
        });
}
function createCustomSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createCustomSeries`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                createCustomSeries: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function updateCustomSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCustomSeries`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                updateCustomSeries: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function disableVenues(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/disableVenues`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                disableVenues: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function disableCustomSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCustomSeriesStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                disableCustomSeries: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function deleteVenues(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteVenues`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                deleteVenues: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}
function deleteCustomSeries(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteCustomSeries`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let playerObj = {
                deleteCustomSeries: data.data
            }
            console.log("playerObjplayerObjplayerObjplayerObj", playerObj);
            return playerObj;
        });
}










function logout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
}
function onerrorlogout() {
    localStorage.removeItem('player');
    history.push(`#/login`);
    window.location.reload();
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(player => {
            let playerObj = {
                playerinfo: player
            }
            if (player.data) {
                localStorage.setItem('player', JSON.stringify(player.data));
            }
            return playerObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {

                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}