import { playerConstants } from '../_constants';
import { playerService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';
export const playerActions = {
    getPlayerList,
    getAllPlayerList,
    createPlayer,
    UpdatePlayerDetails,
    disablePlayer,
    deletePlayer,
};

function getPlayerList(data) {
    return dispatch => {
        dispatch(request());
        playerService.getPlayerList(data)
            .then(
                players => {
                    console.log("getPlayerList___players_Action:::", players);
                    dispatch(success(players))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerConstants.GET_PLAYER_LIST_REQUEST } }
    function success(players) { return { type: playerConstants.GET_PLAYER_LIST_SUCCESS, players } }
    function failure(error) { return { type: playerConstants.GET_PLAYER_LIST_FAILURE, error } }
}
function getAllPlayerList() {
    return dispatch => {
        dispatch(request());
        playerService.getAllPlayerList()
            .then(
                players => {
                    console.log("getAllPlayerList___players_Action:::", players);
                    dispatch(success(players))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerConstants.GET_ALL_PLAYER_LIST_REQUEST } }
    function success(players) { return { type: playerConstants.GET_ALL_PLAYER_LIST_SUCCESS, players } }
    function failure(error) { return { type: playerConstants.GET_ALL_PLAYER_LIST_FAILURE, error } }
}

function createPlayer(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerService.createPlayer(data)
            .then(
                players => {
                    console.log("getPlayerList___players_Action:::", players);
                    dispatch(success(players));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerConstants.ADD_PLAYER_DETAILS_REQUEST } }
    function success(players) { return { type: playerConstants.ADD_PLAYER_DETAILS_SUCCESS, players } }
    function failure(error) { return { type: playerConstants.ADD_PLAYER_DETAILS_FAILURE, error } }
}
function UpdatePlayerDetails(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerService.UpdatePlayerDetails(data)
            .then(
                players => {
                    console.log("getPlayerList___players_Action:::", players);
                    dispatch(success(players));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerConstants.UPDATE_PLAYER_DETAILS_REQUEST } }
    function success(players) { return { type: playerConstants.UPDATE_PLAYER_DETAILS_SUCCESS, players } }
    function failure(error) { return { type: playerConstants.UPDATE_PLAYER_DETAILS_FAILURE, error } }
}
function disablePlayer(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerService.disablePlayer(data)
            .then(
                players => {
                    console.log("getPlayerList___players_Action:::", players);
                    dispatch(success(players));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerConstants.UPDATE_PLAYER_STATUS_REQUEST } }
    function success(players) { return { type: playerConstants.UPDATE_PLAYER_STATUS_SUCCESS, players } }
    function failure(error) { return { type: playerConstants.UPDATE_PLAYER_STATUS_FAILURE, error } }
}
function deletePlayer(data, pagination) {
    return dispatch => {
        dispatch(request());
        playerService.deletePlayer(data)
            .then(
                players => {
                    console.log("getPlayerList___players_Action:::", players);
                    dispatch(success(players));
                    dispatch(alertActions.success("success"));
                    dispatch(this.getPlayerList(pagination))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: playerConstants.UPDATE_PLAYER_DELETE_REQUEST } }
    function success(players) { return { type: playerConstants.UPDATE_PLAYER_DELETE_SUCCESS, players } }
    function failure(error) { return { type: playerConstants.UPDATE_PLAYER_DELETE_FAILURE, error } }
}