import {
  playerRankTypeConstants
} from '../_constants';

export function playerRankType(state = {}, action) {

  console.log('actionactionactionactionactionactionactionactionactionaction', action);

  switch (action.type) {

    case playerRankTypeConstants.GET_PLAYER_RANK_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
    case playerRankTypeConstants.GET_PLAYER_RANK_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        rankType: action.player.getPlayerRankingTypeList.list,
        rankTypeTotal: action.player.getPlayerRankingTypeList.total
      };
    case playerRankTypeConstants.GET_PLAYER_RANK_TYPE_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankTypeConstants.GET_ALL_PLAYER_RANK_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        addplayeruccess: false
      };
      case playerRankTypeConstants.GET_ALL_PLAYER_RANK_TYPE_SUCCESS:
        return {
          ...state,
          addplayeruccess: false,
        loading: false,
        allRankType: action.player.getAllPlayerRankingType,
        // allRankTypeTotal: action.player.getAllPlayerRankingType.total
      };
    case playerRankTypeConstants.GET_ALL_PLAYER_RANK_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankTypeConstants.ADD_PLAYER_RANK_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankTypeConstants.ADD_PLAYER_RANK_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankTypeConstants.ADD_PLAYER_RANK_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankTypeConstants.UPDATE_PLAYER_RANK_TYPE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case playerRankTypeConstants.DELETE_PLAYER_RANK_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case playerRankTypeConstants.DELETE_PLAYER_RANK_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        addplayeruccess: true,
      };
    case playerRankTypeConstants.DELETE_PLAYER_RANK_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    default:
      return state
  }
}