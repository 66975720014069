import React, { Component } from 'react';
import { connect } from 'react-redux';
import { newsActions, userActions, alertActions } from '../../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Multiselect from 'multiselect-react-dropdown';
// import ReactQuill from "react-quill";
import ReactQuill, { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { HiArrowSmLeft } from "react-icons/hi";
import "./style.css"
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageUploader", ImageUploader);



class UpdateMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorName: "News",
      newsRowData: {},
      selectedTag: [""],
      updateSelectedTag: [""],
      limit: 5,
      imageWebLink: "",
      imageUrl: "",
      fieldsAddUser: {
        content: [""],
        story: ``
      },
      errorsAddUser: {
        content: [""],
      },
      fieldsUpdatePassword: {
        content: [""],
      },
      errorsUpdatePassword: {
        content: [""],
      },
      viewRowData: {},
      inputAddUserChange: [],
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdatePasswordCreateModal: false,
      loginToThisAccountModal: false,
      isImageSet: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',

      newsContent: [],
    };



  }


  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size,
    }

    let reqData = {
      id: "6470a1fe03fa456319f36904"
    }
    this.handleOpenCreateModal();
    this.props.dispatch(newsActions.getNewsList(data));

  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.news.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {
          content: [""],
          tag: [""]
        },
        errorsAddUser: {
          content: [""],
          tag: [""]
        },
        addUserCreateModal: false,
        imageWebLink: "",
        imageUrl: "",

      }
    }


    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
        imageUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "isActive": false
    }
    this.props.dispatch(newsActions.getNewsList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "newsType": "MANUAL",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size,
      "isActive": false
    }
    this.props.dispatch(newsActions.getNewsList(data));
  }
  disableNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "newsType": "MANUAL",
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size,
      "isActive": false
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.author} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.disableNews(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteNews = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "newsType": "MANUAL",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.author}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(newsActions.deleteNews(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }



  handleOpenCreateModal = () => {

    var val = Math.floor(100000 + Math.random() * 9000);
    let { fieldsAddUser } = this.state;
    fieldsAddUser['news_id'] = val;
    this.setState({ fieldsAddUser })

    this.setState({ addUserCreateModal: true, fieldsAddUser });
  }
  inputAddUserChange = (e) => {
    // e.preventDefault();
    let { name, value } = e.target;
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = this.state.errorsAddUser;
    fieldsAddUser[name] = value;
    console.log(name, value);
    errorsAddUser[name] = "";
    console.log(e.target.name, e.target.value)
    this.setState({ fieldsAddUser, errorsAddUser });
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = this.state.errorsUpdatePassword;
    fieldsUpdatePassword[name] = value;
    errorsUpdatePassword[name] = "";
    console.log(name, value);
    this.setState({ fieldsUpdatePassword, errorsUpdatePassword });
  }

  addNewsSubmit = () => {

    if (this.handleValidationAddNews()) {
      if (this.state.isImageSet) {
        let reqData = {
          "news_id": this.state.fieldsAddUser.news_id.toString(),
          "title": this.state.fieldsAddUser.title,
          "descirption": this.state.fieldsAddUser.descirption,
          "pub_date": this.state.fieldsAddUser.pub_date,
          "content": this.state.fieldsAddUser.content,
          "image": this.state.fieldsAddUser && this.state.fieldsAddUser.image ? this.state.fieldsAddUser.image : null,
        }
        let paginationData = {
          "newsType": "MANUAL",
          "keyWord": this.state.keyWord,
          "pageNo": this.state.pageNo,
          "size": this.state.size,
        }
        this.props.dispatch(newsActions.createNews(reqData, paginationData, this.props));
      } else {
        this.props.dispatch(alertActions.error("Please Set The Image."));
      }
    }
  }

  handleValidationAddNews = () => {
    let fieldsAddUser = this.state.fieldsAddUser;
    let errorsAddUser = {};
    let formIsValid = true;

    //news_id
    if (!fieldsAddUser["news_id"] || fieldsAddUser["news_id"] === "") {
      formIsValid = false;
      errorsAddUser["news_id"] = "Cannot be empty";
    }

    //title
    if (!fieldsAddUser["title"] || fieldsAddUser["title"] === "") {
      formIsValid = false;
      errorsAddUser["title"] = "Cannot be empty";
    }

    //description
    if (!fieldsAddUser["description"] || fieldsAddUser["description"] === "") {
      formIsValid = false;
      errorsAddUser["description"] = "Cannot be empty";
    }

    //pub_date
    if (!fieldsAddUser["pub_date"] || fieldsAddUser["pub_date"] === "") {
      formIsValid = false;
      errorsAddUser["pub_date"] = "Cannot be empty";
    }

    //story
    if (!fieldsAddUser["story"] || fieldsAddUser["story"] === "") {
      formIsValid = false;
      errorsAddUser["story"] = "Cannot be empty";
    }


    console.log("errorsAddUser_errorsAddUser_::::", errorsAddUser);

    this.setState({ errorsAddUser: errorsAddUser });
    return formIsValid;
  }

  updateNewsSubmit = () => {

    if (this.handleValidationUpdateNews()) {
      let reqData = {
        "id": this.state.fieldsUpdatePassword.id,
        "news_id": this.state.fieldsUpdatePassword.news_id,
        "title": this.state.fieldsUpdatePassword.title,
        "author": this.state.fieldsUpdatePassword.author,
        "image": this.state.fieldsUpdatePassword && this.state.fieldsUpdatePassword.image ? this.state.fieldsUpdatePassword.image : null,
        "tag": this.state.updateSelectedTag ? this.state.updateSelectedTag : this.state.fieldsUpdatePassword.content,
        "category": this.state.fieldsUpdatePassword.category,
        "subcategory": this.state.fieldsUpdatePassword.subcategory,
        "story": this.state.fieldsUpdatePassword.story,
        "newsType": this.state.fieldsUpdatePassword.newsType,
      }

      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }
      this.props.dispatch(newsActions.updateNews(reqData, paginationData));
    }
  }
  handleValidationUpdateNews = () => {
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    let errorsUpdatePassword = {};
    let formIsValid = true;

    if (!fieldsUpdatePassword["news_id"] || fieldsUpdatePassword["news_id"] === "") {
      formIsValid = false;
      errorsUpdatePassword["news_id"] = "Cannot be empty";
    }
    //title
    if (!fieldsUpdatePassword["title"] || fieldsUpdatePassword["title"] === "") {
      formIsValid = false;
      errorsUpdatePassword["title"] = "Cannot be empty";
    }
    //author
    if (!fieldsUpdatePassword["author"] || fieldsUpdatePassword["author"] === "") {
      formIsValid = false;
      errorsUpdatePassword["author"] = "Cannot be empty";
    }
    //category
    if (!fieldsUpdatePassword["category"] || fieldsUpdatePassword["category"] === "") {
      formIsValid = false;
      errorsUpdatePassword["category"] = "Cannot be empty";
    }
    //subcategory
    if (!fieldsUpdatePassword["subcategory"] || fieldsUpdatePassword["subcategory"] === "") {
      formIsValid = false;
      errorsUpdatePassword["subcategory"] = "Cannot be empty";
    }
    //story
    if (!fieldsUpdatePassword["story"] || fieldsUpdatePassword["story"] === "") {
      formIsValid = false;
      errorsUpdatePassword["story"] = "Cannot be empty";
    }
    //newsType
    if (!fieldsUpdatePassword["newsType"] || fieldsUpdatePassword["newsType"] === "") {
      formIsValid = false;
      errorsUpdatePassword["newsType"] = "Cannot be empty";
    }

    this.setState({ errorsUpdatePassword: errorsUpdatePassword });
    return formIsValid;
  }

  inputChangeContentUpdate = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let fieldsUpdatePassword = this.state.fieldsUpdatePassword;
    console.log("fieldsAddUserfieldsAddUser  ", fieldsUpdatePassword);
    fieldsUpdatePassword["content"][this.state.currentConetntUpdateIndex] = value;

    this.setState({ fieldsUpdatePassword });
  }

  handleSelectedTag = (selectedTag) => {
    console.log("selectedselectedselected  ", selectedTag);
    this.setState({ selectedTag });
  };

  handleSelectedTagUpdate = (updateSelectedTag) => {
    console.log("selectedselectedselected  ", updateSelectedTag);
    this.setState({ updateSelectedTag });
  };



  handleFile = (event) => {
    // console.log("handleFileevent",event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleUpload = () => {
    console.log('______________________', this.state.imageWebLink);
    const { fieldsAddUser, fieldsUpdatePassword, imageWebLink, imageUrl } = this.state;
    fieldsAddUser['image'] = imageWebLink;
    fieldsUpdatePassword['image'] = imageWebLink;
    fieldsAddUser['imageUrlLink'] = imageUrl;
    fieldsUpdatePassword['imageUrlLink'] = imageUrl;
    this.setState({ fieldsAddUser, fieldsUpdatePassword, isImageSet: true });
  }

  handleBack = () => {
    this.props.history.goBack()
  }



  render() {

    let { errorsAddUser,

      formats } = this.props;

    const options = ["MAIN", "FEATURE_POST", "TRENDING_STORIES", "PHOTOS"]

    return (

      <>
        <div className="h-screen m-4  overflow-y-auto">

          <div className="overflow-y-auto bg-white ">
            <div className="w-full px-6 py-4 text-left">
              <div className="flex pb-3">
                <div onClick={this.handleBack} className='p-1.5 bg-gray-200 border rounded-full hover:bg-blue-300 hover:text-white'>
                  <HiArrowSmLeft className='w-5 h-5 ' />
                </div>
                <p className="pl-4 mt-0.5 text-2xl font-bold">Update Match Score</p>
              </div>

              <form autoComplete="on">
                <div className="lg:flex justify-between w-full">



                </div>

                <div className="lg:flex justify-between w-full mt-2">


                  <div className="w-full mr-2">
                    <div className="relative shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Title :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["title"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="title" name="title" placeholder="Title" value={this.state.fieldsAddUser.title} type="title" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["title"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsAddUser["title"]}
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="w-full mr-2">
                    <div className="relative shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Description :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["description"] ? "placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                        id="description" name="description" placeholder="Title" value={this.state.fieldsAddUser.description} type="description" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["description"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsAddUser["description"]}
                        </div>
                        : null}
                    </div>
                  </div>


                  <div className="w-full mr-2">
                    <div className="relative rounded-md shadow-sm">
                      <label className="mt-2 mr-2 text-sm font-medium text-black">Publish Date :</label>
                      <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${this.state.errorsAddUser && !this.state.errorsAddUser["pub_date"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500 "}`}
                        id="pub_date" name="pub_date" placeholder="Author" value={this.state.fieldsAddUser.pub_date} type="date" onChange={this.inputAddUserChange} />
                      {this.state.errorsAddUser && this.state.errorsAddUser["pub_date"] ?
                        <div className="text-yellow-600 invalid-feedback">
                          {this.state.errorsAddUser["pub_date"]}
                        </div>
                        : null}
                    </div>
                  </div>


                </div>




                <div className="grid grid-cols-1 gap-4 mt-2 lg:grid-cols-2 w-full">
                  <div className="w-full mr-2">
                    {
                      this.state.fieldsAddUser && this.state.fieldsAddUser.imageUrlLink ?
                        <>
                          <div className="w-full mt-1 mr-2 ">
                            <img src={this.state.fieldsAddUser && this.state.fieldsAddUser.imageUrlLink ? this.state.fieldsAddUser.imageUrlLink : "NA"}
                              alt=" " height="20%" width="20%" />
                          </div>
                        </> :
                        null
                    }
                    <div className="mt-1 shadow-md w-full">
                      <div className="flex flex-wrap justify-between w-full p-2 border border-gray-300">
                        <div className="w-full">
                          <label class="block text-gray-700 text-sm" for="username"> News Image </label>
                          <input type="file" name="image" onChange={this.handleFile} />
                          <p class="block text-gray-700 text-sm" for="username">Note :<span>320 pixels – 500 pixels</span> </p>
                        </div>
                        <div className="w-20 mt-1 space-x-4">
                          <button className="px-4 py-1 text-base font-semibold text-white bg-blue-500 border rounded-lg cursor-pointer" type="button" onClick={() => this.handleUpload()} >Set</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-auto mt-6 w-60">
                  <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out border rounded-full bg-shine-400 border-shine-400 hover:bg-shine hover:text-white focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700" type="button" onClick={this.addNewsSubmit}>Submit</button>
                </div>

              </form>

            </div>
          </div>
        </div >

      </>

    );
  }
}
function mapStateToProps(state) {
  const { news, users, category } = state;
  return {
    news,
    users,
    category
  };

}
export default connect(mapStateToProps)(UpdateMatch);
