import { userConstants, teamsConstants } from '../_constants';
import { userService, teamsService } from '../_services';
import { alertActions } from './';
// import { toast } from 'react-toastify';
export const teamsActions = {
    // login,
    // logout,
    disableTeams,
    getAllTeams,
    createTeams,
    updateTeams,
    getTeamsList,
    // getAllCityByStateId,
    deleteTeams,
    uploadImageClear,
    getTeamsById
};


// function login(data, props) {
//     return dispatch => {
//         dispatch(request({ data }));
//         userService.login(data)
//             .then(
//                 user => {
//                     dispatch(success(user));
//                     props.history.push({ pathname: 'app/dashboard' });
//                 },
//                 error => {
//                     console.log("errorerror ", error);
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
//     function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
// }
// function logout() {
//     // userService.logout();
//     return { type: userConstants.LOGOUT };
// }

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createTeams(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamsService.createTeams(data)
            .then(

                team => {
                    dispatch(success(team));
                    dispatch(alertActions.success("Teams Add Successfully."));
                    dispatch(this.getTeamsList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.ADD_TEAMS_REQUEST } }
    function success(team) { return { type: teamsConstants.ADD_TEAMS_SUCCESS, team } }
    function failure(error) { return { type: teamsConstants.ADD_TEAMS_FAILURE, error } }
}
function updateTeams(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamsService.updateTeams(data)
            .then(

                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Teams Update Successfully."));
                    dispatch(this.getTeamsList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.UPDATE_TEAMS_REQUEST } }
    function success(users) { return { type: teamsConstants.UPDATE_TEAMS_SUCCESS, users } }
    function failure(error) { return { type: teamsConstants.UPDATE_TEAMS_FAILURE, error } }
}
function getAllTeams(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        teamsService.getAllTeams(data)
            .then(
                users => {
                    console.log("getAllTeams $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.GETALL_TEAMS_REQUEST } }
    function success(users) { return { type: teamsConstants.GETALL_TEAMS_SUCCESS, users } }
    function failure(error) { return { type: teamsConstants.GETALL_TEAMS_FAILURE, error } }
}
function getTeamsList(data) {
    return dispatch => {
        dispatch(request());
        teamsService.getTeamsList(data)
            .then(
                team => {
                    console.log("getTeamsList $$$$ action:", team);
                    dispatch(success(team));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.GET_LIST_TEAMS_REQUEST } }
    function success(team) { return { type: teamsConstants.GET_LIST_TEAMS_SUCCESS, team } }
    function failure(error) { return { type: teamsConstants.GET_LIST_TEAMS_FAILURE, error } }
}
// function getAllCityByStateId(data) {
//     return dispatch => {
//         dispatch(request());
//         teamsService.getAllCityByStateId(data)
//             .then(
//                 users => {
//                     console.log("getAllCityByStateId $$$$ action:", users);
//                     dispatch(success(users));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: teamsConstants.GET_ALL_CITY_BY_STATE_ID_REQUEST } }
//     function success(users) { return { type: teamsConstants.GET_ALL_CITY_BY_STATE_ID_SUCCESS, users } }
//     function failure(error) { return { type: teamsConstants.GET_ALL_CITY_BY_STATE_ID_FAILURE, error } }
// }

function deleteTeams(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        teamsService.deleteTeams(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTeamsList(paginationData));
                    dispatch(alertActions.success("Teams Delete Successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.DELETE_TEAMS_REQUEST } }
    function success(users) { return { type: teamsConstants.DELETE_TEAMS_SUCCESS, users } }
    function failure(error) { return { type: teamsConstants.DELETE_TEAMS_FAILURE, error } }
}

function getTeamsById(data) {

    return dispatch => {
        dispatch(request());
        teamsService.getTeamsById(data)
            .then(
                users => {
                    dispatch(success(users));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.GET_TEAMS_BY_ID_REQUEST } }
    function success(users) { return { type: teamsConstants.GET_TEAMS_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: teamsConstants.GET_TEAMS_BY_ID_FAILURE, error } }
}
function disableTeams(data, paginationData) {

    return dispatch => {
        dispatch(request());
        teamsService.disableTeams(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTeamsList(paginationData))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: teamsConstants.DISABLE_TEAMS_REQUEST } }
    function success(users) { return { type: teamsConstants.DISABLE_TEAMS_SUCCESS, users } }
    function failure(error) { return { type: teamsConstants.DISABLE_TEAMS_FAILURE, error } }
}
