import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { imageActions, userActions } from '../../../_actions';
import Modal from 'react-modal';
import {
  // Dialog,
  // AppBar,
  // Toolbar,
  // IconButton,
  // Container,
  // CssBaseline,
  // Grid,
  Typography,
  // Box
} from "@material-ui/core";


export default function ImageUploadModal(props) {
  const [optionUpload, setOptionUpload] = useState('selectImage')
  const [selectedFile, setSelectedFile] = useState(null)
  let data = {
    keyWord: "",
    pageNo: 1,
    size: 100
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(imageActions.ImageList(data));
  }, [])


  const imageData = useSelector(state => state.image.getImageList);
  console.log('imageDataimageDataimageDataimageDataimageData', imageData);
  const imageURL = useSelector(state => state && state.users && state.users.filesDetails && state.users.filesDetails.imageURL ? state.users.filesDetails.imageURL : null);

  let { imageUploadModal, hideModalImageUpload,
    // loginToThisAccountItems, uploadImageOption,
    selectImageUpload,
    //  errorsappsetting, inputChange, appSettingSubmit
  } = props;
  // console.log("loginToThisAccountItems data inside:::: ", loginToThisAccountItems);
  function openTab(URL) {
    window.open(URL);
  }

  const handleFile = (event) => {
    // console.log("handleFileevent",event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0])
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      // this.setState({ selectedFile: null });
      setSelectedFile(null)

    }
    else {
      console.log("No File To Upload!")
    }

  }

  console.log('imageUploadModal_______', imageUploadModal);
  return (

    <Modal
      isOpen={imageUploadModal}
      style={{ width: 100 }}
    // contentLabel="Example Modal"
    >


      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-8/12 h-[43rem] mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container h-8/12">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between pb-3">
              <p className="text-2xl font-bold">Image</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => hideModalImageUpload()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <div className="m-9">
              <Typography style={{ fontSize: "17px" }} component="h1" variant="h5">

                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => setOptionUpload('selectImage')}>
                  Select Image
                </button>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={() => setOptionUpload('uploadImage')}>
                  Upload Image
                </button>

              </Typography>
            </div>
            <div>

              {optionUpload && optionUpload == 'selectImage' ?
                <>
                  {imageData && imageData.length > 0 ?
                    <>
                      {imageData.map((element) => (
                        <div className="p-2 cursor-pointer border border-green-400 m-4" onClick={() => selectImageUpload(element.imageLinkUrl)}>
                          <span >
                            <img
                              src={element && element.imageLinkUrl} width='200' height='50'
                              alt="Grapefruit slice atop a pile of other slices"></img>
                          </span>
                        </div>

                      ))}
                    </>
                    :
                    null}
                </> :
                <>
                  <div>
                    {imageURL ?
                      <>
                        <div className="object-cover h-40 rounded-sm w-100">
                          <img src={imageURL ? imageURL : null}
                            alt=" " />
                          <div className="justify-center">
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full " onClick={() => selectImageUpload(imageURL)}>
                              Save
                            </button>
                          </div>
                        </div>
                      </> : null
                    }
                    <div className="flex flex-wrap w-full justify-center mt-60">
                      <label htmlFor="image">
                        Upload Image
                      </label>
                      <input
                        // style={{ display: 'none' }}
                        id="image"
                        name="image"
                        type="file"
                        onChange={handleFile}
                      />
                    </div>
                  </div>
                </>}
            </div>
            {/*Footer*/}
          </div>
        </div>
      </div>


    </Modal>



  );
}
