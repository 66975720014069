import React, { Component } from 'react';
import { connect } from 'react-redux';
import { categoryActions, userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import DialogExample from "./components/DialogExample/DialogExample";
import ViewMoreDetailsModal from "./components/ViewMoreDetailsModal/ViewMoreDetailsModal";
// import ViewloginToThisAccountModal from "./components/ViewloginToThisAccountModal/ViewloginToThisAccountModal";
import CreateAddCategoryModal from "./components/CreateAddCategoryModal/CreateAddCategoryModal";
import UpdateCategoryModal from "./components/UpdateCategoryModal/UpdateCategoryModal";
import { isMobile } from "react-device-detect";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { HiTrash, HiPencilAlt, HiOutlineViewGridAdd } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideo: false,
      isVideoUpdate: false,
      categoryRowData: {},
      fieldsCategory: {},
      errorsCategory: {},
      fieldsCategoryUpdate: {},
      errorsUpdateCategory: {},
      viewRowData: {},
      type: null,
      gameType: null,
      rankType: null,
      addUserCreateModal: false,
      moreDetailsCreateModal: false,
      UpdateCategoryCreateModal: false,
      loginToThisAccountModal: false,
      offset: 0,
      size: 10,
      pageNo: 1,
      keyWord: '',
      updatePwdformData: {
        "id": "",
        "password": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(categoryActions.getCategoryList(data));
    this.props.dispatch(categoryActions.getAllCategory());
    this.props.dispatch(categoryActions.getAllCategoryParent());
    // this.props.dispatch(categoryActions.createSport(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    console.log("STATIC______nextProps.category.addUserSuccess", nextProps.category.addUserSuccess);

    if (nextProps.category.addUserSuccess) {
      return {
        ...nextProps,
        updatePwdformData: {
          "id": "",
          "password": ""
        },
        fieldsCategory: {},
        errorsCategory: {},
        addUserCreateModal: false,
        UpdateCategoryCreateModal: false,

        type: null,
        gameType: null,
        rankType: null,

      }
    }
    // if (nextProps.category.updateUserSuccess) {
    //   return {
    //     ...nextProps,
    //     // updatePwdformData: {
    //     //   "id": "",
    //     //   "password": ""
    //     // },
    //     // fieldsCategory: {},
    //     // errorsCategory: {},
    //     // addUserCreateModal: false,
    //     UpdateCategoryCreateModal: false,

    //     type: null,
    //     gameType: null,
    //     rankType: null,

    //   }
    // } 

    else {
      return {
        ...nextProps,

      }
    }
  }
  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(categoryActions.getCategoryList(datatemp));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(categoryActions.getCategoryList(data));
  }
  disableUser = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to disable User?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(categoryActions.disableCategory(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  deleteCategory = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(categoryActions.deleteCategory(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  verifyEmail = (data) => {

    let datatemp = {
      "id": data.id,
    }
    let paginationData = {
      "userType": "ACTIVE",
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    confirmAlert({


      title: 'Confirm to verifyEmail?',
      message: `Are you sure to verifyEmail ${data.email} email?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(categoryActions.verifyEmail(datatemp, paginationData))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleOpenCreateModalMoreDetails = (data) => {
    // let req = {
    //   "id": data.id
    // }
    // this.props.dispatch(categoryActions.getProfile(req));
    this.setState({ moreDetailsCreateModal: true, categoryRowData: data });
  }

  handleMoreDetailsHideModal = () => {
    this.setState({ moreDetailsCreateModal: false });
  }

  handleOpenCreateModalUpdatePassword = (data) => {
    this.setState({ UpdateCategoryCreateModal: true, fieldsCategoryUpdate: data });
  }
  handleUpdatePasswordHideModal = () => {
    this.setState({ UpdateCategoryCreateModal: false });
  }

  handleloginToThisAccountHideModal = () => {
    this.setState({ loginToThisAccountModal: false });
  }
  handleOpenloginToThisAccountModal = (data) => {
    // console.log("data loginToThisAccountloginToThisAccountloginToThisAccount....", data);
    let req = {
      "id": data.id
    }
    this.props.dispatch(categoryActions.loginToThisAccount(req));
    this.setState({ loginToThisAccountModal: true });
  }
  handleAddUserHideModal = () => {
    this.setState({ addUserCreateModal: false });
    // this.setState({ appsettingUpdateModal: false });
  }

  handleOpenCreateModal = () => {
    // console.log("clicked");
    this.setState({ addUserCreateModal: true });
  }
  inputAddUserChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCategory = this.state.fieldsCategory;
    let errorsCategory = this.state.errorsCategory;
    fieldsCategory[name] = value;
    console.log(name, value);
    errorsCategory[name] = "";
    this.setState({ fieldsCategory, errorsCategory });
  }
  inputChangeUpdateCategory = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsCategoryUpdate = this.state.fieldsCategoryUpdate;
    let errorsUpdateCategory = this.state.errorsUpdateCategory;
    fieldsCategoryUpdate[name] = value;
    errorsUpdateCategory[name] = "";
    console.log(name, value);
    this.setState({ fieldsCategoryUpdate, errorsUpdateCategory });
  }

  createCategorySubmit = () => {

    let { users } = this.props;


    let { filesDetails,
      //  filesDetailsVideo
    } = users;

    if (this.handleValidationAddUser()) {
      let reqData = {
        "parentId": this.state.fieldsCategory.parentId ? this.state.fieldsCategory.parentId : null,
        "name": this.state.fieldsCategory.name,
        "shortName": this.state.fieldsCategory.shortName,
        "indexCategory": this.state.fieldsCategory.indexCategory,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : null
      }

      console.log("createCategory>>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqData);
      this.props.dispatch(categoryActions.createCategory(reqData));
    }

  }


  updateCategorySubmit = () => {
    let { users } = this.props;
    let { filesDetails } = users;

    if (this.handleValidationUpdateCategory()) {
      let reqData = {
        "id": this.state.fieldsCategoryUpdate.id,
        "parentId": !this.state.fieldsCategoryUpdate.parentId || this.state.fieldsCategoryUpdate.parentId === "NA" ? null : this.state.fieldsCategoryUpdate.parentId.id,
        "name": this.state.fieldsCategoryUpdate.name,
        "shortName": this.state.fieldsCategoryUpdate.shortName,
        "indexCategory": this.state.fieldsCategoryUpdate.indexCategory,
        "image": filesDetails && filesDetails.imageName ? filesDetails.imageName : this.state.fieldsCategoryUpdate.image

      }
      let paginationData = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      console.log("update>>>>>>>>>>>>>>>>>>>>>>>000000000", reqData);

      this.props.dispatch(categoryActions.updateCategory(reqData, paginationData));
    }

  }

  handleValidationUpdateCategory = () => {
    let fieldsCategoryUpdate = this.state.fieldsCategoryUpdate;
    let errorsUpdateCategory = {};
    let formIsValid = true;

    //name
    if (!fieldsCategoryUpdate["name"] || fieldsCategoryUpdate["name"] === "") {
      formIsValid = false;
      errorsUpdateCategory["name"] = "Cannot be empty";
    }

    this.setState({ errorsUpdateCategory: errorsUpdateCategory });
    return formIsValid;
  }

  handleValidationAddUser = () => {
    let fieldsCategory = this.state.fieldsCategory;
    let errorsCategory = {};
    let formIsValid = true;

    //name
    if (!fieldsCategory["name"] || fieldsCategory["name"] === "") {
      formIsValid = false;
      errorsCategory["name"] = "Cannot be empty name";
    }

    this.setState({ errorsCategory: errorsCategory });
    return formIsValid;
  }

  handleSelectType = (e) => {
    console.log("handleSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleSelectGameType = (e) => {
    console.log("handleSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleSelectRankType = (e) => {
    console.log("handleSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleUpdateSelectType = (e) => {
    console.log("handleUpdateSelectType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ type: value });
  }

  handleUpdateSelectGameType = (e) => {
    console.log("handleUpdateSelectGameType:::#", e.target.value);
    let { value } = e.target;
    this.setState({ gameType: value });
  }

  handleUpdateSelectRankType = (e) => {
    console.log("handleUpdateSelectRankType::::#", e.target.value);
    let { value } = e.target;
    this.setState({ rankType: value });
  }

  handleFile = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleFile2 = (event) => {
    console.log("handleFileevent", event);

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadVideo(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }



  inputChangeIsVideo = (e) => {
    e.preventDefault();
    this.setState({ isVideo: !this.state.isVideo });
  }

  inputChangeIsVideoUpdate = (e) => {
    e.preventDefault();
    this.setState({ isVideoUpdate: !this.state.isVideoUpdate });
  }

  render() {

    let { category, users } = this.props;
    let { items, total, loading, allCategory, allCategoryParent } = category;
    let { filesDetails } = users;
    console.log("render______total::::", total);

    return (

      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
          <main className="relative flex-1 ">
            <div className="p-3 2xl:p-10 sm:p-5">
              <div className="mx-auto max-w-screen-3xl ">
                {/* Transaction Section */}
                <section className="p-6 space-y-6 bg-white border rounded-md">

                  <div className="flex flex-wrap items-center justify-between w-full ">
                    <div className="">
                      <h3 className="text-xl font-semibold ">Category</h3>
                    </div>
                    
                    <div className='md:flex md:space-x-4 space-x-0'>
                      <form action="#" method="GET"  >
                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <BiSearch style={{ fontSize: '1.5rem' }} className="text-gray-500" />
                          </div>
                          <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search" onChange={this.handleSearch} required />
                        </div>
                      </form>
                      <button className="bg-blue-500 text-white py-2 w-64 text-sm px-6 mt-2 md:mt-0 rounded-md font-medium capitalize flex items-center justify-center " onClick={() => this.handleOpenCreateModal()}><MdAdd style={{ fontSize: "1.5rem" }} className="mr-1.5" /> Add Category </button>
                    </div>
                  </div>

                  <div className="pb-2 mt-4 overflow-hidden ">
                    <div className="max-w-full overflow-x-auto ">
                      <div className="inline-block min-w-full ">
                        <div className="">
                          <table className="min-w-full border-0 divide-y ">
                            <thead className="bg-gray-200">
                              <tr className="">
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">#</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Sub Category</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Category</th>
                                <th className="px-6 py-3 text-sm font-semibold text-left text-gray-500 uppercase whitespace-nowrap">Date/Time</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">image</th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Status </th>
                                <th className="px-6 py-3 text-sm font-semibold text-center text-gray-500 uppercase whitespace-nowrap">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                items && items.length > 0 ?
                                  items.map((element, index) => (<React.Fragment key={element.id}>
                                    <tr key={element.id} className="bg-white border-b border-black border-opacity-10 ">
                                      <td className="px-6 py-3 text-sm font-medium text-gray-600 whitespace-nowrap">
                                        {this.state.offset + index + 1}</td>

                                      <td className="px-6 py-3 text-sm font-medium text-gray-800 capitalize whitespace-nowrap" title={element.name} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {element.name && element.name.length > 50 ? element.name.substring(0, 25) + "..." : element.name}
                                      </td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{element && element.parentId && element.parentId.name ? element.parentId.name : "-"}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap">
                                        <div className='flex justify-center'>
                                          <img className='object-cover h-10 rounded-sm w-14 ' src={element && element.imageLinkUrl ? element.imageLinkUrl : "NA"} alt="not found" />
                                        </div>
                                      </td>


                                      <td className="px-6 py-3 text-sm text-gray-600 whitespace-nowrap ">
                                        <div class="flex justify-center">
                                          <label class="flex items-center cursor-pointer targetablepx-4 ">

                                            <div className='flex items-center p-1'>
                                              <button className={` ${element.isDisable?" bg-[#059669] hover:bg-emerald-600":" bg-red-500 hover:bg-red-700"}  text-white text-sm px-2 py-1.5 rounded-sm`} onClick={() => this.disableUser(element)}>{element.isDisable ? "Enable" : "Disable"}</button>
                                            </div>
                                
                                          </label>
                                        </div>
                                      </td>



                                      <td className="flex justify-center px-2 py-3 space-x-2 text-center text-gray-600 whitespace-nowrap">
                                        <div className='flex space-x-2'>
                                          <span className="relative ">
                                            {element.isActive ? "ADDED" : <div class="targetablepx-4 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer  " title='More Details' onClick={() => this.handleOpenCreateModalMoreDetails(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-6 text-sm text-white bg-gray-500 rounded tooltip-text'>More Details</span> */}
                                              <BiDetail style={{ fontSize: "1.5rem" }} />
                                            </div>}
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4  rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" title='Edit' onClick={() => this.handleOpenCreateModalUpdatePassword(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 text-sm text-white bg-gray-500 rounded tooltip-text'>Edit</span> */}

                                              <MdOutlineEdit style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>

                                          <span className="relative">
                                            <div class="targetablepx-4 rounded-full  font-medium    hover:bg-blue-100 cursor-pointer" title='Delete' onClick={() => this.deleteCategory(element)}>
                                              {/* <span className='top-0 left-0 p-6 mx-auto -mt-8 -ml-2 text-sm text-white bg-gray-500 rounded tooltip-text'>Delete</span> */}
                                              <RiDeleteBinLine style={{ fontSize: "1.5rem" }} />
                                            </div>
                                          </span>
                                        </div>
                                      </td>


                                    </tr>
                                  </React.Fragment>))
                                  : (<tr className="bg-white bg-opacity-5 " >
                                    <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Not found</td>
                                  </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Table Pagination Section */}
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            total && total > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>

        <DialogExample />

        <CreateAddCategoryModal
          addUserCreateModal={this.state.addUserCreateModal}
          fieldsCategory={this.state.fieldsCategory}
          errorsCategory={this.state.errorsCategory}
          allCategoryParent={allCategoryParent}
          inputAddUserChange={this.inputAddUserChange}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          createCategorySubmit={this.createCategorySubmit}
          handleSelectType={this.handleSelectType}
          handleSelectGameType={this.handleSelectGameType}
          handleSelectRankType={this.handleSelectRankType}
          handleAddUserHideModal={this.handleAddUserHideModal}
          inputChangeIsVideo={this.inputChangeIsVideo}
          isVideo={this.state.isVideo}
          filesDetails={filesDetails}
        />

        <UpdateCategoryModal
          UpdateCategoryCreateModal={this.state.UpdateCategoryCreateModal}
          fieldsCategoryUpdate={this.state.fieldsCategoryUpdate}
          errorsUpdateCategory={this.state.errorsUpdateCategory}
          allCategoryParent={allCategoryParent}
          inputChangeUpdateCategory={this.inputChangeUpdateCategory}
          updateCategorySubmit={this.updateCategorySubmit}
          allCategory={allCategory}
          handleFile={this.handleFile}
          handleFile2={this.handleFile2}
          handleUpdateSelectType={this.handleUpdateSelectType}
          handleUpdateSelectGameType={this.handleUpdateSelectGameType}
          handleUpdateSelectRankType={this.handleUpdateSelectRankType}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
          inputChangeIsVideoUpdate={this.inputChangeIsVideoUpdate}
          isVideoUpdate={this.state.isVideoUpdate}
          filesDetails={filesDetails}
        />

        <ViewMoreDetailsModal
          moreDetailsCreateModal={this.state.moreDetailsCreateModal}
          categoryRowData={this.state.categoryRowData}
          handleMoreDetailsHideModal={this.handleMoreDetailsHideModal}
        />

        {/* <ViewloginToThisAccountModal
          loginToThisAccountModal={this.state.loginToThisAccountModal}
          handleloginToThisAccountHideModal={this.handleloginToThisAccountHideModal}
        /> */}
      </>

    );
  }
}
function mapStateToProps(state) {
  const { category, users } = state;
  return {
    category,
    users
    // kyc,
    // authentication
  };
}
export default connect(mapStateToProps)(Category);
