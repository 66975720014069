import React from "react";
import { CgLogIn } from 'react-icons/cg';


export default function AddPlayerDetailsModal(props) {

  let { addPlayerModal,buttonDisabled,addRef, handleCloseAddPlayerModal, fieldsplayerAdd, errorsPlayerAdd, inputChangePlayerAdd, handleFile, filesDetails, playerAddSubmit } = props;

  return (
    <>
      <div className={addPlayerModal ? "fixed w-full lg:left-24 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} >
        <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb] md:w-[38rem] w-full mx-auto rounded shadow-lg overflow-y-auto mt-2">
          {/*modal header*/}
          <div className="flex items-center justify-between p-6 py-2 bg-[rgb(44,171,181)] border-b">
            <p className="text-[16px] lg:px-0 px-12 font-medium text-white uppercase">Add Player</p>
            <div className="rounded-full cursor-pointer modal-close ">
              <svg onClick={() => handleCloseAddPlayerModal()} className="fill-current text-white/60 hover:text-white/100" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>

          <div className="p-3">

            <form autoComplete="off" className="p-2 space-y-3 capitalize border border-gray-300 ">

              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">

                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">PlayerId * : </label>
                  <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500   ${errorsPlayerAdd && !errorsPlayerAdd["playerId"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                    id="playerId" name="playerId" placeholder="PlayerId" value={fieldsplayerAdd && fieldsplayerAdd.playerId ? fieldsplayerAdd.playerId : ''} type="text" onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["playerId"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["playerId"]}
                    </div>
                    : null}
                </div>

                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Name * :</label>
                  <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsPlayerAdd && !errorsPlayerAdd["name"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                    id="name" name="name" placeholder="name" value={fieldsplayerAdd && fieldsplayerAdd.name ? fieldsplayerAdd.name : ''} type="text" onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["name"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["name"]}
                    </div>
                    : null}
                </div>

                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Country * :</label>
                  <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsPlayerAdd && !errorsPlayerAdd["country"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                    id="country" name="country" placeholder="Country" value={fieldsplayerAdd && fieldsplayerAdd.country ? fieldsplayerAdd.country : ''} type="text" onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["country"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["country"]}
                    </div>
                    : null}
                </div>







                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Play Role * :</label>
                  <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsPlayerAdd && !errorsPlayerAdd["play_role"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                    id="play_role" name="play_role" placeholder="Play role" value={fieldsplayerAdd && fieldsplayerAdd.play_role ? fieldsplayerAdd.play_role : ''} type="text" onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["play_role"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["play_role"]}
                    </div>
                    : null}
                </div>


                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Rank :</label>
                  <input type="number" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsPlayerAdd && !errorsPlayerAdd["Rank"] ? "border  placeholder-gray-500" : "border border-red-500 "}`}
                    id="Rank" name="Rank" placeholder="Rank" value={fieldsplayerAdd && fieldsplayerAdd.Rank ? fieldsplayerAdd.Rank : ''}  onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["Rank"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["Rank"]}
                    </div>
                    : null}
                </div>

                <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Rating :</label>
                  <input type="number" className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsPlayerAdd && !errorsPlayerAdd["Rating"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                    id="Rating" name="Rating" placeholder="Rating" value={fieldsplayerAdd && fieldsplayerAdd.Rating ? fieldsplayerAdd.Rating : ''}  onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["Rating"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["Rating"]}
                    </div>
                    : null}
                </div>

              
              </div>

              <div className="w-full">
                  <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Description :</label>
                  <input className={`w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsPlayerAdd && !errorsPlayerAdd["description"] ? "border  placeholder-gray-500" : "border  border-red-500 "}`}
                    id="description" name="description" placeholder="description" value={fieldsplayerAdd && fieldsplayerAdd.description ? fieldsplayerAdd.description : ''} type="text" onChange={inputChangePlayerAdd} />
                  {errorsPlayerAdd && errorsPlayerAdd["description"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["description"]}
                    </div>
                    : null}
                </div>



              <div className="flex pt-4 space-x-4">
                {
                  filesDetails && filesDetails.imageLinkUrl ?
                    <>
                      <div className="w-full mt-1 mr-2 ">
                        <img src={filesDetails && filesDetails.imageLinkUrl ? filesDetails.imageLinkUrl : "NA"}
                          alt=" " height="20%" width="20%" />
                      </div>
                    </> :
                    null
                }
              </div>

              <div className="flex flex-wrap w-full ">
                <label htmlFor="image">
                  Upload Image
                </label>
                <input id="image" ref={addRef} name="image" type="file" onChange={handleFile} />
                {errorsPlayerAdd && errorsPlayerAdd["img"] ?
                    <div className="text-xs text-red-500 ">
                      {errorsPlayerAdd["img"]}
                    </div>
                    : null}
              </div>


              <div className="flex justify-end py-0 space-x-4">
                <button disabled={buttonDisabled} className="bg-[rgb(44,171,181)] transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
                  onClick={() => playerAddSubmit()}>Submit
                  <CgLogIn size={18} className="ml-2" />
                </button>
              </div>
            </form>

          </div>
          {/*Footer*/}

        </div >
      </div >

    </>

  );
}
