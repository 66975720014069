export const matchMoreDetailConstants = {


    DELETE_INNING_REQUEST:"DELETE_INNING_REQUEST",
    DELETE_INNING_SUCCESS:"DELETE_INNING_SUCCESS",
    DELETE_INNING_FAILURE:"DELETE_INNING_FAILURE",


    UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_REQUEST: 'UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_REQUEST',
    UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_SUCCESS: 'UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_SUCCESS',
    UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_FAILURE: 'UPDATE_MATCH_STATUS_FOR_MATCH_STATUS_FAILURE',

    GET_LIVE_MATCH_BATSMAN_BY_ID_REQUEST: 'GET_LIVE_MATCH_BATSMAN_BY_ID_REQUEST',
    GET_LIVE_MATCH_BATSMAN_BY_ID_SUCCESS: 'GET_LIVE_MATCH_BATSMAN_BY_ID_SUCCESS',
    GET_LIVE_MATCH_BATSMAN_BY_ID_FAILURE: 'GET_LIVE_MATCH_BATSMAN_BY_ID_FAILURE',

    GET_LAST_TWENTY_BALL_DATA_REQUEST: 'GET_LAST_TWENTY_BALL_DATA_REQUEST',
    GET_LAST_TWENTY_BALL_DATA_SUCCESS: 'GET_LAST_TWENTY_BALL_DATA_SUCCESS',
    GET_LAST_TWENTY_BALL_DATA_FAILURE: 'GET_LAST_TWENTY_BALL_DATA_FAILURE',

    GET_LIVE_MATCH_BOWLER_BY_ID_REQUEST: 'GET_LIVE_MATCH_BOWLER_BY_ID_REQUEST',
    GET_LIVE_MATCH_BOWLER_BY_ID_SUCCESS: 'GET_LIVE_MATCH_BOWLER_BY_ID_SUCCESS',
    GET_LIVE_MATCH_BOWLER_BY_ID_FAILURE: 'GET_LIVE_MATCH_BOWLER_BY_ID_FAILURE',

    GET_ROLL_BACK_RUN_DATA_REQUEST: 'GET_ROLL_BACK_RUN_DATA_REQUEST',
    GET_ROLL_BACK_RUN_DATA_SUCCESS: 'GET_ROLL_BACK_RUN_DATA_SUCCESS',
    GET_ROLL_BACK_RUN_DATA_FAILURE: 'GET_ROLL_BACK_RUN_DATA_FAILURE',

    UPDATE_FANCY_RESULT_DECLARE_REQUEST: 'UPDATE_FANCY_RESULT_DECLARE_REQUEST',
    UPDATE_FANCY_RESULT_DECLARE_SUCCESS: 'UPDATE_FANCY_RESULT_DECLARE_SUCCESS',
    UPDATE_FANCY_RESULT_DECLARE_FAILURE: 'UPDATE_FANCY_RESULT_DECLARE_FAILURE',

    UPDATE_MATCH_INFO_REQUEST: 'UPDATE_MATCH_INFO_REQUEST',
    UPDATE_MATCH_INFO_SUCCESS: 'UPDATE_MATCH_INFO_SUCCESS',
    UPDATE_MATCH_INFO_FAILURE: 'UPDATE_MATCH_INFO_FAILURE',

    GETALL_MATCH_MORE_DETAIL_REQUEST: 'GETALL_MATCH_MORE_DETAIL_REQUEST',
    GETALL_MATCH_MORE_DETAIL_SUCCESS: 'GETALL_MATCH_MORE_DETAIL_SUCCESS',
    GETALL_MATCH_MORE_DETAIL_FAILURE: 'GETALL_MATCH_MORE_DETAIL_FAILURE',

    GET_LIST_MATCH_MORE_DETAIL_REQUEST: 'GET_LIST_MATCH_MORE_DETAIL_REQUEST',
    GET_LIST_MATCH_MORE_DETAIL_SUCCESS: 'GET_LIST_MATCH_MORE_DETAIL_SUCCESS',
    GET_LIST_MATCH_MORE_DETAIL_FAILURE: 'GET_LIST_MATCH_MORE_DETAIL_FAILURE',

    GET_LIST_BATSMAN_REQUEST: 'GET_LIST_BATSMAN_REQUEST',
    GET_LIST_BATSMAN_SUCCESS: 'GET_LIST_BATSMAN_SUCCESS',
    GET_LIST_BATSMAN_FAILURE: 'GET_LIST_BATSMAN_FAILURE',

    GET_LIVE_MATCH_RUN_REQUEST: 'GET_LIVE_MATCH_RUN_REQUEST',
    GET_LIVE_MATCH_RUN_SUCCESS: 'GET_LIVE_MATCH_RUN_SUCCESS',
    GET_LIVE_MATCH_RUN_FAILURE: 'GET_LIVE_MATCH_RUN_FAILURE',

    GET_LIVE_MATCH_EXTRA_RUNS_REQUEST: 'GET_LIVE_MATCH_EXTRA_RUNS_REQUEST',
    GET_LIVE_MATCH_EXTRA_RUNS_SUCCESS: 'GET_LIVE_MATCH_EXTRA_RUNS_SUCCESS',
    GET_LIVE_MATCH_EXTRA_RUNS_FAILURE: 'GET_LIVE_MATCH_EXTRA_RUNS_FAILURE',

    GET_LIST_TEAM_REQUEST: 'GET_LIST_TEAM_REQUEST',
    GET_LIST_TEAM_SUCCESS: 'GET_LIST_TEAM_SUCCESS',
    GET_LIST_TEAM_FAILURE: 'GET_LIST_TEAM_FAILURE',

    GET_MATCHINFO_REQUEST: 'GET_MATCHINFO_REQUEST',
    GET_MATCHINFO_SUCCESS: 'GET_MATCHINFO_SUCCESS',
    GET_MATCHINFO_FAILURE: 'GET_MATCHINFO_FAILURE',

    GET_CURRENT_STATUS_REQUEST: 'GET_CURRENT_STATUS_REQUEST',
    GET_CURRENT_STATUS_SUCCESS: 'GET_CURRENT_STATUS_SUCCESS',
    GET_CURRENT_STATUS_FAILURE: 'GET_CURRENT_STATUS_FAILURE',

    GET_AB_TEAM_REQUEST: 'GET_AB_TEAM_REQUEST',
    GET_AB_TEAM_SUCCESS: 'GET_AB_TEAM_SUCCESS',
    GET_AB_TEAM_FAILURE: 'GET_AB_TEAM_FAILURE',

    GET_CURRENT_TEAM_REQUEST: 'GET_CURRENT_TEAM_REQUEST',
    GET_CURRENT_TEAM_SUCCESS: 'GET_CURRENT_TEAM_SUCCESS',
    GET_CURRENT_TEAM_FAILURE: 'GET_CURRENT_TEAM_FAILURE',

    GET_MANNUAL_TEAM_REQUEST: 'GET_MANNUAL_TEAM_REQUEST',
    GET_MANNUAL_TEAM_SUCCESS: 'GET_MANNUAL_TEAM_SUCCESS',
    GET_MANNUAL_TEAM_FAILURE: 'GET_MANNUAL_TEAM_FAILURE',

    GET_STATUS_TEAM_REQUEST: 'GET_STATUS_TEAM_REQUEST',
    GET_STATUS_TEAM_SUCCESS: 'GET_STATUS_TEAM_SUCCESS',
    GET_STATUS_TEAM_FAILURE: 'GET_STATUS_TEAM_FAILURE',

    GET_CURRENT_INNIGNS_REQUEST: 'GET_CURRENT_INNIGNS_REQUEST',
    GET_CURRENT_INNIGNS_SUCCESS: 'GET_CURRENT_INNIGNS_SUCCESS',
    GET_CURRENT_INNIGNS_FAILURE: 'GET_CURRENT_INNIGNS_FAILURE',

    GET_LIST_BOWLER_REQUEST: 'GET_LIST_BOWLER_REQUEST',
    GET_LIST_BOWLER_SUCCESS: 'GET_LIST_BOWLER_SUCCESS',
    GET_LIST_BOWLER_FAILURE: 'GET_LIST_BOWLER_FAILURE',

    ADD_MATCH_MORE_DETAIL_REQUEST: 'ADD_MATCH_MORE_DETAIL_REQUEST',
    ADD_MATCH_MORE_DETAIL_SUCCESS: 'ADD_MATCH_MORE_DETAIL_SUCCESS',
    ADD_MATCH_MORE_DETAIL_FAILURE: 'ADD_MATCH_MORE_DETAIL_FAILURE',

    ADD_BATSMAN_DETAIL_REQUEST: 'ADD_BATSMAN_DETAIL_REQUEST',
    ADD_BATSMAN_DETAIL_SUCCESS: 'ADD_BATSMAN_DETAIL_SUCCESS',
    ADD_BATSMAN_DETAIL_FAILURE: 'ADD_BATSMAN_DETAIL_FAILURE',

    ADD_BOWLER_DETAIL_REQUEST: 'ADD_BOWLER_DETAIL_REQUEST',
    ADD_BOWLER_DETAIL_SUCCESS: 'ADD_BOWLER_DETAIL_SUCCESS',
    ADD_BOWLER_DETAIL_FAILURE: 'ADD_BOWLER_DETAIL_FAILURE',

    DELETE_MATCH_MORE_DETAIL_REQUEST: 'DELETE_MATCH_MORE_DETAIL_REQUEST',
    DELETE_MATCH_MORE_DETAIL_SUCCESS: 'DELETE_MATCH_MORE_DETAIL_SUCCESS',
    DELETE_MATCH_MORE_DETAIL_FAILURE: 'DELETE_MATCH_MORE_DETAIL_FAILURE',

    DELETE_BATSMEN_DETAIL_REQUEST: 'DELETE_BATSMEN_DETAIL_REQUEST',
    DELETE_BATSMEN_DETAIL_SUCCESS: 'DELETE_BATSMEN_DETAIL_SUCCESS',
    DELETE_BATSMEN_DETAIL_FAILURE: 'DELETE_BATSMEN_DETAIL_FAILURE',

    CANCEL_BATSMEN_DETAIL_REQUEST: 'CANCEL_BATSMEN_DETAIL_REQUEST',
    CANCEL_BATSMEN_DETAIL_SUCCESS: 'CANCEL_BATSMEN_DETAIL_SUCCESS',
    CANCEL_BATSMEN_DETAIL_FAILURE: 'CANCEL_BATSMEN_DETAIL_FAILURE',

    DELETE_BOWLER_DETAIL_REQUEST: 'DELETE_BOWLER_DETAIL_REQUEST',
    DELETE_BOWLER_DETAIL_SUCCESS: 'DELETE_BOWLER_DETAIL_SUCCESS',
    DELETE_BOWLER_DETAIL_FAILURE: 'DELETE_BOWLER_DETAIL_FAILURE',

    UPDATE_MATCH_MORE_DETAIL_REQUEST: 'UPDATE_MATCH_MORE_DETAIL_REQUEST',
    UPDATE_MATCH_MORE_DETAIL_SUCCESS: 'UPDATE_MATCH_MORE_DETAIL_SUCCESS',
    UPDATE_MATCH_MORE_DETAIL_FAILURE: 'UPDATE_MATCH_MORE_DETAIL_FAILURE',

    DISABLE_MATCH_MORE_DETAIL_REQUEST: 'DISABLE_MATCH_MORE_DETAIL_REQUEST',
    DISABLE_MATCH_MORE_DETAIL_SUCCESS: 'DISABLE_MATCH_MORE_DETAIL_SUCCESS',
    DISABLE_MATCH_MORE_DETAIL_FAILURE: 'DISABLE_MATCH_MORE_DETAIL_FAILURE',

    GET_MATCH_MORE_DETAIL_BY_ID_REQUEST: 'GET_MATCH_MORE_DETAIL_BY_ID_REQUEST',
    GET_MATCH_MORE_DETAIL_BY_ID_SUCCESS: 'GET_MATCH_MORE_DETAIL_BY_ID_SUCCESS',
    GET_MATCH_MORE_DETAIL_BY_ID_FAILURE: 'GET_MATCH_MORE_DETAIL_BY_ID_FAILURE',

    GET_FANCY_LIST_FOR_FANCY_RESULT_REQUEST: 'GET_FANCY_LIST_FOR_FANCY_RESULT_REQUEST',
    GET_FANCY_LIST_FOR_FANCY_RESULT_SUCCESS: 'GET_FANCY_LIST_FOR_FANCY_RESULT_SUCCESS',
    GET_FANCY_LIST_FOR_FANCY_RESULT_FAILURE: 'GET_FANCY_LIST_FOR_FANCY_RESULT_FAILURE',

    GET_ALL_CITY_BY_STATE_ID_REQUEST: 'GET_ALL_CITY_BY_STATE_ID_REQUEST',
    GET_ALL_CITY_BY_STATE_ID_SUCCESS: 'GET_ALL_CITY_BY_STATE_ID_SUCCESS',
    GET_ALL_CITY_BY_STATE_ID_FAILURE: 'GET_ALL_CITY_BY_STATE_ID_FAILURE',

};
