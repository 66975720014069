
import { authHeader } from '../_helpers';
import { CONST } from '../_config';


export const newsService = {
    login,
    logout,
    // verifyEmail,
    // loginToThisAccount,
    getAllNews,
    createNews,
    createVenues,
    updateNews,
    updateVenues,
    getNewsList,
    getNewsById,
    getVenuesById,
    deleteNews,
    disableNews,
    createNotification,
    getNotificationList,
    uploadImage,
    disableActivePtiNews,
    updateNewsPti,
    getNewsListManual,
    disableActiveManualNews

};
function logout() {
    // localStorage.removeItem('adminuser');
    // window.location.href = "#/login";
    // history.push(`#/login`);
    // window.location.reload();


}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadImage`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            console.log("servive_uploadImage", userObj);
            return userObj;
        });
}


function getVenuesById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getVenuesById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getVenuesById: data.data
            }
            console.log("i am in service getVenuesById", userObj);

            return userObj;
        });
}
function getNewsById(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNewsById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNewsById: data.data
            }
            console.log("i am in service getNewsById", userObj);

            return userObj;
        });
}
function getNotificationList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNotificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNotificationList: data.data
            }
            console.log("i am in service getNotificationList", userObj);

            return userObj;
        });
}
function getNewsList(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // return fetch(CONST.BACKEND_URL + `/getNewsList`, requestOptions)
    // return fetch(CONST.BACKEND_URL + `/getNewsListNew`, requestOptions)
    return fetch(CONST.BACKEND_URL + `/getNewsListNewPTI`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNewsList: data.data
            }
            console.log("i am in service getNewsList", userObj);

            return userObj;
        });
}

function getNewsListManual(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNewsList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getNewsListManual: data.data
            }
            console.log("i am in service getNewsListManual", userObj);

            return userObj;
        });
}

function disableActiveManualNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateManualNewsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateManualNewsStatus: data.data
            }
            console.log("i am in service''...>> updateManualNewsStatus ::", userObj);

            return userObj;
        });
}

function disableActivePtiNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePtiNewsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updatePtiNewsStatus: data.data
            }
            console.log("i am in service''...>> updatePtiNewsStatus ::", userObj);

            return userObj;
        });
}

function getAllNews(data) {
    // console.log("data m kya aa rha h::service:::", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllNews`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getAllNews: data.data
            }
            console.log("i am in service getAllNews", userObj);

            return userObj;
        });
}

// function verifyEmail(data) {
//     let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch(CONST.BACKEND_URL + `/validateOtpAdmin`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let userObj = {
//                 verifyEmail: data.data
//             }
//             console.log("i am in service''...>> verifyEmail ::", userObj);

//             return userObj;
//         });
// }

function deleteNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteNews`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteNews: data.data
            }
            console.log("i am in service''...>> deleteNews ::", userObj);

            return userObj;
        });
}
function disableNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNewsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                updateNewsStatus: data.data
            }
            console.log("i am in service''...>> updateNewsStatus ::", userObj);

            return userObj;
        });
}

function updateNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNews`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateNews: data.data
            }
            console.log("I am in service updateNews", userObj);

            return userObj;
        });
}

function updateVenues(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateVenues`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateVenues: data.data
            }
            console.log("I am in service updateVenues", userObj);

            return userObj;
        });
}

function updateNewsPti(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNews`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                updateNewsPti: data.data
            }
            console.log("I am in service updateNewsPti", userObj);

            return userObj;
        });
}

function createVenues(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createVenues`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createVenues: data.data
            }
            console.log("I am in service createVenues", userObj);

            return userObj;
        });
}

function createNews(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createNews`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNews: data.data
            }
            console.log("I am in service createNews", userObj);

            return userObj;
        });
}
function createNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createNotification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNotification: data.data
            }
            console.log("I am in service createNotification", userObj);

            return userObj;
        });
}









function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}