import { userConstants, imageConstants } from '../_constants';
import { userService, imageService } from '../_services';
import { userActions } from '../_actions';
// import { Service, categoryService } from '../_services/category.service';

import { alertActions, categoryActions } from '.';
import { toast } from 'react-toastify';
export const imageActions = {

    login,
    logout,
    disableImage,
    getAllImage,
    createImage,
    updateImage,
    ImageList,
    getImageById,
    // getAllCategory,
    deleteImage,
    uploadImageClear,
    createNotification,
    getNotificationList,
    uploadImage
};

function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    // userService.logout();
    return { type: userConstants.LOGOUT };
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createImage(data, paginationData) {
    // let reqData = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        imageService.createImage(data)
            .then(

                users => {
                    dispatch(alertActions.success("Image Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                    dispatch(this.ImageList(paginationData));
                


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.ADD_IMAGE_REQUEST } }
    function success(users) { return { type: imageConstants.ADD_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.ADD_IMAGE_FAILURE, error } }
}

function createNotification(data, paginationData) {
    return dispatch => {
        dispatch(request());
        imageService.createNotification(data)
            .then(

                users => {
                    // toast("Password Updated successfully.")
                    dispatch(alertActions.success("Notification Add Successfully."));
                    dispatch(success(users));
                    dispatch(this.getNotificationList(paginationData));
                    // dispatch(this.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.ADD_NOTIFICATION_REQUEST } }
    function success(users) { return { type: imageConstants.ADD_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.ADD_NOTIFICATION_FAILURE, error } }
}
function getNotificationList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        imageService.getNotificationList(data)
            .then(
                users => {
                    console.log("getNotificationList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.GET_LIST_NOTIFICATION_REQUEST } }
    function success(users) { return { type: imageConstants.GET_LIST_NOTIFICATION_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.GET_LIST_NOTIFICATION_FAILURE, error } }
}
function updateImage(data, paginationData) {

    return dispatch => {
        dispatch(request());
        imageService.updateImage(data)
            .then(

                users => {
                    dispatch(alertActions.success("Image Update Successfully."));
                    dispatch(success(users));
                    dispatch(this.uploadImageClear());
                    dispatch(this.ImageList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.UPDATE_IMAGE_REQUEST } }
    function success(users) { return { type: imageConstants.UPDATE_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.UPDATE_IMAGE_FAILURE, error } }
}

function getAllImage(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        imageService.getAllImage(data)
            .then(
                users => {
                    console.log("getAllImage $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.GETALL_IMAGE_REQUEST } }
    function success(users) { return { type: imageConstants.GETALL_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.GETALL_IMAGE_FAILURE, error } }
}
function getImageById(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        imageService.getImageById(data)
            .then(
                users => {
                    console.log("getImageById $$$$ action:", users);
                    dispatch(success(users));
                    // 8888888


                    //  dispatch(categoryActions.getSubCategoryById(error));
                    dispatch(categoryActions.getSubCategoryByIdTemp({
                        shortName: users.getImageById.category
                    }));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.GET_LIST_IMAGE_BY_ID_REQUEST } }
    function success(users) { return { type: imageConstants.GET_LIST_IMAGE_BY_ID_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.GET_LIST_IMAGE_BY_ID_FAILURE, error } }
}
function ImageList(data) {
    // console.log("data m kya aa rha h::action:::", data);
    return dispatch => {
        dispatch(request());
        imageService.ImageList(data)
            .then(
                users => {
                    console.log("ImageList $$$$ action:", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.GET_LIST_IMAGE_REQUEST } }
    function success(users) { return { type: imageConstants.GET_LIST_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.GET_LIST_IMAGE_FAILURE, error } }
}

function deleteImage(data, paginationData) {

    return dispatch => {
        dispatch(request());
        imageService.deleteImage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.ImageList(paginationData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.DELETE_IMAGE_REQUEST } }
    function success(users) { return { type: imageConstants.DELETE_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.DELETE_IMAGE_FAILURE, error } }
}
function disableImage(data, paginationData) {

    return dispatch => {
        dispatch(request());
        imageService.disableImage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.ImageList(paginationData));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: imageConstants.DISABLE_IMAGE_REQUEST } }
    function success(users) { return { type: imageConstants.DISABLE_IMAGE_SUCCESS, users } }
    function failure(error) { return { type: imageConstants.DISABLE_IMAGE_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        imageService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: imageConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: imageConstants.FILE_UPLOAD_STATUS_FAILURE, error } }

}